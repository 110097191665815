import { HAS_EDIT_ACCESS } from 'constants/index';

/**
 * Metodo para revisar la ruta, este metodo checa que solo entre a editar usuario en caso de venir de su correspondiente view de vista
 *  NOTA: Revisar si se quiere este guard para las rutas de editar
 * 
 * @param {Object} to - Objeto con los datos de la ruta  la que vamos, propiedad puesta por "react-router-guards" el objeto tiene (history, location, match, meta, staticContext)
 * @param {Object} to.meta - del objeto que es dado por "react-router-guards" aqui es donde nosotros ponemos nuestra informacion
 * @param {Object} to.meta[] - cada key de este objeto (excluyendo 'data') representa el guard al que esta dirigidos,
 * @param {Object} to.meta.data - objeto con informacion adicional necesitada por el metod en este caso el token
 * @param {Object} from - object data of pairs key value to use for replace on enpoint 
 * @param {function} next - Function que nos llevara a nuestra ruta, o hara la redirección
 * 
*/
const hasEditAccess = (to, from, next) => {
   // console.log("hasEditAccess", to, from);
    if(!to.meta[HAS_EDIT_ACCESS]){
      next();
      return;
    }

    if(from.location.pathname === to.meta.accessPage) {
      next();
    }else{
      next.redirect(to.meta.accessPage);
    }
  };

export default hasEditAccess;