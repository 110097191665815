import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import ModalContainer from 'containers/general/ModalContainer/ModalContainer';

import HeadComponent from '../general/Head';
import ContentComponent from './ContentComponent';
import ShareQr from '../general/Modals/shareQr/shareQr.js';
import useStyles from "../../style";
import './styles.css';



const Dashboard = (props) => {
  const { headInfo, language, handleDownloadChart, dashboardData, handleOpenModal, modalData, openModal, donwloading} = props;
  const classes = useStyles();

  /**
   * Método para renderizar el dashboardEmpty
   */
  const dashboardEmpty = () => {
    return <>
      <Grid container className="emptyBody">
          <h5>{language.dashboard.empty_title}</h5>
          <p>{language.dashboard.empty_text}</p>
          <Button id="ShareLink" type="submit" className="btn"  onClick={() => {
                handleOpenModal(ShareQr);
              }}>
              {language.dashboard.share_link}
          </Button>
      </Grid>
      <ModalContainer id="modalContainer" classes={classes}
        handleOpenModal={handleOpenModal}
        modalData={modalData}
        openContainer={openModal}/>
    </>
  }

  /**
   * Método para renderizar el dashboard cuando tuvo errores al obtener los datos del servidor
   */
  const dashboardDataError = () => {
    return <>
      <Grid container className="emptyBody">
          <h5>{language.dashboard.error_title}</h5>
          <p>{language.dashboard.error_text}</p>
      </Grid>
    </>
  }

  return dashboardData.total > 0 ? 
      <Typography component={'div'} style={{height: "100%"}}>
        <HeadComponent 
          headInfo={headInfo}
          language={language}
        />
        <ContentComponent language={language} 
          donwloading={donwloading}
          handleDownloadChart={handleDownloadChart}
          dashboardData={dashboardData} />
      </Typography>
      :
       dashboardData.error ? dashboardDataError() : dashboardEmpty();
};

Dashboard.propTypes = {
  headInfo: PropTypes.shape({                     //Objeto con la información del header
    title: PropTypes.string.isRequired,           //Titulo que se mostrará en header
    subtitle: PropTypes.string.isRequired         //Subtitulo que se mostrará en header
  }),
  handleDownloadChart: PropTypes.func.isRequired, //Func. para el evento del boton descargar
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  dashboardData: PropTypes.object,                //Variable con la informacion de las tablas obtenidad de backEnd
  modalData: PropTypes.shape({                    //Información a para mandar al modalContainer, para generar el contenido
    ModalSelected: PropTypes.elementType,         //Modal Component to render in modal Container
    modalInfo: PropTypes.object                   //Objeto con datos a usar en el modal, el objeto es especifico por cada modalComponent por lo que puede ser opcional y diferente entre cada uno
  }),
  handleOpenModal: PropTypes.func.isRequired,     //Func. para llamar al evento de abrir o cerrar el modal
  openModal: PropTypes.bool.isRequired,           //Nos dice si el modal esta abierto.
  donwloading: PropTypes.shape({                  //Grupo de datos que nos dice si se descargada la tabla
    pie: PropTypes.bool.isRequired,               //Variable con la que sabremos si la table de tipo pie se esta descargando
    bubble: PropTypes.bool.isRequired,            //Variable con la que sabremos si la table de tipo pie se esta descargando
    line: PropTypes.bool.isRequired,              //Variable con la que sabremos si la table de tipo pie se esta descargando
    geo: PropTypes.bool.isRequired,               //Variable con la que sabremos si la table de tipo pie se esta descargando
  }),
};

export default Dashboard;