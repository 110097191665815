import { CONTROL_INVALID } from "constants/index";

/**
 * Método para obtener el valor correcto y traducido del resultado de la enfermedad analizada en el registro
 * 
 * @param {Object} register - Register from whom the change will be made
 * @param {Object} language - Object with langauge selected
 * 
 * @returns {string} string traducido al idioma seleccionado del resultado
*/
export function transformResultLanguage(register, language) {
    if(register.control === CONTROL_INVALID || !register.control){
        return language.results.cardView.result_option2;
    }
    switch (register.disease[0].result) {
        case "Negative": case "negative":
        return language.results.cardView.result_option4;
        case "Positive": case "positive":
        return language.results.cardView.result_option3;
        default:
        return language.results.cardView.result_option2;
    }
}

/**
 * Método para obtener el valor correcto y traducido de la enfermedad analizada en el registro
 * 
 * @param {string} disease - Nombre de la enfermedad a cambiar
 * @param {Object} language - Object with langauge selected
 * 
 * @returns {string} string traducido al idioma seleccionado del resultado
*/
export function transformDiseaseLanguage(disease, language) {
    switch (disease) {
        case "COVID19": case "COVID-19":
        return language.results.cardView.disease_option2;
        case "Tuberculosis (Tb)":
        return language.results.cardView.disease_option3;
        default:
        return language.results.cardView.disease_option2;
    }
}

/**
 * Método para obtener el valor correcto y traducido de la genero guardado en el registro
 * 
 * @param {string} gender - Genero guardado en el registro, dato a cambiar
 * @param {Object} language - Object with langauge selected
 * 
 * @returns {string} string traducido al idioma seleccionado del genero
*/
export function transformGenderLanguage(gender, language) {
    switch (gender) {
        case "Male": case "male":
        return language.results.cardView.gender_option2;
        case "Female": case "female":
        return language.results.cardView.gender_option3;
        default:
        return language.results.cardView.gender_option2;
    }
}

/**
 * Método para cambiar la region al formato deseado
 * 
 * @param {string} region - Region, ubicacion de la prueba 
 * @param {Object} language - Object with langauge selected
 * 
 * @returns {string} string traducido al idioma seleccionado 
*/
export function transformRegionLanguage(region, language) {
  if(region === "Unknown, Unknown" || region === "Unknown,Unknown" || !region){
    return language.results.withoutRegion;
  }

  if( region.includes("Unknown,") || region.includes(",Unknown") ){
    let correctRegion = region.replace(",Unknown", "");
    correctRegion = correctRegion.replace("Unknown,", language.results.withoutRegion + ",");
    return correctRegion;
  }

  return region;
}

/**
 * Método que transforma el array de objetos "results" en un string necesario 
 * para crear el csv,
 * 
 * @param {Object} props - Objeto con los datos del componente
 * @param {Array<Object>} props.results - lista de objetos con los resultados a mostrar en el csv
 * @param {Object} props.language - Objeto con las variables del idioma seleccionado
 * @param {Object} props.columnsToShow - Objeto que nos dira que columnas serán mostradas
 * 
 * @returns {string} string transformado para crear el csv
*/
export function transformToCsvData({results, language, columnsToShow }) {
    const csvHeaders = [language.results.col_qrcode, language.results.col_result];
    let separator = ",";
    let stringJson = language.results.col_qrcode + separator;
    stringJson += language.results.col_result + separator;
  
    for (let column of Object.keys(columnsToShow)) {
      if (columnsToShow[column]) {
        let stringName = "col_" + column;
  
        stringJson += language.results[stringName] + separator;
        csvHeaders.push(language.results[stringName]);
      }
    }
  
    stringJson += '\n';
    for (let result of results) {
      stringJson += '="' + result.qrCode + '"' + separator;
      stringJson += result.disease[0].result + separator;
  
      if (columnsToShow.sickness)
        stringJson += result.disease[0].name + separator;
  
      if (columnsToShow.date)
        stringJson += result.createdAt + separator;
  
      if (columnsToShow.gender) {
        stringJson += result.gender + separator;
      }
  
      if (columnsToShow.age)
        stringJson += result.age + separator;
    
      if (columnsToShow.region)
        stringJson += '"' + result.region + '"' + separator;

      if (columnsToShow.userId)
        stringJson += '"' + result.userId + '"' + separator;
  
      stringJson += "\n";
    }
  
    return stringJson
  }
  