import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import UserProfile from 'components/UserProfile';
import { getLanguage, getCurrentUser } from 'selectors';
import { renderSnackBarMessage, renderLoader } from 'components/general/commonRenders';
import { setAuthentication, updateUser, fetchUser, setLanguages } from 'actions';
import PerfectScrollbar from 'react-perfect-scrollbar';

class ProfileContainer extends Component {

  constructor(props){
    super(props);
    this.state = {
      headInfo:{
        title: this.props.language.profile.title,
        subtitle: ''
      },
      user: props.user,
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
      loaded_profile: false,
    }
  }

  componentDidMount(){
    this.getUserInfo();
    if(this.props.location.state && this.props.location.state.showSnackbar){
      const { snackbarMessage } = this.props.location.state;
      var newStateData =  {
          message: snackbarMessage,
          typesnack: 'success',
          openSnackBar: true
      }
      this.setState({snackBarInfo: newStateData});
      this.props.history.replace({ state: {} });
    }
  }

////Get User
  /**
   * Método para obtener los datos de usuario en el backend
  */
  getUserInfo(){
    // console.log("entro a getUserInfo");
    this.props.fetchUser(this.props.user._id).then(response => {
      // console.log("success");
        this.successGetUser(response);
    }).catch(error => {
      // console.log("error");
        this.errorGetUser(error);
    });
  }

  successGetUser(response){
      // console.log("successGetUser", response.payload);
      this.setState({
        loaded_profile: true,
        user: response.payload.response.user
      });
  }

  errorGetUser(error){
    // console.log("errorGetUser", error);
      this.setState({
        loaded_profile: true,
        // snackBarInfo:{
        //   message: "Error al obtener los datos, mostrando los ultimos obtenidos",
        //   typesnack: 'error',
        //   openSnackBar: true
        // }
      });
  }

  /**
   * Change the current view to url sended by parameter
   * 
   * @param {string} url - variable with url of view that we want to go
  */
  goToView = (url) => {
    this.props.history.push(url);
  }

  printQr = () => {
    // console.log("printQr");
    var w = window.open("./../../../images/qrExample.png");
    w.print();
  }

  /**
   * Method to handle the change of the language selected
  */
  handleChangeLanguage = (values) => {
    // console.log("value", values.target.value);
    var language_code = null;
    switch(values.target.value){
      case this.props.language.english:
        language_code = "en";
      break;
      case this.props.language.spanish:
      default:
        language_code = "es";
      break;
    }

    this.props.setLanguages(language_code);
  }

  render() {
    return (<PerfectScrollbar style={{marginRight: "-30px", width: "100%"}}>
          {this.state.loaded_profile ? 
            <UserProfile 
              loaded_profile={this.state.loaded_profile}
              headInfo={this.state.headInfo}
              goToView={this.goToView}
              user={this.state.user}
              printQr={this.printQr}
              handleChangeLanguage={this.handleChangeLanguage}
              language={this.props.language} />
            : renderLoader(this.props.language.profile.loader, null, 290)
        }
        {renderSnackBarMessage(
            this.state.snackBarInfo.openSnackBar, 
            this.state.snackBarInfo.message, 
            this.state.snackBarInfo.typesnack,
            () => this.setState({snackBarInfo:{openSnackBar: false}}),
            4000)
        }
      </PerfectScrollbar>
    );
  }
}

ProfileContainer.propTypes = {
  language: PropTypes.object.isRequired,    //Variable donde se guarda el lenguaje
  user: PropTypes.object.isRequired,        //Variable obtenida del backend con los datos del usuario
};

ProfileContainer.defaultProps  = {};

const mapStateToProps = (state) => ({
  language:  getLanguage(state),
  user:      getCurrentUser(state)
});

export default withRouter( connect( mapStateToProps, { 
  updateUser,
  setAuthentication,
  fetchUser,
  setLanguages
}) (ProfileContainer) );