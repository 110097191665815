import { combineReducers } from 'redux';
import {reducer as reduxForm} from 'redux-form';

import { results, resultView } from './results';
import { authentication } from './authentication';
import { languageSelect } from './languageSelect';
import { dashboard } from './dashboard';
import { organization } from './organization';
import { organization_users } from './user';

export default combineReducers({
  form: reduxForm,
  languageSelect,
  results,
  resultView,
  authentication,
  organization,
  dashboard,
  organization_users
});