import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { getLanguage, getCurrentUser } from 'selectors';
import { getDashboard } from 'actions';
import Dashboard from 'components/Dashboard';
import { renderSnackBarMessage, renderLoader } from 'components/general/commonRenders';
import './styles.css';
import html2canvas from 'html2canvas';
import { saveAs } from 'helpers/general';
import moment from 'moment';
import { LINE_CHART, GEO_CHART, BUBBLE_CHART, PIE_CHART } from 'constants/index';

import 'moment/locale/es';

class DashboardContainer extends Component {

  constructor(props) {
    super(props);
    this.language = this.props.language;
    moment.locale(this.language.locale);
    let todayDate = moment(new Date()).format("yyyy MMMM DD");
    this.state = {
      headInfo: {
        title: this.language.dashboard.title,
        subtitle: this.language.dashboard.date.replace("<date>", todayDate)
      },
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
      donwloading: {
        pie: false,
        bubble: false,
        geo: false,
        line: false
      },
      openModal: false,
      modalData: {},
      dashboardData: {},
      loaded_dashboard: false,
    }

    this.donwloadQR = this.donwloadQR.bind(this);
    this.handleDownloadChart = this.handleDownloadChart.bind(this);
  }

  componentDidMount() {
    this.getDashboardData();
  }

  ////Get COMPANY
  /**
   * Método para obtener los datos del dashboard obtenidos del backend
   */
  getDashboardData() {
    this.props.getDashboard(this.props.user.company._id).then(response => {
      this.successGetDashboardData(response);
    }).catch(error => {
      this.errorGetDashboardData(error);
    });
  }

  successGetDashboardData(response) {
    // console.log("successGetDashboardData", response);
    const { pieChartData, bubbleChartData } = response.payload.response.dashboard;
    const { pie_negative_legend, pie_positive_legend, pie_inderteminate_legend } = this.props.language.dashboard;
    pieChartData[0][0] = pie_negative_legend.replace("<totalCases>", pieChartData[0][1]);
    pieChartData[1][0] = pie_positive_legend.replace("<totalCases>", pieChartData[1][1]);
    pieChartData[2][0] = pie_inderteminate_legend.replace("<totalCases>", pieChartData[2][1]);

    for (let data of bubbleChartData) {
      data.push(`${this.props.language.dashboard.bubble_tooltip} ${data[1]}`);
    }
    this.setState({
      loaded_dashboard: true,
      dashboardData: response.payload.response.dashboard
    });
  }

  errorGetDashboardData(error) {
    // console.log("errorGetDashboardData", error);
    this.setState({
      loaded_dashboard: true,
      dashboardData: {
        error: true,
      },
    });
  }

  /**
   * Método llamado cuando se hace click en el botón de descargar de las gráficas
   * 
   * @param {string} typeChart - variable con el tipo de grafica que se selecciono
   * 
  */
  handleDownloadChart(typeChart) {
    var chart = document.getElementById("chart_" + typeChart);
    var chartName = null;
    let donwloading = null;

    switch (typeChart) {
      case LINE_CHART:
        chartName = this.language.filenames.line;
        donwloading = { ...this.state.donwloading, line: true };
        break;
      case BUBBLE_CHART:
        chartName = this.language.filenames.bubble;
        donwloading = { ...this.state.donwloading, bubble: true };
        break;
      case GEO_CHART:
        chartName = this.language.filenames.geo;
        donwloading = { ...this.state.donwloading, geo: true };
        break;
      case PIE_CHART: default:
        chartName = this.language.filenames.pie;
        donwloading = { ...this.state.donwloading, pie: true };
        break;
    }

    this.setState({ donwloading });

    chart.className += ' chartExported';

    chartName += moment(new Date()).format("yyyy-MM-DD");

    setTimeout(() => {
      html2canvas(document.getElementById("chart_" + typeChart), {
        useCORS: true,
        allowTaint: false,
        logging: true,
      }).then(canvas => {
        saveAs(canvas.toDataURL(), `${chartName}.png`);
        chart.className = chart.className.replace('chartExported').trim();
        let newDownloading
        switch (typeChart) {
          case LINE_CHART:
            chartName = "graf-sexo-edad-";
            newDownloading = { ...this.state.donwloading, line: false };
            break;
          case BUBBLE_CHART:
            chartName = "graf-dias-";
            newDownloading = { ...this.state.donwloading, bubble: false };
            break;
          case GEO_CHART:
            chartName = "mapa-casos-";
            newDownloading = { ...this.state.donwloading, geo: false };
            break;
          case PIE_CHART: default:
            chartName = "graf-total-casos-";
            newDownloading = { ...this.state.donwloading, pie: false };
            break;
        }
        this.setState({ donwloading: newDownloading });
      });
    }, 750);

  }

  donwloadQR = () => {
    console.log("en donwloadQR", this.props.user.company.qrcode);
    // svgToPng(this.props.user.company.qrcode , 10).then(data => {
    //   console.log("funciono svgToPng");
    //   let filename = this.language.filenames.QRCode + moment(new Date()).format("yyyy-MM-DD");
    //   saveAs(data, filename);
    // }).catch(function (err) {
    //   console.log("fallo svgToPng", err);
    //   // console.log("err", err);
    // });

    let filename = this.language.filenames.QRCode + moment(new Date()).format("yyyy-MM-DD");
    var svgElements = document.body.querySelectorAll('svg');
    svgElements.forEach(function (item) {
      item.setAttribute("width", item.getBoundingClientRect().width);
      item.setAttribute("height", item.getBoundingClientRect().height);
      item.style.width = null;
      item.style.height = null;
    });

    setTimeout(() => {
      html2canvas(document.getElementById("QRImg"), {
        useCORS: true,
        allowTaint: false,
        logging: true,
      }).then(canvas => {
        if (navigator.userAgent.search('Chrome') >= 0) {
          saveAs(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"), `${filename}.png`);
        } else {
          saveAs(canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream"), `${filename}.jpeg`);
        }
        //saveAs(canvas.toDataURL(), `${filename}.jpg`);
      });
    }, 750);
  }

  /**
   * Method que es llamado para abrir modal, renderizará el componente mandado en modalComponent
   * 
   * @param {string} modalComponent - Un tipo de elemento React (ej. MyComponent).
  */
  handleOpenModal = (modalComponent) => {
    // console.log("Entro a handleOpenModal", this.state.openModal, modalComponent);
    this.setState({
      openModal: !this.state.openModal,
      modalData: {
        ModalSelected: modalComponent,
        modalInfo: {
          user: this.props.user,
          donwloadQR: this.donwloadQR
        }
      }
    });

    // console.log("handleOpen", this.state.openModal);
  }

  render() {
    return (<PerfectScrollbar style={{ minHeight: "100%", height: "auto", width: "100%" }}>
      {this.state.loaded_dashboard ?
        <Dashboard id="Dashboard"
          headInfo={this.state.headInfo}
          dashboardData={this.state.dashboardData}
          handleDownloadChart={this.handleDownloadChart}
          handleOpenModal={this.handleOpenModal}
          modalData={this.state.modalData}
          openModal={this.state.openModal}
          donwloading={this.state.donwloading}
          language={this.language} />
        :
        renderLoader(this.language.dashboard.loader, null, 290)
      }
      {renderSnackBarMessage(
        this.state.snackBarInfo.openSnackBar,
        this.state.snackBarInfo.message,
        this.state.snackBarInfo.typesnack,
        () => this.setState({ snackBarInfo: { openSnackBar: false } }),
        4000)
      }
    </PerfectScrollbar>
    );
  }
}

DashboardContainer.propTypes = {
  language: PropTypes.object.isRequired,    //Variable donde se guarda el lenguaje
};

const mapStateToProps = (state) => ({
  language: getLanguage(state),
  user: getCurrentUser(state)
});

export default withRouter(connect(mapStateToProps, {
  getDashboard,
})(DashboardContainer));