import { createMuiTheme, makeStyles } from "@material-ui/core/styles";

const drawerWidth = 180;
const primaryColor = "#3F4443";    //Grey 
const secondaryColor = "#D9514E";  //Red

export const theme = createMuiTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "screen", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      screen: 1440,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles(theme => {
  var media1080 = "@media (max-width:1080px)";
  var media1360 = "@media (max-width:1360px)";
  var media1439 = "@media (max-width:1439px)";
  var media1530 = "@media (min-width:1530px) and (max-width: 1724px)";
  var media1725 = "@media (min-width:1725px)";
  var media1920 = "@media (min-width:1920px)";
  //var media1360 = "@media (max-width:1360px)";
  //var media1360 = "@media (max-width:1360px)";
  //theme.keys.push("screen");
  //theme.values["screen"] = 1439;
  return ({
    backgroundBody: {
      backgroundImage:"url('./../../images/iconpatternv2.svg')", 
      backgroundRepeat:"no-repeat", 
      backgroundSize:"cover",
      height: "100vh",
    },
    SignUpBody: {
      height: "100vh",
    },
    loginContainer: {
      height: "100%",
      background: "#FFF", 
      borderRadius: "30px",
      overflow: "auto",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      '& > div': {
        height: "100%"
      }
    },
    ForgotPasswordContainer: {
      height: "85%",
      margin: "auto",
      background: "#FFF", 
      borderRadius: "30px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      '& > div': {
        height: "100%"
      },
      [media1530]: {
        height: "95%"
      },
      [media1725]: {
        height: "95%"
      },
      [media1920]: {
        height: "95%"
      }
    },
    RestrictedPageContainer: {
      height: "85%",
      margin: "auto",
      background: "#FFF", 
      borderRadius: "30px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      '& > div': {
        height: "100%"
      },
      [media1530]: {
        height: "95%"
      },
      [media1725]: {
        height: "95%"
      },
      [media1920]: {
        height: "95%"
      }
    },
    NotConfirmedMailContainer: {
      height: "85%",
      margin: "auto",
      background: "#FFF", 
      borderRadius: "30px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      '& > div': {
        height: "100%"
      },
      [media1530]: {
        height: "95%"
      },
      [media1725]: {
        height: "95%"
      },
      [media1920]: {
        height: "95%"
      }
    },
    ExpiredLinkContainer: {
      height: "85%",
      margin: "auto",
      background: "#FFF", 
      borderRadius: "30px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      '& > div': {
        height: "100%"
      },
      [media1530]: {
        height: "95%"
      },
      [media1725]: {
        height: "95%"
      },
      [media1920]: {
        height: "95%"
      }
    },
    FormLayoutContainer: {
      background: "#FFF", 
      borderRadius: "30px",
      height: "85%",
      margin: "auto",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      '& > div': {
        height: "100%"
      }
    },
    HeadDeviceContainer: {
      background: "#FFF",
      height: "85%",
      margin: "auto",
      boxShadow: "none",
      '& > div': {
        height: "100%"
      }
    },
    loginFormContainer: {
      padding: "20px 45px 0px 50px !important",
      '& h3': {
        paddingLeft: "0px"
      },
      '& h4': {
        paddingLeft: "0px"
      },
      '& form': {
        paddingLeft: "0px"
      },
      [media1360]: {
        padding: "20px 40px 0px !important",
      },
      [media1439]: {
        padding: "20px 40px 0px !important",
      },
      [media1530]: {
      },
      [media1725]: {
      },
      [media1920]: {
      },
    },
    ForgotPasswordBox: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "40px",
      padding: "0px 50px !important",
      [media1080]: {
        padding: "0px 35px !important",
      },
      '& h3': {
        padding: "10px",
        [media1360]: {
          padding: "0px 10px",
        },
        [media1439]: {
          padding: "0px 10px",
        },
        [media1530]: {
        },
      },
      '& h4': {
        padding: "10px",
        [media1360]: {
          padding: "10px",
        },
        [media1439]: {
          padding: "10px",
        },
        [media1530]: {
        },
      },
      '& form': {
        padding: "10px",
        [media1360]: {
          padding: "0px 10px",
        },
        [media1439]: {
          padding: "0px 10px",
        },
        [media1530]: {
        },
      }
    },
    RestrictedPageBox: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "40px",
      padding: "0px 50px !important",
      [media1080]: {
        padding: "0px 35px !important",
      },
      '& h3': {
        padding: "10px",
        fontFamily: "Sarabun",
        fontWeight: "700",
        fontSize: "1.625rem",
        lineHeight: "2.5rem",
        textAlign: "center",
        color: "#3F4443",
        marginTop: "100px",
        [media1920]: {
          marginTop: "150px",
          marginBottom: "0px",
          fontSize: "2rem",
        },
        [media1725]: {
          marginTop: "150px",
          marginBottom: "0px",
          fontSize: "2.25rem",
        },
        [media1530]: {
          fontSize: "1.75rem",
        },
        [media1439]: {
          marginTop: "40px",
          marginBottom: "0px",
          fontSize: "1.375rem",
        },
        [media1360]: {
          marginTop: "40px",
          marginBottom: "0px",
          fontSize: "1.125rem",
        },
        [media1080]: {
          marginTop: "40px",
          marginBottom: "0px",
          fontSize: "1.125rem",
        }
      },
      '& h4': {
        fontFamily: "Sarabun",
        fontWeight: "700",
        fontSize: "1.125rem",
        lineHeight: "1.875rem",
        textAlign: "center",
        color: "#3F4443",
        marginBottom: "60px",
        [media1920]: {
          marginTop: "20px",
          marginBottom: "120px",
          fontSize: "1.5rem",
        },
        [media1725]: {
          marginTop: "20px",
          marginBottom: "120px",
          fontSize: "1.75rem",
        },
        [media1530]: {
          fontSize: "1.25rem",
        },
        [media1439]: {
          marginBottom: "30px",
          fontSize: "1.125rem",
          padding: "0px 10px 10px 10px",
        },
        [media1360]: {
          marginBottom: "30px",
          fontSize: "0.875rem",
          padding: "0px 10px 10px 10px",
        },
        [media1080]: {
          marginBottom: "30px",
          fontSize: "0.875rem",
          padding: "0px 10px 10px 10px",
        },
      },
      '& h6': {
        fontFamily: "Sarabun",
        fontWeight: "400",
        fontSize: "1.125rem",
        lineHeight: "1.875rem",
        textAlign: "center",
        color: "#3F4443",
        marginBottom: "15px",
        [media1920]: {
          fontSize: "1.5rem",
        },
        [media1725]: {
          fontSize: "1.875rem",
        },
        [media1530]: {
          fontSize: "1.25rem",
        },
        [media1439]: {
          fontSize: "1.125rem",
        },
        [media1360]: {
          fontSize: "1rem",
        },
        [media1080]: {
          fontSize: "1rem",
        },
      },
      '& form': {
        padding: "10px",
        [media1360]: {
          padding: "0px 10px",
        },
        [media1439]: {
          padding: "0px 10px",
        },
        [media1530]: {
        },
      }
    },
    NotConfirmedMailBox: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "40px",
      padding: "0px 50px !important",
      '& h3': {
        padding: "10px",
        [media1360]: {
          padding: "0px 10px",
        },
        [media1439]: {
          padding: "0px 10px",
        },
        [media1530]: {
        },
      },
      '& h4': {
        padding: "10px",
        [media1360]: {
          padding: "10px",
        },
        [media1439]: {
          padding: "10px",
        },
        [media1530]: {
        },
      },
      '& .blockInfo': {
        padding: "10px",
        [media1360]: {
          padding: "0px 10px",
        },
        [media1439]: {
          padding: "0px 10px",
        },
        [media1530]: {
        },
      }
    },
    ExpiredLinkBox: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "40px",
      padding: "0px 50px !important",
      '& h3': {
        padding: "10px",
        marginTop: "80px !important",
        [media1439]: {
          padding: "0px 10px",
          marginTop: "10px !important",
        },
        [media1360]: {
          padding: "0px 10px",
          fontSize: "1.375rem !important",
          marginTop: "10px !important",
        },
        [media1080]: {
          padding: "0px 10px !important",
          marginTop: "30px !important",
        },
        [media1530]: {
          marginTop: "40px !important",
        },
        [media1725]: {
          marginTop: "30px !important",
          fontSize: "1.875rem"
        },
        [media1920]: {
          fontSize: "2.125rem",
          marginTop: "100px !important",
        }
      },
      '& h4': {
        padding: "10px",
        [media1439]: {
          padding: "10px",
          fontSize: "1rem"
        },
        [media1360]: {
          padding: "20px 10px 10px",
        },
        [media1080]: {
          fontSize: "0.75rem",
          marginTop: "0px !important",
        },
        
        [media1530]: {
        },
        [media1920]: {
          fontSize: "1.625rem",
        }
      },
      '& .blockInfo': {
        padding: "10px",
        [media1360]: {
          padding: "0px 10px",
        },
        [media1439]: {
          padding: "0px 10px",
        },
        [media1530]: {
        },
      },
      [media1439]: {
        marginTop: "55px"
      },
    },
    PasswordRecoveryBox: {
      margin: "auto",
      width: "50%",
      '& > *': {
        padding: "10px"
      },
    },
    FormBox: {
      margin: "120px auto 80px auto",
      width: "60%",
      '& > *': {
        padding: "10px"
      },
      [media1360]: {
        margin: "120px auto 80px auto",
      },
      [media1439]: {
        margin: "40px auto 50px auto",
      },
      [media1530]: {
        margin: "35px auto 45px auto",
      },
      [media1725]: {
      },
      [media1920]: {
        margin: "120px auto 80px auto",
      },
    },
    loginImgContainer: {
      backgroundImage:"url('./../../images/login-cover.png')",
      backgroundRepeat:"no-repeat", 
      backgroundSize:"cover",
      width: "100%",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
    },
    signUpForm: {
      background: "white",
      padding: "65px 60px 55px 60px",
      height: "auto",
      marginBottom: "10px",
      //overflow: "auto",
      // borderRadius: "20px",
      borderRadius: "50px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [media1360]: {
        padding: "35px 25px 20px 30px",
        borderRadius: "40px",
      },
      [media1439]: {
        padding: "35px 25px 20px 30px",
        borderRadius: "40px",
      },
      [media1530]: {
        padding: "50px 60px 40px 60px",
      },
      [media1725]: {
      },
      [media1920]: {
        padding: "75px 60px 65px 60px",
      },
      '& > p' : {
        fontSize: "1.125rem",
        fontFamily: "'Sarabun'",
        lineHeight: "1.5rem",
        color: "#3F4443",
        fontWeight: "700",
        marginBottom: "0rem",
        [media1360]: {
          fontSize: "0.9375rem",
          lineHeight: "1rem",
        },
        [media1439]: {
          fontSize: "0.9375rem",
          lineHeight: "1rem",
        },
        [media1080]: {
          fontSize: "0.8125rem !important",
          lineHeight: "0.875rem !important",
        },
        [media1530]: {
        },
        [media1725]: {
          fontSize: "1.5rem",
        },
        [media1920]: {
          fontSize: "1.5rem",
        },
      },
      '& > h4': {
        fontFamily: "'Sarabun'",
        fontSize: "0.9375rem",
        lineHeight: "1.375rem",
        fontWeight: "400",
        marginTop: "20px",
        color: "#828282",
        borderBottom: "1px solid #828282",
        paddingBottom: "10px",
        [media1360]: {
          fontSize: "0.875rem",
          paddingBottom: "5px",
          marginTop: "15px",
        },
        [media1530]: {
          fontSize: "0.9375rem",
          marginTop: "15px",
          paddingBottom: "7px",
        },
        [media1725]: {
          fontSize: "1.125rem",
        },
        [media1920]: {
          marginTop: "40px",
          fontSize: "1.375rem",
        },
      }
    },
    signUpFormOrganization: {
      background: "white",
      padding: "45px 70px 35px 70px",
      borderRadius: "50px",
      height: "auto",
      marginBottom: "10px",
      fontWeight: "700",
      //overflow: "auto",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [media1360]: {
        padding: "25px 40px 15px 35px",
      },
      [media1439]: {
        padding: "20px 30px 10px 35px",
      },
      [media1080]: {
        padding: "25px 30px 15px 30px"
      },
      [media1530]: {
        padding: "25px 60px 15px 60px",
      },
      [media1725]: {
      },
      [media1920]: {
      },
      '& > p' : {
        marginBottom: "10px",
        fontSize: "1rem",
        fontFamily: "'Sarabun'",
        color: "#3F4443",
        fontWeight: "700",
        lineHeight: "140%",
        [media1360]: {
          fontSize: "0.875rem",
          lineHeight: "1rem",
        },
        [media1439]: {
          fontSize: "0.9375rem",
          lineHeight: "1rem",
        },
        [media1080]: {
          fontSize: "0.8125rem"
        },
        [media1530]: {
        },
        [media1725]: {
          fontSize: "1.5rem",
        },
        [media1920]: {
          fontSize: "1.5rem",
        },
      },
      '& > h4': {
        fontFamily: "'Sarabun'",
        fontSize: "0.9375rem",
        lineHeight: "1.375rem",
        fontWeight: "400",
        marginTop: "20px",
        color: "#828282",
        borderBottom: "1px solid #828282",
        paddingBottom: "10px",
        [media1360]: {
          fontSize: "0.8125rem",
          paddingBottom: "3px",
          marginTop: "15px",
        },
        [media1530]: {
          fontSize: "0.9375rem",
          marginTop: "15px",
          paddingBottom: "7px",
        },
        [media1725]: {
          fontSize: "1.125rem",
        },
        [media1920]: {
          marginTop: "30px",
          fontSize: "1.375rem",
        },
      }
    },
    FormBlockSingUp:{
      paddingBottom: "10 px", 
      alignSelf: "center", 
      margin: "auto", 
      height: "100%"
    },
    languageContainer: {
      background: "transparent",
      padding: "0px 40px 0px 0px !important",
      alignSelf: "center",
      textAlign: "right",
      color: secondaryColor,
      borderColor: secondaryColor
    },
    languageButton: {
      color: secondaryColor,
      border: "1px solid",
      borderColor: secondaryColor
    },
    footerContainer: {
      height: "50px",
    },
    footer: {
      alignSelf: "center",
      background: "transparent",
      height: "50px",
    },
    titleRed: {
      color: secondaryColor,
      fontFamily: "Sarabun",
      fontSize: "2rem",
      margin: "0px",
      marginTop: "10px",
      lineHeight: "42px",
      [media1360]: {
        fontSize: "1.5rem !important",
        lineHeight: "35px !important",
      },
      [media1439]: {
        fontSize: "1.5rem !important",
        lineHeight: "35px !important",
      },
      [media1080]: {
        fontSize: "1.125rem !important",
        lineHeight: "25px !important",
      },
      [media1530]: {
        fontSize: "1.875rem !important",
      },
      [media1725]: {
        fontSize: "2.5rem",
        lineHeight: "2.5rem"
      },
      [media1920]: {
        fontSize: "2.25rem",
        lineHeight: "2.25rem"
      },
    },
    subtitleGrey: {
      fontFamily: "Sarabun",
      color: primaryColor,
      fontSize: "1.125rem",
      marginBottom: "7px",
      margin: "0px",
      lineHeight: "1.5rem",
      [media1360]: {
        fontSize: "0.875rem",
        lineHeight: "1rem",
        marginTop: "7px",
      },
      [media1439]: {
        fontSize: "0.875rem",
        lineHeight: "1rem",
        marginTop: "7px",
      },
      [media1530]: {
        fontSize: "1.125rem",
        height: "50px",
      },
      [media1725]: {
        fontSize: "1.375rem",
        marginTop: "22px",
        lineHeight: "2rem"
      },
      [media1920]: {
        fontSize: "1.375rem",
        marginTop: "22px",
        lineHeight: "2rem"
      },
    },
    linkText: {
      color: secondaryColor,
      fontSize: "0.875rem",
      marginBottom: "15px",
      display: "inline-block",
      fontWeight: "bold",
      cursor: "pointer",
      '&:hover': {
        borderBottom: "1px solid #D9514E"
      },
      [media1360]: {
        fontSize: "0.75rem",
        marginBottom: "10px",
      },
      [media1439]: {
        fontSize: "0.75rem",
        marginBottom: "10px",
      },
      [media1080]: {
        fontSize: "0.6875rem !important",
        lineHeight: "20px !important",
      },
      [media1530]: {
      },
      [media1725]: {
        fontSize: "1rem"
      },
      [media1920]: {
        fontSize: "1.125rem"
      },
    },
    linkAddAccount: {
      color: secondaryColor,
      fontSize: "0.875rem",
      marginBottom: "0px",
      display: "table",
      fontWeight: "bold",
      cursor: "pointer",
      '&:hover': {
        borderBottom: "1px solid #D9514E"
      },
      [media1360]: {
        fontSize: "0.75rem",
      },
      [media1439]: {
        fontSize: "0.75rem",
      },
      [media1530]: {
      },
      [media1725]: {
        fontSize: "1rem",
        marginTop: "20px"
      },
      [media1920]: {
        fontSize: "1.125rem",
        marginTop: "20px"
      },
    },
    loginText: {
      color: primaryColor,
      fontSize: "0.875rem",
      fontWeight: "400",
      marginBottom: "0px",
      [media1360]: {
        fontSize: "0.6875rem",
      },
      [media1439]: {
        fontSize: "0.6875rem",
      },
      [media1530]: {
      },
      [media1725]: {
        fontSize: "1.125rem",
      },
      [media1920]: {
        fontSize: "1.25rem",
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth
    },
    navbarLogo:{
      position:"relative",
      top: "27px"
    },
    appBar: {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
      boxShadow: "none",
      backgroundColor: "white",
      borderBottom: "1px solid #D5D5D6",
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        display: "none",
        marginLeft: drawerWidth,
      },
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        width: `100%`,
        height: `calc(100% - 50px)`,
        paddingTop: "55px",
      },
      [theme.breakpoints.down('xs')]: {
        width: `100%`,
        padding: "15px 10px",
      },
    },
  })},
  {
    defaultTheme: theme
  }
);

//export const theme =  createMuiTheme({});

export default useStyles;