import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { renderTextField, renderPhoneField2, renderPassword } from '../general/Form';
import { stringTrim } from '../../helpers/transforms/formTransforms.js';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import useStyles from "../../style";
import {
  isRequired as _isRequired,
  passwordsMatch as _passwordsMatch,
  maxLength as _maxLength,
  includeLowercase as _includeLowercase,
  includeUppercase as _includeUppercase,
  includeNumber as _includeNumber,
  isEmail as _isEmail,
  isPhoneNumber as _isPhoneNumber,
} from '../../helpers/validations';
import './styles.css';
import { PASSWORD_MIN_LENGTH } from 'constants/index';

var isRequired = _isRequired('');
var passwordsMatch = _passwordsMatch('');
var passwordLength = _maxLength('', PASSWORD_MIN_LENGTH);
var hasLowercase = _includeLowercase('');
var hasUppercase = _includeUppercase('');
var hasNumber = _includeNumber('');
var isEmail = _isEmail('');
var isPhoneNumber = _isPhoneNumber('');

const FormUserComponent = (props) => {
  const { handleSubmit, goToLogin, submitting, pristine, invalid, error } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  useEffect(() => {
    setLanguage(props.language);
    isRequired = _isRequired(props.language.errors.required);
    isEmail = _isEmail(props.language.errors.email);
    passwordsMatch = _passwordsMatch(props.language.errors.passwordMatch);
    passwordLength = _maxLength(props.language.errors.maxLength, PASSWORD_MIN_LENGTH);
    hasLowercase = _includeLowercase(props.language.errors.notLowercase);
    hasUppercase = _includeUppercase(props.language.errors.notUppercase);
    hasNumber = _includeNumber(props.language.errors.notNumber);
    isPhoneNumber = _isPhoneNumber(props.language.errors.notPhone);
  }, [props.language]);

  const [showPassword, setShowPassword] = useState(false);
  const clickShowPassword = (value, phoneData) => {
    setShowPassword(!showPassword);
  }

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const clickShowConfirmPassword = (value, phoneData) => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const changePhone = (value) => {
  //   console.log("changePhone", value);
  //   props.change("phone", value);
  // }

  const [phone, setPhone] = useState(null);

  return (
    <form onSubmit={handleSubmit} className={`${classes.signUpForm} signUpForm`}>
      <p>{language.signup.user.formTitle}</p>
      <h4>{language.signup.user.userSectionTitle}</h4>
      <Grid container>
        <Grid item xs={12} sm={6} className="gridInput" style={{ paddingRight: "15px" }}>
          <Field
            id="name" name="name" type="text"
            label={language.signup.user.label_name}
            placeholder={language.signup.user.input_name}
            validate={[isRequired]}
            component={renderTextField}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="gridInput">
          <div style={{ width: "70%", display: "inline-block" }}>
            <Field
              id="phone" name="phone"
              label={language.signup.user.label_phone}
              placeholder={language.signup.user.input_phone}
              validate={[isRequired, isPhoneNumber]}
              onChange={setPhone}
              phoneValue={phone}
              component={renderPhoneField2}
            />
          </div>
          <div style={{ width: "30%", display: "inline-block" }}>
            <Field
              id="extension" name="extension"
              label={language.signup.user.label_extension}
              subText={language.signup.user.label_optional}
              placeholder={language.signup.user.input_extension}
              component={renderTextField}
            />
          </div>
        </Grid>
      </Grid>
      <h4>{language.signup.user.accountSectionTitle}</h4>
      <Grid container>
        <Grid item xs={12} sm={8} className="gridInput" style={{ paddingRight: "15px" }}>
          <Field
            id="email" name="email" type="email"
            label={language.signup.user.label_email}
            placeholder={language.signup.user.input_email}
            validate={[isRequired, isEmail]}
            normalize={stringTrim}
            component={renderTextField}
          />
        </Grid>
        <Grid item xs={12} sm={4} className="gridInput">
          <Field
            id="code" name="code" type="text"
            label={language.signup.user.label_code}
            tooltipData={{
              text: language.signup.user.codeTooltip,
              color: "#D9514E",
              fontSize: "0.9375rem"
            }}
            placeholder={language.signup.user.input_code}
            validate={[isRequired]}
            component={renderTextField}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="gridInput" style={{ paddingRight: "15px" }}>
          <Field
            id="password" name="password" type="password"
            label={language.signup.organization.label_password}
            showPassword={showPassword}
            tooltipData={{
              text: language.signup.passwordTooltip,
              color: "#D9514E",
              fontSize: "0.9375rem"
            }}
            handleClickShowPassword={clickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            placeholder={language.signup.organization.input_password}
            validate={[isRequired, passwordLength, hasLowercase, hasUppercase, hasNumber]}
            component={renderPassword}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="gridInput">
          <Field
            id="confirm_password" name="confirm_password" type="password"
            label={language.signup.user.label_confirm_password}
            showPassword={showConfirmPassword}
            handleClickShowPassword={clickShowConfirmPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            placeholder={language.signup.user.input_confirm_password}
            validate={[isRequired, passwordsMatch]}
            component={renderPassword}
          />
        </Grid>
      </Grid>
      {error && <strong className="formError">{error}</strong>}
      <div style={{ marginTop: "20px" }}>
        {/* <Button id="SignUpButton" type="submit"
          className="btn"
          disabled={submitting || pristine || invalid}>
          {language.signup.user.submit_btn}
          
        </Button> */}
        {submitting ?
          <div style={{ textAlign: "center", width: "250px" }}>
            <CircularProgress size={30} />
          </div> :
          <Button id="SignUpButton" type="submit"
            className="btn"
            disabled={submitting || pristine || invalid}>
            {language.signup.organization.submit_btn}
          </Button>}
      </div>
      <div className="blockAlreadyRegister">
        <span className={classes.loginText}>{language.signup.user.already_logged}</span>
        <span className={classes.linkText} onClick={goToLogin}>{language.signup.user.link_login}</span>
      </div>
    </form>
  );
};

FormUserComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,  //Func. donde se haran las acciones del submit de user.
  goToLogin: PropTypes.func.isRequired,         //Func. que te a la view de login
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  submitting: PropTypes.bool.isRequired,        //Flag dada por redux-form para decirnos si se esta ejecutando el submit
  pristine: PropTypes.bool.isRequired,          //Flag dada por redux-form para si el form tiene los mismos valores que al inicio
  invalid: PropTypes.bool.isRequired,           //Flag dada por redux-form para si el form tiene algun input invalido.
  error: PropTypes.bool,                        //Variable dada por redux-form para decirnos si se regreso un error general a traves del submit del form
};

export default reduxForm({
  form: 'FormUserComponent',
  enableReinitialize: true
})(FormUserComponent);