import React from 'react';
import PropTypes from 'prop-types';
import { Grid  } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleOutlinedIcon from '@material-ui/icons/ViewModuleOutlined';
import Icon from '@material-ui/core/Icon';

import {VIEW_LIST, VIEW_CARD, VIEW_INDIVIDUAL} from '../../constants';
import { DarkerTooltip } from 'components/general/commonRenders';

  /**
   * Método para renderizar los botones de cambio de view
   * 
   * @param {string} title - String con el titulo del boton, es el texto que saldrá en el tooltip.
   * @param {SvgIcon} IconComponent - componente de tipo SvgIcon, es el icono que tendra el boton.
   * @param {Function}  clickCallback - funcion que recibe el cambio de view
   * @param {string} viewName - string con el nombre de la view a la que queremos cambiar
   *    
   * @returns {array} JSX a renderizar, es el boton para el cambio de view
  */
  const renderChangeViewButton = (title, IconComponent, clickCallback, viewName, disabled) => {
    if(disabled){
      return (<IconButton size="small"
              className="iconRound"
              disabled={disabled}>
              <IconComponent fontSize="large" />
          </IconButton>);
    }else{
      return (
        <DarkerTooltip title={title} placement="bottom">
            <IconButton 
                className="iconRound"
                size="small"
                disabled={disabled}
                onClick={() => {
                  if(clickCallback) 
                    clickCallback(viewName)
                }}>
                <IconComponent fontSize="large" />
            </IconButton>
        </DarkerTooltip>
      );
    }
  }

  const renderChangeViewButtonMaterial = (title, clickCallback, viewName, disabled) => {
    if(disabled){
      return (<IconButton size="small"
              className="iconRound iconMaterial"
              disabled={disabled}>
              <Icon className="material-icons-outlined">view_sidebar</Icon>
          </IconButton>);
    }else{
      return (
        <DarkerTooltip title={title} placement="bottom">
            <IconButton 
                className="iconRound iconMaterial"
                size="small"
                disabled={disabled}
                onClick={() => {
                  console.log("click edit"); 
                  if(clickCallback) 
                    clickCallback(viewName)
                }}>
                <Icon className="material-icons-outlined">view_sidebar</Icon>
            </IconButton>
        </DarkerTooltip>
      );
    }
  }

const HeadResultsComponent = (props) => {
  const { headInfo, typeView, handleChangeView , language} = props;

  return (
    <Grid container className="headResults">
      <Grid item xs={12} sm={7}>
        <div className="principal-title" >
              {headInfo.title}
        </div>
        <div className="secundary-title">
              {headInfo.subtitle}
        </div>
      </Grid>
      <Grid item xs={12} sm={5} style={{paddingRight: "20px"}}>

        {renderChangeViewButton(language.results.head.view_list, ViewListOutlinedIcon, handleChangeView, VIEW_LIST, typeView === VIEW_LIST)}
        {renderChangeViewButton(language.results.head.view_cards, ViewModuleOutlinedIcon, handleChangeView, VIEW_CARD, typeView === VIEW_CARD)}
        {renderChangeViewButtonMaterial(language.results.head.view_individual, handleChangeView, VIEW_INDIVIDUAL, typeView === VIEW_INDIVIDUAL)}
      </Grid>
    </Grid>
  );
};

HeadResultsComponent.propTypes = {
  headInfo: PropTypes.shape({                     //Objeto con la información del header
    title: PropTypes.string.isRequired,           //Titulo que se mostrará en header
    subtitle: PropTypes.string.isRequired         //Subtitulo que se mostrará en header
  }),
  typeView: PropTypes.string.isRequired,          //Nos dice que tipo de vista se va a mostrar. (VIEW_LIST | VIEW_CARD | VIEW_INDIVIDUAL)
  handleChangeView: PropTypes.func.isRequired,    //Func. para el evento del boton descargar
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  columnsToShow: PropTypes.shape({                //Objeto con los datos de columnnas a mostrar dentro de la tabla de resultados (usado en la view list)
    sickness: PropTypes.bool.isRequired,          //Dato para la columna "sickness", al estar en true se muestra
    date: PropTypes.bool.isRequired,              //Dato para la columna "result", al estar en true se muestra
    gender: PropTypes.bool.isRequired,            //Dato para la columna "gender", al estar en true se muestra
    age: PropTypes.bool.isRequired,               //Dato para la columna "age", al estar en true se muestra
    region: PropTypes.bool.isRequired             //Dato para la columna "region", al estar en true se muestra
  }),
  handleToggleColumns: PropTypes.func.isRequired,  //Función que nos permite mostrar/ocultar la columna que se le mande como parametro
};

export default HeadResultsComponent;