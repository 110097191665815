import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const FooterDashboardComponent = (props) => {
  const [language, setLanguage] = useState(props.language);
  useEffect(() => {
    setLanguage(props.language);
  }, [props.language]);

  return (
    <div style={{backgroundColor: "transparent"}} className="footer">
      <img id="footerLogo" className="footerImg" alt="logo" src="./../../images/logoUnima-footer.png" />
      <p className="footerParagraph">
        {language.footer.title}
      </p>
    </div>
  );
};

FooterDashboardComponent.propTypes = {
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
};

export default FooterDashboardComponent;