import React from 'react';
import { Grid  } from "@material-ui/core";
import PropTypes from 'prop-types';
import './head.css';

const HeadComponent = (props) => {
  const { headInfo} = props;
  return (
    <Grid container>
      <Grid item xs={12} sm={10}>
        <div className="principal-title" >
              {headInfo.title}
        </div>
        <div className="secundary-title">
              {headInfo.subtitle}
        </div>
      </Grid>
    </Grid>
  );
};

HeadComponent.propTypes = {
  headInfo: PropTypes.shape({                     //Objeto con la información del header
    title: PropTypes.string.isRequired,           //Titulo que se mostrará en header
    subtitle: PropTypes.string.isRequired         //Subtitulo que se mostrará en header
  }),
};

export default HeadComponent;