import {handleActions} from 'redux-actions';

//import {resultsExample} from 'config'
import { 
    VIEW_LIST, 
    //VIEW_CARD, 
    //VIEW_INDIVIDUAL,
    FETCH_RESULTS, SET_RESULT_VIEW 
} from 'constants/index';

export const results = handleActions({
    [FETCH_RESULTS]: (state, action) => {
        // console.log("FETCH_RESULTS", state, action);
        if(action.error)
            return [...state];
            
        return [...action.payload.response.registers];
        //return [...action.payload.response.results];
    }
}, []);

export const resultView = handleActions({
    [SET_RESULT_VIEW]: (state, action) => {
        // console.log("SET_RESULT_VIEW", state, action);
        return {...action.payload};
    },
}, {typeSelected: VIEW_LIST});