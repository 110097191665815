import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import HeadComponent from '../general/Head';
import ContentComponent from './ContentComponent.js';
import ModalContainer from '../../containers/general/ModalContainer/ModalContainer';
import useStyles from "../../style";
import './styles.css';

const OrganizationProfile = (props) => {
  const classes = useStyles();
  const { headInfo, language, handleInviteUser, organization, donwloadQR, user, invitedUserIsAdmin,
          handleModalSubmit, handleOpenModal, openModal, goToView, modalData, listUsers, handleChangeIsAdmin} = props;

  var NewUsers = [...listUsers];

  return (
      <Typography component={'div'}>
        <HeadComponent 
          headInfo={headInfo}
          language={language}
        />
        <ContentComponent language={language} 
            handleOpenModal={handleOpenModal}
            goToView={goToView}
            organization={organization}
            donwloadQR={donwloadQR}
            listUsers={NewUsers}
            user={user}
            invitedUserIsAdmin={invitedUserIsAdmin}
            handleChangeIsAdmin={handleChangeIsAdmin}
            onSubmit={handleInviteUser} /> 
        <ModalContainer id="modalContainer" classes={classes}
            handleOpenModal={handleOpenModal}
            handleSubmit={handleModalSubmit}
            modalData={modalData}
            openContainer={openModal}/>
      </Typography>
  );
};

OrganizationProfile.propTypes = {
  headInfo: PropTypes.shape({                     //Objeto con la información del header
    title: PropTypes.string.isRequired,           //Titulo que se mostrará en header
    subtitle: PropTypes.string.isRequired         //Subtitulo que se mostrará en header
  }),
  handleInviteUser: PropTypes.func.isRequired,    //Func. para el evento del boton invitar usuario
  handleModalSubmit: PropTypes.func.isRequired,   //Func. para el evento del boton invitar usuario
  handleOpenModal: PropTypes.func.isRequired,     //Func. para llamar al evento de abrir o cerrar el modal
  openModal: PropTypes.bool.isRequired,           //Nos dice si el modal esta abierto.
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  goToView: PropTypes.func.isRequired,            //Func. que te lleva a la url pasada como parametro.
  organization: PropTypes.object.isRequired,        //Variable obtenida del backend con los datos del organización
  donwloadQR: PropTypes.func.isRequired,          //Func. para descargar la imagen qr del usuario.
  listUsers: PropTypes.array.isRequired,          //Array con los usuarios que tienen acceso a esta organizacion obtenida de backEnd
  modalData: PropTypes.shape({                    //Información a para mandar al modalContainer, para generar el contenido
    ModalSelected: PropTypes.elementType,   //Modal Component to render in modal Container
    modalInfo: PropTypes.object        //Objeto con datos a usar en el modal, el objeto es especifico por cada modalComponent por lo que puede ser opcional y diferente entre cada uno
  }),
  user: PropTypes.object.isRequired,              //Variable obtenida del localstorage con los datos del usuario
  invitedUserIsAdmin: PropTypes.bool.isRequired,  //Campo que nos dice que el usuario al que se va a invitar es de tipo admin
  handleChangeIsAdmin: PropTypes.func.isRequired,  //Funcion para actualizar el swicth de invistar usuario usando la variable "invitedUserIsAdmin"
};

OrganizationProfile.defaultProps  = {
  listUsers: [], 
};

export default OrganizationProfile;