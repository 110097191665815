import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';

import ExpiredLinkComponent from "components/ExpiredLinkComponent";
import { setLanguages, login, setAuthentication, confirmationMail } from 'actions';
import { getLanguage } from 'selectors';
import { renderSnackBarMessage } from 'components/general/commonRenders';
import { HOME_URL } from 'constants/index';

class ExpiredLinkContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLanguage: false,
      email: null,
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
    }
  }

  componentDidMount() {
    // console.log("ExpiredLinkContainer componentDidMount");
  }

  /**
   * Method what is called on submit of send confiramtions mail button
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * @param {string} values.email - string with user's email
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  sendConfirmationMail = (values) => {
    // console.log("handleSendConfirmationMail", values);
    // return new Promise(function (resolve, reject) {
    //   setTimeout(() => {
    //     console.log("acabo el tiempo");
    //     reject();
    //   }, 50000);
    // });
    return this.props.confirmationMail(values).then(response => {
      this.successSendConfirmationMail(response);
    }).catch((error) => {
      this.errorSendConfirmationMail(error);
    });
  }

  /**
   * Method when promise of resend confirmation mail success
   * 
   * @param {Object} response - backEnd response transformed to json from api.js
   * 
  */
  successSendConfirmationMail(response) {
    // console.log("successSendConfirmationMail", response);
    this.setState({
      snackBarInfo: {
        message: this.props.language.confirmationMail.success_send_email,
        typesnack: 'success',
        openSnackBar: true
      }
    });
  }

  /**
   * Method when promise of resend confirmation mail fail
   * 
   * @param {Object} error - this object is get from api.js of method validateBackResponse
   * @param {string}  error.message - string with error message
   * @param {integer} [error.code] - string with user's email
   * @param {object}  [error.error] - Object of pairs key-value made specifically for redux-form errors 
   * 
  */
  errorSendConfirmationMail(error) {
    // console.log("errorSendConfirmationMail", error);
    if (error.code === 422 || error.code === 400) {
      throw new SubmissionError(error.error);
    } else {
      var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.expiredLinkGeneral;
      let snackInfo = {
        message,
        typesnack: 'error',
        openSnackBar: true
      }

      if (error.key === "EMAL023") {
        snackInfo.typesnack = "warning";
      }

      this.setState({ snackBarInfo: snackInfo });
    }
  }

  /**
   * Change the current view to login url
  */
  goToLogin = () => {
    this.props.history.push(HOME_URL);
  }

  render() {
    return (
      <div>
        <ExpiredLinkComponent
          onSubmit={this.sendConfirmationMail}
          language={this.props.language}
          goToLogin={this.goToLogin}
        />
        {renderSnackBarMessage(
          this.state.snackBarInfo.openSnackBar,
          this.state.snackBarInfo.message,
          this.state.snackBarInfo.typesnack,
          () => this.setState({ snackBarInfo: { openSnackBar: false } }),
          4000)
        }
      </div>
    );
  }
}

ExpiredLinkContainer.propTypes = {
  setLanguages: PropTypes.func.isRequired,  //Func. obtenida de action para poner el lenguaje en caso de detectar cambios o si no se ha seleccionado
  login: PropTypes.func.isRequired,         //Func. para mandar la solicitud de login
  language: PropTypes.object.isRequired,    //Variable donde se guardan las traducciones del lenguaje
  setAuthentication: PropTypes.func.isRequired,    //Func. obtenida de action para poner los datos de la session
};

const mapStateToProps = (state, props) => ({
  language: getLanguage(state),
  //auth:      getAuthenthication(state)
});

export default withRouter(connect(mapStateToProps, {
  setLanguages,
  setAuthentication,
  login,
  confirmationMail
})(ExpiredLinkContainer));