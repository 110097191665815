import { defaultLanguage } from './../language';
import es from 'language/es';
import en from 'language/en';
import { HOME_URL } from 'constants/index';

/** Fetch constants for any type of request
*/
const GET = "GET", POST = "POST", DELETE = "DELETE", PATCH = "PATCH", PUT = "PUT";

const BARCK_ERROR_404 = "Ruta no encontrada";
const ERROR_INVALID_TOKEN = "Unauthorized: invalid token";
const ERROR_INVALID_TOKEN_2 = "Invalid token";

/** Methods  to made a db call,Each metod of the class recieves the url; 
 * POST, PUT and PATCH also needs data object
*/
export const Get =    (url) => _fetch(url, GET);
export const Post =   (url, data) => () => _fetch(url, POST, data);
export const Put =    (url, data) => () => _fetch(url, PUT, data);
export const Patch =  (url, data) => () => _fetch(url, PATCH, data);
export const Delete = (url, id) => () => _fetch(url, DELETE, {id: id});

/** _fetch general method for do a request to backend
 * 
 * @param {string} url - Url of request
 * @param {string} method - Type of request can be (GET, POST, DELETE, PATCH, PUT)
 * @param {Object} data - body of request this is sended generally on (POST, PATCH, PUT)
 * @return {Promise}
*/
function _fetch(url, method, data){
    var request = fetch(url, _fetchOptions(method, data))
                  .then(response => validateBackResponse(response, false));
    if(data){
        return request.then( response => {
            //console.log("entro en el response de _fetch", response);
            if(method === DELETE || method === PATCH){
                return data;
            }
            //if(response.success){
            if(response){
                data = response.response || response;
                return data;
            }else{
                return Promise.reject(response.response);
            }
        });
    }
    return request;
}

/** Metodo para traducir la respues del back a  un mensaje de error, usado cuando falla la peticion
 * 
 * @param {string | object | array} error - Url of request
 * @param {number} code - codigo de estatus de la petucion http.
 * @return {object} responseObject  objeto de respuesta formateado para uso general del component
 *  - {number} responseObject.code codigo de estatus de la petucion http.
 *  - {string} responseObject.message mensaje de error general
 *  - {object} [responseObject.error] objeto con error para el form puede tener valor por input o general (en caso de tenerlo)
 *  Example: {error: {
 *      _error: "credenciales invalidas"  variable usada en redux-form indica que fallo por una cosa independiente de los datos.
 *      email:  "email ya existente"      la variable tiene que tener el mismo nombre que el input para que se haga uso del reduxform.
 *  }}
*/
function getErrorMessage(error, code){
    error = error.error ? error.error : error;
    var i=0;
    if(typeof error === 'string'){
        return {message: error, code, _error: error};
    }else if( Array.isArray(error) ){
        var arrayMessage = '';
        var errorObj = {};
        for(i=0; i<error.length; i++){
            arrayMessage += error[0].param + ": " + error[0].msg;
            var errorName = error[0].param === "lastName" ? "name" : error[0].param;
            errorObj[errorName] = error[0].msg;
        }
        var response = {
            message: arrayMessage,
            error: errorObj,
            code
        }
        return response;
    }else if(typeof error  === 'object'){
        error.code = code;
        error.error = {
            _error: error.message
        };
        return error;
    }
}

function getErrorMessageFromKey(keyError, code){
    let languageLocale = localStorage.getItem('language') || defaultLanguage;
    let language = localStorage.getItem('language') || defaultLanguage;
    switch(languageLocale){
        case 'es': 
            language = es; 
        break;
        case 'en': default:
            language = en;
    }
    return {message: language.errors[keyError], code, _error: language.errors[keyError], key: keyError};
}

/** Metodo para validar la respuesta del back, revisa los status de la respuesta
 * 
 * @param {object} response - respuesta obtenida del backend, 
 * @param {boolean} typeBlob - si esta en true significa que lo que vamos a recibir es una file en vez
 * 
 * @return {object} responseObject  objeto de respuesta formateado para uso general del component
*/
function validateBackResponse(response, typeBlob){
    if(response.key){
        return response.json().then(err => {    
            throw getErrorMessageFromKey(err.response, 409); 
        });
    }
    switch (response.status) {
        case 401:
            console.log("error 401 go to login");
            return response.json().then(err => {
                if(err.response.message === ERROR_INVALID_TOKEN || err.response.message === ERROR_INVALID_TOKEN_2){
                    localStorage.setItem('token', '');
                    localStorage.setItem('user', '');
                    window.location = HOME_URL;
                }
            });
            //this.props.history.push(HOME_URL);
        case 409:
            return response.json().then(err => {
                if(err.key){
                    throw getErrorMessageFromKey(err.key, 409); 
                }else{
                    throw getErrorMessage(err.response, 409); 
                }
            });
        case 404:
            return new Promise((resolve, reject) => {
                  resolve(); 
              }).then(() => {
                throw getErrorMessage({message: BARCK_ERROR_404}, 404); 
            });
        case 502: case 400: case 403: case 422:
            return response.json().then(err => {
                if(err.key){
                    throw getErrorMessageFromKey(err.key, 409); 
                }else{
                    throw getErrorMessage(err.response, 409); 
                }
            });
        case 500:
            return response.json().then(err => {
                if(err.key){
                    throw getErrorMessageFromKey(err.key, 409); 
                }else{
                    throw getErrorMessage(err.response, 409); 
                }
            });
            // return new Promise((resolve, reject) => {
            //     resolve(); 
            // }).then(() => {
            //     throw getErrorMessage({message: BARCK_ERROR_500}, 404); 
            // });
        case 204:
            return '';
        default:
            return typeBlob ? response.blob() : response.json();
    }
}

/** method for get the headers options for fetch request
 * 
 * @param {string} method - name of method (GET, POST, DELETE, PUT, PATCH)
 * @param {string} data - Body of request
 * @return {Object}
*/
function _fetchOptions(method, data){
    var optionsFecth = {method: method};
    var headers = {
        'Content-type': 'application/json', 
        'accept-language': localStorage.getItem('language') || defaultLanguage
    };
    var token = getToken();
    if(token){
        headers["Authorization"] = "Bearer " + token;
    }

    if( data instanceof(FormData) ){
        optionsFecth["body"] = data;
    }else{
        optionsFecth["headers"] = new Headers(headers);
        optionsFecth["body"] = JSON.stringify(data);
    }
    return optionsFecth;
}

/** Metodo para obtener el token del usuario a traves del localStorage
 * 
 * 
 * @return {string} token del usuario
*/
function getToken(){
    //TODO 
    //GET TOKEN FROM LOCALSTORAGE O WHATEVER WE HAVE IT AND RETURN IT
    return localStorage.getItem('token');
}