import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row} from 'reactstrap';
import ContentComponet from './ContentComponent';
import useStyles from "../../style";
import FooterNoAuthComponent from "../general/Footer/layoutNoAuth";
import './styles.css';

const PasswordRecoveryComponent = (props) => {
  const { handleChangePassword, goToProfile, token, goToLogin } = props;
  const classes = useStyles();
  const [language] = useState(props.language);

  return (
    <div id="contentPasswordRecovery">
      <Container fluid={true} className={classes.backgroundBody} style={{padding: "0px"}}>
        <Row noGutters style={{height: "calc(100% - 70px)", padding: "50px 0px 10px"}}>
          <Col xs={12} sm={{size:10, offset:1}} md={{size:8, offset:2}} className={classes.ForgotPasswordContainer}>
            <Row noGutters>
               <ContentComponet onSubmit={handleChangePassword} 
                  language={language} 
                  goToProfile={goToProfile} 
                  token={token} 
                  goToLogin={goToLogin} /> 
            </Row>
          </Col>
        </Row>
        <Row noGutters className={classes.footerContainer}>
          <Col xs={{size:12, offset:0}} className={classes.footer}>
            <FooterNoAuthComponent language={language}></FooterNoAuthComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

PasswordRecoveryComponent.propTypes = {
  handleChangePassword: PropTypes.func.isRequired,   //Func. donde se haran las acciones del submit del form.
  language: PropTypes.object.isRequired,             //Variable donde se guardan las traducciones del lenguaje
  token: PropTypes.string,                          //Variable donde se guardan el token, el token solo es utilizado cuando se hace un reset-password (forgot)
  goToProfile: PropTypes.func.isRequired,         //Func. funcion para regresar a la ventana de user-profile
  goToLogin: PropTypes.func.isRequired,         //Func. funcion para regresar al login
};

export default PasswordRecoveryComponent;