import {handleActions} from 'redux-actions';
import { // DELETE_ORGANIZATION,
    FETCH_USERS_ORGANIZATION,
    UPDATE_ORGANIZATION_USER } from 'constants/index';

export const organization_users = handleActions({
    [FETCH_USERS_ORGANIZATION]: (state, action) => {
        // console.log("FETCH_USERS_ORGANIZATION", state, action);
        if(action.error)
            return [...state];
            
        return [...action.payload];
        //return [...action.payload.response.results];
    },
    [UPDATE_ORGANIZATION_USER]: (state, action) => {
        // console.log("UPDATE_ORGANIZATION_USER", state, action);
        const usersPayload = action.payload;
        const users = state;
        const {id} = usersPayload;
        const newUsers = users.reduce((acc, user) => {
            return  [...acc, (user._id === id) ? usersPayload : user];
        }, []);

        if(action.error)
            return [...state];
            
        return newUsers;
    }
}, []);