import {createAction} from 'redux-actions';
import {Post, Get, Delete} from 'api/api';
import {
    LOGIN, 
    // LOGOUT, 
    AUTHCHECK,
    SET_AUTHENTICATION,
    LOGOUT_USER
} from 'constants/index';
import {Request} from 'api/request';

const LOGIN_ENDPOINT = "users/login";
const LOGOUT_ENDPOINT = "users/logout";
const AUTHCHECK_ENDPOINT = "auth-check";

var Auth = new Request(LOGIN_ENDPOINT);

// export const login = createAction(LOGIN, 
//     loginData => Post(Auth.url(), loginData)() );

export const login = createAction(LOGIN, 
    loginData => Post(Auth.url(), loginData)() );


// export const logout = createAction(LOGOUT, 
//     (id) => {
//         //TODO ADD ID OR TOKEN
//         return Get(Auth.url(LOGOUT_ENDPOINT), id)
// });

export const logout = createAction(LOGOUT_USER, 
    () => {
        return Delete(Auth.url(LOGOUT_ENDPOINT, null), null)()
    }   
);

export const authCheck = createAction(AUTHCHECK, 
    () => {
        //TODO ADD ID
        return Get( Auth.url(AUTHCHECK_ENDPOINT) )
    });

//AUTH
export const setAuthentication = createAction(SET_AUTHENTICATION, payload => payload);