//Constans Actions
//Language
export const SET_LANGUAGE = "SET_LANGUAGE";
//AUTHENTICATION
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTHCHECK = 'AUTHCHECK';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';

//Filters
export const SET_FILTERS = "SET_FILTERS";

//RESULTS
export const FETCH_RESULTS = 'FETCH_RESULTS';
export const SET_RESULT_VIEW = 'SET_RESULT_VIEW';

//DASHBOARD
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';

//USERS
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const INVITE_USER = 'INVITE_USER';
export const CONFIRMATION_USER = 'CONFIRMATION_USER';
export const FORGOT_USER = 'FORGOT_USER';
export const UPDATE_ORGANIZATION_USER = 'UPDATE_ORGANIZATION_USER';
export const FETCH_USERS_ORGANIZATION = 'FETCH_USERS_ORGANIZATION';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const LOGOUT_USER = 'LOGOUT_USER';

//Organizations
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const SET_ORGANIZATION_USER = 'SET_ORGANIZATION_USER';
export const UNLINK_DEVICE = 'UNLINK_DEVICE';