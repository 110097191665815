import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import { SiMicrosoftoutlook, SiGmail, SiWhatsapp } from "react-icons/si";
import Icon from '@material-ui/core/Icon';

import { getCurrentOrganization } from '../../selectors';
import { DarkerTooltip } from 'components/general/commonRenders';

const ContentComponent = (props) => {
  const { organization, donwloadQR, copyToClipboard, user, goToView } = props;

  const [language, setLanguage] = useState(props.language);
  useEffect(() => {
    setLanguage(props.language);
  }, [props.language]);

  /*
   * Variables y metodos para manejar el menu share 
   */
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const open = Boolean(shareAnchorEl);
  const openShareMenu = (event) => {
      setShareAnchorEl(event.currentTarget);
  };

  const closeShareMenu = () => {
      setShareAnchorEl(null);
  };

  /*
   * Constantes para los métodos de compartir el código de empresa 
   */
  let shareCode = organization.assignCode ? organization.assignCode.code : '-';
  let textWhatsapp = language.shareText.whatsappText.replace('<LINKCODE>', shareCode);
  textWhatsapp = textWhatsapp.replace('<ORGANIZATION>', organization.name);
  let textEmail = language.shareText.mailBody.replace('<LINKCODE>', shareCode);
  textEmail = textEmail.replace('<ORGANIZATION>', organization.name);
  const ShareSubjectText = language.shareText.subject.replace('<USER>', user.name);
  const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&su=${ShareSubjectText}&body=${textEmail}`;
  const outlookUrl = `https://outlook.live.com/mail/deeplink/compose?subject=${ShareSubjectText}&body=${textEmail}`;

  const [isHover, setIsHover] = useState(false);

  return (
    <div id="contentLink">
        <Grid container>
            <Grid item xs={12} sm={8} style={{textAlign: "left"}}>
              <Grid item className="blockIntroduction" style={{}}>
                <span className="linkIntroduction">{language.link.introdution}</span>
              </Grid>
              <Grid item className="boxInstruction">
                <img className="img" alt="instruction number one" src={`./../images/number1.svg`} />
                <span className="txt">{language.link.instruction1_1}<strong>{organization.name}</strong>{language.link.instruction1_2}</span>
              </Grid>
              <div id="cardOrganizationQR">
                <Card className="cardQR">
                    <CardContent style={{padding: "0px", textAlign: "center"}}>
                      <Grid container id="cardQrBody">
                        <Grid id="cardHalfCode" item xs={12} md={5} style={{alignSelf: "flex-start"}}>
                          <h5 className="titleCode">{language.link.share_option_1}</h5>
                          <h5 className="codeBox">{organization.assignCode ? organization.assignCode.code : '-'}</h5>
                          <div className="qrButtonContainer">
                            <DarkerTooltip title={language.modals.shareQr.copy}  placement="bottom">
                              <IconButton aria-label="download" className="icon"
                                  onClick={() => { 
                                    copyToClipboard(organization.assignCode ? organization.assignCode.code : '-');
                                  }}>
                                  <Icon className="material-icons-outlined iconMenu">content_copy</Icon>
                              </IconButton>
                            </DarkerTooltip>
                            <DarkerTooltip title={language.modals.shareQr.share} placement="bottom">
                              <IconButton aria-label="share" className="icon"
                                  onClick={openShareMenu}>
                                  <ShareOutlinedIcon fontSize="large"/>
                              </IconButton>
                            </DarkerTooltip>
                            <Menu id="shareMenu"
                              anchorEl={shareAnchorEl}
                              keepMounted
                              open={open}
                              onClose={closeShareMenu} >
                              <MenuItem>
                                <a className="shareLink" 
                                  href={`https://web.whatsapp.com/send?text=${textWhatsapp}`} 
                                  target="_blank" 
                                  rel="noopener noreferrer">
                                  <ListItemIcon>
                                      <SiWhatsapp className="whatsapp" fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit">Whatsapp</Typography>
                                </a>
                              </MenuItem>
                              <MenuItem>
                                  <ListItemIcon>
                                      <SiGmail className="mail" fontSize="small" />
                                  </ListItemIcon>
                                  <a className="shareLink" 
                                    href={`${gmailUrl}`}
                                    target="_blank" 
                                    rel="noopener noreferrer">
                                      <Typography variant="inherit">Gmail</Typography>
                                  </a>
                              </MenuItem>
                              <MenuItem>
                                  <ListItemIcon>
                                      <SiMicrosoftoutlook className="mail" fontSize="small" />
                                  </ListItemIcon>
                                  <a className="shareLink" 
                                    href={`${outlookUrl}`}
                                    target="_blank" 
                                    rel="noopener noreferrer">
                                      <Typography variant="inherit">Outlook</Typography>
                                  </a>
                              </MenuItem>
                              <MenuItem>
                                  <ListItemIcon>
                                      <MailIcon className="mail" fontSize="small" />
                                  </ListItemIcon>
                                  <a className="shareLink" 
                                    href={`mailto:?subject=${ShareSubjectText}&body=${textEmail}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer">
                                      <Typography variant="inherit">Email</Typography>
                                  </a>
                              </MenuItem>
                            </Menu>
                          </div>
                        </Grid>
                        <Grid id="cardHalfQR" item xs={12} md={5} style={{textAlign: "center"}}>
                          <h5 className="titleCode" style={{textAlign: "center"}}>{language.link.share_option_2}</h5>
                          <div id="QRImg" className="QRImg">
                            <SVG src={organization.qrcode} />
                          </div>
                          <div className="qrButtonContainer">
                              <DarkerTooltip title={language.modals.shareQr.donwload}  placement="bottom">
                                <IconButton aria-label="download" className="icon"
                                    onClick={() => { 
                                      donwloadQR();
                                      //ReactPDF.render(<MyDocument {...props} />, `example.pdf`);
                                    }}>
                                    <GetAppOutlinedIcon fontSize="large"/>
                                </IconButton>
                              </DarkerTooltip>
                              {/* MENU SHARE FOR QR */}
                              <Menu id="shareMenu"
                                anchorEl={shareAnchorEl}
                                keepMounted
                                open={open}
                                onClose={closeShareMenu} >
                                <MenuItem>
                                  <a className="shareLink" 
                                    href={`https://web.whatsapp.com/send?text=${textWhatsapp}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer">
                                    <ListItemIcon>
                                        <SiWhatsapp className="whatsapp" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Whatsapp</Typography>
                                  </a>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <SiGmail className="mail" fontSize="small" />
                                    </ListItemIcon>
                                    <a className="shareLink" 
                                      href={`${gmailUrl}`}
                                      target="_blank" 
                                      rel="noopener noreferrer">
                                        <Typography variant="inherit">Gmail</Typography>
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <SiMicrosoftoutlook className="mail" fontSize="small" />
                                    </ListItemIcon>
                                    <a className="shareLink" 
                                      href={`${outlookUrl}`}
                                      target="_blank" 
                                      rel="noopener noreferrer">
                                        <Typography variant="inherit">Outlook</Typography>
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <MailIcon className="mail" fontSize="small" />
                                    </ListItemIcon>
                                    <a className="shareLink" 
                                      href={`mailto:?subject=${ShareSubjectText}&body=${textEmail}`} 
                                      target="_blank" 
                                      rel="noopener noreferrer">
                                        <Typography variant="inherit">{language.modals.shareQr.email_title}</Typography>
                                    </a>
                                </MenuItem>
                              </Menu>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                </Card>
              </div>
              <Grid item className="boxInstruction instruction2">
                <img className="img" alt="instruction number one" src={`./../images/number2.svg`} />
                <span className="txt">{language.link.instruction2_1}</span>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} style={{textAlign: "left"}}>
              <Grid item className="containerDeviceViewButton">
                <Button id="deviceViewButton" type="btn"
                  onMouseEnter={async ()=> {await setIsHover(true); }}
                  onMouseLeave={async ()=> {await setIsHover(false); }}
                  onClick={() => {goToView("device-managment")}}
                  startIcon={
                    isHover ? 
                    <img className="deviceBtnIcon" alt="button icon" src={`./../images/iconCel.svg`} /> :
                    <img className="deviceBtnIcon" alt="button icon" src={`./../images/xploraLink-red.svg`} />
                  }
                  className="btn">
                  {language.link.deviceViewBtn}
                </Button>
              </Grid>
              <Grid item className="blockLinkImg">
                <img className="linkImg" alt="images xplora" src={`./../images/Xplora-phoneapp.png`} />
              </Grid> 
            </Grid>
        </Grid >
    </div>
  );
};

ContentComponent.propTypes = {
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  submitting: PropTypes.bool.isRequired,        //flag dada por redux-form para decirnos si se esta ejecutando el submit
  pristine: PropTypes.bool.isRequired,          //flag dada por redux-form para si el form tiene los mismos valores que al inicio
  invalid: PropTypes.bool.isRequired,           //flag dada por redux-form para si el form tiene algun input invalido.
  error: PropTypes.bool,                        //variable dada por redux-form para decirnos si se regreso un error general a traves del submit del form
  goToView: PropTypes.func.isRequired,          //Func. que te lleva a la url pasada como parametro.
  organization: PropTypes.object.isRequired,    //Variable obtenida del backend con los datos del organización
  donwloadQR: PropTypes.func.isRequired,          //Func. para descargar la imagen qr del usuario.
  copyToClipboard: PropTypes.func.isRequired,     //Func. para copiar el código para enlacede organizacion
};

const mapStateToProps = (state) => ({
  initialValues:  getCurrentOrganization(state)
});

const afterSubmit = (result, dispatch) => {
  dispatch(reset('ContentComponent'));
}
  

let InitializeFromStateForm = reduxForm({
  form:'ContentComponent',
  enableReinitialize: true,
  onSubmitSuccess: afterSubmit,
})(ContentComponent);

export default connect(mapStateToProps, {})(InitializeFromStateForm);