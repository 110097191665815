const en = {
  locale: 'en',

  spanish: 'Spanish',
  english: 'English',

  select_language: 'Select language',

  footer: {
    title: "© 2021 UNIMA, INC. All rights reserved.",
  },

  //Login view
  login: {
    language: 'Language',
    title: 'Dxter',
    log_in: 'Log in to start managing Find™ tests’ results',
    email: 'Email',
    email_placeholder: 'E.g. name@email.com',
    password: 'Password',
    password_placeholder: 'Enter a password',
    start: 'Go to Dxter',
    forgot_password: 'Forgot password?',
    signup_link: 'Create an account',
    signup_label: ' Or ',
    organization_link: ' register your organization ',
    organization_label: ' as administrator ',
    error_confirmation_1: 'User already confirmed',
    error_confirmation_2: 'Failed to do user confirmation',
  },

  //Signup view
  signup: {
    passwordTooltip: "<b>Password must: </b><br>-Be at least 8 characters long <br>-Have at least 1 uppercase (A-Z) <br>-Have at least 1 lowercase (a-z) <br>-Have at least 1 number (0-9)",
    success_register: "A verification link was sent. Check your email to complete the process. If you have not received the email check your spam folder or get a new link.",
    error_register: "We are very sorry, something went wrong while processing the data. Please try again.",
    error_duplicated_register: "The entered email has already been registered. Check the verification link in your email to get access to Dxter.",
    user: {
      title: 'Register as a user',
      subtitle: 'United we go further',
      formTitle: 'Complete this form to get access to Dxter',
      userSectionTitle: 'CONTACT INFO',
      accountSectionTitle: 'PERSONAL ACCOUNT INFO',
      orgSectionTitle: 'ORGANIZATION INFO',
      personalSectionTitle: 'PERSONAL ACCOUNT INFO',
      label_name: 'Contact name',
      input_name: 'Full name',
      label_phone: 'Contact telephone number',
      input_phone: '+___  ( ___ )  _____ - ______',
      label_email: 'Contact email',
      input_email: 'E.g. name@email.com',
      label_extension: 'Ext.',
      label_optional: 'Optional',
      input_extension: 'E.g. 8040',
      label_code: 'User code',
      input_code: '********',
      codeTooltip: 'The user code should be supplied by your organization administrator.',
      label_password: 'Password',
      input_password: 'Enter a password ',
      label_confirm_password: 'Confirm password',
      input_confirm_password: 'Repeat your password',
      already_logged: ' Already registered? ',
      link_login: ' Go to Dxter ',
      submit_btn: 'Register',
    },
    organization: {
      title: 'Register your organization',
      subtitle: 'United we go further',
      formTitle: 'Complete this form to register your organization and get access to Dxter',
      label_name: 'Contact name',
      input_name: 'Full name',
      label_organization: 'Organization',
      input_organization: 'Your organization name',
      label_code: 'Key code',
      input_code: '********',
      codeTooltip: 'The key code should be supplied by your Find™ provider. ',
      label_extension: 'Ext.',
      label_phone_contact: 'Contact telephone number',
      input_extension: 'E.g. 8040',
      label_phone: 'Organization telephone number',
      input_phone: '+___  ( ___ )  _____ - ______',
      label_email: 'Contact email',
      input_email: 'E.g. name@email.com',
      label_password: 'Password',
      input_password: 'Enter a password ',
      label_confirm_password: 'Confirm password',
      input_confirm_password: 'Repeat your password',
      already_logged: ' Already registered? ',
      link_login: ' Go to Dxter ',
      submit_btn: 'Register',
      success_org: 'Organization created successfully',
    },
  },

  //Forgot password view
  forgot: {
    title: 'Password recovery',
    subtitle: 'Enter your registered email, we will send you instructions to reset your password and regain access to Dxter.',
    label_email: 'Email',
    input_email: 'E.g. name@email.com',
    already_logged: ' Already registered? ',
    button: 'Send instructions',
    cancelBtn: 'Cancel',
    success_forgot_password: "Instructions were successfully sent to the requested email.",
    error_forgot_password: "There was a problem while sending instructions. Please try again later.",
  },

  //Not allowed page view
  restrictedPage: {
    title: 'We are sorry,',
    subtitle: 'it seems you do not have access to this page.',
    text: 'You can get access in the option below:',
    button: 'Go to log in',
  },

  //Password recovery view
  passwordRecovery: {
    title: 'Password modification',
    recoveryTitle: 'Password recovery',
    label_CurrentPassword: 'Current password',
    label_NewPassword: 'New password',
    input_NewPassword: 'Enter a password ',
    label_ConfirmPassword: 'Confirm password',
    input_password: 'Repeat your new password',
    input_CurrentPassword: 'Enter your current password',
    input_ConfirmPassword: 'Repeat your new password',
    button: 'Save new password',
    btnCancel: 'Cancel',
    success_forgot_password: "Correo de recuperación de contraseña ha sido enviado",
    success_change_password: "Your password has been successfully updated",
  },

  //Confirm your email view
  confirmationMail: {
    success_send_email: "The email has been sent",
    title: "Email confirmation",
    subtitle: "It is necesary to confirm your email to get access to Dxter. If you have not received the email click on the button to get a new link.",
    label_email: "Email: ",
    button: "Get new link",
    cancelBtn: "Cancel"
  },

  //expiration Link view
  expirationLink: {
    success_send_email: "The mail has been sent.",
    title: "Verification link has expired",
    subtitle: "Enter the email associated with your account and we will send you a new verification link to get access to Dxter™.",
    label_email: "Email ",
    placeholder_email: "E.g. name@email.com",
    button: "Send new link",
    cancelBtn: "Cancel"
  },

  editUser: {
    title: "Edit information",
    label_name: "Name",
    input_name: "Name",
    label_phone: "Contact telephone number",
    input_phone: "+___  ( ___ )  _____ - ______",
    optional: "Optional",
    label_extension: "Ext.",
    input_extension: "E.g. 8040",
    label_email: "Contact email",
    input_email: "E.g. name@email.com",
    btnAccept: "Save changes",
    btnCancel: "Cancel",
    successEdit: "All changes were successfully saved.",
  },

  editOrganization: {
    title: "Edit information",
    label_organization: "Organization",
    input_organization: "Organization name",
    label_phone: "Organization telephone number",
    input_phone: "+___  ( ___ )  _____ - ______",
    input_email: "E.g. organization@email.com",
    label_email: "Organization's email",
    btnAccept: "Save changes",
    btnCancel: "Cancel",
  },

  //Menu (NavBar)
  navbar: {
    dashboard: 'Dashboard',
    results: 'Results',
    link: 'Xplora',
    profile: 'Profile',
    organization: 'Organization',
    logout: 'Log out',
  },

  //DashBoard Section
  dashboard: {
    title: 'General test results data',
    date: 'Registered data until: <date>',
    loader: "Loading data",
    loader_graph: "Loading graph",
    pie_title: "Find™ SARS-CoV-2 test",
    pie_subtitle: "Total results cases: ",
    pie_positive_legend: "<totalCases> detected results",
    pie_negative_legend: "<totalCases> not detected results",
    pie_inderteminate_legend: "<totalCases> indeterminate results",
    pie_results: "",
    pie_total: "",
    line_title: ' sex and age',
    line_male: "Men",
    line_female: "Women",
    line_hAxis: "Age ranges",
    line_vAxis: "Number of cases",
    bubble_day: "by Day",
    bubble_total: "Detected cases",
    bubble_title: "by day",
    bubble_vAxis: "Detected cases",
    bubble_hAxis: "Day",
    bubble_tooltip: "Detected cases:",
    geo_country: "Country",
    geo_cases: "Positive cases",
    geo_title: " region",
    title_prefix: "Total of detected cases ",
    empty_title: "There is no data found",
    empty_text: "Invite the personnel doing the Find™ tests to link their Xplora™ application to this organization in order to view all the obtained tests’ results.",
    share_link: "Share link code",
    error_title: "Sorry, unexpected error",
    error_text: "There was a problem while synchronyzing the data. We are working to fix it. Please try again later."
  },

  //Profile Section
  profile: {
    title: 'User profile',
    loader: "Loading data",
    cardProfile_title: "Contact information",
    card_label_extension: 'EXT: ',
    card_label_name: "NAME: ",
    card_label_org: "ORGANIZATION: ",
    card_label_phone: "CONTACT PHONE NUMBER: ",
    card_label_email: "CONTACT EMAIL: ",
    card_btn_password: "Change password",
    role_user: 'User',
    role_admin: 'Administrator user',
    cardQr: {
      title: "Organization link information",
      subtitle: "Unima S.A. de C.V.",
      code: "CODE: ",
      url: "URL: ",
      link_txt: "https://qrcodexplora.com",
    }
  },

  //Link Section
  link: {
    title: "Xplora™ link organization",
    loader: "Loading data",
    introdution: "To manage the Find™ test results in Dxter, invite the personnel to link their Xplora app to your organization.",
    // introdution2: "to view all the obtained tests’ results.",
    card_organization: "ORGANIZATION: ",
    card_subtitle: "Choose one of the next options to share the link code: ",
    share_option_1: "To enter the link code manually",
    share_option_2: "To link with QR code",
    deviceViewBtn: "Linked phones management",
    instruction1_1: "Send the ",
    instruction1_2: " code to Xplora users.",
    instruction2_1: "Xplora users may find the ORGANIZATION SET UP section in Xplora’s menu and can enter the organization’s code manually or with QR code. ",
  },

  //Device Section
  devices: {
    title: "Linked phones management",
    col_profileId: 'USER ID',
    col_phoneInfo: 'PHONE INFORMATION',
    col_linkDate: 'LINK DATE',
    col_lastDate: 'LAST TEST DATE',
    col_totalTests: 'RAN TESTS',
    clean_filters: 'Clean filters',
    phone_filter_txt: 'Phone',
    linkDate_filter_txt: 'Link date',
    linkDate_from_label: 'From:',
    linkDate_to_label: 'To:',
    tests_filter_txt  : 'Tests',
    tests_from_label: 'From:',
    tests_from_placeholder: 'E.g. 0',
    tests_to_label: 'To:',
    tests_to_placeholder: ' E.g. 250',
    search_filter_txt  : 'Search user ID',
    search_filter_tooltip  : 'The User ID can be found on the <br>Xplora’s menu ORGANIZATION SET <br>UP section.',
    option_unlink  : 'Unlink phone',
    empty_last_test: 'no tests',
    empty_phone_filter: "No options",
  },

  //Profile Section
  organization: {
    title: 'Organization management',
    loader: "Loading data",
    cardProfile_title: "Organization information",
    card_organization: "ORGANIZATION: ",
    card_phone: "REGISTERED PHONE NUMBER: ",
    card_email: "ORGANIZATION’S EMAIL: ",
    cardList_title: "Organization’s users",
    cardList_Subtitle: "USER NAME",
    cardList_Subtitle2: "ACCESS LEVEL",
    make_admin: "Make admin",
    make_user: "Make user",
    delete_user: "Remove user",
    cardQR_title: "Organization link information",
    qrTitle: "Unima S.A. de C.V.",
    qrCode_label: "CODE: ",
    qrUrl_label: "URL: ",
    invite_user_title: "Access invitation to Dxter™",
    invite_user_text: "Add an email to send an invitation to access as a Dxter user. ",
    switch_text: "Give admin access level",
    switch_note: "Activate this option only if you want to invite this user as admin. Admins can manage all the users in Dxter.",
    switch_note1: "<b>Activate this option only if you <br>want to invite this user as admin.</b><br> Admins can manage all the users in <br>Dxter.",
    label_email: 'Email',
    input_email: 'E.g. name@email.com',
    add_mail_button: 'Register organization’s email',
    invite_user_button: 'Invite user',
    success_invite_user: 'Your invitation was successfully sent to the requested email.',
    success_delete_user: 'User has been successfully removed.',
    error_invite_user: 'There was an unexpected error. Please try again later.',
    role_user: 'User',
    role_admin: 'Admin',
    role_mainAdmin: 'Main admin',
    tooltip_edit: 'Edit',
    error_load: "There was a problem while synchronizing the users’ data. We are working to fix it. Please check back later.",
    succes_user_role: "The access level was successfully changed.",
    error_user_role: "There was an unexpected error while changing the access level. Please try again later.",
  },

  //Results Section
  results: {
    title_cards: 'Results - comparative view',
    title_list: 'Results - list view',
    title_individual: 'Results - detailed view',
    date: 'Registered data until: <date>',
    loader: "Loading data",
    empty_search_title: "No matches found",
    empty_search_text: "There is no matches found with current filters, try something else.",
    clean_filters: "Clear filters",
    head: {
      search: "Search",
      download: "Download",
      print: "Print",
      hide_columns: "Columns",
      filter: "Filter",
      view_list: "List view",
      view_cards: "Comparative view",
      view_individual: "Detailed view",
    },
    col_sickness: 'DISEASE',
    col_qrcode: 'QR CODE',
    col_date: 'TEST DATE',
    col_result: 'RESULT',
    col_gender: 'SEX',
    col_age: 'AGE',
    col_region: 'REGION',
    col_userId: 'USER ID',
    cardView: {
      date: "Test date",
      from: "From:",
      to: "To:",
      fromAge: "From:",
      toAge: "To:",
      fromPlaceholderAge: "E.g. 18 years old",
      toPlaceholderAge: "E.g. 50 years old",
      toSuffixAge: "years old",
      fromQR: "From QR code:",
      toQR: "To QR code:",
      fromPlaceholderQR: "E.g. 000000000000",
      toPlaceholderQR: "E.g. 000000000000",
      map_title: "Location map",
      marker_title: "The marker`s title will appear as a tooltip.",
      result_option1: "Result",
      result_option2: "Indeterminate",
      result_option3: "Detected",
      result_option4: "No detected",
      gender_option1: "Sex",
      gender_option2: "Male",
      gender_option3: "Female",
      disease_option1: "Test type",
      disease_option2: "COVID-19",
      disease_option3: "Tuberculosis (TB)",
      qrCode_option1: "QR Code",
      age_option1: "Age",
      region_filter: "Region",
      empty_phone_filter: "No options",
    },
    individual: {
      cardInfo_title: 'Result details',
      map_title: "Location map",
      marker_title: "The marker`s title will appear as a tooltip.",
    },
    pdf: {
      title: "Results list",
      subtitle: "Registered data until: ",
      footer: "Data collected by: ",
      footerData: "<USER> in Dxter™ ",
      page: "Page ",
      pageOf: "of ",
      filtersTilte: "Results parameters focused on: ",
      ageTextFilter: " From <STARTAGE> to <ENDAGE> years old",
      startAgeTextFilter: " From <STARTAGE> years old",
      endAgeTextFilter: " To <ENDAGE> years old",
      dateTextFilter: " From <STARTDATE> to <ENDDATE>",
      startDateTextFilter: " From <STARTDATE>",
      endDateTextFilter: " To <ENDDATE>",
      qrTextFilter: " From <STARTQR> to <ENDQR>",
      startQrTextFilter: " From <STARTQR>",
      endQrTextFilter: " To <ENDQR>",
    },
    donwload_pdf: "Export as PDF",
    loading_pdf: "Loading...",
    donwload_csv: "Export as CSV",
    withoutRegion: "Unspecified"
  },

  ///TABLES  of Users and Registers components
  /*NOTE THIS VALUES ARE FIELD USED IN ALL TABLES*/
  table: {
    search: 'Search',
    exportTitle: 'Export',
    emptyData: 'No records to display',
    rowSelect: 'rows',
    rowsSelected: 'row(s) selected',
    of: 'of',
    actionsName: 'ACTIONS',
  },

  modals: {
    general_success_submit: "Successful updating...",
    accept_button: "SAVE",
    cancel_button: "CANCEL",
    makeAdmininistrador: {
      text: "Administrators have access to organization’s information and manage users in Dxter™.",
      subtitle: "Are you sure you want to give this access level?",
      accept_button: "Yes, change user’s access level ",
      cancel_button: "No, cancel this action",
    },
    makeUser: {
      text: "Changing this user’s access level will remove their permissions and will not be able to invite new users to Dxter™.",
      subtitle: "Are you sure you want to change this access level?",
      accept_button: "Yes, change user’s access level ",
      cancel_button: "No, cancel this action",
    },
    inviteAdmin: {
      text: "You are about to invite someone as a user with admin access level.",
      subtitle: "Do you really wish to give this access level?",
      accept_button: "Yes, send invitation",
      cancel_button: "No, cancel invitation",
    },
    deleteUser: {
      text: "You are about to permanently delete this user and remove their entire access to Dxter™.",
      subtitle: "Are you sure you want to delete this user?",
      accept_button: "Yes, delete user",
      cancel_button: "No, cancel this action",
    },
    confirmLogout: {
      text: "You are about to log out. You can log in next time using your credentials.",
      subtitle: "Do you really wish to log out?",
      accept_button: "Yes, log out",
      cancel_button: "No, stay in Dxter",
    },
    confirmUnlinkDevice: {
      text: "You are about to unlink an Xplora user’s phone from your organization.",
      subtitle: "Do you wish to unlink this phone?",
      accept_button: "Yes, unlink this phone",
      cancel_button: "Do not unlink",
    },
    shareQr: {
      header_title: "Choose an option to share your organization’s link code ",
      donwload: "Download",
      copy: "Copy",
      whatsapp: "Whatsapp",
      email_title: "Another email",
      email: "Email",
      share: "Share",
      print: "Print",
    }
  },

  //Error messages
  errors: {
    general: "There was an unexpected error. Please try again later.",
    required: "This field is required",
    email: "Please enter a valid email",
    passwordMatch: "Passwords don't match",
    number: "This fields must be a number",
    missingPermission: "You don't have access to this section.",
    maxLength: "Password must be at least 8 characters long",
    notLowercase: "Password must have at least 1 lowercase",
    notUppercase: "Password must have at least 1 uppercase",
    notNumber: "Password must have at least 1 number",
    notPhone: "Enter a valid country calling code and phone number",
    expiredLinkGeneral: "Something went wrong while processing the verification. Please try again or get a new link.",
    updateData: "There was an unexpected error while saving changes. Please try again later.",
    linkData: "Something went wrong while loading the Xplora link code. Please check back later.",
    codeInvalidOrg: "The entered key code is incorrect or does not exists. Please be sure to enter an existing code and try again.",
    EMAL001: "Incorrect or invalid email or password. Please try again.",
    EMAL002: "Failed to authenticate user",
    EMAL003: "The entered user code is incorrect or does not exists. Please be sure to enter an existing code and try again.",
    EMAL004: "A organization already exists with this email",
    EMAL005: "The user role could not be found",
    EMAL006: "Error creating organization",
    EMAL007: "The entered user code is incorrect or does not exists. Please be sure to enter an existing code and try again.",
    EMAL008: "There is already a user with that email",
    EMAL009: "Error creating user",
    EMAL010: "Failed to retrieve users",
    EMAL011: "Organization not found",
    EMAL012: "Error retrieving organization information",
    EMAL013: "Failed to retrieve dashboard data",
    EMAL014: "User not found",
    EMAL015: "Passwords do not match",
    EMAL016: "Organization main user can't be demoted",
    EMAL017: "Failed to update user",
    EMAL018: "Error sending user invitation",
    EMAL019: "Failed update the organization",
    EMAL020: "Failed to get user information",
    EMAL021: "You need to confirm your email to access Dxter",
    EMAL022: "Failed to confirm user",
    EMAL023: "Your email is already verified. Log in with your registered data.",
    EMAL024: "You need authorization for this route",
    EMAL025: "Invalid token",
    EMAL026: "Error resetting password",
    EMAL027: "Failed to unlink the phone",
    EMAL028: "The phone to be unlinked was not found",
  },

  shareText: {
    subject: "<USER> invited you to link your Xplora app",
    mailBody: "Hello! %0D%0DThis is an invitation to link your Xplora app to <ORGANIZATION>. %0D%0DJust copy this code: <LINKCODE> %0D%0DThen paste it into the “ORGANIZATION SET UP” section that you can find inside Xplora’s menu to start saving all the Find’s tests results. %0D%0DGreetings!",
    base: "Organization link code ",
    whatsappText: "Hi! I’m inviting you to link your Xplora app to <ORGANIZATION>. %0D%0DJust add the code <LINKCODE> into the “ORGANIZATION SET UP” section that you will find inside Xplora’s menu to start saving all the Find’s tests results. %0D%0DGreetings!",
  },

  filenames: {
    line: "sex-age-graph-",
    bubble: "days-graph-",
    geo: "cases-map-",
    pie: "total-cases-graph-",
    pdfList: "results-list-",
    IndividiualView: "detailed-result-",
    QRCode: "xplora-qrcode-",
  }
}

export default en;