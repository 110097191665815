import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import jwt_decode from "jwt-decode";

import LoginComponent from "./../../../components/LoginComponent";
import { setLanguages, login, setAuthentication } from './../../../actions';
import { getLanguage } from './../../../selectors';
import { renderSnackBarMessage } from '../../../components/general/commonRenders';
import { CONFIRM_EMAIL_URL, DASHBOARD_URL } from 'constants/index';

class LoginContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      openAlert:false,
      openLanguage: false,
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
    }
  }

  componentDidMount(){
    this.checkQueryParams(this.props.location.search, this.props.language);

    //this.checkAuthSession();
  }

  /**
   * Method what is called when we enter to login view and this route has query params 
   * 
   * @param {string} queryParams - String with queryparams on login route
   * @param {object} language - object with translations
  */
  checkQueryParams = (queryParams, language) => {
    if( queryParams.includes("?token=") ){
      this.handleSuccessQueryParams(queryParams, language);
    }else if( queryParams.includes("?error=") ){
      this.handleErrorQueryParams(queryParams, language);
    }
  }

  /**
   * Method what is called when we enter to login view and this route has query params 
   * Note: this route is called when backend succeeds in confirming user mail
   * 
   * @param {string} queryParams - String with queryparams on login route
   * @param {object} language - object with translations
  */
  handleSuccessQueryParams = (queryParams, language) => {
    var token = queryParams.replace('?token=', '');
    var decoded = jwt_decode(token);
    if(decoded.user){
      localStorage.setItem("user", JSON.stringify(decoded.user) );
      localStorage.setItem("token", token);
      this.props.setAuthentication({
        token,
        user: decoded.user
      });
      this.goToView(DASHBOARD_URL);
    }else{
      this.setState({
        snackBarInfo:{
          message: "Formato del token inválido",
          typesnack: 'error',
          openSnackBar: true
        }
      });
    }
    var message =  '';
    this.setState({
      snackBarInfo:{
        message,
        typesnack: 'error',
        openSnackBar: true
      }
    });
  }

  /**
   * Method what is called when we enter to login view and this route has query params 
   * Note: this route is called when backend try to confirm email and has errors
   * 
   * @param {string} queryParams - String with queryparams on login route
   * @param {object} language - object with translations
  */
  handleErrorQueryParams = (queryParams, language) => {
    var error_code = queryParams.replace('?error=', '');
    var message =  '';
    switch(error_code){
      case "error_user_already_assinged":
        message = language.login.error_confirmation_1;
      break;
      case "error_general_check_logs": default: 
        message = language.login.error_confirmation_2;
      break;
    }
    this.setState({
      snackBarInfo:{
        message,
        typesnack: 'error',
        openSnackBar: true
      }
    });
  }

  /**
   * Method what is called on submit of login, this function call to backEnd and go to dashboard
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * @param {string} values.email - string with user's email
   * @param {string} values.password - string with user's password
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  handleLogin = (values) => {
    // console.log("handleLogin", values);
    //this.props.history.push(DASHBOARD);
    return this.props.login(values).then( response => {
        this.successHandleLogin(response);
    }).catch((error)=>{
        this.errorHandleLogin(error, values);
    });
  }

  /**
   * Method when promise of handleLogin success
   * 
   * @param {Object} response - backEnd response transformed to json from api.js
   * 
  */
  successHandleLogin(response){
    // console.log("successHandleLogin", response);
    var backResponse = response.payload.data;
    localStorage.setItem("user", JSON.stringify(backResponse.user) );
    localStorage.setItem("token", backResponse.token);
    this.props.setAuthentication({
      token: backResponse.token,
      user: backResponse.user
    });
    this.goToView(DASHBOARD_URL);
  }

  /**
   * Method when promise of handleLogin fail
   * 
   * @param {Object} error - this object is get from api.js of method validateBackResponse
   * @param {string}  error.message - string with error message
   * @param {integer} [error.code] - string with user's email
   * @param {object}  [error.error] - Object of pairs key-value made specifically for redux-form errors 
   * 
  */
  errorHandleLogin(error, values){
    // console.log("en errorHandleLogin", error, values);
    if(error.code === 422 || error.code === 400){
        throw new SubmissionError(error.error);
    }else{
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      if(error.key === "EMAL021"){
        this.props.history.push(CONFIRM_EMAIL_URL, {email: values.email});
      }else{
        var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.general;
        this.setState({
          snackBarInfo:{ message ,
            typesnack: 'error',
            openSnackBar: true
          }
        });
      }
    }
  }

  /**
   * Change the current view to url sended by parameter
   * 
   * @param {string} url - variable with url of view that we want to go
  */
  goToView = (url) => {
    // console.log("goToView", url)
    this.props.history.push(url);
  }

  /**
   * Method to handle the change of the language selected
  */
  handleChangeLanguage = (values) => {
    // console.log("value", values.target.value);
    var language_code = null;
    switch(values.target.value){
      case this.props.language.english:
        language_code = "en";
      break;
      case this.props.language.spanish:
      default:
        language_code = "es";
      break;
    }

    this.props.setLanguages(language_code);
  }

  render() {
    return (
      <div>
        <LoginComponent 
          onSubmit={this.handleLogin}
          language={this.props.language}
          goToView={this.goToView}
          handleChangeLanguage={this.handleChangeLanguage}
        />
        {renderSnackBarMessage(
            this.state.snackBarInfo.openSnackBar, 
            this.state.snackBarInfo.message, 
            this.state.snackBarInfo.typesnack,
            () => this.setState({snackBarInfo:{openSnackBar: false}}),
            400000)
        }
      </div>
    );
  }
}

LoginContainer.propTypes = {
  setLanguages: PropTypes.func.isRequired,  //Func. obtenida de action para poner el lenguaje en caso de detectar cambios o si no se ha seleccionado
  login: PropTypes.func.isRequired,         //Func. para mandar la solicitud de login
  language: PropTypes.object.isRequired,    //Variable donde se guardan las traducciones del lenguaje
  setAuthentication: PropTypes.func.isRequired,    //Func. obtenida de action para poner los datos de la session
};

const mapStateToProps = (state, props) => ({
  language:  getLanguage(state),
  //auth:      getAuthenthication(state)
});

export default withRouter( connect(mapStateToProps, { 
  setLanguages,
  setAuthentication,
  login
}) (LoginContainer) );