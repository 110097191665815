import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';

import SignUpComponent from "components/SignUpComponent";
import { setLanguages, createUser, setAuthentication, createOrganization } from 'actions';
import { getLanguage } from 'selectors';
import { SIGN_UP_USER, SIGN_UP_ORGANIZATION, CONFIRM_EMAIL_URL, HOME_URL } from "constants/index";
import { renderSnackBarMessage } from 'components/general/commonRenders';


class SignUpContainer extends Component {
  constructor(props) {
    super(props);
    this.signUpType = null;
    this.state = {
      openAlert: false,
      message: '',
      openLanguage: false,
      backError: '',
      typesnack: "info",
      openSnackBar: false,
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
  }

  handleSubmit(values) {

    console.log(" sign up handleSubmit", values);
    if (this.signUpType === SIGN_UP_USER) {
      this.handleSignUpUser(values);
    } else if (this.signUpType === SIGN_UP_ORGANIZATION) {
      this.handleSignUpOrganization(values);
    }
  }

  /**
   * Method what is called on submit of sign up create a user in database
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * @param {string} values.name - string with contact name
   * @param {string} values.code - string with code
   * @param {string} values.email - string with email
   * @param {string} values.phone - string with phone
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  handleSignUpUser = (values) => {
    // console.log("handleSignUpUser", values);
    values.phone = values.phone.replace(/\s/g, "");

    return this.props.createUser(values).then(response => {
      this.successSignUpUser(response, values);
    }).catch((error) => {
      this.errorSignUpUser(error);
    });
  }

  /**
    * Method when promise of handleSignUp success
    * 
    * @param {Object} response - backEnd response transformed to json from api.js
    * 
  */
  successSignUpUser(response, values) {
    // console.log("successSignUpUser", response);
    setTimeout(() => {
      this.props.history.push(CONFIRM_EMAIL_URL, { email: values.email, showSnackbar: true, snackbarMessage: this.props.language.signup.success_register });
    }, 2000);

  }

  /**
    * Method when promise of handleSignUp error
    * 
    * @param {Object} error - backEnd error transformed to json from api.js
    * 
  */
  errorSignUpUser(error) {
    // console.log("errorSignUpUser", error);
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.general;
    this.setState({
      snackBarInfo: {
        message,
        openSnackBar: true,
        typesnack: 'error'
      }
    });
  }

  /**
   * Method what is called on submit of sign up create a organization in database
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * @param {string} values.name - string with contact name
   * @param {string} values.code - string with code
   * @param {string} values.organization - string with code
   * @param {string} values.email - string with email
   * @param {string} values.phone - string with phone
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  handleSignUpOrganization = (values) => {
    values.mainUser = values.email;
    values.phone = values.phone.replace(/\s/g, "");
    values.contactPhone = values.contactPhone.replace(/\s/g, "");
    // console.log("handleSignUpOrganization", values);

    return this.props.createOrganization(values).then(response => {
      this.successSignUpOrganization(response, values);
    }).catch((error) => {
      this.errorSignUpOrganization(error);
    });
  }

  /**
    * Method when promise of handleSignUp success
    * 
    * @param {Object} response - backEnd response transformed to json from api.js
    * 
  */
  successSignUpOrganization(response, values) {
    // console.log("successSignUpOrganization", response);
    setTimeout(() => {
      this.props.history.push(CONFIRM_EMAIL_URL, { email: values.email, showSnackbar: true, snackbarMessage: this.props.language.signup.success_register });
    }, 2000);
  }

  /**
   * Method when promise of handleSignUp fail
   * 
   * @param {Object} error - this object is get from api.js of method validateBackResponse
   * @param {string}  error.message - string with error message
   * @param {integer} [error.code] - string with user's email
   * @param {object}  [error.error] - Object of pairs key-value made specifically for redux-form errors 
   * 
  */
  errorSignUpOrganization(error) {
    // console.log("en errorHandleLogin", error);
    if (error.code === 422 || error.code === 400) {
      throw new SubmissionError(error.error);
    } else {
      var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.general;
      if (error.key === "EMAL003" || error.key === "EMAL007") {
        message = this.props.language.errors.codeInvalidOrg;
      }

      this.setState({
        snackBarInfo: {
          message,
          openSnackBar: true,
          typesnack: 'error'
        }
      });
    }
  }

  /**
   * Change the current view to login url
   * 
  */
  goToLogin = () => {
    this.props.history.push(HOME_URL);
  }

  /**
   * Method to handle the change of the language selected
  */
  handleChangeLanguage = (values) => {
    // console.log("value", values.target.value);
    var language_code = null;
    switch (values.target.value) {
      case this.props.language.english:
        language_code = "en";
        break;
      case this.props.language.spanish:
      default:
        language_code = "es";
        break;
    }

    this.props.setLanguages(language_code);
  }

  render() {
    const { location } = this.props;
    var urlEndpoints = location.pathname.split("/");
    this.signUpType = urlEndpoints[urlEndpoints.length - 1];
    return (
      <div>
        <SignUpComponent
          signUpType={this.signUpType}
          language={this.props.language}
          handleSubmit={this.handleSubmit}
          goToLogin={this.goToLogin}
          handleChangeLanguage={this.handleChangeLanguage}
        />
        {renderSnackBarMessage(
          this.state.snackBarInfo.openSnackBar,
          this.state.snackBarInfo.message,
          this.state.snackBarInfo.typesnack,
          () => this.setState({ snackBarInfo: { openSnackBar: false } }),
          4000)
        }
      </div>
    );
  }
}

SignUpContainer.propTypes = {
  setLanguages: PropTypes.func.isRequired,  //Func. obtenida de action para poner el lenguaje en caso de detectar cambios o si no se ha seleccionado
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
};

const mapStateToProps = (state, props) => ({
  language: getLanguage(state),
  //auth:      getAuthenthication(state)
});


export default withRouter(connect(mapStateToProps, {
  setLanguages,
  createUser,
  setAuthentication,
  createOrganization
})(SignUpContainer));