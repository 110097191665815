import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import HeadDeviceComponent from './HeadDeviceComponent.js';
import ContentComponent from './ContentComponent.js';
import ModalContainer from '../../containers/general/ModalContainer/ModalContainer';
import useStyles from "../../style";
import './styles.css';

const DeviceComponent = (props) => {
  const { headInfo, language, organization, user, goToView, handleFilter, 
    unlinkDevice, cleanFilters, isFilterActive, optionsFilterPhone, handleOpenModal, 
    handleModalSubmit, modalData, openModal
  } = props;

  const classes = useStyles();

  const [filteredDevices, setfilteredDevices] = useState(props.filteredDevices);
  useEffect(() => {
    setfilteredDevices(props.filteredDevices);
  }, [props.filteredDevices]);

  return (
      <Typography component={'div'}>
        <HeadDeviceComponent 
          headInfo={headInfo}
          language={language}
          goToView={goToView}
        />
        <ContentComponent language={language}
            goToView={goToView}
            organization={organization}
            devices={filteredDevices}
            handleFilter={handleFilter}
            cleanFilters={cleanFilters}
            isFilterActive={isFilterActive}
            unlinkDevice={unlinkDevice}
            optionsFilterPhone={optionsFilterPhone}
            user={user} /> 
        <ModalContainer id="modalContainerDeviceView" classes={classes}
            handleOpenModal={handleOpenModal}
            handleSubmit={handleModalSubmit}
            modalData={modalData}
            openContainer={openModal}/>
      </Typography>
  );
};

DeviceComponent.propTypes = {
  headInfo: PropTypes.shape({                     //Objeto con la información del header
    title: PropTypes.string.isRequired,           //Titulo que se mostrará en header
    subtitle: PropTypes.string.isRequired         //Subtitulo que se mostrará en header
  }),
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  goToView: PropTypes.func.isRequired,            //Func. que te lleva a la url pasada como parametro.
  devices: PropTypes.array.isRequired,            //Variable obtenida del backend, dispostivos de la organización
  filteredDevices: PropTypes.array.isRequired,    //Lista de dispositivos filtrada de acuerdo a los filtros
  user: PropTypes.object.isRequired,              //Variable obtenida del localstorage con los datos del usuario
  handleFilter: PropTypes.func.isRequired,        //Func. para el evento de cambio en los filtros
  cleanFilters: PropTypes.func.isRequired,        //Func. para limpiar los filtros utilizados
  isFilterActive: PropTypes.bool.isRequired,      //Dato que nos dice si hay algun filtro activo
  unlinkDevice: PropTypes.func.isRequired,        //Func. para limpiar los filtros utilizados
  optionsFilterPhone: PropTypes.array.isRequired, //Array con las opciones que estaran en el filtro de "Telefono"
  handleOpenModal: PropTypes.func.isRequired,     //Func. para llamar al evento de abrir o cerrar el modal
  openModal: PropTypes.bool.isRequired,           //Nos dice si el modal esta abierto.
  modalData: PropTypes.shape({                    //Información a para mandar al modalContainer, para generar el contenido
    ModalSelected: PropTypes.elementType,   //Modal Component to render in modal Container
    modalInfo: PropTypes.object        //Objeto con datos a usar en el modal, el objeto es especifico por cada modalComponent por lo que puede ser opcional y diferente entre cada uno
  }),
  handleModalSubmit: PropTypes.func.isRequired,   //Func. para el evento del boton invitar usuario
};

DeviceComponent.defaultProps  = {
  listUsers: [], 
};

export default DeviceComponent;