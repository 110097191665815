import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

import FiltersComponent from './FiltersComponent/FiltersComponent';

const mapStyles = {
  width: '100%',
  height: '100%',
};

/**
 * Método para la renderizacion de los elementos de la lista en cada card
 * 
 * @param {string} title - Titulo de la columna
 * @param {string} info- Informacion correspondiente al titulo
 *    
 * @returns {array} JSX a renderizar
*/
const renderCardListItem = (title, info) => {
  return (<div className="card-list-item">
    <div className="title">
      <span>{title}</span>
    </div>
    <div className="info">
      <span>{info}</span>
    </div>
  </div>);
}

const CardContentComponent = (props) => {
  const { results, language } = props;

  return (
    <div id="contentResults">
      <Grid container style={{ paddingTop: "0px", height: "100%" }}>
        <FiltersComponent {...props} />
        {results.length > 0 ?
          results.map(element => {
            return (<Grid item xs={12} sm={6} md={3} lg={3} key={element._id} style={{ marginBottom: "15px" }}>
              <Card className="cardResult">
                <CardContent style={{ padding: "0px", textAlign: "center" }}>
                  <List style={{ padding: "0px" }}>
                    {renderCardListItem(language.results.col_sickness, element.disease[0].name)}
                    {renderCardListItem(language.results.col_qrcode, element.qrCode)}
                    {renderCardListItem(language.results.col_date, element.createdAt)}
                    {renderCardListItem(language.results.col_result, element.disease[0].result)}
                    {renderCardListItem(language.results.col_gender, element.gender)}
                    {renderCardListItem(language.results.col_age, element.age)}
                    {/* {renderCardListItem(language.results.col_region, element.region)} */}
                    <div className="MapContainer mapcardView" style={{ height: "140px" }}>
                      <div className="map-title">{language.results.cardView.map_title}</div>
                      <Map
                        google={props.google}
                        zoom={14}
                        initialCenter={{ lat: element.location.latitude, lng: element.location.longitude }}
                        zoomControl={false}
                        mapTypeControl={false}
                        scaleControl={false}
                        streetViewControl={false}
                        draggable={false}
                        scrollwheel={false}
                        rotateControl={false}
                        fullscreenControl={false}
                        style={mapStyles}>
                        <Marker name={'test_location'}
                          //title={language.results.cardView.marker_title}
                          title=""
                          icon={{
                            url: "./../../images//unima-marker.png",
                            anchor: new props.google.maps.Point(32, 32),
                            scaledSize: new props.google.maps.Size(32, 32)
                          }}
                          position={{ lat: element.location.latitude, lng: element.location.longitude }} />
                      </Map>
                    </div>
                  </List>
                </CardContent>
              </Card>
            </Grid>);
          })
          :
          <Grid container className="emptySearchBody">
            <h5>{language.results.empty_search_title}</h5>
            <p>{language.results.empty_search_text}</p>
          </Grid>
        }
      </Grid>
    </div>
  );
};

CardContentComponent.propTypes = {
  results: PropTypes.array.isRequired,            //Respuesta al fetchResults con los datos del backEnd
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  handleFilter: PropTypes.func.isRequired,        //Func. para el evento de cambio en los filtros
  filters: PropTypes.object.isRequired,          //Objeto con los filtros que se han aplicado
};

const ReduxCardContentComponent = reduxForm({
  form: 'CardContentComponent',
  enableReinitialize: true
})(CardContentComponent);

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCF6MkvOrGvo1BbTf63ljAEKPKEVe6uXU4'
})(ReduxCardContentComponent);