import React from 'react';
import PropTypes from 'prop-types';
import Drawer from "@material-ui/core/Drawer";
import { Nav, NavItem } from "reactstrap";
import useStyles from "../../../style";
import { AppBar, Hidden } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import './menu.css';
import { MENU_LOGOUT } from '../../../config';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import { DASHBOARD_URL } from 'constants/index';
//import { DarkerTooltip } from '../commonRenders';

const MenuComponent = (props) => {
  const { menu, logoutClick } = props;
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  /**
   * Método para abrir o cerrar el menu cuando esta en vista para celular
  */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  /**
   * Método para renderizar las opciones del menu
   *   
   * @return JSX con las opciones del menu
  */
  const renderDrawerOptions = () => {

    return (<PerfectScrollbar>
      <Nav vertical id="Menu">
        <NavItem id="MenuLogo">
          <NavLink to={DASHBOARD_URL}>
            <img className="imgMenuLogo" src="../images/logo_dxter.png" alt="Dxter logo" />
          </NavLink>
        </NavItem>
        {menu.map(element => {
          let isActiveUrl = element.activesUrl.includes(props.location.pathname);
          return (<NavItem key={element.code} style={{ height: "50px", textAlign: "center" }}>
            {element.code === MENU_LOGOUT ?
              <div className="menuLogout" onClick={element.code === MENU_LOGOUT ? logoutClick : undefined}>
                {element.customIcon ?
                  isActiveUrl ? 
                  //props.location.pathname === element.url ?
                    <img className="IconCustom iconMenu" alt="icon link view" src={`./../${element.customIconActive}`} /> :
                    <img className="IconCustom iconMenu" alt="icon link view" src={`./../${element.customIcon}`} />
                  :
                  <Icon className="material-icons-outlined iconMenu">{element.icon}</Icon>}
                {/* <DarkerTooltip title={element.title} placement="right">
                    <Icon className="material-icons-outlined iconMenu">{element.icon}</Icon>
                </DarkerTooltip> */}
                <span className={`textMenu ${isActiveUrl ? 'activeUrl' : '' }`} style={{ display: "block", fontFamily: "Sarabun" }}>{element.title}</span>
              </div>
              :
              <NavLink to={element.url} activeStyle={{
                color: "#D9514E",
              }} onClick={element.code === MENU_LOGOUT ? logoutClick : undefined}>
                {element.customIcon ?
                  isActiveUrl ?
                  //props.location.pathname === element.url ?
                    <img className="IconCustom iconMenu" alt="icon linkView" src={`./../${element.customIconActive}`} /> :
                    <img className="IconCustom iconMenu" alt="icon linkview" src={`./../${element.customIcon}`} />
                  :
                  <Icon className="material-icons-outlined iconMenu">{element.icon}</Icon>}
                {/* <DarkerTooltip title={element.title} placement="right">
                    <Icon className="material-icons-outlined iconMenu">{element.icon}</Icon>
                </DarkerTooltip> */}
                <span className={`textMenu ${isActiveUrl ? 'activeUrl' : '' }`} style={{ display: "block", fontFamily: "Sarabun" }}>{element.title}</span>
              </NavLink>}
          </NavItem>);
        })
        }
      </Nav>
    </PerfectScrollbar>);
  }

  return (<div>
    <Hidden smUp={true}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className="toolbarMenu">
          <IconButton aria-label="open drawer"
            style={{ marginLeft: "auto" }}
            onClick={handleDrawerToggle}
            className={classes.menuButton} >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Hidden>
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp={true}>
        <Drawer className={classes.drawer} variant="temporary" anchor="left" open={mobileOpen}
          onClose={handleDrawerToggle}>
          {renderDrawerOptions()}
        </Drawer>
      </Hidden>
      <Hidden xsDown={true}>
        <Drawer className={classes.drawer} variant="permanent" open>
          {renderDrawerOptions()}
        </Drawer>
      </Hidden>
    </nav>
  </div>);


};

MenuComponent.propTypes = {

  menu: PropTypes.arrayOf(                         //Menu con la informacion a mostrar, array de objetos
    PropTypes.shape({                              //Opciones del menu. Objeto con el que se comforma el array
      code: PropTypes.string.isRequired,           //Variable con identificador de cada menu
      title: PropTypes.string.isRequired,         //Titulo de la opción del menu
      icon: PropTypes.string.isRequired,           //Icono que se mstrará en esta opción del menu
      url: PropTypes.string.isRequired,           //Url a la view a la que te llevará en caso del click
    })
  ).isRequired,
  logoutClick: PropTypes.func.isRequired,          //Func. para cerrar sesion en el portal
};

//export default MenuComponent;
export default withRouter(MenuComponent);
