import React, { useEffect, useState,  } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, FormGroup, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import {
  Field,
  //reduxForm 
} from 'redux-form';
import { 
  //Font, 
  // pdf,
  PDFDownloadLink,
  //PDFViewer 
} from '@react-pdf/renderer';
import moment from 'moment';
import { CSVLink } from "react-csv";
import Select, { components } from 'react-select';

import useStyles from "../../../style";
import { INDETERMINATE, NEGATIVE, POSITIVE, VIEW_LIST, VIEW_INDIVIDUAL } from 'constants/index';
import { inputCalendar, inputOnlyNumbers, renderTextField, renderSelectWithLabel } from '../../general/Form';
import { DarkerTooltip } from 'components/general/commonRenders';
import { transformToCsvData } from 'helpers/transforms/registersTransforms';
import PDFComponent from './pdfComponent';
// import { saveAs } from 'file-saver';
import './styles.css';

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      disableRipple: true // No more ripple
    }
  }
});

const menuOriginOptions = {
  vertical: 'bottom',
  horizontal: 'left',
}

var inputTimer = null;
var genderSelectOptions = [], diseaseSelectOptions = [];

/**
 * Método para poner las opciones en los select según el idioma seleccionado
 * 
 * @param {object} language - Objeto con todos los textos del lenguaje seleccionado
*/
const setLanguageOnSelectOptions = (language) => {
  genderSelectOptions = [
    // {code: "gender", value: language.results.cardView.gender_option1},
    { code: '', value: language.results.cardView.gender_option2 },
    { code: '', value: language.results.cardView.gender_option3 },
  ];

  diseaseSelectOptions = [
    // {code: "disease", value: language.results.cardView.disease_option1},
    { code: "disease", value: language.results.cardView.disease_option2 },
    { code: "disease", value: language.results.cardView.disease_option3 },
  ];
}

let FiltersComponent = (props) => {
  const { results, handleFilter, cleanFilters, isFilterActive, typeView, handleDownloadSection,
    handleToggleColumns, columnsResults, handleToggleResultColumns, user, reset, 
    optionsFilterRegion, filters } = props;
  const classes = useStyles();
  const maxStartDate = new Date();
  
  useEffect(() => {
    console.log("cambio en filters");
  }, [filters.startAge, filters.endAge, filters.startQRCode, filters.endQRCode, filters.disease]);

  /**
   * Métodos y variables necesarios para el manejo del lenguaje
  */
  const [language, setLanguage] = useState(props.language);
  setLanguageOnSelectOptions(props.language)
  useEffect(() => {
    setLanguage(props.language);
    setLanguageOnSelectOptions(props.language);
  }, [props.language]);

  /**
    * Métodos y variables necesarios para el manejo de las columnas en la tabla (ocultarlaas y mostrarlas)
  */
  const [columnsToShow, setColumnsToShow] = useState(props.columnsToShow);
  const [anchorColumn, setAnchorColumn] = useState(null);
  useEffect(() => {
    setColumnsToShow(props.columnsToShow);
  }, [props.columnsToShow]);

  const handleClick = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const ClickToggleColumn = (menuSelected) => {
    handleToggleColumns(menuSelected);
  };
  const handleCloseMenuColumns = (menuSelected) => {
    setAnchorColumn(null);
  };

  /**
    * Métodos y variables necesarios para el manejo de los filtros de fecha
  */
  const [anchorDate, setAnchorDate] = useState(null);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(new Date());
  const handleClickDateSelect = (event) => {
    setAnchorDate(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorDate(null);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleFilter("startDate", date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleFilter("endDate", date);
  };

  /**
   * Métodos y variables necesarios para el manejo de las opciones del filtro de edad
   */
  const [gender, setGender] = useState('');
  const [startAge, setStartAge] = useState(null);
  const [endAge, setEndAge] = useState(null);
  const [anchorAge, setAnchorAge] = useState(null);
  const openAgeMenu = (event) => {
    setAnchorAge(event.currentTarget);
  };
  const closeAgeMenu = () => {
    setAnchorAge(null);
  };
  const handleStartAgeChange = (data) => {
    setStartAge(data.target.value);
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      console.log("handleStartAgeChange timeout", data.target.value);
      handleFilter("startAge", data.target.value);
    }, 750);
  };
  const handleEndAgeChange = (data) => {
    setEndAge(data.target.value);
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      console.log("handleEndAgeChange timeout", data.target.value)
      handleFilter("endAge", data.target.value);
    }, 750);
  };

  /**
   * Métodos y variables necesarios para el manejo de las opciones del filtro de qrCode
   */
  const [startQRCode, setStartQRCode] = useState(null);
  const [endQRCode, setEndQRCode] = useState(null);
  const [anchorQRCode, setAnchorQRCode] = useState(null);
  const openQRCodeMenu = (event) => {
    setAnchorQRCode(event.currentTarget);
  };
  const closeQRCodeMenu = () => {
    setAnchorQRCode(null);
  };
  const handleStartQRCodeChange = (data) => {
    setStartQRCode(data.target.value);
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      handleFilter("startQRCode", data.target.value);
    }, 750);
  };
  const handleEndQRCodeChange = (data) => {
    setEndQRCode(data.target.value);
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      handleFilter("endQRCode", data.target.value);
    }, 750);
  };

  /**
   * Métodos y variables necesarios para el manejo de las opciones del filtro de resultado
   */
  const [anchorResult, setAnchorResult] = useState(null);
  const openResultMenu = (event) => {
    setAnchorResult(event.currentTarget);
  };
  const closeResultMenu = (menuSelected) => {
    setAnchorResult(null);
  };
  const ClickResultsColumns = (menuSelected) => {
    handleToggleResultColumns(menuSelected);
  };
  const handleChangeOnFilters = (event, newValue, previousValue, name) => {
    if (gender === 'gender') {
      setGender(newValue);
    }

    handleFilter(name, newValue);
  }

  /**
   * Métodos y variables necesarios para el manejo de la opcion de filtro de search
   */
  const [searchValue, setSearchVallue] = useState('');
  var handleSearchOnChangeFilter = (event) => {
    setSearchVallue(event.target.value);
  }
  useEffect(() => {
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      handleFilter("search", searchValue);
    }, 750);
  }, [searchValue, handleFilter]);

  /**
    * Métodos y variables necesarios para el manejo de las columnas en la tabla (ocultarlaas y mostrarlas)
  */
  const [anchorDownloadMenu, setAnchorDownloadMenu] = useState(null);
  const openMenuDownload = (event) => {
    setAnchorDownloadMenu(event.currentTarget);
  };
  const closeMenuDownload = (menuSelected) => {
    setAnchorDownloadMenu(null);
  };

  const [isSelected, setIsSelected] = useState(false);
  const [valueRegion, setValueRegion] = useState(null);
  const handleChangeRegion = (value, action) => {
    setValueRegion(value);
    handleFilter("region", value.value);
  }

  const handleCleanFilters = async () => {
    console.log("handleCleanFilters", language.results.cardView.gender_option1);
    reset();
    props.change("disease", '');
    props.change("startQRCode", null);
    props.change("endQRCode", null);
    await setValueRegion(null);
    await setStartDate(undefined);
    await setEndDate(new Date());
    await setStartAge(null);
    await setEndAge(null);
    await setStartQRCode(oldValue => '');
    await setEndQRCode(oldValue => '');
    cleanFilters();
  }

  const getFilename = (base) => {
    return language.filenames.pdfList + moment(new Date()).format("yyyy-MM-DD");
  }

  let csvData = transformToCsvData(props);

  // const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  return (
    <Grid id="filtersBox" item xs={12} style={{ textAlign: "left", marginBottom: "40px" }}>
      {/* Filter for  type disease */}
      <div className="selectContainer">
        <FormGroup id="diseaseSelect">
          <Field id="disease" name="disease" key="disease"
            classes={classes}
            onChange={handleChangeOnFilters}
            label={language.results.cardView.disease_option1}
            options={diseaseSelectOptions}
            component={renderSelectWithLabel}
          />
        </FormGroup>
      </div>

      {/* Filter for  type qr */}
      <div className="selectContainer selectQr">
        <Button elevation={0}
          style={{ marginTop: "0px" }}
          className="btn select-group"
          variant="contained"
          onClick={openQRCodeMenu}>
          {language.results.cardView.qrCode_option1} <ArrowDropDownIcon />
        </Button>
        <Menu id="qrMenu" keepMounted
          elevation={2}
          getContentAnchorEl={null}
          anchorOrigin={menuOriginOptions}
          anchorEl={anchorQRCode}
          open={!!anchorQRCode}
          onClose={closeQRCodeMenu}>
          <MenuItem disableRipple id="MenuQr">
            <FormGroup className="group_qr">
              <Field id="startQRCode" name="startQRCode"
                classes={classes}
                label={language.results.cardView.fromQR}
                placeholder={language.results.cardView.fromPlaceholderQR}
                value={startQRCode}
                onChange={handleStartQRCodeChange}
                component={renderTextField}
              />
            </FormGroup>
            <FormGroup className="group_qr">
              <Field id="endQRCode" name="endQRCode"
                classes={classes}
                label={language.results.cardView.toQR}
                placeholder={language.results.cardView.toPlaceholderQR}
                value={endQRCode}
                onChange={handleEndQRCodeChange}
                component={renderTextField}
              />
            </FormGroup>
          </MenuItem>
        </Menu>
      </div>

      {/* Filter for  type date */}
      <div className="selectContainer selectDate">
        <Button elevation={0}
          style={{ marginTop: "0px" }}
          className="btn select-group "
          aria-controls="menu-date"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClickDateSelect}>
          {language.results.cardView.date} <ArrowDropDownIcon />
        </Button>
        <Menu id="dateMenu" keepMounted
          elevation={2}
          getContentAnchorEl={null}
          anchorOrigin={menuOriginOptions}
          anchorEl={anchorDate}
          open={!!anchorDate}
          onClose={handleClose}>
          <MenuItem disableRipple id="MenuDate">
            <FormGroup className="group_date">
              <Field id="date_start" name="date_start"
                classes={classes}
                label={language.results.cardView.from}
                type="input"
                dateValue={startDate}
                maxDate={maxStartDate}
                handleChange={handleStartDateChange}
                component={inputCalendar}
              />
            </FormGroup>
            <FormGroup className="group_date">
              <Field id="date_end" name="date_end"
                classes={classes}
                label={language.results.cardView.to}
                type="input"
                dateValue={endDate}
                minDate={startDate}
                maxDate={maxStartDate}
                handleChange={handleEndDateChange}
                component={inputCalendar}
              />
            </FormGroup>
          </MenuItem>
        </Menu>
      </div>

      {/* Filter for  type result */}
      <div className="selectContainer">
        <Button elevation={0}
          style={{ marginTop: "0px" }}
          className="btn select-group"
          aria-controls="menu-date"
          aria-haspopup="true"
          variant="contained"
          onClick={openResultMenu}>
          {language.results.cardView.result_option1} <ArrowDropDownIcon />
        </Button>
        <Menu
          id="MenuColumns"
          anchorEl={anchorResult}
          keepMounted
          open={Boolean(anchorResult)}
          onClose={closeResultMenu}>
          <MenuItem id="ColumnsInderterminate" onClick={() => { ClickResultsColumns(INDETERMINATE) }}>
            <Checkbox checked={columnsResults.indeterminate} />
            {language.results.cardView.result_option2}
          </MenuItem>
          <MenuItem id="ColumnsPositive" onClick={() => { ClickResultsColumns(POSITIVE) }}>
            <Checkbox checked={columnsResults.positive} />
            {language.results.cardView.result_option3}
          </MenuItem>
          <MenuItem id="ColumnsNegative" onClick={() => { ClickResultsColumns(NEGATIVE) }}>
            <Checkbox checked={columnsResults.negative} />
            {language.results.cardView.result_option4}
          </MenuItem>
        </Menu>
      </div>

      {/* Filter for  type gender */}
      <div className="selectContainer">
        <FormGroup id="genderSelect">
          <Field id="gender" name="gender" key="gender"
            classes={classes}
            value={gender}
            onChange={handleChangeOnFilters}
            label={language.results.cardView.gender_option1}
            options={genderSelectOptions}
            component={renderSelectWithLabel}
          />
        </FormGroup>
      </div>

      {/* Filter for  type age */}
      <div className="selectContainer selectAge">
        <Button elevation={0}
          style={{ marginTop: "0px" }}
          className="btn select-group"
          variant="contained"
          tabIndex="3"
          onClick={openAgeMenu}>
          {language.results.cardView.age_option1} <ArrowDropDownIcon />
        </Button>
        <Menu id="ageMenu" keepMounted
          elevation={2}
          getContentAnchorEl={null}
          anchorOrigin={menuOriginOptions}
          anchorEl={anchorAge}
          open={!!anchorAge}
          onClose={closeAgeMenu}>
          <MenuItem disableRipple id="MenuAge">
            <FormGroup className="group_age">
              <Field id="age_start" name="age_start"
                classes={classes}
                label={language.results.cardView.fromAge}
                NumberValue={startAge}
                type="input"
                placeholder={language.results.cardView.fromPlaceholderAge}
                handleChange={handleStartAgeChange}
                formatNumber="### años"
                component={inputOnlyNumbers}
              />
            </FormGroup>
            <FormGroup className="group_age">
              <Field id="age_end" name="age_end"
                classes={classes}
                label={language.results.cardView.toAge}
                placeholder={language.results.cardView.toPlaceholderAge}
                NumberValue={endAge}
                type="input"
                suffix={language.results.cardView.toSuffixAge}
                handleChange={handleEndAgeChange}
                formatNumber="### años"
                component={inputOnlyNumbers}
              />
            </FormGroup>
          </MenuItem>
        </Menu>
      </div>

      {/* Filter for  type region */}
      <div className="selectContainer">
          <Select
            id="regionSelect"
            className="regionSelect"
            classNamePrefix="regionSelect"
            placeholder={isSelected ? '' : language.results.cardView.region_filter}
            value={valueRegion}
            onChange={handleChangeRegion}
            onFocus={(_) => {
              setIsSelected(true);
            }}
            onBlur={(_) => {
              setIsSelected(false);
            }}
            noOptionsMessage={() => language.results.cardView.empty_region_filter}
            setValue={valueRegion}
            menuPosition="absolute"
            components={{ 
              DropdownIndicator : (props) => {
                let { isFocused } = props
                return (!isFocused &&
                  <components.DropdownIndicator {...props}>
                    <ArrowDropDownIcon style={{color: "#d9514e"}} />
                  </components.DropdownIndicator>
                );
              },
            }}
            styles={{
                menu: (provided, state) => ({
                  zIndex: 9999,
                  background: "white"
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...styles,
                    backgroundColor: isFocused ? "#d9514e33" : null,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    color: "#3F4443",
                    ':active': {
                      ...styles[':active'],
                      backgroundColor: !isDisabled && (isSelected ? data.color : "#d9514e33"),
                      color: "#3F4443"
                    },
                  };
                },
            }}
            options={optionsFilterRegion}
          />
      </div>

      {/* Btn for Clean Filters */}
      <div className="selectContainer selectClean">
        <DarkerTooltip title={language.results.clean_filters} placement="bottom">
          <div style={{ display: "inline-block" }}>
            <IconButton
              className="icon iconCleanFilter"
              size="medium"
              disabled={!isFilterActive}
              onClick={handleCleanFilters}>
                {isFilterActive ?
                  <img className="cleanFiltersIcon" alt="button icon" src={`./../images/clean_filters_active.png`} /> :
                  <img className="cleanFiltersIcon" alt="button icon" src={`./../images/clean_filters_disabled.png`} />
                }
              {/* <DeleteOutlineOutlinedIcon fontSize="large" /> */}
            </IconButton>
          </div>
        </DarkerTooltip>
      </div>

      {/* DOWNLOAD TABLE RESULTS BUTTON */}
      {typeView === VIEW_LIST &&
        (results.length ?
          <>
            <DarkerTooltip title={language.results.head.download} placement="bottom">
              <IconButton className="icon iconFilter btnHeaderFilter columns" size="medium"
                onClick={openMenuDownload}>
                <GetAppOutlinedIcon fontSize="large" />
              </IconButton>
            </DarkerTooltip>
            <Menu
              id="simple-menu"
              anchorEl={anchorDownloadMenu}
              keepMounted
              open={Boolean(anchorDownloadMenu)}
              onClose={closeMenuDownload}>
              <MenuItem>
                {/* <Button
                  className="titlePDF"
                  onClick={async () => {
                    console.log("onClick");
                    await delay(1000);
                    console.log("results.length", results.length);
                    for(var i=0; i<results.length; i+=300){
                      var resultsToRender = results.slice(i, i+300);
                      console.log("resultsToRender", resultsToRender);
                      const doc = <PDFComponent 
                        results={resultsToRender} 
                        language={language} 
                        columnsToShow={columnsToShow} 
                        user={user} 
                        filters={props.filters} />;
                      let asPdf = pdf([]); // throws without an argument
                      asPdf.updateContainer(doc);
                      console.log("before asPdf.toBlob");
                      let blob = await asPdf.toBlob();
                      saveAs(blob, getFilename());
                      console.log("after asPdf.toBlob");
                    }
                  }}
                  >
                  {props.language.results.donwload_pdf}
                </Button> */}
                {Boolean(anchorDownloadMenu) && <PDFDownloadLink 
                  className="titlePDF" 
                  document={
                    <PDFComponent 
                      results={results.slice(0,500)} 
                      language={language} 
                      columnsToShow={columnsToShow} 
                      user={user} 
                      filters={props.filters} />
                  } 
                  fileName={getFilename()}>
                    {({ blob, url, loading, error }) => (loading ?  props.language.results.loading_pdf : props.language.results.donwload_pdf)  }
                </PDFDownloadLink>}
              </MenuItem>
              <MenuItem>
                <CSVLink data={csvData} filename={getFilename() + ".csv"} className="titleCSV">{props.language.results.donwload_csv}</CSVLink>
              </MenuItem>
            </Menu>
          </>
          :
          <IconButton disabled={true} className="icon iconFilter btnHeaderFilter" size="medium">
            <GetAppOutlinedIcon fontSize="large" />
          </IconButton>)
      }
      {/* DOWNLOAD GENERIC BUTTON */}
      {typeView === VIEW_INDIVIDUAL && <DarkerTooltip title={language.results.head.download} placement="bottom">
        <IconButton className="icon iconFilter btnHeaderFilter" size="medium" onClick={handleDownloadSection}>
          <GetAppOutlinedIcon fontSize="large" />
        </IconButton>
      </DarkerTooltip>}

      {/* Btn hide columns */}
      {typeView === VIEW_LIST && <MuiThemeProvider theme={theme}>
        <DarkerTooltip title={language.results.head.hide_columns} placement="bottom">
          <div style={{ display: "inline-block" }}>
            <IconButton className="icon iconFilter btnHeaderFilter columns" size="medium"
              onClick={handleClick}>
              <ViewColumnOutlinedIcon fontSize="large" />
            </IconButton>
          </div>
        </DarkerTooltip>
        <Menu
          id="menuColumns"
          anchorEl={anchorColumn}
          keepMounted
          open={Boolean(anchorColumn)}
          onClose={handleCloseMenuColumns}>
          { Object.entries(columnsToShow).map(
              (element, _) => {
                let columnName = element[0];
                let translationColumnName = "col_" + element[0];
              return (<MenuItem key={columnName} onClick={() => { ClickToggleColumn(columnName) }}>
                <Checkbox checked={columnsToShow[columnName]} />
                {props.language.results[translationColumnName]}
              </MenuItem>);
            })
          }
        </Menu>
      </MuiThemeProvider>}

      {/* Search general (only shown on card and list view) */}
      {typeView !== VIEW_INDIVIDUAL && <div className="searchListView" style={{ display: "inline-block", marginTop: "10px" }}>
        <DarkerTooltip title={language.results.head.search} placement="bottom">
          <SearchIcon className="search-icon" />
        </DarkerTooltip>
        <TextField id="ContentResultSearch"
          className="ContentResultSearch"
          placeholder={language.results.head.search}
          value={searchValue}
          onChange={handleSearchOnChangeFilter} />
      </div>}

      {/* <PDFViewer width={800} height={700}>
        <PDFComponent results={results.slice(0,1000)} language={language} columnsToShow={columnsToShow} user={user} filters={props.filters} />
      </PDFViewer> */}
    </Grid>
  );
};

FiltersComponent.propTypes = {
  results: PropTypes.array.isRequired,            //Respuesta al fetchResults con los datos del backEnd
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  handleDownloadSection: PropTypes.func.isRequired,     //Func. para descargar la vista individual
  user: PropTypes.object.isRequired,          //Variable obtenida del localstorage con los datos del usuario
  optionsFilterRegion: PropTypes.array.isRequired, //Array con las opciones que estaran en el filtro de "Region"
};

export default FiltersComponent;