import React from 'react';
import { CircularProgress } from '@material-ui/core';
import {MuiThemeProvider} from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {theme} from "../../../style";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


/** Metodos que renderizan cosas generales utilizadas en cualquier tipo de componente, 
 * pueden ser opcionales, Por ejemplo: loaders, snackBar
 * 
*/

/**
 * Renderiza un loader general, al que se le puede cambiar el texto y tamaño
 * 
 * @param {string} [textLoader] - Texto que se mostrará debaje del loader, no se renderiza el texto si es null, 
 * @param {string} [id] - Id que tendra el div del loader. Default: ''
 * @param {string} [size] - Tamaño que tendrá el loader, Default: '80px'
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
*/
export const renderLoader = (textLoader, id='', size=165) => {
    return <MuiThemeProvider theme={theme}>
    <div id={`loader_${id}`}>
      {textLoader && <h5>{textLoader}</h5>}
      <img id="loaderImg" width={size} alt="logo" src="./../../images/loader.gif" />
      {/* <CircularProgress size={size} /> */}
    </div>
  </MuiThemeProvider>
}

/**
 * Renderiza un maetrialu UI loader, al que se le puede cambiar el texto y tamaño
 * 
 * @param {string} [textLoader] - Texto que se mostrará debaje del loader, no se renderiza el texto si es null, 
 * @param {string} [id] - Id que tendra el div del loader. Default: ''
 * @param {string} [size] - Tamaño que tendrá el loader, Default: '80px'
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
*/
export const renderMaterilUiLoader = (textLoader, id='', size=80) => {
  return <MuiThemeProvider theme={theme}>
  <div id={`${id}`}>
    {textLoader && <h4>{textLoader}</h4>}
    <CircularProgress size={size} />
  </div>
</MuiThemeProvider>
}

/**
 * Renderiza un snackBar general, al que se le puede cambiar el texto y tamaño para mostrar mensajes y avisos
 * 
 * @param {string} [textLoader] - Texto que se mostrará debaje del loader, no se renderiza el texto si es null, 
 * @param {string} [id] - Id que tendra el div del loader. Default: ''
 * @param {string} [size] - Tamaño que tendrá el loader, Default: '80px'
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
*/
// export const renderSnackBarMessage = (open, message, type, OnCloseCallBack, duration=4000) => {
//   return <MuiThemeProvider theme={theme}>
//     <Snackbar 
//           id="snackBarMessage"
//           anchorOrigin={{ 'horizontal': 'right', 'vertical':'top' }}
//           open={open} 
//           autoHideDuration={duration}
//           typesnack={type}
//           message={message}
//           onClose={() => {
//             if(OnCloseCallBack)
//               OnCloseCallBack();
//           }}>
//     </Snackbar>
//   </MuiThemeProvider>
// }
export const renderSnackBarMessage = (open, message, type, OnCloseCallBack, duration=4000) => {
  var typeAlert = type;
  if(type === "danger"){
    typeAlert="error";
  }
  return <MuiThemeProvider theme={theme}>
    <Snackbar 
          id="snackBarMessage"
          anchorOrigin={{ 'horizontal': 'right', 'vertical':'top' }}
          open={open}
          typesnack={type}
          autoHideDuration={duration}>
        <Alert severity={typeAlert} onClose={() => {
            if(OnCloseCallBack)
              OnCloseCallBack();
          }}>
          {message}
        </Alert>
    </Snackbar>
  </MuiThemeProvider>
}


/**
 * Tooltip con diseño negro, usando en botones
 */
export const DarkerTooltip = withStyles(theme => ({
  tooltip: {
      backgroundColor: "#615E5E",
      color: "#FFFFFF",
      boxShadow: theme.shadows[1],
      fontSize: 13,
      fontFamily: "Sarabun",
      fontWeight: "500",
      lineHeight: "15px",
      fontStyle: "normal"
  }
}))(Tooltip);


/**
 * Tooltip con diseño blanco, usando generalmente para mostrar notas dentro del icono ⓘ
 */
export const NoteTooltip = withStyles({
  tooltip: {
    color: "#3F4443",
    backgroundColor: "white",
    lineHeight: "1.5rem",
    fontSize: "0.875rem",
    fontFamily: "Sarabun",
    width: "240px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    padding: "20px 15px 10px 20px",
    borderRadius: "15px",
    whiteSpace: "pre-line",
  }
})(Tooltip);
