import jwt_decode from "jwt-decode";

import { AUTH_ONLY } from 'constants/index';

/**
 * Metodo para revisar la ruta, nos dice que es necesario tener un token para entrar a esta ruta
 * 
 * @param {Object} to - Objeto con los datos de la ruta  la que vamos, propiedad puesta por "react-router-guards" el objeto tiene (history, location, match, meta, staticContext)
 * @param {Object} to.meta - del objeto que es dado por "react-router-guards" aqui es donde nosotros ponemos nuestra informacion
 * @param {Object} to.meta[] - cada key de este objeto (excluyendo 'data') representa el guard al que esta dirigidos,
 * @param {Object} to.meta.data - objeto con informacion adicional necesitada por el metod en este caso el token
 * @param {Object} from - object data of pairs key value to use for replace on enpoint 
 * @param {function} next - Function que nos llevara a nuestra ruta, o hara la redirección
 * 
*/
const requireLogin = (to, from, next) => {
  var token = localStorage.getItem("token");
  if(!to.meta[AUTH_ONLY]){
    next();
    return;
  }

  if(token){
    var decoded = jwt_decode(token);
    if( decoded.user){
      next();
    }else{
      // console.log("invalid token");
      localStorage.removeItem("token");
      next.redirect('/not-allowed');
    }
  }else{
    // console.log("no token or not is a view with AUTH_ONLY");
    next.redirect('/not-allowed');
  }
};

export default requireLogin;