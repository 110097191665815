import {createSelector} from 'reselect';

export const getCurrentOrganization = createSelector(
    (state, props) => {
        // var user = localStorage.getItem('user');
        // var currentUser = user ? JSON.parse(user) : {};
        return state.organization;
    },
    organization => organization
)
