import {handleActions} from 'redux-actions';

import { LOGIN, SET_AUTHENTICATION} from 'constants/index';

export const authentication = handleActions({
    [LOGIN]: (state, action) => {
        // console.log("authentication LOGIN", state, action);
        return {...state, ...action.payload};
    },
    [SET_AUTHENTICATION]: (state, action) => ({...state, ...action.payload}),
}, []);