import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { 
    Modal, 
    ModalHeader, 
    ModalBody } from 'reactstrap';
import { 
    //Field, 
    reduxForm } from 'redux-form';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import './styles.css';

class MakeAdministrator extends Component{

    // constructor(props){
    //     super(props);
    // }

    toggle = () => {
        this.props.handleOpenModal();
    }

    render(){
        const { openModal, modalData,
            handleOpenModal, handleCancel, handleSubmit, language } = this.props;
        //const { text, subtitle, acceptText, cancelText} = modalData || {};
        const { text, subtitle, accept_button: acceptText, cancel_button: cancelText } = language.modals.makeAdmininistrador;
        return (
            <Modal 
              id="modalMakeAdministrator" 
              isOpen={openModal} 
              toggle={() => handleOpenModal(modalData.code)} 
              size="lg" style={{ height: "auto" }}>
              <ModalHeader toggle={this.toggle}><WarningRoundedIcon className="iconModalText" /></ModalHeader>
              <ModalBody>
                <Grid
                  container 
                  direction="row"
                  alignItems="center"
                  justify="center"
                  style={{ margin: "auto"}} >
                    <Grid item xs={10}>
                        {/* <Grid container item xs={12}>
                            <WarningRoundedIcon className="iconModalText" />
                        </Grid> */}
                        <Grid container item xs={12}>
                            <p className="adminModalText">{text}</p>
                        </Grid>
                        <Grid container item xs={12}>
                            <p className="userModalSubtitle">
                                <b>{subtitle}</b>
                            </p>
                        </Grid>
                        <Grid container item xs={12}>
                            <div style={{margin: "auto"}}>
                                <Button id="btnCancelAdmin" type="button"
                                    style={{textTransform: "inherit", width: "220px"}}
                                    onClick={handleCancel}
                                    className="btn" >
                                    {cancelText}
                                </Button>
                                <Button id="btnMakeAdmin" type="button"
                                    style={{textTransform: "inherit", width: "220px"}}
                                    onClick={() => {handleSubmit()}}
                                    className="btn btnCancel" >
                                    {acceptText}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
              </ModalBody>
          </Modal>
        );
    }
};

MakeAdministrator.propTypes = {
  handleSubmit: PropTypes.func.isRequired,      //Func. donde se haran las acciones del submit.
  handleOpenModal: PropTypes.func.isRequired,   //Func. para llamar al evento de abrir o cerrar el modal
  handleCancel: PropTypes.func.isRequired,      //Func que será llamada al hacer click en cancelar.
  handleChange: PropTypes.func.isRequired,      //Func que será llamada en el onChange de cada input. 
  openModal: PropTypes.bool.isRequired,         //Nos dice si el modal esta abierto.
  modalData: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,              //Usuario seleccionado al que se le aplicaran los cambios en caso de aceptar
};

MakeAdministrator.defaultProps  = {
    modalData: {
        acceptText: 'Aceptar',  //Texto para el botón submit
        cancelText: 'Cancelar',  //Texto para el botón cancelar
    }
};

export default reduxForm({
    form: 'ModalForm',
    enableReinitialize: true
  }) (MakeAdministrator);