import {createAction} from 'redux-actions';

import {
    FETCH_DASHBOARD
} from 'constants/index';
import {Get} from 'api/api';
import {Request} from 'api/request';

const DASHBOARDS_ENDPOINT = "dashboard";
const DASHBOARD_ENDPOINT = DASHBOARDS_ENDPOINT + "/:id";

var Dashboard = new Request(DASHBOARDS_ENDPOINT);

export const getDashboard = createAction(FETCH_DASHBOARD, (id) => {
    var custom_dashboard = Dashboard.replaceEndPointParams(DASHBOARD_ENDPOINT, {id});
    return Get(Dashboard.url(custom_dashboard));
});