export const DEFAULT_LANGUAGE = 'en';

export const SIGN_UP_USER = 'user';
export const SIGN_UP_ORGANIZATION = 'organization';

//TYPES OF VIEW OF RESULTS 
export const VIEW_LIST = "list";
export const VIEW_CARD = "card";
export const VIEW_INDIVIDUAL = "individual";


//ROUTES NAMES
export const HOME_URL = "/";
export const LOGIN_URL = "/login";
export const FORGOT_PASSWORD_URL = "/forgot-password";
export const CONFIRM_EMAIL_URL = "/confirm-email";
export const EXPIRED_LINK_URL = "/expired-link";
export const SIGN_USER_URL = "/sign-up/user";
export const SIGN_ORGANIZATION_URL = "/sign-up/organization";
export const NOT_ALLOWED_URL = "/not-allowed";
export const BASE_WEB_URL = "/web";
export const DASHBOARD_URL = BASE_WEB_URL + "/dashboard";
export const RESULTS_URL = BASE_WEB_URL + "/results";
export const LINK_URL = BASE_WEB_URL + "/link";
export const DEVICE_MANAGMENT_URL = BASE_WEB_URL + "/device-managment";
export const USER_PROFILE_URL = BASE_WEB_URL + "/user-profile";
export const ORGANIZATION_PROFILE_URL = BASE_WEB_URL + "/organization-profile";
export const PASSWORD_RECOVERY_BASE_URL = "/password-recovery";
export const PASSWORD_RECOVERY_URL = "/password-recovery/:token?";
export const EDIT_USER_URL = "/edit-user";
export const EDIT_ORGANIZATION_URL = "/edit-organization";

//ROUTER TYPES
export const AUTH_ONLY = "AUTH_ONLY";
export const HAS_AUTH = "HAS_AUTH";
export const HAS_EDIT_ACCESS = "HAS_EDIT_ACCESS";
export const HAS_PASSWORD_ACCESS = "HAS_PASSWORD_ACCESS";

//DATA
export const PASSWORD_MIN_LENGTH = 8;

export const POSITIVE = 1;
export const NEGATIVE = 2;
export const INDETERMINATE = 3;

export const CONTROL_VALID = "Valid";
export const CONTROL_INVALID = "Invalid";

export const PIE_CHART = "PieChart";
export const LINE_CHART = "LineChart";
export const BUBBLE_CHART = "BubbleChart";
export const GEO_CHART = "GeoChart";