import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import HeadComponent from '../general/Head';
import ContentComponent from './ContentComponent.js';
import './styles.css';

const LinkComponent = (props) => {
  const { headInfo, language, organization, donwloadQR, user, goToView, copyToClipboard} = props;


  return (
      <Typography component={'div'}>
        <HeadComponent 
          headInfo={headInfo}
          language={language}
        />
        <ContentComponent language={language}
            goToView={goToView}
            organization={organization}
            donwloadQR={donwloadQR}
            copyToClipboard={copyToClipboard}
            user={user} /> 
      </Typography>
  );
};

LinkComponent.propTypes = {
  headInfo: PropTypes.shape({                     //Objeto con la información del header
    title: PropTypes.string.isRequired,           //Titulo que se mostrará en header
    subtitle: PropTypes.string.isRequired         //Subtitulo que se mostrará en header
  }),
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  goToView: PropTypes.func.isRequired,            //Func. que te lleva a la url pasada como parametro.
  organization: PropTypes.object.isRequired,        //Variable obtenida del backend con los datos del organización
  donwloadQR: PropTypes.func.isRequired,          //Func. para descargar la imagen qr del usuario.
  user: PropTypes.object.isRequired,              //Variable obtenida del localstorage con los datos del usuario
  copyToClipboard: PropTypes.func.isRequired,     //Func. para copiar el código para enlacede organizacion
};

LinkComponent.defaultProps  = {
  listUsers: [], 
};

export default LinkComponent;