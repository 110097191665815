import {handleActions} from 'redux-actions';
import { // DELETE_ORGANIZATION,
    SET_ORGANIZATION_USER,
    FETCH_ORGANIZATION } from 'constants/index';

export const organization = handleActions({
    [FETCH_ORGANIZATION]: (state, action) => {
        if(action.error)
            return [...state];
            
        return {...action.payload.response.company};
        //return [...action.payload.response.results];
    },
    [SET_ORGANIZATION_USER]: (state, action) => {
        var newState = {...state};
        var listUsers = [];
        for(var i=0; i<newState.Users.length; i++){
            //listUsers.push( (newState.Users[i].id === user.id) ? user :  newState.Users[i])
            if(newState.Users[i]._id === action.payload.user._id){
                action.payload.user.test = "qwerty";
                listUsers.push(action.payload.user);
            }else{
                listUsers.push(newState.Users[i]);
            }
        }
        if(action.error)
            return [...state];
            
        return {
            ...newState,
            
            Users: [...listUsers]
        };
        //return [...action.payload.response.results];
    }
}, {});