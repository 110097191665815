import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid  } from "@material-ui/core";
import { Field, reduxForm } from 'redux-form';
import { FormGroup } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import LanguageIcon from '@material-ui/icons/Language';
import { connect } from 'react-redux';

import useStyles from "../../style";
import { setLanguageSelected } from '../../helpers/general';
import { renderSelectMaterialUi } from '../general/Form';

const createLanguageOptions = (language) => [
  {value: language.spanish},
  {value: language.english},
];

var language_options = null;

const HeadUserComponent = (props) => {
  const { handleChangeLanguage } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  language_options = language_options ? language_options : createLanguageOptions(language);
  var language_selected = setLanguageSelected(props.language);

  useEffect(() => {
    setLanguage(props.language);
    language_options = createLanguageOptions(props.language);
  }, [props.language]);

  return ( <Grid container className="headUser">
      <Grid item xs={12}>
        <FormGroup id="groupLanguage" className="headUserProfile">
          <Field id="language" name="language" key="language" 
            classes={classes}
            options={language_options}
            onChange={handleChangeLanguage}
            value={language_selected}
            startAdornment={
              <InputAdornment position="start">
                <LanguageIcon className="iconLanguage" />
              </InputAdornment>
            }
            label="Select language" 
            component={renderSelectMaterialUi}/>
        </FormGroup>
      </Grid>
    </Grid>
  );
};

HeadUserComponent.propTypes = {
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  handleChangeLanguage: PropTypes.func.isRequired,//Func. para recbir los cambios de lenguaje
};

const mapStateToProps = (state, props) => {
  return {
      initialValues: { language: setLanguageSelected(props.language) }
  };
};

let InitializeFromStateForm = reduxForm({
  form: 'HeadUserComponent',
  enableReinitialize: true,
})(HeadUserComponent)

export default connect(mapStateToProps)(InitializeFromStateForm)