import React, { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import { Grid, Button, FormGroup, IconButton } from '@material-ui/core';

import {
  Field,
  reduxForm 
} from 'redux-form';
import MaterialTable, { MTableToolbar } from 'material-table';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useStyles from "style";
import { inputCalendar, renderTextField } from 'components/general/Form';
import { DarkerTooltip } from 'components/general/commonRenders';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReactTooltip from 'react-tooltip';
import Select, { components } from 'react-select';
import './styles.css';

var inputTimer = null;
const menuOptions = {
  vertical: 'top',
  horizontal: 'center',
}

const ContentComponent = (props) => {
  const { cleanFilters, isFilterActive, devices, handleFilter, 
    unlinkDevice, optionsFilterPhone, reset } = props;
  const maxStartDate = new Date();
  const classes = useStyles();
  const [language] = useState(props.language);

  /**
    * Métodos y variables necesarios para el manejo de las opciones del filtro de TestCount
  */
  const [startTestCount, setStartTestCount] = useState(null);
  const [endTestCount, setEndTestCount] = useState(null);
  const [anchorTestCount, setAnchorTestCount] = useState(null);
  const openTestCountMenu = (event) => {
    setAnchorTestCount(event.currentTarget);
  };
  const closeTestCountMenu = () => {
    setAnchorTestCount(null);
  };
  const handleStartTestCountChange = (data) => {
    setStartTestCount(data.target.value);
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      handleFilter("minTests", data.target.value);
    }, 1000);
  };
  const handleEndTestCountChange = (data) => {
    setEndTestCount(data.target.value);
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      handleFilter("maxTests", data.target.value);
    }, 1000);
  };

  /**
    * Métodos y variables necesarios para el manejo de los filtros de fecha
  */
  const [anchorDate, setAnchorDate] = useState(null);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(new Date());
  const handleClickDateSelect = (event) => {
    setAnchorDate(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorDate(null);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleFilter("startDate", date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleFilter("endDate", date);
  };

  /**
   * Métodos y variables necesarios para el manejo de la opcion de filtro de search
  */
  const [searchValue, setSearchVallue] = useState('');
  var handleSearchOnChangeFilter = (event) => {
    setSearchVallue(event.target.value);
  }
  useEffect(() => {
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      handleFilter("search", searchValue);
    }, 750);
  }, [searchValue, handleFilter]);

  const handleCleanFilters = async () => {
    console.log("handleCleanFilters", language.results.cardView.gender_option1);
    reset();
    props.change("startTestCount", null);
    props.change("endTestCount", null);
    await setValuePhone(null);
    await setStartDate(undefined);
    await setStartTestCount(oldValue => '');
    await setEndTestCount(oldValue => '');
    cleanFilters();
  }

  let [rowMenu, setRowMenu] = useState(null);
  let [actionElement, setActionElement] = useState(null);
  let [menuOpen, setMenuOpen] = useState(false);
  const menuActionsOpen = (event, row) => {
    setActionElement(event.currentTarget);
    setRowMenu(row);
  }
  const menuActionsClose = (event, row) => {
    setActionElement(null);
    setRowMenu(null);
  }
  useEffect(() => {
    console.log("menuOpen", !!actionElement);
    setMenuOpen(!!actionElement);
  }, [actionElement]);

  const clickUnlink = () => {
    console.log("clickUnlink", rowMenu);
    unlinkDevice(rowMenu);
    setActionElement(null);
    setRowMenu(null);
  }

  const renderDevicesMenu = () => {
    return (<Menu
      id="deviceMenu"
      keepMounted
      anchorEl={actionElement}
      anchorOrigin={menuOptions}
      transformOrigin={menuOptions}
      open={menuOpen}
      onClose={menuActionsClose}
      >
      <MenuItem onClick={clickUnlink}>{language.devices.option_unlink}</MenuItem>
    </Menu>);
  }

  const renderHeaderTable = (props) => {
      return  (<thead className="MuiTable-root" style={{tableLayout: "auto", display: "table-header-group"}}>
        <tr className="MuiTableHead-root">
        {props.columns.map( element => (
          <th key={element.title} className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignLeft headerCustom">
            <label className="MuiButtonBase-root MuiTableSortLabel-root headerCustom">
              <div>{element.title}</div>
              <ReactTooltip />
              {element.showTooltip && 
                  <p className="tooltipAdminBox" style={{ display: "inline-block" }} data-tip={`<p>${language.devices.search_filter_tooltip}</p>`}
                  data-text-color="#3F4443"
                  data-background-color="white"
                  data-class="tooltipAdmin"
                  data-html={true}>
                  <InfoOutlinedIcon className="tooltipAdminIcon" style={{
                    color: "#D9514E",
                    fontSize: "1.125rem",
                  }}>
                  </InfoOutlinedIcon>
                </p>}
            </label>
          </th>
          ) )
        }
          <th key="actionsHeader" className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignLeft headerCustom">
            <span className="MuiButtonBase-root MuiTableSortLabel-root headerCustom">
              {/* <div>{language.table.actionsName}</div> */}
              
            </span>
          </th>
        </tr>
      </thead>);
  }

  const [isSelected, setIsSelected] = useState(false);
  const [valuePhone, setValuePhone] = useState(null);
  const handleChangePhone = (value, action) => {
    console.log("handleChangePhone", value)
    setValuePhone(value);
    handleFilter("phone", value.value);
  }


  return (
    <div className="contentDevices">
      <Container fluid={true} style={{ padding: "0px", height: "auto" }}>
        <Row noGutters>
          <Col xs={12} sm={{ size: 11, offset: 1 }} md={{ size: 11, offset: 1 }} className={classes.FormLayoutContainer}>
            <div className="devicesBox">
              <Grid id="filtersBox" item xs={12} style={{ textAlign: "left", marginBottom: "40px" }}>
                {/* Search general (only shown on card and list view) */}
                <div className="searchListView" style={{ display: "inline-block", marginTop: "10px" }}>
                  <DarkerTooltip title={language.results.head.search} placement="bottom">
                    <SearchIcon className="search-icon" />
                  </DarkerTooltip>
                  <TextField id="ContentResultSearch"
                    className="ContentResultSearch"
                    placeholder={language.devices.search_filter_txt}
                    value={searchValue}
                    onChange={handleSearchOnChangeFilter} />
                </div>

                {/* Filter for  type phone */}
                <div className="selectContainer">
                    <Select
                      id="phoneSelect"
                      className="phoneSelect"
                      classNamePrefix="phoneSelect"
                      placeholder={isSelected ? '' : language.devices.phone_filter_txt}
                      value={valuePhone}
                      onChange={handleChangePhone}
                      onFocus={(_) => setIsSelected(true) }
                      onBlur={(_) => setIsSelected(false) }
                      setValue={valuePhone}
                      noOptionsMessage={() => language.devices.empty_phone_filter}
                      menuPosition="absolute"
                      components={{ 
                        DropdownIndicator : (props) => {
                          let { isFocused } = props
                          return (!isFocused &&
                            <components.DropdownIndicator {...props}>
                              <ArrowDropDownIcon style={{color: "#d9514e"}} />
                            </components.DropdownIndicator>
                          );
                        },
                      }}
                      styles={{
                          menu: (provided, state) => ({
                            zIndex: 9999,
                            background: "white"
                          }),
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            return {
                              ...styles,
                              backgroundColor: isFocused ? "#d9514e33" : null,
                              cursor: isDisabled ? 'not-allowed' : 'pointer',
                              color: "#3F4443",
                              ':active': {
                                ...styles[':active'],
                                backgroundColor: !isDisabled && (isSelected ? data.color : "#d9514e33"),
                                color: "#3F4443"
                              },
                            };
                          },
                      }}
                      options={optionsFilterPhone}
                    />
                </div>

                {/* <div className="selectContainer selectPhone">
                  <FormGroup id="phoneSelect">
                    <Field id="phone" name="phone" key="phone"
                      classes={classes}
                      onChange={handleChangeOnFilters}
                      label={language.devices.phone_filter_txt}
                      options={optionsFilterPhone}
                      component={renderSelectWithLabel}
                    />
                  </FormGroup>
                </div> */}

                <div className="selectContainer selectDate">
                  <Button elevation={0}
                    style={{ marginTop: "0px" }}
                    className="btn select-group "
                    aria-controls="menu-date"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={handleClickDateSelect}>
                    {language.devices.linkDate_filter_txt} <ArrowDropDownIcon />
                  </Button>
                  <Menu id="dateMenu" keepMounted
                    elevation={2}
                    getContentAnchorEl={null}
                    anchorOrigin={menuOptions}
                    anchorEl={anchorDate}
                    open={!!anchorDate}
                    onClose={handleClose}>
                    <MenuItem disableRipple id="MenuDate">
                      <FormGroup className="group_date">
                        <Field id="date_start" name="date_start"
                          classes={classes}
                          label={language.devices.linkDate_from_label}
                          type="input"
                          dateValue={startDate}
                          maxDate={maxStartDate}
                          handleChange={handleStartDateChange}
                          component={inputCalendar}
                        />
                      </FormGroup>
                      <FormGroup className="group_date">
                        <Field id="date_end" name="date_end"
                          classes={classes}
                          label={language.devices.linkDate_to_label}
                          type="input"
                          dateValue={endDate}
                          minDate={startDate}
                          maxDate={maxStartDate}
                          handleChange={handleEndDateChange}
                          component={inputCalendar}
                        />
                      </FormGroup>
                    </MenuItem>
                  </Menu>
                </div>

                <div className="selectContainer selectTestCount">
                  <Button elevation={0}
                    style={{ marginTop: "0px" }}
                    className="btn select-group"
                    variant="contained"
                    onClick={openTestCountMenu}>
                    {language.devices.tests_filter_txt} <ArrowDropDownIcon />
                  </Button>
                  <Menu id="qrMenu" keepMounted
                    elevation={2}
                    getContentAnchorEl={null}
                    anchorOrigin={menuOptions}
                    anchorEl={anchorTestCount}
                    open={!!anchorTestCount}
                    onClose={closeTestCountMenu}>
                    <MenuItem disableRipple id="MenuQr">
                      <FormGroup className="group_qr">
                        <Field id="startTestCount" name="startTestCount"
                          classes={classes}
                          label={language.devices.tests_from_label}
                          placeholder={language.devices.tests_from_placeholder}
                          value={startTestCount}
                          onChange={handleStartTestCountChange}
                          component={renderTextField}
                        />
                      </FormGroup>
                      <FormGroup className="group_qr">
                        <Field id="endTestCount" name="endTestCount"
                          classes={classes}
                          label={language.devices.tests_to_label}
                          placeholder={language.devices.tests_to_placeholder}
                          value={endTestCount}
                          onChange={handleEndTestCountChange}
                          component={renderTextField}
                        />
                      </FormGroup>
                    </MenuItem>
                  </Menu>
                </div>

                {/* Btn for Clean Filters */}
                <div className="selectContainer selectClean">
                  <DarkerTooltip title={language.results.clean_filters} placement="bottom">
                    <div style={{ display: "inline-block" }}>
                      <IconButton
                        className="icon iconCleanFilter"
                        size="medium"
                        disabled={!isFilterActive}
                        onClick={handleCleanFilters}>
                          {isFilterActive ?
                            <img className="cleanFiltersIcon" alt="button icon" src={`./../images/clean_filters_active.png`} /> :
                            <img className="cleanFiltersIcon" alt="button icon" src={`./../images/clean_filters_disabled.png`} />
                          }
                      </IconButton>
                    </div>
                  </DarkerTooltip>
                </div>
              </Grid>
              {actionElement && renderDevicesMenu() }

              {devices.length > 0 ? <Grid id="boxDeviceTable" item xs={12}>
                <MaterialTable
                  title=''
                  columns={[
                    { title: language.devices.col_profileId, field: 'userId', showTooltip: true },
                    { title: language.devices.col_phoneInfo, field: 'model' },
                    { title: language.devices.col_linkDate, field: 'linkDate', emptyValue: "-" },
                    { title: language.devices.col_lastDate, field: 'usedAt', emptyValue: language.devices.empty_last_test,
                      render: rowData => (
                        <p>{rowData.olafTestsRead ? rowData.usedAt : '-' }</p> 
                      )
                    },
                    { title: language.devices.col_totalTests, field: 'olafTestsRead' },
                  ]}
                  data={devices}
                  components={{
                    Toolbar: props => (
                      <div id="customToolbar">
                        <MTableToolbar {...props} />
                      </div>
                    ),
                    Header: renderHeaderTable
                  }}
                  actions={[
                    {
                      icon: () => <MoreVertIcon/> ,
                      onClick: (event, rowData) => {
                        menuActionsOpen(event, rowData);
                      }
                    }
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    pageSize: 5,
                    selection: false,
                    search: false,
                    toolbar: false,
                    exportButton: false,
                    emptyRowsWhenPaging: false,
                    headerStyle: {
                      fontSize: "1rem",
                      fontFamily: "Sarabun",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      color: "#828282",
                      lineHeight: "21px"
                    },
                    rowStyle: {
                      fontSize: "1rem",
                      lineHeight: "21px",
                      fontFamily: "Sarabun",
                      color: "#3F4443",
                    }
                  }}
                  localization={{
                    pagination: {
                      labelDisplayedRows: '{from}-{to} ' + language.table.of + ' {count}',
                      labelRowsSelect: language.table.rowSelect
                    },
                    toolbar: {
                      searchTooltip: language.table.search,
                      exportTitle: language.table.exportTitle,
                      searchPlaceholder: language.table.search,
                      nRowsSelected: '{0} ' + language.table.rowsSelected
                    },
                    body: {
                      emptyDataSourceMessage: language.table.emptyData,
                    },
                    header: {
                      actions: language.table.actionsName,
                    }
                  }}
                />
                </Grid>
                :
                <Grid container className="emptySearchBody">
                  <h5>{language.results.empty_search_title}</h5>
                  <p>{language.results.empty_search_text}</p>
                </Grid>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};


ContentComponent.propTypes = {
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  organization: PropTypes.object.isRequired,      //Objeto con los datos de la organización
  devices: PropTypes.array.isRequired,            //Lista de dispositivos filtrada de acuerdo a los filtros
  handleFilter: PropTypes.func.isRequired,        //Func. para el evento de cambio en los filtros
  cleanFilters: PropTypes.func.isRequired,        //Func. para limpiar los filtros utilizados
  isFilterActive: PropTypes.bool.isRequired,      //Dato que nos dice si hay algun filtro activo
  goToView: PropTypes.func.isRequired,            //Func. que te lleva a la url pasada como parametro.
  unlinkDevice: PropTypes.func.isRequired,        //Func. para limpiar los filtros utilizados
  optionsFilterPhone: PropTypes.array.isRequired, //Array con las opciones que estaran en el filtro de "Telefono"
};

// export default ContentComponent;
export default reduxForm({
    form:'ContentComponent',
    enableReinitialize: true
})(ContentComponent);