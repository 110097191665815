import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import bigInt from 'big-integer';
import { getPropertyString } from 'helpers/general';

import DeviceComponent from 'components/deviceComponent';
import { getLanguage, getCurrentOrganization, getCurrentUser } from 'selectors';
import { fetchUsersByOrganization, 
  updateUser, 
  setOrganizationUser, 
  fetchOrganization, 
  inviteUser,
  deleteUser,
  unlinkDevice
} from 'actions';
import { renderSnackBarMessage, renderLoader } from 'components/general/commonRenders';
import ConfirmUnlinkDevice from 'components/general/Modals/confirmUnlinkDevice/confirmUnlinkDevice.js';

class DeviceContainer extends Component {

  constructor(props){
    super(props);
    this.language = this.props.language;
    this.filters = {
      startDate: null,
      endDate: null,
      minTests: null,
      maxTests: null,
      search: null,
      phone: null,
    }
    this.state = {
      headInfo:{
        title: this.language.devices.title,
        subtitle: ''
      },
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
      openModal: false,
      modalSelected: null,
      modalData: {},
      loaded_organization: false,
      isFilterActive: false,
      devices: [],
      filteredDevices: [],
      optionsFilterPhone: [],
    }
    this.handleFilter = this.handleFilter.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this);
    this.unlinkDevice = this.unlinkDevice.bind(this);
  }

  componentDidMount(){
    this.getOrganization();
  }

  /**
   * Método llamado para obtener la organizacion (compañia o institución) del usuario
   * 
  */
  getOrganization(){
    this.props.fetchOrganization(this.props.user.company._id).then(response => {
      this.successGetOrganization(response);
    }).catch(error => {
      this.errorGetOrganization(error);
    });
  }

  getPhoneFilterOptions(devices){
    let arrayFilterOptions = []
    for(let device of devices){
      let exists = arrayFilterOptions.find((option, _) => {
          return option.value === device.model;
      });
      if(!exists){
        arrayFilterOptions.push({value: device.model, label: device.model,});
      }
    }

    return arrayFilterOptions;
  }

  /**
   * Método llamado cuando getOrganization obtuvo una respuesta exitosa
  */
  successGetOrganization(response){
    // console.log("successGetOrganization", response);
    let { company } = response.payload.response;
    let listOptions = this.getPhoneFilterOptions(company.devices);
    for(let device of company.devices){
      let fingerprintLength = device.fingerprint.length;
      device.userId = device.fingerprint.substring(fingerprintLength-6, fingerprintLength);
      // console.log("device.userId", device.userId);
    }
    console.log("listOptions", listOptions);
    this.setState({
      loaded_organization: true,
      organization: company,
      devices: company.devices,
      filteredDevices: company.devices,
      optionsFilterPhone: listOptions
    });
  }

  /**
   * Método llamado cuando getOrganization obtuvo una respuesta fallida, o hubo un error en el successGetOrganization
  */
  errorGetOrganization(error){
    // console.log("catch fetchOrganizations", error);
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.linkData;
    let organization = {
      name: this.props.user.company.name
    };
    this.setState({
      loaded_organization: true,
      organization,
      snackBarInfo:{ message,
        typesnack: 'error',
        openSnackBar: true
      }
    });
  }

  /**
   * Change the current view to url sended by parameter
   * 
   * @param {string} url - variable with url of view that we want to go
  */
  goToView = (url) => {
    this.props.history.push(url);
  }

  checkFilterActive(){
    const { startDate, endDate, minTests, maxTests, search, phone } = this.filters;
    if(startDate || endDate || minTests || maxTests || search || phone){
      return true;
    }else{
      return false;
    }
  }

  /**
   * Método que filtra los datos de las tarjetas
   * 
   * @param {string} filterType - String que nos dice que view es la que esta seleccionada
   * @param {object} data - String que nos dice que view es la que esta seleccionada
   *    
  */
  handleFilter(filterType, valueSelected) {
    console.log("handleFilter", filterType, valueSelected);
    this.filters[filterType] = valueSelected;
    this.setState({
      isFilterActive: this.checkFilterActive(),
      filteredDevices: this.filter(this.state.devices, this.filters)
    });
  }

  cleanFilters() {
    this.filters = {
      age: null,
      gender: null,
      startDate: null,
      search: null,
      minTests: null,
      maxTests: null,
    }
    this.setState({
      filteredDevices: this.filter(this.state.devices, this.filters),
      isFilterActive: false,
    })
  }

  /**
   * Metodo para filtrar los datos del array(registros)
   * 
   * @param {string} obj1 - objecto a validar
   * @param {string} filters - todos los filtros que queremos aplicar
   * 
   * @returns {array} - array con los datos filtrados
  */
  filter = (array, filtersData) => {
    return array.filter(element => this.validateDataByObject(element, filtersData));
  }

  /**
   * Metodo para saber si el objeto cumple con los requisitos de los filtros mandados como parametros
   * 
   * @param {string} obj1 - objecto a validar
   * @param {string} filters - todos los filtros que queremos aplicar
   * 
   * @returns {boolean} - resultado de la comparacion del objeto con los filtros
  */
  validateDataByObject = (obj1, filters) => {
    var isValidValue = true;
    for (let key in filters) {
      if(!filters[key] || filters[key].length===0){
        continue;
      }
      switch (key) {
        case "startDate":
          isValidValue = this.validateByDate(obj1.linkDate, filters[key], "start");
        break;
        case "endDate":
          isValidValue = this.validateByDate(obj1.linkDate, filters[key], "end");
        break;
        case "minTests":
          isValidValue = this.validateByTestCount(obj1.olafTestsRead, filters[key], "start");
        break;
        case "maxTests":
          isValidValue = this.validateByTestCount(obj1.olafTestsRead, filters[key], "end");
        break;
        case "phone":
          isValidValue = this.validateByPhoneModel(obj1.model, filters[key], "end");
        break;
        case "search":
          //var valueForSearch = ["fingerprint", "model", "linkDate", "usedAt", "olafTestsRead"];
          var valueForSearch = ["fingerprint"];
          isValidValue = this.validateBySearch(obj1, valueForSearch, filters[key])
        break;
        default: break;
      }

      if (!isValidValue) {
        return isValidValue;
      }
    }
    return isValidValue;
  }

  /**
   * Metodo especifico para el filtro por el campo de date, 
   * si el type es 'start' y según la fecha seleccionada se compara si el value es igual o mayor
   * si el type es 'end' u otro y según la fecha seleccionada se compara si el value es igual o antes
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} dateSelected - fecha de inicio para el filtro 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByDate(value, dateSelected, type) {
    if (!dateSelected) {
      return true;
    }

    if (type === 'start') {
      return moment(value).isSameOrAfter(moment(dateSelected), 'day');
    } else {
      return moment(value).isSameOrBefore(moment(dateSelected), 'day');
    }
  }

  /**
   * Metodo especifico para el filtro por el campo de telefono (usando el atributo "model"), 
   *   se valida el campo si value y phoneOption son iguales
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} phoneOption - opcion seleccionada del filtro gender 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByPhoneModel(value, phoneOption) {
    var acceptAllOption = this.language.devices.phone_filter_txt;
    return this.isValidFilterOption(phoneOption, acceptAllOption) ? value === phoneOption : true;
  }

  /**
   * Metodo especifico para el filtro por el campo cantidad de pruebas, 
   * si el type es 'start', el testCount seleccionado se compara si el value es igual o mayor
   * si el type es 'end' u otro, el testCount seleccionado se compara si el value es igual o menor
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} qrSelected - qr obtenido del filtro 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByTestCount(value, testCount, type) {
    if (!testCount) {
      return true;
    }
    console.log("validateByTestCount", testCount, value)

    if (type === 'start') {
      return testCount <= value;
    } else {
      return testCount >= value;
    }
  }

  /**
   * Metodo especifico para el filtro por el campo de qrCode, 
   * si el type es 'start' y según el qrCode seleccionado se compara si el value es igual o mayor
   * si el type es 'end' u otro y según el qrCode seleccionado se compara si el value es igual o menor
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} qrSelected - qr obtenido del filtro 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByQRCode(value, qrSelected, type) {
    var qrNumeric = null;
    try {
      qrNumeric = bigInt(qrSelected);
    } catch (err) {
      //console.log("err", err);
    }
    if (!qrSelected || !qrNumeric) {
      return true;
    }

    if (type === 'start') {
      return bigInt(qrSelected).lesserOrEquals(value);
    } else {
      return bigInt(qrSelected).greaterOrEquals(value);
    }
  }

  /**
   * Metodo especifico para filtrar los campos restantes, usando el input de search, 
   * según el parametro fields se usara el string del input para validar si se tiene 
   * la cadena en cualquiera de esos campos
   * 
   * @param {Object} date - objeto en el cual se buscaran los datos de fields
   * @param {Array<string>} fields - un array de cadenas con los nombres de las key con las propiedades a buscar del objeto
   * @param {string} searchData - texto con el que se compararan los fields para saber si se contiene la cadena
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateBySearch(data, fields, searchData) {
    searchData = searchData.toLowerCase();
    if (searchData) {
      for (var i = 0; i < fields.length; i++) {
        var valueToCheck = getPropertyString(data, fields[i]);
        if (valueToCheck.includes(searchData)) {
          return true;
        }
      }

      return false;
    } else {
      return true;
    }
  }

  /**
   * Metodo para saber si la variable seleccionada de los filtros esta vacia o si se escogio la opcion para mostrar todos los datos
   *  Por ejemplo en Sexo hay tres opciones: Sexo, Hombre, Mujer, 
   *  si se escoge la primera (Sexo) es como no tener ningun filtro y se muestran todos los resultados
   * 
   * @param {string} filterOption - La opción selecccionada al hacer un change de un filtro
   * @param {string} optionAcceptAll - Es la opcion del select de los filtros para aceptar todos los datos
   * 
   * @returns {boolean} - true Cuando filterOption es un string con el cual se puede filtrar los datos
   *                    - false Cuando filterOption es null, empty o cuando es igual a la opcion de mostrar todos los datos
  */
   isValidFilterOption(filterOption, optionAcceptAll) {
    if (filterOption && filterOption !== optionAcceptAll) {
      return true;
    }
  }

  /**
   * Método llamado para desligar un celular de la organizacion (usando el fingerprint)
   * 
  */
  unlinkDevice(unlinkData) {
    this.handleOpenModal(ConfirmUnlinkDevice, unlinkData);
    // this.props.unlinkDevice({fingerprint: data.fingerprint, companyId: this.state.organization._id}).then(response => {
    //   this.successUnlinkDevice(response, data.fingerprint);
    // }).catch(error => {
    //   this.errorUnlinkDevice(error);
    // });
  }

  /**
   * Method que es llamado para abrir modal, renderizará el componente mandado en modalComponent
   * 
   * @param {string} modalComponent - Un tipo de elemento React (ej. MyComponent).
  */
  handleOpenModal = (modalComponent, data) => {
    this.setState({
      openModal: !this.state.openModal,
      modalData: {
        ModalSelected: modalComponent,
        modalInfo: {
          unlinkData: data
        }
      }
    });
  }

  /**
   * Method que es llamado cuando se acepta el agregar un productor
   * 
   * @param {Object} data - objeto con losdatos del productor obtenidos del modal
   * @param {Object} data.name - nombre del productor
  */
  handleModalSubmit = (typeModal, data) => {
    console.log("handleModalSubmit", data);
    if (typeModal === "ConfirmUnlinkDevice") {
      this.props.unlinkDevice({fingerprint: data.fingerprint, companyId: this.state.organization._id}).then(response => {
        this.successUnlinkDevice(response, data.fingerprint);
      }).catch(error => {
        this.errorUnlinkDevice(error);
      });
    }
  }

  /**
   * Método llamado cuando unlinkDevice obtuvo una respuesta exitosa
  */
  successUnlinkDevice(response, fingerprint){
    let newDevices = [];
    newDevices = this.state.devices.filter( (device) => {
      return device.fingerprint !== fingerprint 
    });
    console.log("successunlinkDevice", newDevices);
    this.setState({
      devices: newDevices,
      filteredDevices: this.filter(newDevices, this.filters),
    });
  }
  
  /**
   * Método llamado cuando unlinkDevice obtuvo una respuesta fallida, o hubo un error en el successUnlinkDevice
  */
  errorUnlinkDevice(error){
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors["EMAL027"];
    this.setState({
      loaded_organization: true,
      snackBarInfo:{ message,
        typesnack: 'error',
        openSnackBar: true
      }
    });
  }

  render() {
    return (<> 
        {this.state.loaded_organization ? 
          <PerfectScrollbar style={{minHeight: "calc(100vh - 180px)", width: "100%", height: "auto"}}> 
            <DeviceComponent
              {...this.state}
              handleInviteUser={this.handleInviteUser}
              unlinkDevice={this.unlinkDevice}
              handleFilter={this.handleFilter}
              cleanFilters={this.cleanFilters}
              language={this.language}
              goToView={this.goToView}
              user={this.props.user}
              handleOpenModal={this.handleOpenModal}
              handleModalSubmit={this.handleModalSubmit}
            />
          </PerfectScrollbar>
          : 
          <PerfectScrollbar style={{minHeight: "calc(100vh - 180px)", width: "100%", height: "calc(100vh - 180px)"}}> 
            {renderLoader(this.language.link.loader, null, 290)}
          </PerfectScrollbar>
          }
        {renderSnackBarMessage(
            this.state.snackBarInfo.openSnackBar, 
            this.state.snackBarInfo.message, 
            this.state.snackBarInfo.typesnack,
            () => this.setState({snackBarInfo:{openSnackBar: false}}),
            4000)
        }
      </>
    );
  }
}

DeviceContainer.propTypes = {
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  user: PropTypes.object.isRequired,                //Variable obtenida del backend con los datos del usuario
  fetchUsersByOrganization: PropTypes.func.isRequired,     //Func. para obtener todos los datos de organizacion
  updateUser: PropTypes.func.isRequired,//Func. actualizar los datos de un usuario de la organizacion
  setOrganizationUser: PropTypes.func.isRequired,   //Func. actualizar informacion mostrada 
  inviteUser: PropTypes.func.isRequired,            //Func. para mandar el correo del usuario al backend y mandar los datos
};

const mapStateToProps = (state) => ({
  language:  getLanguage(state),
  organization:      getCurrentOrganization(state),
  user:      getCurrentUser(state)
});

export default withRouter( connect( mapStateToProps, { 
  fetchUsersByOrganization,
  fetchOrganization,
  updateUser,
  deleteUser,
  setOrganizationUser,
  inviteUser,
  unlinkDevice
}) (DeviceContainer) );