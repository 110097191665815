import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { getCurrentUser } from '../../selectors';
import { connect } from 'react-redux';
import { ROLE_ADMIN } from 'config';
import { DarkerTooltip } from 'components/general/commonRenders';
import { PASSWORD_RECOVERY_BASE_URL } from 'constants/index';

const ContentComponent = (props) => {
    const { goToView, user } = props;

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    return (
        <div id="contentUserProfile">
            <Grid container>
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={8}>
                    <Card className="card cardProfile">
                        <CardHeader className="cardHeader"
                            action={<DarkerTooltip title={props.language.organization.tooltip_edit} placement="bottom">
                                <IconButton className="iconRound btnDanger"
                                    aria-label="Edit user data" type="button"
                                    onClick={() => { goToView('/edit-user') }}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </DarkerTooltip>}
                            title={user.name} />
                        <CardContent className="cardBody ProfileCardContent">
                            <Grid item xs={12}>
                                <h4 className="userRole">{user.role.code === ROLE_ADMIN ? language.profile.role_admin : language.profile.role_user}</h4>
                            </Grid>
                            <Grid item xs={12}>
                                <span className="title">{language.profile.card_label_org}</span>
                                <span className="info">{user.company.name}</span>
                            </Grid>
                            <Grid item xs={12}>
                                <span className="title">{language.profile.card_label_phone}</span>
                                <span className="info">{formatPhoneNumberIntl(user.phone)}</span>
                                <span className="title extensionLabel">{`  ${language.profile.card_label_extension}`}</span>
                                <span className="info">{user.extension ? user.extension : '-'}</span>
                            </Grid>
                            <Grid item xs={12}>
                                <span className="title">{language.profile.card_label_email}</span>
                                <span className="info">{user.email}</span>
                            </Grid>
                            <Grid item xs={12}>
                                <Button id="changePasswordButton"
                                    style={{ marginTop: "90px" }}
                                    type="button" className="btn"
                                    onClick={() => goToView(PASSWORD_RECOVERY_BASE_URL)}>
                                    {language.profile.card_btn_password}
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={2}></Grid>
            </Grid >
        </div>
    );
};

ContentComponent.propTypes = {
    language: PropTypes.object.isRequired,    //Variable donde se guardan las traducciones del lenguaje
    goToView: PropTypes.func.isRequired,      //Func. que te lleva a la url pasada como parametro.
    user: PropTypes.object.isRequired,        //Variable obtenida del backend con los datos del usuario
};

const mapStateToProps = (state) => ({
    initialValues: getCurrentUser(state)
});

export default connect(mapStateToProps, {})(ContentComponent);