/** Class with methods to made the call, exportable for use on actions file
*/
export class Request {
    constructor(defaultEndpoint = ""){
        this._url = "";
        this.setBaseUrl(process.env.REACT_APP_BASE_URL);
        this.setEndpoint(defaultEndpoint);
    }

    _updateUrl(){
        this._url = this.baseUrl+this.defaultEndpoint;
    }

    /**
     * Get the complete url format.
     * 
     * @param {string} [endpoint] - if this values is set will override defaultEndpoint
     * @param {Object} [endPointParams] - object data of pairs key value to use for replace on enpoint 
     * @param {Object} [queryParams] - Object with extra data of request example: {gender: male}
     * @param {(string[] | string)} queryParams[][key] - key = Name of the property, with value string or string array with values
     * 
     * @returns {string} (baseUrl + (endpoint|defaultEndpoint) + queryParams)
    */
    url(endpoint, endPointParams = null, queryParams = "") {
        if(endPointParams){
            endpoint =  this.replaceEndPointParams(endpoint, endPointParams);
        }
        endpoint = endpoint || this.defaultEndpoint;
        this._url = this.baseUrl + endpoint;
        return this.createUrl(this._url, queryParams);
    }

    /** createUrl
     * 
     * @param {string} url  
     * @param {Object} params 
     * @param {(string[] | string)} queryParams[][key] - key = Name of the property, with value string or string array with values
     * 
     * @return {string} 
     */
    createUrl(url, params) {
        var queryString = this.convertParamsToString(params);
        url = (queryString) ?  url + "?" + queryString : url;
        return url;
    }


    /** convertParamsToString
     * 
     * @param {Object} params 
     * @param {(string[] | string)} queryParams[][key] - key = Name of the property, with value string or string array with values
     * 
     * @return {string} 
     */
    convertParamsToString(params){
        var queryString = '';
        var paramsArray = Object.entries(params);
        var lastEntrie = paramsArray[paramsArray.length-1];
        if(paramsArray.length === 0){
            return queryString;
        }
        for(let key in params){
            if(params[key]){
                queryString += key + "=";
                queryString += Array.isArray(params[key]) ? params[key].toString() : params[key];
                queryString += lastEntrie[0] !== key ? "&" : "";
            }
        }
        return queryString;
    }

    /**
     * Set the base url.
     * 
     * @param {string} url - variable for set baseUrl
    */
    setBaseUrl(url) {
        this.baseUrl = url;
        this._updateUrl();
    }

    /**
     * Get the defaultEndpoint.
     * @param {string} endpoint For defaultEndpoint
    */
    setEndpoint(endpoint) {
        this.defaultEndpoint = endpoint;
        this._updateUrl();
    }

    /**
     * Get the base url.
     * @return {string}
    */
    getBaseUrl() {
        return this.baseUrl;
    }

    /**
     * Get the defaultEndpoint.
     * @return {string}
    */
    getEndPoint() {
        return this.defaultEndpoint;
    }

    /**
     * Replace the params on endpoint given
     * @param {string} endPoint        - Enpoint to use for replace the params
     * @param {Object} endPointParams  - Data of pairs key value to use for replace on enpoint 
     * @param {string} endPointParams[][key] - key = Name of the property, with value to set
     * E.g.: Enpoint = "users/:id"  endPointParams = {id: "1"}      
     *       Response --> "users/1"
     *       Enpoint = "users/:rol" endPointParams = {rol: "Admin"}  
     *       Response --> "users/Admin"
     * @return {string}
    */
    replaceEndPointParams(endPoint, endPointParams = {}){
        if(Object.entries(endPointParams).length === 0){
            return endPoint;
        }else{
            for(let key in endPointParams){
                endPoint = endPoint.replace(':'+key, endPointParams[key]);
            }
        }

        return endPoint;
    }
}