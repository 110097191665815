import { handleActions } from 'redux-actions';

import { SET_LANGUAGE } from 'constants/index';
import es from 'language/es';
import en from 'language/en';
import pt from 'language/pt';

export const languageSelect = handleActions({
  [SET_LANGUAGE]: (state, action) => {
    switch(action.payload){
      case 'es': return es;
      case 'pt': return pt;
      case 'en': 
      default:
        return en;
    }
  }
}, es);

