import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { renderTextField } from '../general/Form';
import { Button, CircularProgress } from '@material-ui/core';
import useStyles from "../../style";
import { isRequired as _isRequired } from '../../helpers/validations';
import { stringTrim } from '../../helpers/transforms/formTransforms.js';
import FooterNoAuthComponent from "../general/Footer/layoutNoAuth";
import PerfectScrollbar from 'react-perfect-scrollbar';
import './styles.css';

var isRequired = _isRequired('');

const ForgotPasswordComponent = (props) => {
  const { handleSubmit, submitting, pristine, invalid, error, goToLogin } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  useEffect(() => {
    setLanguage(props.language);
    isRequired = _isRequired(props.language.errors.required);
  }, [props.language]);

  return (
    <div id="contentForgotPassword">
      <Container fluid={true} className={classes.backgroundBody} style={{ padding: "0px" }}>
        <Row noGutters style={{ height: "calc(100% - 70px)", padding: "90px 0px 10px" }}>
          <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} className={classes.ForgotPasswordContainer}>
            <PerfectScrollbar style={{ minHeight: "100%" }}>
              <Row noGutters>
                <Col xs={12} sm={10} className={classes.ForgotPasswordBox}>
                  <img id="forgotLogo" alt="logo" src="./../../images/logo_dxter.png" />
                  <h3 className={classes.titleRed}>{language.forgot.title}</h3>
                  <h4 className={classes.subtitleGrey}>{language.forgot.subtitle}</h4>
                  <form onSubmit={handleSubmit}>
                    <Field
                      id="email" name="email" type="text"
                      label={language.forgot.label_email}
                      placeholder={language.forgot.input_email}
                      validate={[isRequired]}
                      normalize={stringTrim}
                      component={renderTextField}
                    />
                    {error && <strong className="formError">{error}</strong>}
                    <div className="ForgotPasswordBlockButtons">
                      {submitting ?
                        <div className="loaderForgotPassword">
                          <CircularProgress size={30} />
                        </div> :
                        <Button id="forgotButton"
                          className="btn" type="submit"
                          style={{ display: "inline-block" }}
                          disabled={submitting || pristine || invalid}>
                          {language.forgot.button}
                        </Button>}
                      {!submitting && <Button id="cancelButton"
                        className="btn btnCancel" type="button"
                        onClick={goToLogin}>
                        {language.forgot.cancelBtn}
                      </Button>}
                    </div>
                  </form>
                </Col>
              </Row>
            </PerfectScrollbar>
          </Col>
        </Row>
        <Row noGutters className={classes.footerContainer}>
          <Col xs={{ size: 12, offset: 0 }} className={classes.footer}>
            <FooterNoAuthComponent language={language}></FooterNoAuthComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ForgotPasswordComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,      //Func. donde se haran las acciones del submit.
  goToLogin: PropTypes.func.isRequired,         //Func. funcion para regresar al login
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  submitting: PropTypes.bool.isRequired,        //Flag dada por redux-form para decirnos si se esta ejecutando el submit
  pristine: PropTypes.bool.isRequired,          //Flag dada por redux-form para si el form tiene los mismos valores que al inicio
  invalid: PropTypes.bool.isRequired,           //Flag dada por redux-form para si el form tiene algun input invalido.
  error: PropTypes.bool,                        //Variable dada por redux-form para decirnos si se regreso un error general a traves del submit del form
};

export default reduxForm({
  form: 'ForgotPasswordComponent',
  enableReinitialize: true
})(ForgotPasswordComponent);