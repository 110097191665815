import {createSelector} from 'reselect';

export const getCurrentUser = createSelector(
    (state, props) => {
        //var user = localStorage.getItem('user');
        //var currentUser = user ? JSON.parse(user) : {};
        //return currentUser;
        return state.authentication.user;
    },
    user => user
)

export const getOrganizationUsers = createSelector(
    (state, props) => {
        return state.organization_users;
    },
    organization_users => organization_users
)
