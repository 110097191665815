import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Menu from 'components/general/Menu';
import { setAuthentication} from 'actions';
import { getAuthenthication, getLanguage } from 'selectors';
import ModalContainer from 'containers/general/ModalContainer/ModalContainer';
import ConfirmLogout from 'components/general/Modals/confirmLogout/confirmLogout.js';
import permission, {
    MENU_DASHBOARD,
    MENU_RESULTS,
    MENU_USER_PROFILE,
    MENU_ORGANIZATION_PROFILE,
    MENU_LOGOUT,
    MENU_LINK} from './../../../config';
import { LOGIN_URL } from 'constants/index';
import { logout } from 'actions';

class MenuContainer extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      openModal: false,
      modalSelected: null,
      modalData: {},
    }
    this.logoutClick = this.logoutClick.bind(this);
    this.handleModalLogoutSubmit = this.handleModalLogoutSubmit.bind(this);
  }

  /**
   * Método para quitar las secciones a la que el usaurio no tiene permiso
   * 
   * @param {Object} menus Objeto para el menu definido en config/index.js
   * @param {String} menus.code Identificador del menu}
   * @param {String} menus.icon name of icon to use for menu
   * @param {String} menus.url Url de la seccion, a la que se irá con el en click
   * @param {array<string>} menus.hasPermission Array con los roles que tienen permiso a esta sección
   * @param {string} user_role variable que nos dice que tipo de usuario es el que esta loggeado. 
   * 
   * @returns {Object} - Objeto con SÓLO las secciones a la que el usuario tiene permiso
  */
  filterMenuByPermission(menus, user_role){
    var menuToShow = [];
    menus.filter( (menu, index) => {
      if(menu.hasPermission.includes(user_role) ){
        menuToShow.push(menu);
        return true;
      }
      return false;
    });

    return menuToShow;
  }

  /**
   * Método para traducir el menu mostrado a la izquierda al lenguaje seleccionado
   * @param {Object} menus - Objeto para el menu definido en config/index.js
   *   - code .- String -> Identificador para el menu
   *   - title .- String -> Nombre para el tooltip, (variable a traducir)
   *   - src_active .- Strign -> Direccion de la imagen del menu cuando esta activa
   *   - src .- Strign -> Direccion de la imagen del menu cuando esta inactiva
   *   - url .- String -> Direccion url del sitio para dictaminar la imagen activa
   * 
   * @returns {Object} - Objeto con los datos traducciones la lenguaje selecccionado.
  */
  translateMenu(menus){
      menus.map( (menu) => {
          const { language } = this.props;
        var oldTitle = menu.title;
        switch (menu.code) {
          case MENU_DASHBOARD:
            menu.title = language.navbar.dashboard;
          break;
          case MENU_RESULTS:
            menu.title = language.navbar.results;
          break;
          case MENU_LINK:
            menu.title = language.navbar.link;
          break;
          case MENU_USER_PROFILE:
            menu.title = language.navbar.profile;
          break;
          case MENU_ORGANIZATION_PROFILE:
            menu.title = language.navbar.organization;
          break;
          case MENU_LOGOUT:
            menu.title = language.navbar.logout;
          break;
          default: break;
        }
        menu.title = menu.title ? menu.title : oldTitle;
        return menu;
      });
  }

  /**
   * Method que es llamado para abrir modal, renderizará el componente mandado en modalComponent
   * 
   * @param {string} modalComponent - Un tipo de elemento React (ej. MyComponent).
  */
  handleOpenModal = async (modalComponent) => {
    // console.log("Entro a handleOpenModal", this.state.openModal, modalComponent);
    await this.setState({
      openModal: true,
      modalData: {
        ModalSelected: modalComponent,
        modalInfo: {
          test: "tester"
        }
      }
    });

    // console.log("handleOpen", this.state.openModal);
  }
  

  handleModalLogoutSubmit() {
    console.log("handleModalLogoutSubmit");
    this.sendLogout();
    // localStorage.removeItem("user");
    // localStorage.removeItem("token");
    // this.props.setAuthentication({
    //   token: '',
    //   user: ''
    // });
    // this.props.history.push(LOGIN_URL);
  }


  /**
   * Method that sent token to DB to know that now that token is invalid
   * 
   * @returns {promise} Promise to be resolved
  */
  sendLogout = () => {
    console.log("antes del logout");
    return this.props.logout({token: localStorage.getItem('token')}).then(response => {
      this.successLogout(response);
    }).catch((error) => {
      this.errorLogout(error);
    });
  }

  /**
    * Method when promise of sendLogout success
    * 
    * @param {Object} response - backEnd response transformed to json from api.js
    * 
  */
  successLogout(response) {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    this.props.history.push(LOGIN_URL);
    this.props.setAuthentication({
      token: '',
      user: ''
    });
  }

  /**
   * Method when promise of sendLogout fail
   * 
   * @param {Object} error - this object is get from api.js of method validateBackResponse
   * 
  */
  errorLogout(error) {
    console.log("en errorLogout", error);
    // var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.general;
    // if (error.key === "EMAL003" || error.key === "EMAL007") {
    //   message = this.props.language.errors.codeInvalidOrg;
    // }

    // localStorage.removeItem("user");
    // localStorage.removeItem("token");
    // this.props.setAuthentication({
    //   token: '',
    //   user: ''
    // });
    // this.props.history.push(LOGIN_URL);
  }

  logoutClick() {
    this.handleOpenModal(ConfirmLogout);
  }

  render() {
      const {  user_role} = this.props;
      var FilteredMenu = this.filterMenuByPermission(permission.menu, user_role);
      this.translateMenu(FilteredMenu);
      return (<>
          <Menu menu={FilteredMenu} logoutClick={this.logoutClick} />
          <ModalContainer id="modalLoguotContainer"
            handleOpenModal={this.handleOpenModal}
            handleSubmit={this.handleModalLogoutSubmit}
            modalData={this.state.modalData}
            openContainer={this.state.openModal}/>
        </>
      );
  }
}

MenuContainer.propTypes = {
  language: PropTypes.object.isRequired,      //Variable donde se guardan las traducciones del lenguaje
  logout: PropTypes.func.isRequired,         //Func. de la action para mandar la solicitud de logout
  user_role: PropTypes.string.isRequired,     //variable que nos dice que tipo de usuario es el que esta loggeado
};

MenuContainer.defaultProps  = {
  auth: {
    user: {
      role: "user"
    }
  }
};


const mapStateToProps = (state) => ({
    language: getLanguage(state),
    auth:     getAuthenthication(state)
});
  
export default withRouter( connect( mapStateToProps, {
  setAuthentication,
  logout
}) (MenuContainer) );
