import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import EditOrganizationComponent from "components/EditOrganizationComponent";
import { updateOrganization } from 'actions';
import { getCurrentOrganization, getLanguage } from 'selectors';
import { renderSnackBarMessage } from 'components/general/commonRenders';
import { ORGANIZATION_PROFILE_URL } from 'constants/index';

class EditOrganizationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatingOrg: false,
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info',
      },
    }

    this.handleEditOrganization = this.handleEditOrganization.bind(this);
  }

  componentDidMount() { }

  /**
   * Method what is called on submit of change organization data, this function call to backEnd 
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * @param {string} values.organization - string with organization's name
   * @param {string} values.phone - string with organization's phone
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  handleEditOrganization = (values) => {
    console.log("handleEditOrganization", values);
    var dataToEdit = {
      organization: values.name,
      //phone: values.phone.replace(/\s/g, ""),
      phone: values.phone,
      email: values.email
    }
    this.setState({
      updatingOrg: true
    });

    return this.props.updateOrganization(values._id, dataToEdit).then(response => {
      this.successEditOrganization(response);
    }).catch(error => {
      this.errorEditOrganization(error);
    });
  }

  successEditOrganization(response) {
    // console.log("success updateOrganization", response);
    // this.setState({
    //   snackBarInfo:{
    //     message: this.props.language.editUser.successEdit,
    //     typesnack: 'success',
    //     openSnackBar: true
    //   }
    // });
    setTimeout(() => {
      this.setState({
        updatingOrg: false
      });
      this.goToOrganization({
        showSnackbar: true,
        snackbarMessage: this.props.language.editUser.successEdit
      });
    }, 500);
  }

  errorEditOrganization(error) {
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.updateData;
    this.setState({
      updatingOrg: false,
      snackBarInfo: {
        message,
        typesnack: 'error',
        openSnackBar: true
      }
    });
    // console.log("catch getDashboard", error);
  }

  /**
   * Change the current view to login url
  */
  goToOrganization = (data = null) => {
    if (data) {
      this.props.history.push(ORGANIZATION_PROFILE_URL, data);
    } else {
      this.props.history.push(ORGANIZATION_PROFILE_URL);
    }
  }

  render() {
    return (
      <div>
        <EditOrganizationComponent
          language={this.props.language}
          goToOrganization={this.goToOrganization}
          updatingOrg={this.state.updatingOrg}
          organization={this.props.organization}
          handleEditOrganization={this.handleEditOrganization} />
        {renderSnackBarMessage(
          this.state.snackBarInfo.openSnackBar,
          this.state.snackBarInfo.message,
          this.state.snackBarInfo.typesnack,
          () => this.setState({ snackBarInfo: { openSnackBar: false } }),
          4000)
        }
      </div>
    );
  }
}

EditOrganizationContainer.propTypes = {
  language: PropTypes.object.isRequired,    //Variable donde se guarda el lenguaje
  organization: PropTypes.object.isRequired,        //Variable obtenida del backend con los datos de la organizacion
  updateOrganization: PropTypes.func.isRequired,    //Func. donde se hace la actualizacion de los datos de organizacion
};

const mapStateToProps = (state, props) => ({
  language: getLanguage(state),
  organization: getCurrentOrganization(state)
});


export default withRouter(connect(mapStateToProps, {
  updateOrganization,
  // login
})(EditOrganizationContainer));