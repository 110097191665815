import {createAction} from 'redux-actions';

import {
    FETCH_RESULTS,
    SET_RESULT_VIEW
} from 'constants/index';
import {
    //Post, 
    Get, 
    //Delete, 
    //Patch
} from 'api/api';
import {Request} from 'api/request';

const RESULTS_ENDPOINT = "registers";
//const RESULT_ENDPOINT = RESULTS_ENDPOINT + "/:id";

var Result = new Request(RESULTS_ENDPOINT);

// export const createResult = createAction(CREATE_RESULT, 
//     customer => {
//         return Post(Result.url(), customer )();
// });

// export const deleteResult = createAction(DELETE_RESULT, 
//     (id) => {
//        return Delete(Result.url(RESULT_ENDPOINT, {id: id}), id)();
//    });

export const fetchResults = createAction(FETCH_RESULTS, (...args) => {
    const { age, gender, qrCode, fingerprint, institution } = args[0];
    let queryParams = {};
    if (age) queryParams.age = age;
    if (gender) queryParams.gender = gender;
    if (qrCode) queryParams.qrCode = qrCode;
    if (fingerprint) queryParams.fingerprint = fingerprint;
    if (institution) queryParams.institution = institution;

    return Get(Result.url(null, null, queryParams));
});

// export const updateResult = createAction(UPDATE_RESULT, 
//     (id, customer) => {
//          var edit_user_url = Result.replaceEndPointParams(RESULT_ENDPOINT, {id: id});
//          console.log("edit_user_url", edit_user_url);
//         return Patch(Result.url(edit_user_url), customer)()
//     });

export const setResultView = createAction(SET_RESULT_VIEW, (viewToSave) => {
    return {typeSelected: viewToSave};
});

    