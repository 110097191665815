import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, FormGroup, CircularProgress } from '@material-ui/core';

import { renderPhoneField2, renderTextField } from '../general/Form';
import useStyles from "../../style";
import {
  isRequired as _isRequired,
  isPhoneNumber as _isPhoneNumber,
} from '../../helpers/validations';
import './styles.css';

var isRequired = _isRequired('');
var isPhoneNumber = _isPhoneNumber('');

const ContentComponet = (props) => {
  const { handleSubmit, submitting, pristine, invalid, error, goToProfile, updatingUser } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  useEffect(() => {
    setLanguage(props.language);
    isRequired = _isRequired(props.language.errors.required);
    isPhoneNumber = _isPhoneNumber(props.language.errors.notPhone);
  }, [props.language]);

  const changePhone = (value, phoneData) => {
    props.change("phone", value);
  }

  return (<div className={classes.FormBox}>
    <h3 className={classes.titleRed}>{language.editUser.title}</h3>
    <form onSubmit={handleSubmit} >
      <FormGroup>
        <Field id="name" name="name" type="text"
          label={language.editUser.label_name}
          placeholder={language.editUser.input_name}
          validate={[isRequired]}
          component={renderTextField} />
      </FormGroup>
      <div>
        <div style={{ width: "70%", display: "inline-block" }}>
          <Field
            id="phone" name="phone" type="text"
            label={language.editUser.label_phone}
            placeholder={language.editUser.input_phone}
            validate={[isRequired, isPhoneNumber]}
            methodChange={changePhone}
            component={renderPhoneField2}
          />
        </div>
        <div style={{ width: "30%", display: "inline-block" }}>
          <Field
            id="extension" name="extension"
            label={language.editUser.label_extension}
            subText={language.signup.user.label_optional}
            placeholder={language.editUser.input_extension}
            component={renderTextField}
          />
        </div>
      </div>
      {/* <FormGroup>
            <Field id="email" name="email" type="text"
                label={language.editUser.label_email}
                placeholder={language.editUser.input_email}
                validate={[isRequired]}
                component={renderTextField}/>
          </FormGroup> */}
      {error && <strong className="formError">{error}</strong>}
      <div style={{ marginTop: "15px" }}>
        {updatingUser ?
          <div className="loaderEditOrg">
            <CircularProgress size={30} />
          </div> :
          <Button id="editPasswrdButton"
            className="btn" type="submit"
            style={{ display: "inline-block", width: "65%", minWidth: "inherit" }}
            disabled={submitting || pristine || invalid}>
            {language.editUser.btnAccept}
          </Button>}
        {!updatingUser && <Button id="cancelButton"
          className="btn btnCancel" type="button"
          style={{ width: "30%", minWidth: "inherit", marginLeft: "5%" }}
          onClick={() => { goToProfile() }}>
          {language.editUser.btnCancel}
        </Button>}
      </div>
    </form>
  </div>
  );
};

ContentComponet.propTypes = {
  handleSubmit: PropTypes.func.isRequired,      //Func. donde se haran las acciones del submit de user.
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  goToProfile: PropTypes.func.isRequired,       //Func. para regresar a la view anterior para el edit
  initialValues: PropTypes.object.isRequired,        //Variable obtenida del backend con los datos del usuario
  submitting: PropTypes.bool.isRequired,        //Flag dada por redux-form para decirnos si se esta ejecutando el submit
  pristine: PropTypes.bool.isRequired,          //Flag dada por redux-form para si el form tiene los mismos valores que al inicio
  invalid: PropTypes.bool.isRequired,           //Flag dada por redux-form para si el form tiene algun input invalido.
  error: PropTypes.bool,                        //Variable dada por redux-form para decirnos si se regreso un error general a traves del submit del form
  updatingUser: PropTypes.bool.isRequired,         //Valor que nos dice si se esta en la peticion para actualizar usuario
};

export default reduxForm({
  form: 'ContentComponet',
  enableReinitialize: true
})(ContentComponet);
