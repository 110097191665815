import {
  LOGIN_URL,
  DASHBOARD_URL,
  RESULTS_URL,
  LINK_URL,
  DEVICE_MANAGMENT_URL,
  USER_PROFILE_URL, 
  ORGANIZATION_PROFILE_URL,
} from 'constants/index';

/* CONSTANTS FOR MENU */
export const MENU_DASHBOARD = "menu_dashboard";
export const MENU_RESULTS = "menu_results";
export const MENU_LINK = "menu_link";
export const MENU_USER_PROFILE = "menu_user_profile";
export const MENU_ORGANIZATION_PROFILE = "menu_organization_profile";
export const MENU_LOGOUT = "menu_logout";

export const ROLE_USER = "user";
export const ROLE_ADMIN = "admin";


const permission = {
  menu:[
      {
        code: MENU_DASHBOARD,
        title:"Dashboard",   //This option will be override by translation if exists 
        icon: "dashboard",
        url:DASHBOARD_URL,
        activesUrl: [DASHBOARD_URL],
        hasPermission: [
          ROLE_USER, ROLE_ADMIN
        ]
      },
      {
        code: MENU_RESULTS,
        title:"Resultados",   ///This option will be override by translation if exists 
        icon: "timeline",
        url:RESULTS_URL,
        activesUrl: [RESULTS_URL],
        hasPermission: [
          ROLE_USER, ROLE_ADMIN
        ]
      },
      {
        code: MENU_LINK,
        title:"Link",   ///This option will be override by translation if exists 
        icon: "phone_android",
        customIcon: "/images/xploraLink.svg",
        customIconActive: "/images/xploraLink-red.svg",
        url:LINK_URL,
        activesUrl: [LINK_URL, DEVICE_MANAGMENT_URL],
        hasPermission: [
          ROLE_USER, ROLE_ADMIN
        ]
      },
      {
        code: MENU_ORGANIZATION_PROFILE,
        title:"Cuenta",   //This option will be override by translation if exists 
        icon: "business",
        url:ORGANIZATION_PROFILE_URL,
        activesUrl: [ORGANIZATION_PROFILE_URL],
        hasPermission: [
          ROLE_ADMIN
        ]
      },
      {
        code: MENU_USER_PROFILE,
        title:"Perfil",   //This option will be override by translation if exists 
        icon: "person",
        url:USER_PROFILE_URL,
        activesUrl: [USER_PROFILE_URL],
        hasPermission: [
          ROLE_USER, ROLE_ADMIN
        ]
      },
      {
        code: MENU_LOGOUT,
        title:"Salir",   //This option will be override by translation if exists 
        icon: "exit_to_app",
        url: LOGIN_URL,
        activesUrl: [],
        hasPermission: [
          ROLE_USER, ROLE_ADMIN
        ]
      }
  ]
}

export default permission;