import {createAction} from 'redux-actions';

import {
    CREATE_ORGANIZATION, 
    // DELETE_ORGANIZATION, 
    FETCH_ORGANIZATIONS,
    FETCH_ORGANIZATION,
    SET_ORGANIZATION_USER,
    UPDATE_ORGANIZATION,
    UNLINK_DEVICE
} from 'constants/index';
//import {Post, Get, Delete, Patch} from '/api/api';
import {Get, Post, Patch} from 'api/api';
import {Request} from 'api/request';

const ORGANIZATIONS_ENDPOINT = "companies";
const ORGANIZATION_ENDPOINT = ORGANIZATIONS_ENDPOINT + "/:id";
const UNLINK_ENDPOINT = "web/companies/unlink";

var Organization = new Request(ORGANIZATIONS_ENDPOINT);

export const createOrganization = createAction(CREATE_ORGANIZATION, 
    customer => {
        return Post(Organization.url(), customer )();
});

// export const deleteOrganization = createAction(DELETE_ORGANIZATION, 
//     (id) => {
//        return Delete(Organization.url(ORGANIZATION_ENDPOINT, {id: id}), id)();
//    });

export const fetchOrganizations = createAction(FETCH_ORGANIZATIONS, () => {
    return Get(Organization.url(null, null, {_embed: "Users"}));
});

export const fetchOrganization = createAction(FETCH_ORGANIZATION, (id) => {
    return Get(Organization.url(ORGANIZATION_ENDPOINT, {id: id}));
});

export const updateOrganization = createAction(UPDATE_ORGANIZATION, 
    (id, organization) => {
         var edit_user_url = Organization.replaceEndPointParams(ORGANIZATION_ENDPOINT, {id: id});
        return Patch(Organization.url(edit_user_url), organization)()
});

export const unlinkDevice = createAction(UNLINK_DEVICE, 
    (unlikData) => {
        var unlink_url = Organization.replaceEndPointParams(UNLINK_ENDPOINT);
        return Post(Organization.url(unlink_url), unlikData)();
});


export const setOrganizationUser = createAction(SET_ORGANIZATION_USER , payload => payload);
    