import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Button, CircularProgress, FormGroup } from '@material-ui/core';

import { renderTextField, renderSelectMaterialUi, renderPassword } from '../general/Form';
import useStyles from "../../style";
import { isRequired as _isRequired } from '../../helpers/validations';
import { stringTrim } from '../../helpers/transforms/formTransforms.js';
import FooterNoAuthComponent from "../general/Footer/layoutNoAuth";
import PerfectScrollbar from 'react-perfect-scrollbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import LanguageIcon from '@material-ui/icons/Language';

import './styles.css';
import { connect } from 'react-redux';
import { setLanguageSelected } from '../../helpers/general';
import { FORGOT_PASSWORD_URL } from 'constants/index';
import { SIGN_ORGANIZATION_URL, SIGN_USER_URL } from 'constants/index';

const createLanguageOptions = (language) => [
  { value: language.spanish },
  { value: language.english },
];

var isRequired = _isRequired('');
var language_options = null;

const LoginComponent = (props) => {
  const { handleSubmit, goToView, submitting, error, handleChangeLanguage } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  language_options = language_options ? language_options : createLanguageOptions(language);
  var language_selected = setLanguageSelected(props.language);

  useEffect(() => {
    setLanguage(props.language);
    language_options = createLanguageOptions(props.language);
    isRequired = _isRequired(props.language.errors.required);
  }, [props.language]);

  const [showPassword, setShowPassword] = useState(false);
  const clickShowPassword = (value, phoneData) => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Container fluid={true} className={classes.backgroundBody} style={{ padding: "0px" }}>
        <Row noGutters style={{ height: "80px" }}>
          <Col xs={{ size: 10, offset: 2 }} sm={{ size: 3, offset: 9 }} className="selectContainer">
            <FormGroup id="groupLanguage">
              <Field id="language" name="language" key="language"
                classes={classes}
                options={language_options}
                onChange={handleChangeLanguage}
                value={language_selected}
                startAdornment={
                  <InputAdornment position="start">
                    <LanguageIcon className="iconLanguage" />
                  </InputAdornment>
                }
                label="Select language"
                component={renderSelectMaterialUi} />
            </FormGroup>
          </Col>
        </Row>
        <Row noGutters style={{ height: "calc(100% - 150px)", padding: "10px 0px" }}>
          <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} className={classes.loginContainer}>
            <PerfectScrollbar style={{ minHeight: "100%" }}>
              <Row noGutters style={{ height: "100%" }}>
                <Col xs={12} sm={6} className={classes.loginFormContainer}>
                  <div xs={12} sm={6}>
                    <img id="loginLogo" alt="logo" src="./../../images/logo_dxter.png" />
                    <h4 className={classes.subtitleGrey}>{language.login.log_in}</h4>
                    <form onSubmit={(data) => { return handleSubmit(data) }}>
                      <Field
                        id="email" name="email" type="text"
                        label={language.login.email}
                        placeholder={language.login.email_placeholder}
                        validate={[isRequired]}
                        normalize={stringTrim}
                        component={renderTextField}
                      />
                      <Field
                        style={{ margin: "100%" }}
                        id="password" name="password" type="password"
                        label={language.login.password}
                        showPassword={showPassword}
                        handleClickShowPassword={clickShowPassword}
                        handleMouseDownPassword={handleMouseDownPassword}
                        placeholder={language.login.password_placeholder}
                        validate={[isRequired]}
                        component={renderPassword}
                      />
                      {error && <strong className="formError">{error}</strong>}
                      <div className="footerForm" style={{ marginTop: "5px" }}>
                        <p className={classes.linkText} onClick={() => goToView(FORGOT_PASSWORD_URL)}>{language.login.forgot_password}</p>
                        {submitting ?
                          <div style={{ width: "100%", textAlign: "center" }}>
                            <CircularProgress size={30} />
                          </div> :
                          <Button id="loginButton" className="btn" type="submit">
                            {language.login.start}
                          </Button>}
                      </div>
                      <div className="loginLinks">
                        <p className={classes.linkAddAccount} onClick={() => goToView(SIGN_USER_URL)}>{language.login.signup_link}</p>
                        <span className={classes.loginText}>{language.login.signup_label}</span>
                        <span className={classes.linkText} onClick={() => goToView(SIGN_ORGANIZATION_URL)}>{language.login.organization_link}</span>
                        <span className={classes.loginText}>{language.login.organization_label}</span>
                      </div>
                    </form>
                  </div>
                </Col>
                <Col xs={12} sm={6} className={classes.loginImgContainer}>
                </Col>
              </Row>
            </PerfectScrollbar>
          </Col>
        </Row>
        <Row noGutters className={classes.footerContainer}>
          <Col xs={{ size: 12, offset: 0 }} className={classes.footer}>
            <FooterNoAuthComponent language={language}></FooterNoAuthComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

LoginComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,      //Func. donde se haran las acciones del submit de login.
  goToView: PropTypes.func.isRequired,          //Func. que te lleva a la url pasada como parametro.
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  submitting: PropTypes.bool.isRequired,        //flag dada por redux-form para decirnos si se esta ejecutando el submit
  pristine: PropTypes.bool.isRequired,          //flag dada por redux-form para si el form tiene los mismos valores que al inicio
  invalid: PropTypes.bool.isRequired,           //flag dada por redux-form para si el form tiene algun input invalido.
  error: PropTypes.bool,                        //variable dada por redux-form para decirnos si se regreso un error general a traves del submit del form
  handleChangeLanguage: PropTypes.func.isRequired,//Func. para recbir los cambios de lenguaje
};

const mapStateToProps = (state, props) => {
  return {
    initialValues: { language: setLanguageSelected(props.language) }
  };
};

let InitializeFromStateForm = reduxForm({
  form: 'LoginComponent',
  enableReinitialize: true,
})(LoginComponent)

export default connect(mapStateToProps)(InitializeFromStateForm)