import { LOGIN_URL } from 'constants/index';
import { HAS_PASSWORD_ACCESS } from 'constants/index';

/**
 * Metodo para revisar la ruta, este método checa que solo entre a cambiar la contraseña en los casos permitidos, 
 * para la contraseña puede entrar si accedes desde la seccion de user-profile o si lo hace desde la opción de 
 * forgot-password con el link que te llega al correo.
 * 
 * @param {Object} to - Objeto con los datos de la ruta  la que vamos, propiedad puesta por "react-router-guards" el objeto tiene (history, location, match, meta, staticContext)
 * @param {Object} to.meta - del objeto que es dado por "react-router-guards" aqui es donde nosotros ponemos nuestra informacion
 * @param {Object} to.meta[] - cada key de este objeto (excluyendo 'data') representa el guard al que esta dirigidos,
 * @param {Object} to.meta.data - objeto con informacion adicional necesitada por el metod en este caso el token
 * @param {Object} from - object data of pairs key value to use for replace on enpoint 
 * @param {function} next - Function que nos llevara a nuestra ruta, o hara la redirección
 * 
*/
const hasPasswordAccess = (to, from, next) => {
    //console.log("HAS_PASSWORD_ACCESS", to, from);
    //console.log("Validations", to.meta[HAS_PASSWORD_ACCESS], from.location.pathname === to.meta.accessPage);
    if(!to.meta[HAS_PASSWORD_ACCESS]){
        next();
        return;
    }

    if( from.location.pathname === to.meta.accessPage || to.match.params.token) {
        next();
    }else{
        var token = localStorage.getItem("token");
        var redirectPage = token ? to.meta.accessPage : LOGIN_URL;
        next.redirect(redirectPage);
    }
  };

export default hasPasswordAccess;