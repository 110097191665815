import React from 'react';
import PropTypes from 'prop-types';
import { Grid,Button } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Container, Col, Row } from 'reactstrap';
import useStyles from "style";
import { LINK_URL } from 'constants/index';

const HeadDeviceComponent = (props) => {
  const { headInfo, language, goToView} = props;
  const classes = useStyles();

  return (
    <Container fluid={true} className="headResults" style={{ padding: "0px" }}>
      <Row noGutters style={{justifyContent: "flex-end"}}>
        <div className="breadcrumb" >
          <Button className="breadcrumbItem" onClick={() => {console.log("goToView"); goToView(LINK_URL)}}>
            <ArrowBackIosIcon />
            <span style={{textTransform: "initial"}}>{language.navbar.link}</span>
          </Button>
        </div>
      </Row>
      <Row noGutters>
        <Col xs={12} sm={{ size: 11, offset: 1 }} md={{ size: 11, offset: 1 }} className={classes.HeadDeviceContainer}>
          <Grid container className="headResults">
            {/* <Grid item xs={12} sm={12}>
              <div className="breadcrumb" >
                <button className="breadcrumbItem">
                  <ArrowBackIosIcon />
                  <span>{language.navbar.link}</span>
                </button>
              </div>
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <div className="principal-title" >
                {headInfo.title}
              </div>
              <div className="secundary-title">
                {headInfo.subtitle}
              </div>
            </Grid>
          </Grid>
        </Col>
       </Row>
    </Container>
  );
};

HeadDeviceComponent.propTypes = {
  headInfo: PropTypes.shape({                     //Objeto con la información del header
    title: PropTypes.string.isRequired,           //Titulo que se mostrará en header
    subtitle: PropTypes.string.isRequired         //Subtitulo que se mostrará en header
  }),
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  goToView: PropTypes.func.isRequired,            //Func. que te lleva a la url pasada como parametro.
};

export default HeadDeviceComponent;