import React from 'react';
import Chart from "react-google-charts";
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CircularProgress } from '@material-ui/core';

import { DarkerTooltip, renderLoader } from './../general/commonRenders';
import { LINE_CHART, BUBBLE_CHART, GEO_CHART, PIE_CHART } from 'constants/index';

const ContentComponent = (props) => {
    const { language, dashboardData, donwloading } = props;

    const pieChart = {
        data: [
            [language.dashboard.pie_results, language.dashboard.pie_total],
        ],
        options: {
            pieStartAngle: 100,
            legend: "none",
            // legend: {
            //     position: "left",
            //     fontSize: "36"
            // },
            sliceVisibilityThreshold: 0,
            slices: {
                0: {
                    color: '#94b7bb',
                    textStyle: {
                        color: 'black', fontSize: '14',
                    }
                },  // Blue - no detected cases
                1: {
                    color: '#d9514e', textStyle: {
                        color: 'white', fontSize: '12',
                    }
                },  // Red  - detected cases
                2: {
                    color: '#E0E0E0',
                    textStyle: {
                        color: 'black', fontSize: '14',
                    }
                },  // Grey - Indeterminate
            },
            tooltip: {
                textStyle: {
                    fontName: 'Sarabun',
                }
            },
            pieHole: 0.35,
            fontName: 'Sarabun',
            fontSize: 15,
            chartArea: { left: 0, top: 5, bottom: 5, width: '100%', height: '100%' },
            pieSliceTextStyle: { fontName: 'Sarabun' },
        }
    }
    pieChart.data = pieChart.data.concat(dashboardData.pieChartData);

    const lineChart = {
        data: [
            ["x", language.dashboard.line_male, language.dashboard.line_female],
        ],
        options: {
            hAxis: { title: language.dashboard.line_hAxis },
            vAxis: { title: language.dashboard.line_vAxis, minValue: 1 },
            series: {
                0: { curveType: 'function', targetAxisIndex: 0 },
                1: { curveType: 'function', targetAxisIndex: 1 },
            },
            legend: {
                position: 'bottom',
                alignment: 'center'
            },
            fontName: 'Sarabun',
            fontSize: 14,
        }
    }

    lineChart.data = lineChart.data.concat(dashboardData.lineChartData);

    const bubbleChart = {
        data: [
            [language.dashboard.bubble_day, language.dashboard.bubble_total, { type: 'string', role: 'tooltip' }],
        ],
        options: {
            legend: "none",
            fontName: 'Sarabun',
            fontSize: 14,
            colors: ['#d59190'],
            hAxis: { title: language.dashboard.bubble_hAxis },
            vAxis: { title: language.dashboard.bubble_vAxis, minValue: 1 },
        }
    }
    bubbleChart.data = bubbleChart.data.concat(dashboardData.bubbleChartData);

    const geoChart = {
        data: [
            [language.dashboard.geo_country, language.dashboard.geo_cases],
        ],
        options: {
            fontName: 'Sarabun',
            fontSize: 14,
            colorAxis: {
                values: [50000, 200000, 350000, 500000, 700000],
                colors: ['#F5F5F5', '#D0D3D4', '#828282', "#D59190", "#D8524F"]
            },
        }
    }
    geoChart.data = geoChart.data.concat(dashboardData.geoChartData);

    /**
     * Método para renderizar la grafico pastel (pie chart)
     */
    const renderPieChart = () => {
        const { pieChartData } = dashboardData;
        const { pie_negative_legend, pie_positive_legend, pie_inderteminate_legend } = language.dashboard;
        let titleNegative = pie_negative_legend.replace("<totalCases>", pieChartData[0][1]);
        let titlePositive = pie_positive_legend.replace("<totalCases>", pieChartData[1][1]);
        let titleIndeterminate = pie_inderteminate_legend.replace("<totalCases>", pieChartData[2][1]);
        return (<Grid container id="PieChart" style={{ top: "0px", position: "relative" }}>
            <Grid item xs={12} sm={6} className="TextPieChartGrid">
                <h6 style={{ fontSize: "0.9375rem" }}>{language.dashboard.pie_title}</h6>
                <h6 style={{ fontSize: "1.125rem" }}><b>{language.dashboard.pie_subtitle}</b>{dashboardData.total}</h6>
                <div className="pieLegendBlock">
                    <span className="square" style={{ backgroundColor: '#d9514e' }}></span>
                    <h6 style={{ fontSize: "0.9375rem" }}>{titlePositive}</h6>
                </div>
                <div >
                    <span className="square" style={{ backgroundColor: '#94b7bb' }}></span>
                    <h6 style={{ fontSize: "0.9375rem" }}>{titleNegative}</h6>
                </div>
                <div>
                    <span className="square" style={{ backgroundColor: '#E0E0E0' }}></span>
                    <h6 style={{ fontSize: "0.9375rem" }}>{titleIndeterminate}</h6>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Chart height="auto" width="100%"
                    chartType="PieChart"
                    loader={renderLoader(language.dashboard.loader_graph, 'pieGraph', 130)}
                    data={pieChart.data} options={pieChart.options} />
            </Grid>
        </Grid>);
    }

    /**
     * Método para renderizar la grafica de linea (line chart)
     */
    const renderLineChart = () => {
        return (<Grid container id="LineChart">
            <Grid item xs={12}>
                <Chart height="230px" width="100%"
                    chartType="LineChart"
                    loader={renderLoader(language.dashboard.loader_graph, 'lineGraph', 130)}
                    data={lineChart.data} options={lineChart.options} />
            </Grid>
        </Grid>);
    }

    /**
     * Método para renderizar la grafica de puntos 
    */
    const renderBubbleChart = () => {
        return (<Grid container id={BUBBLE_CHART}>
            <Grid item xs={12}>
                <Chart height="230px" width="100%"
                    chartType="ScatterChart"
                    loader={renderLoader(language.dashboard.loader_graph, 'bubbleGraph', 130)}
                    data={bubbleChart.data} options={bubbleChart.options} />
            </Grid>
        </Grid>);
    }

    /**
     * Método para renderizar la grafica de mapa (geo chart) 
     */
    const renderGeoChart = () => {
        return (<Grid container id="GeoChart">
            <Grid item xs={12}>
                <Chart height="230px" width="100%"
                    chartType="GeoChart"
                    loader={renderLoader(language.dashboard.loader_graph)}
                    data={geoChart.data} options={geoChart.options} />
            </Grid>
        </Grid>);
    }

    /**
     * Método para renderizar las graficas este metodo es el que decide que grafica renderizar 
    */
    const renderChart = (hasData, typeChart, language, title) => {
        const { dashboard } = language;
        var idChart = "chart_";
        switch (typeChart) {
            case LINE_CHART:
                idChart += LINE_CHART;
                break;
            case BUBBLE_CHART:
                idChart += BUBBLE_CHART;
                break;
            case GEO_CHART:
                idChart += GEO_CHART;
                break;
            case PIE_CHART: default:
                idChart += PIE_CHART;
                break;
        }

        return (hasData ?
            <Card className="card cardGraph" id={idChart}>
                <CardHeader className="graphHeader"
                    title={title &&
                        <div className="blockTitle">
                            {dashboard.title_prefix} <b>{title}</b>
                        </div>
                    }
                    // action={<DarkerTooltip title={props.language.results.head.download} placement="bottom">
                    //     <IconButton aria-label="download" size="small"
                    //         onClick={() => props.handleDownloadChart(typeChart)}>
                    //         <GetAppOutlinedIcon style={{fontSize: "1.875rem"}} />
                    //     </IconButton>
                    //   </DarkerTooltip>
                    // }
                    action={checkIfChartIsDownloading(typeChart) ?
                        <div className="blockLoaderSpinner" >
                            <CircularProgress size={30} id="loaderSpinner" />
                        </div> :
                        <DarkerTooltip title={props.language.results.head.download} placement="bottom">
                            <IconButton aria-label="download" size="small"
                                onClick={() => props.handleDownloadChart(typeChart)}>
                                <GetAppOutlinedIcon style={{ fontSize: "1.875rem" }} />
                            </IconButton>
                        </DarkerTooltip>
                    }
                />
                <PerfectScrollbar>
                    <CardContent id="graphBody" className="graphBody">
                        {typeChart === PIE_CHART && renderPieChart()}
                        {typeChart === LINE_CHART && renderLineChart()}
                        {typeChart === BUBBLE_CHART && renderBubbleChart()}
                        {typeChart === GEO_CHART && renderGeoChart()}
                    </CardContent>
                </PerfectScrollbar>
            </Card>
            :
            renderEmptyChart(language)
        );
    }

    const checkIfChartIsDownloading = (typeChart) => {
        if (donwloading.pie && typeChart === PIE_CHART) {
            return true;
        }

        if (donwloading.bubble && typeChart === BUBBLE_CHART) {
            return true;
        }

        if (donwloading.geo && typeChart === GEO_CHART) {
            return true;
        }

        if (donwloading.line && typeChart === LINE_CHART) {
            return true;
        }
    }

    const renderEmptyChart = (language) => {
        const { dashboard } = language;

        return (<Card style={{ height: "250px" }}>
            <PerfectScrollbar>
                <CardContent className="emptyGraphBody">
                    <h5>{dashboard.empty_data}</h5>
                </CardContent>
            </PerfectScrollbar>
        </Card>);
    }

    return (
        <div id="contentDashboard">
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    {renderChart(!!dashboardData.pieChartData, PIE_CHART, language)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {renderChart(!!dashboardData.bubbleChartData, LINE_CHART, language, language.dashboard.line_title)}
                </Grid>
                <Grid item xs={12} sm={12}>
                    {renderChart(!!dashboardData.lineChartData, BUBBLE_CHART, language, language.dashboard.bubble_title)}
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6}>
                {renderChart(!!dashboardData.geoChartData, GEO_CHART, language, language.dashboard.geo_title)}
            </Grid> */}
            </Grid>
        </div>
    );
};

ContentComponent.propTypes = {
    language: PropTypes.object.isRequired,   //Variable donde se guardan las traducciones del lenguaje
    dashboardData: PropTypes.object,         //Variable con la informacion de las tablas obtenidad de backEnd
    donwloading: PropTypes.shape({                  //Grupo de datos que nos dice si se descargada la tabla
        pie: PropTypes.bool.isRequired,               //Variable con la que sabremos si la table de tipo pie se esta descargando
        bubble: PropTypes.bool.isRequired,                     //Variable con la que sabremos si la table de tipo pie se esta descargando
        line: PropTypes.bool.isRequired,                       //Variable con la que sabremos si la table de tipo pie se esta descargando
        geo: PropTypes.bool.isRequired,                        //Variable con la que sabremos si la table de tipo pie se esta descargando
    }),
};

export default ContentComponent;