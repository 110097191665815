import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import EditUserComponent from "components/EditUserComponent";
import { getLanguage, getCurrentUser } from 'selectors';
import { renderSnackBarMessage } from 'components/general/commonRenders';
import { setAuthentication, updateUser } from 'actions';
import { USER_PROFILE_URL } from 'constants/index';

class EditUserContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatingUser: false,
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
      user: props.user
    }
    this.handleEditUser = this.handleEditUser.bind(this);
  }

  componentDidMount() { }

  /**
   * Method what is called on submit of login, this function call to backEnd and go to dashboard
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * @param {string} values.name - string with user's name
   * @param {string} values.phone - string with user's phone
   * @param {string} [values.extension] - string with phone extension 
   * @param {string} values.email - string with user's email
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  handleEditUser(values) {
    var dataToEdit = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      extension: values.extension,
    }
    this.setState({
      updatingUser: true
    });

    this.props.updateUser(values._id, dataToEdit).then(response => {
      this.successEditUser(response);
    }).catch(error => {
      // console.log("catch updateUser", error);
      var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.updateData;
      this.setState({
        updatingUser: false,
        snackBarInfo: {
          message,
          typesnack: 'error',
          openSnackBar: true
        }
      });
      // this.props.history.push(USER_PROFILE);
    });
  }

  /**
   * Method what is called on submit of login, this function call to backEnd and go to dashboard
   * 
   * @param {Object} response - Respuesta de la accion de editar usuario
   * @param {string} response.type - Type de accion que se realizó
   * @param {Object} response.payload - Respuesta del backEnd transformed to json from api.js
  */
  successEditUser(response) {
    // console.log("success updateUser", response);
    var user = JSON.parse(localStorage.getItem('user'));
    user.email = response.payload.email;
    user.name = response.payload.name;
    user.phone = response.payload.phone;
    user.extension = response.payload.extension;
    localStorage.setItem("user", JSON.stringify(user));

    this.props.setAuthentication({
      token: localStorage.getItem('token'),
      user: user
    });

    setTimeout(() => {
      this.setState({
        updatingUser: false
      });
      this.goToProfile({ showSnackbar: true, snackbarMessage: this.props.language.editUser.successEdit });
    }, 500);
  }

  /**
   * Change the current view to login url
  */
  goToProfile = (data = null) => {
    if (data) {
      this.props.history.push(USER_PROFILE_URL, data);
    } else {
      this.props.history.push(USER_PROFILE_URL);
    }
  }

  render() {
    return (
      <div>
        <EditUserComponent
          language={this.props.language}
          user={this.props.user}
          updatingUser={this.state.updatingUser}
          goToProfile={this.goToProfile}
          handleEditUser={this.handleEditUser} />
        {renderSnackBarMessage(
          this.state.snackBarInfo.openSnackBar,
          this.state.snackBarInfo.message,
          this.state.snackBarInfo.typesnack,
          () => this.setState({ snackBarInfo: { openSnackBar: false } }),
          4000)
        }
      </div>
    );
  }
}

EditUserContainer.propTypes = {
  language: PropTypes.object.isRequired,    //Variable donde se guarda el lenguaje
  user: PropTypes.object.isRequired,        //Variable obtenida del backend con los datos del usuario
  setAuthentication: PropTypes.func.isRequired,    //Func. obtenida de action para poner los datos de la session
  updateUser: PropTypes.func.isRequired,    //Func. para actualizar la informacion del usuario
};

const mapStateToProps = (state, props) => ({
  language: getLanguage(state),
  user: getCurrentUser(state)
});


export default withRouter(connect(mapStateToProps, {
  updateUser,
  setAuthentication
})(EditUserContainer));