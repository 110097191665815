import React from 'react';
import PropTypes from 'prop-types';
import Sarabun from 'fonts/Sarabun/Sarabun-Regular.ttf';
import SarabunBold from 'fonts/Sarabun/Sarabun-Bold.ttf';
import SarabunItalic from 'fonts/Sarabun/Sarabun-Italic.ttf';
import DidactGothic from 'fonts/DidactGothic/DidactGothic-Regular.ttf';
import {Document, Page, View,
  Text, StyleSheet, Font
} from '@react-pdf/renderer';
import moment from 'moment';
import { POSITIVE } from 'constants/index';

// Register font
Font.register({ family: 'Sarabun-Regular', src: Sarabun });
Font.register({ family: 'Sarabun-Bold', src: SarabunBold });
Font.register({ family: 'Sarabun-Italic', src: SarabunItalic });
Font.register({ family: 'DidactGothic-Regular', src: DidactGothic });

const styles = StyleSheet.create({
  page: { padding: 60, },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  listTitle: {
    width: "100%",
    color: "#D9514E",
    marginBottom: "10px"
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Sarabun-Bold',
    marginTop: "5px"
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    color: "#D9514E",
    fontFamily: 'DidactGothic-Regular',
    marginTop: "5px",
  },
  filterText: {
    fontSize: 12,
    fontWeight: 400,
    color: "#3F4443",
    fontFamily: 'Sarabun-Regular',
    marginTop: "5px",
  },
  filterTitle: {
    fontFamily: 'Sarabun-Regular',
    color: "#3F4443",
    fontSize: 12,
    marginTop: "7px",
    marginBottom: "5px",
  },
  blockFilter: {
   display: "flex",
   flexDirection: "row",
   flexWrap: "wrap",
   width: "100%"
  },
  boxFilter: {
    fontFamily: 'Sarabun-Regular',
    fontSize: 12,
    paddingLeft: "12",
    position: "relative",
    background: "#3beadc",
    display: "inline-block",
    flexGrow: 0,
    marginRight: "35px",
    marginTop: "10px",
  },
  borderLeft: {
    borderLeft: "2 solid #828282",
    position: "absolute",
    display: "inline-block",
    top: "5%",
    bottom: "5%"
  },
  filterName: {
    fontFamily: 'Sarabun-Italic',
    color: "#3F4443",
    fontSize: 11,
  },
  filterData: {
    fontFamily: 'Sarabun-Bold',
    fontSize: 11,
    color: "#3F4443",
  },
  footer: {
    marginTop: "50px",
    fontSize: 12,
    display: "flex",
    fontFamily: 'Sarabun-Regular',
    flexDirection: "row",
  },
  footerText: {
    fontFamily: 'Sarabun-Regular',
    flexGrow: 0,
    display: "inline-block",
  },
  footerUser: {
    fontFamily: 'Sarabun-Bold',
    display: "inline-block",
  },
  table: {
    display: "flex",
    width: "100%",
    alignItems: "left"
  },
  tableRow: {
    width: "100%",
    flexDirection: "row",
    display: "flex",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#DADADA",
    padding: "10px 0px !important",
  },
  rowData: { margin: "0", },
  rowHead: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    fontFamily: 'Sarabun-Bold',
    lineHeight: "1rem",
  },
  tableColUserId: {
    width: "10%",
    maxWidth: "10%",
  },
  tableColRegion: {
    width: "18%",
    maxWidth: "18%",
  },
  tableColAge: {
    width: "6%",
    maxWidth: "6%",
  },
  tableColGender: {
    width: "12%",
    maxWidth: "12%",
  },
  tableColResult: { flexGrow: 1 },
  tableColDate: {
    width: "12%",
    maxWidth: "12%",
  },
  tableColQRCode: {
    width: "15%",
    maxWidth: "15%",
  },
  tableColDisease: {
    width: "12%",
    maxWidth: "12%",
    margin: "0",
  },
  tableColHead: {
    color: "#828282 !important",
    textAlign: "left",
    justifyContent: "left",

    margin: "0",
    marginTop: 5,
    fontSize: 10,
    flexGrow: 0,
    lineHeight: "1rem",
  },
  tableCell: {
    margin: "0",
    marginTop: 5,
    fontSize: 10,
    flexGrow: 0,
    color: "#3F4443",
    lineHeight: "1rem",
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 20,
    textAlign: 'right',
    fontSize: 12,
    fontFamily: 'Sarabun-Regular',
    lineHeight: "1rem",
    color: "#3F4443"
  },
});

/**
  * Metodo para crear el texto que se mostrará indicando que filtros se estan aplicando en la generación de pdf
*/
function generateFilterText(filters, language){
  let { disease, endAge, endDate, endQRCode, gender, 
    region, result, startAge, startDate, 
    startQRCode } = filters;
  var textFilter = [];
  let ageText = null;
  let dateText = null;
  let qrText = null;
  let dateFormat = "YYYY MMMM DD";

  if(result.length && result.length < 3){
    let allCasesFilter = result.join(", ");
    allCasesFilter = allCasesFilter.replace(POSITIVE, language.results.cardView.result_option3); //POSITIVOS
    allCasesFilter = allCasesFilter.replace("2", language.results.cardView.result_option4); //NEGATIVOS
    allCasesFilter = allCasesFilter.replace("3", language.results.cardView.result_option2); //INCONCLUSO
    textFilter.push([language.results.cardView.result_option1, `${allCasesFilter}`]);
  }

  if(disease) textFilter.push([language.results.cardView.disease_option1, disease]); 
  if(gender) textFilter.push([language.results.cardView.gender_option1, gender]); 
  if(region) textFilter.push([language.results.cardView.region_filter, region]);

  if(startAge && endAge){
    ageText = language.results.pdf.ageTextFilter.replace("<STARTAGE>", startAge);
    ageText = ageText.replace("<ENDAGE>", endAge);
  }else if(startAge && !endAge){
    ageText = language.results.pdf.startAgeTextFilter.replace("<STARTAGE>", startAge );
  }else if(!startAge && endAge) {
    ageText = language.results.pdf.endAgeTextFilter.replace("<ENDAGE>", startAge);
  }
  if(ageText) textFilter.push([language.results.cardView.age_option1, ageText]);

  if(startDate && endDate){
    dateText = language.results.pdf.dateTextFilter.replace("<STARTDATE>", moment(startDate).format(dateFormat) );
    dateText = dateText.replace("<ENDDATE>",  moment(endDate).format(dateFormat));
  }else if(startDate && !endDate){
    dateText = language.results.pdf.startDateTextFilter.replace("<STARTDATE>", moment(startDate).format(dateFormat));
  }else if(!startDate && endDate) {
    dateText = language.results.pdf.endDateTextFilter.replace("<ENDDATE>", moment(endDate).format(dateFormat) );
  }
  if(dateText) textFilter.push([language.results.cardView.date, dateText]);

  if(startQRCode && endQRCode){
    qrText = language.results.pdf.qrTextFilter.replace("<STARTQR>", startQRCode);
    qrText = qrText.replace("<ENDQR>", endQRCode);
  }else if(startQRCode && !endQRCode){
    qrText = language.results.pdf.startQrTextFilter.replace("<STARTQR>", startQRCode);
  }else if(!startQRCode && endQRCode) {
    qrText = language.results.pdf.endQrTextFilter.replace("<ENDQR>", endQRCode);
  }
  if(qrText) textFilter.push([language.results.cardView.qrCode_option1, qrText]);

  textFilter.sort( (a, b) => {
    // ASC  -> a.length - b.length
    // DESC -> b.length - a.length
    return a[1].length - b[1].length;
  });

  return textFilter;
}

function renderRows(results, columnsToShow){
  let rows = [];

  for(let i=0; i<results.length; i++){
    rows.push(<View key={i} style={[styles.tableRow, styles.rowData]} wrap={false}>
      {columnsToShow.sickness && 
        <Text style={[styles.tableCell, styles.tableColDisease]}>
          {results[i].disease[0].name}
        </Text>
      }
      <Text style={[styles.tableCell, styles.tableColQRCode]}>{results[i].qrCode}</Text>
      {columnsToShow.date && 
        <Text style={[styles.tableCell, styles.tableColDate]}>{results[i].createdAt}</Text>
      }
      <Text style={[styles.tableCell, styles.tableColResult]}>{results[i].disease[0].result}</Text>
      {columnsToShow.gender && 
        <Text style={[styles.tableCell, styles.tableColGender]}>{results[i].gender}</Text>
      }
      {columnsToShow.age &&
        <Text style={[styles.tableCell, styles.tableColAge]}>{results[i].age}</Text>
      }
      {columnsToShow.region &&
        <Text style={[styles.tableCell, styles.tableColRegion]}>{results[i].region}</Text>
      }
      {columnsToShow.userId && 
        <Text style={[styles.tableCell, styles.tableColUserId]}>{results[i].userId}</Text>
      }
    </View>)
  }

  return rows;
}

let PDFComponent = (props) => {
  const { results, language, columnsToShow, user, filters } = props;
  let date = moment(new Date()).format("yyyy MMMM DD");
  let footerText = language.results.pdf.footerData.replace('<USER>', user.name);
  
  var textFilter = generateFilterText(filters, language) || [];

  return (<Document>
    <Page style={styles.page} size="A4" orientation="landscape" wrap>
      <View style={styles.listTitle}>
        <Text style={styles.title}>{language.results.pdf.title}</Text>
        <Text style={styles.subtitle}>{language.results.pdf.subtitle + date}</Text>
        {textFilter.length > 0 && <Text style={styles.filterTitle}>{language.results.pdf.filtersTilte}</Text> }
        {textFilter.length > 0 && <View style={styles.blockFilter}>
          {textFilter.map((element, index) => (
              <View key={index} style={styles.boxFilter}>
                <Text style={styles.borderLeft}></Text>
                <Text style={styles.filterName}>{element[0]}:</Text>
                <Text style={styles.filterData}>{element[1]}</Text>
              </View>
            ))
          }
        </View>}
        {/* <Text style={styles.filterText}>{textFilter}</Text> */}
      </View>
      <View style={styles.table}>
        <View style={[styles.tableRow, styles.rowHead]}>
          {columnsToShow.sickness &&
            <Text style={[styles.tableColDisease, styles.tableColHead]}>{language.results.col_sickness}</Text>
          }
          <Text style={[styles.tableColQRCode, styles.tableColHead]}>{language.results.col_qrcode}</Text>
          {columnsToShow.date && 
            <Text style={[styles.tableColDate, styles.tableColHead]}>{language.results.col_date}</Text>
          }
          <Text style={[styles.tableColHead, styles.tableColResult]}>{language.results.col_result}</Text>
          {columnsToShow.gender &&
            <Text style={[styles.tableColGender, styles.tableColHead]}>{language.results.col_gender}</Text>
          }
          {columnsToShow.age && 
            <Text style={[styles.tableColAge, styles.tableColHead]}>{language.results.col_age}</Text>
          }
          {columnsToShow.region && 
            <Text style={[styles.tableColRegion, styles.tableColHead]}>{language.results.col_region}</Text>
          }
          {columnsToShow.userId &&
            <Text style={[styles.tableColUserId, styles.tableColHead]}>{language.results.col_userId}</Text>
          }
        </View>
        { renderRows(results, columnsToShow) }
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerText}>{language.results.pdf.footer}</Text>
        <Text style={styles.footerUser}>{footerText}</Text>
      </View>
      <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (`${language.results.pdf.page} ${pageNumber} ${language.results.pdf.pageOf} ${totalPages}`)} fixed />
    </Page>
  </Document>)
}

PDFComponent.propTypes = {
  results: PropTypes.array.isRequired,            //Respuesta al fetchResults con los datos del backEnd
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  columnsToShow: PropTypes.shape({                //Objeto con los datos de columnnas a mostrar dentro de la tabla de resultados (usado en la view list)
    sickness: PropTypes.bool.isRequired,          //Dato para la columna "disease", al estar en true se muestra
    date: PropTypes.bool.isRequired,              //Dato para la columna "date", al estar en true se muestra
    gender: PropTypes.bool.isRequired,            //Dato para la columna "gender", al estar en true se muestra
    age: PropTypes.bool.isRequired,               //Dato para la columna "age", al estar en true se muestra
    region: PropTypes.bool.isRequired,            //Dato para la columna "region", al estar en true se muestra
    userId: PropTypes.bool.isRequired             //Dato para la columna "userId", al estar en true se muestra
  }),
  user: PropTypes.object.isRequired,              //Variable obtenida del localstorage con los datos del usuario
  filters: PropTypes.object.isRequired,           //Objeto con los filtros que se han aplicado
};

export default PDFComponent;