/** Metodos de uso general, que podrian ser usado en cualquier parte 
 * por ejemplo un metodo que encuentre un elemento de una lista podria ir aquí
*/


/**
 * Metodo para obtener la fecha actual con un formato de 'dd-mm-yyy'
 * 
 * 
 * @returns {string} string con la fecha de hoy formateada al estilo de: 'dd-mm-yyy'
*/
export function getTodayDate(){
    var currentDate = new Date();
    var month = '' + (currentDate.getMonth() + 1);
    var day = '' + currentDate.getDate();

    month = (month.length < 2) ? '0' + month : month;
    day = (day.length < 2) ? '0' + day : day;

    return [day, month, currentDate.getFullYear()].join('-');
}

/**
 * Metodo para obtener un elemento de un array  a partir de la comparacion en uno de sus elementos con el valor dadp
 * 
 * @param {array} list - Lista de objectos en la cual queremos buscar el elemento
 * @param {array} field - Nombre de la llave dentro del objeto con el que haremos la comparación
 * @param {array} value - Valor con el cual comparar, al hacer comparacion exitosa se regresará el objeto
 * 
 * @returns {object} objeto con la comparacion aceptada en su field
*/
export function getListElementByField(list, field, value){
  var item = null;
  for(var iter=0; iter<list.length; iter++){
      if(list[iter][field]===value){
          item = list[iter];
          break;
      }
  }
  return item;
};

/**
 * Metodo para obtener un elemento de un array  a partir de la comparacion en uno de sus elementos con el valor dadp
 * 
 * @param {Object} language - Objeto con toda la informacion del lenguaje, es tomado de la carpeta '/language'
 * 
 * @returns {string} cadena con la informacion del lenguaje seleccionado para usar en select en el respectivo idioma:
 *   e.g:  ('Español' | 'Ingles' | 'Spanish' | 'English')
*/
export function setLanguageSelected(language){
  switch (language.locale) {
    case "en":
      return language.english;
    case "es": default:
      return language.spanish;
  }
}

export function saveAs(uri, filename) {
  var link = document.createElement('a');
    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
}

export function getPropertyString(object, valueToGet) {
  valueToGet = valueToGet.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  valueToGet = valueToGet.replace(/^\./, '');           // strip a leading dot
  var values = valueToGet.split('.');
  for (var i = 0, n = values.length; i < n; ++i) {
      var property = values[i];
      if (property in object) {
        object = object[property];
      } else {
          return '';
      }
  }

  if(typeof object == 'number'){ // convert numbers to string
    object = object.toString();
  }
  return object.toLowerCase();
}