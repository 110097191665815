import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';

import MailNotConfirmedComponent from "components/MailNotConfirmed";
import { setLanguages, login, setAuthentication, confirmationMail } from 'actions';
import { getLanguage } from 'selectors';
import { renderSnackBarMessage } from 'components/general/commonRenders';
import { HOME_URL } from 'constants/index';

class MailNotConfirmedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLanguage: false,
      email: null,
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
    }
  }

  componentDidMount() {
    if (!this.props.location.state || !this.props.location.state.email) {
      this.goToLogin();
    } else {
      const { email, showSnackbar, snackbarMessage } = this.props.location.state;
      var newStateData = { email }
      if (showSnackbar) {
        newStateData.snackBarInfo = {
          message: snackbarMessage,
          typesnack: 'success',
          openSnackBar: true
        }
      }
      this.setState(newStateData);
    }
  }

  /**
   * Method what is called on submit of send confiramtions mail button
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * @param {string} values.email - string with user's email
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  sendConfirmationMail = () => {
    // console.log("handleSendConfirmationMail");
    return this.props.confirmationMail({ email: this.props.location.state.email }).then(response => {
      this.successSendConfirmationMail(response);
    }).catch((error) => {
      this.errorSendConfirmationMail(error);
    });
  }

  /**
   * Method when promise of resend confirmation mail success
   * 
   * @param {Object} response - backEnd response transformed to json from api.js
   * 
  */
  successSendConfirmationMail(response) {
    // console.log("successSendConfirmationMail", response);
    this.setState({
      snackBarInfo: {
        message: this.props.language.confirmationMail.success_send_email,
        typesnack: 'success',
        openSnackBar: true
      }
    });
  }

  /**
   * Method when promise of resend confirmation mail fail
   * 
   * @param {Object} error - this object is get from api.js of method validateBackResponse
   * @param {string}  error.message - string with error message
   * @param {integer} [error.code] - string with user's email
   * @param {object}  [error.error] - Object of pairs key-value made specifically for redux-form errors 
   * 
  */
  errorSendConfirmationMail(error) {
    // console.log("errorSendConfirmationMail", error);
    if (error.code === 422 || error.code === 400) {
      throw new SubmissionError(error.error);
    } else {
      this.setState({
        snackBarInfo: {
          message: error.message,
          typesnack: 'error',
          openSnackBar: true
        }
      });

      setTimeout(() => {
        this.goToLogin();
      }, 1000);
    }
  }

  /**
   * Change the current view to login url
  */
  goToLogin = () => {
    this.props.history.push(HOME_URL);
  }

  render() {
    return (
      <div>
        <MailNotConfirmedComponent
          sendConfirmationMail={this.sendConfirmationMail}
          email={this.state.email}
          language={this.props.language}
          goToLogin={this.goToLogin}
        />
        {renderSnackBarMessage(
          this.state.snackBarInfo.openSnackBar,
          this.state.snackBarInfo.message,
          this.state.snackBarInfo.typesnack,
          () => this.setState({ snackBarInfo: { openSnackBar: false } }),
          4000)
        }
      </div>
    );
  }
}

MailNotConfirmedContainer.propTypes = {
  setLanguages: PropTypes.func.isRequired,  //Func. obtenida de action para poner el lenguaje en caso de detectar cambios o si no se ha seleccionado
  login: PropTypes.func.isRequired,         //Func. para mandar la solicitud de login
  language: PropTypes.object.isRequired,    //Variable donde se guardan las traducciones del lenguaje
  setAuthentication: PropTypes.func.isRequired,    //Func. obtenida de action para poner los datos de la session
};

const mapStateToProps = (state, props) => ({
  language: getLanguage(state),
  //auth:      getAuthenthication(state)
});

export default withRouter(connect(mapStateToProps, {
  setLanguages,
  setAuthentication,
  login,
  confirmationMail
})(MailNotConfirmedContainer));