import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import OrganizationProfile from 'components/OrganizationProfile';
import { getOrganizationUsers, getLanguage, getCurrentOrganization, getCurrentUser } from 'selectors';
import {
  fetchUsersByOrganization,
  updateUser,
  setOrganizationUser,
  fetchOrganization,
  inviteUser,
  deleteUser,
} from 'actions';
import { renderSnackBarMessage, renderLoader } from 'components/general/commonRenders';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';

//import MakeAdministrator from 'components/general/Modals/makeAdministrator/makeAdministrator.js';
//import MakeUser from 'components/general/Modals/makeUser/makeUser.js';
//import DeleteUser from 'components/general/Modals/deleteUser/deleteUser.js';
import { svgToPng } from 'helpers/transforms/svgToPng';
import { ROLE_ADMIN } from 'config';
import { ROLE_USER } from 'config';
import ConfirmInviteUser from 'components/general/Modals/confirmInviteUser/confirmInviteUser.js';

class OrganizationContainer extends Component {

  constructor(props) {
    super(props);
    this.language = this.props.language;
    this.state = {
      headInfo: {
        title: this.language.organization.title,
        subtitle: ''
      },
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
      invitedUserIsAdmin: false,
      openModal: false,
      modalSelected: null,
      modalData: {},
      organizationUsers: [],
      loaded_organization: false,
    }

    this.handleChangeIsAdmin = this.handleChangeIsAdmin.bind(this);
    this.donwloadQR = this.donwloadQR.bind(this);
  }

  componentDidMount() {
    //this.getUsersOrganization();
    this.getOrganization();
    if (this.props.location.state && this.props.location.state.showSnackbar) {
      const { snackbarMessage } = this.props.location.state;
      var newStateData = {
        message: snackbarMessage,
        typesnack: 'success',
        openSnackBar: true
      }
      this.setState({ snackBarInfo: newStateData });
      this.props.history.replace({ state: {} });
    }
  }

  /**
   * Método llamado para obtener la organizacion (compañia o institución) del usuario
   * 
  */
  getOrganization() {
    this.props.fetchOrganization(this.props.user.company._id).then(response => {
      this.successGetOrganization(response);
    }).catch(error => {
      this.errorGetOrganization(error);
    });
  }

  /**
   * Método llamado cuando getOrganization obtuvo una respuesta exitosa
  */
  successGetOrganization(response) {
    // console.log("successGetOrganization", response);
    this.setState({
      loaded_organization: true,
      organization: response.payload.response.company,
      organizationUsers: response.payload.response.company.users
    });
  }

  /**
   * Método llamado cuando getOrganization obtuvo una respuesta fallida, o hubo un error en el successGetOrganization
  */
  errorGetOrganization(error) {
    // console.log("catch fetchOrganizations", error);
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.organization.error_load;
    console.log("this.props.user.company", this.props.user.company);
    this.setState({
      loaded_organization: true,
      organization: this.props.user.company,
      organizationUsers: [],
      snackBarInfo: {
        message,
        typesnack: 'error',
        openSnackBar: true
      }
    });
  }

  /**
   * Método llamado cuando se hace click en el botón de invitar usuario
  */
  handleInviteUser = (values) => {
    // console.log("handleInviteUser", values);
    // console.log("isAdmin", this.state.invitedUserIsAdmin);

    var user = JSON.parse(localStorage.getItem('user'));
    let inviteUserData = {
      email: values.inviteUser,
      organizationId: user.company._id,
      invitationType: this.state.invitedUserIsAdmin ? 'admin' : 'user'
    }

    if (this.state.invitedUserIsAdmin) {
      this.handleOpenModal(ConfirmInviteUser, inviteUserData);
    } else {
      // console.log("inviteUserData", inviteUserData);
      this.props.inviteUser(inviteUserData).then(response => {
        this.successInviteUser(response);
      }).catch(error => {
        this.errorInviteUser(error);
      });
    }

    this.setState({
      invitedUserIsAdmin: false
    });
  }

  successInviteUser(response) {
    // console.log("successInviteUser", response);
    this.setState({
      snackBarInfo: {
        message: this.props.language.organization.success_invite_user,
        openSnackBar: true,
        typesnack: 'success'
      }
    });
  }

  errorInviteUser(error) {
    // console.log("errorInviteUser", error);
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.organization.error_invite_user;
    this.setState({
      snackBarInfo: {
        message: message,
        openSnackBar: true,
        typesnack: 'error'
      }
    });
  }

  /**
   * Method que es llamado para abrir modal, renderizará el componente mandado en modalComponent
   * 
   * @param {string} modalComponent - Un tipo de elemento React (ej. MyComponent).
  */
  handleOpenModal = (modalComponent, user) => {
    // console.log("Entro a handleOpenModal", this.state.openModal, modalComponent, user);
    this.setState({
      openModal: !this.state.openModal,
      modalData: {
        ModalSelected: modalComponent,
        modalInfo: {
          user_selected: user
        }
      }
    });

    // console.log("handleOpen", this.state.open);
  }

  /**
   * Method que es llamado cuando se acepta el agregar un productor
   * 
   * @param {Object} data - objeto con losdatos del productor obtenidos del modal
   * @param {Object} data.name - nombre del productor
  */
  handleModalSubmit = (typeModal, data) => {
    console.log("ENTro a handleModalSubmit", typeModal, data);
    if (typeModal === "DeleteUser") {
      this.deleteUser(data._id);
    } else if (typeModal === "ConfirmInviteUser") {
      this.props.inviteUser(data).then(response => {
        this.successInviteUser(response);
      }).catch(error => {
        this.errorInviteUser(error);
      });
    } else {
      var newRole = typeModal === "MakeAdministrator" ? ROLE_ADMIN : ROLE_USER;
      this.updateOrganizationUser(data._id, { role: newRole });
    }
  }

  /**
   * Método llamado cuando se quiere actualizar en el BackEnd al usuario (en este caso solo se actualiza su rol)
  */
  updateOrganizationUser(id, data) {
    this.props.updateUser(id, data).then(response => {
      this.successUpdateUser(response, id);
    }).catch(error => {
      this.errorUpdateUser(error);
    });
  }

  successUpdateUser(response, idUserUpdated) {
    // console.log("successUpdateUser", response);
    this.setState(oldState => {
      var roleUpdated = response.payload.role;
      const newOrganizationUsers = [];
      for (let user of this.state.organizationUsers) {
        if (user._id === idUserUpdated) {
          newOrganizationUsers.push({ ...user, role: { name: roleUpdated } });
        } else {
          newOrganizationUsers.push({ ...user });
        }
      }
      // console.log("newOrganizationUsers", newOrganizationUsers)
      return {
        organizationUsers: newOrganizationUsers,
        snackBarInfo: {
          message: this.props.language.organization.succes_user_role,
          openSnackBar: true,
          typesnack: 'success'
        }
      };
    });
  }

  errorUpdateUser(error) {
    // console.log("errorUpdateUser", error);
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.organization.error_user_role;
    this.setState({
      snackBarInfo: {
        message,
        openSnackBar: true,
        typesnack: 'danger'
      }
    });
  }

  /**
   * Método llamado cuando se quiere eliminar en el BackEnd al usuario
  */
  deleteUser(id) {
    this.props.deleteUser(id).then(response => {
      this.successDeleteUser(response, id);
    }).catch(error => {
      this.errorDeleteUser(error);
    });
  }

  successDeleteUser(response, idUserDeleted) {
    // console.log("successDeleteUser", response);
    this.setState(oldState => {
      const newOrganizationUsers = [];
      for (let user of this.state.organizationUsers) {
        if (user._id !== idUserDeleted) {
          newOrganizationUsers.push({ ...user });
        }
      }
      // console.log("newOrganizationUsers", newOrganizationUsers)
      return { organizationUsers: newOrganizationUsers };
    });
  }

  errorDeleteUser(error) {
    // console.log("errorDeleteUser", error);
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.general;
    this.setState({
      snackBarInfo: {
        message,
        openSnackBar: true,
        typesnack: 'danger'
      }
    });
  }

  /**
   * Change the current view to url sended by parameter
   * 
   * @param {string} url - variable with url of view that we want to go
  */
  goToView = (url) => {
    this.props.history.push(url);
  }

  donwloadQR = () => {
    svgToPng(this.state.organization.qrcode, 10).then(data => {
      var a = document.createElement("a");
      a.href = data;
      let filename = this.language.filenames.QRCode + moment(new Date()).format("yyyy-MM-DD");
      a.download = `${filename}.png`;
      a.click();
    }).catch(function (err) {
      // console.log("err", err);
    });
  }

  handleChangeIsAdmin() {
    // console.log("handleChangeIsAdmin");
    this.setState({
      invitedUserIsAdmin: !this.state.invitedUserIsAdmin
    });
  }

  render() {
    return (<PerfectScrollbar style={{ width: "100%" }}>
      {this.state.loaded_organization ?
        <OrganizationProfile
          handleInviteUser={this.handleInviteUser}
          headInfo={this.state.headInfo}
          language={this.language}
          goToView={this.goToView}
          organization={this.state.organization}
          user={this.props.user}
          listUsers={this.state.organizationUsers}
          handleModalSubmit={this.handleModalSubmit}
          handleOpenModal={this.handleOpenModal}
          openModal={this.state.openModal}
          modalData={this.state.modalData}
          invitedUserIsAdmin={this.state.invitedUserIsAdmin}
          handleChangeIsAdmin={this.handleChangeIsAdmin}
          donwloadQR={this.donwloadQR}
        />
        : renderLoader(this.language.organization.loader, null, 290)
      }
      {renderSnackBarMessage(
        this.state.snackBarInfo.openSnackBar,
        this.state.snackBarInfo.message,
        this.state.snackBarInfo.typesnack,
        () => this.setState({ snackBarInfo: { openSnackBar: false } }),
        4000)
      }
    </PerfectScrollbar>
    );
  }
}

OrganizationContainer.propTypes = {
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  organizationUsers: PropTypes.array.isRequired,        //Variable obtenida del backend con los datos del organización
  user: PropTypes.object.isRequired,                //Variable obtenida del backend con los datos del usuario
  fetchUsersByOrganization: PropTypes.func.isRequired,     //Func. para obtener todos los datos de organizacion
  updateUser: PropTypes.func.isRequired,//Func. actualizar los datos de un usuario de la organizacion
  setOrganizationUser: PropTypes.func.isRequired,   //Func. actualizar informacion mostrada 
  inviteUser: PropTypes.func.isRequired,            //Func. para mandar el correo del usuario al backend y mandar los datos
};

const mapStateToProps = (state) => ({
  language: getLanguage(state),
  organizationUsers: getOrganizationUsers(state),
  organization: getCurrentOrganization(state),
  user: getCurrentUser(state)
});

export default withRouter(connect(mapStateToProps, {
  fetchUsersByOrganization,
  fetchOrganization,
  updateUser,
  deleteUser,
  setOrganizationUser,
  inviteUser
})(OrganizationContainer));