import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import LinkComponent from 'components/LinkComponent';
import { getLanguage, getCurrentOrganization, getCurrentUser } from 'selectors';
import { fetchUsersByOrganization, 
  updateUser, 
  setOrganizationUser, 
  fetchOrganization, 
  inviteUser,
  deleteUser,
} from 'actions';
import { renderSnackBarMessage, renderLoader } from 'components/general/commonRenders';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { saveAs } from 'helpers/general';

class OrganizationContainer extends Component {

  constructor(props){
    super(props);
    this.language = this.props.language;
    this.state = {
      headInfo:{
        title: this.language.link.title,
        subtitle: ''
      },
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
      modalSelected: null,
      loaded_organization: false,
    }
  }

  componentDidMount(){
    //this.getUsersOrganization();
    this.getOrganization();
  }

  /**
   * Método llamado para obtener la organizacion (compañia o institución) del usuario
   * 
  */
  getOrganization(){
    this.props.fetchOrganization(this.props.user.company._id).then(response => {
      this.successGetOrganization(response);
    }).catch(error => {
      this.errorGetOrganization(error);
    });
  }

  /**
   * Método llamado cuando getOrganization obtuvo una respuesta exitosa
  */
  successGetOrganization(response){
    // console.log("successGetOrganization", response);
    this.setState({
      loaded_organization: true,
      organization: response.payload.response.company
    });
  }

  /**
   * Método llamado cuando getOrganization obtuvo una respuesta fallida, o hubo un error en el successGetOrganization
  */
  errorGetOrganization(error){
    // console.log("catch fetchOrganizations", error);
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.linkData;
    let organization = {
      name: this.props.user.company.name
    };
    this.setState({
      loaded_organization: true,
      organization,
      snackBarInfo:{ message,
        typesnack: 'error',
        openSnackBar: true
      }
    });
  }

  /**
   * Change the current view to url sended by parameter
   * 
   * @param {string} url - variable with url of view that we want to go
  */
  goToView = (url) => {
    this.props.history.push(url);
  }
  
  /**
   * Download png image with of company qr code
   * 
   * @param {string} text - variable with company's code
  */
  donwloadQR = () => {
    // svgToPng(this.state.organization.qrcode , 10).then(data => {
    //   var a = document.createElement("a"); 
    //   a.href = data;
    //   let filename = this.language.filenames.QRCode + moment(new Date()).format("yyyy-MM-DD");
    //   a.download = `${filename}.png`;
    //   a.click();
    // }).catch(function (err) {
    //   // console.log("err", err);
    // });

    let filename = this.language.filenames.QRCode + moment(new Date()).format("yyyy-MM-DD");
    var svgElements = document.body.querySelectorAll('svg');
    svgElements.forEach(function(item) {
        item.setAttribute("width", item.getBoundingClientRect().width);
        item.setAttribute("height", item.getBoundingClientRect().height);
        item.style.width = null;
        item.style.height= null;
    });

    setTimeout(() => { 
      html2canvas(document.getElementById("QRImg"), {
        useCORS: true,
        allowTaint: false,
        logging: true,
      }).then( canvas => {
        if(navigator.userAgent.search('Chrome') >= 0){
          saveAs(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"), `${filename}.png`);
        } else {
          saveAs(canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream"), `${filename}.jpeg`);
        }
        //saveAs(canvas.toDataURL(), `${filename}.jpg`);
      });
    }, 750);
  }

  /**
   * Copy text of company's code on clipboard
   * 
   * @param {string} text - variable with company's code
  */
  copyToClipboard = (text) => {
    var copyText = document.createElement("textarea");

    document.body.appendChild(copyText);
    copyText.value = text;
    copyText.select();
    document.execCommand("copy");
    document.body.removeChild(copyText);
  }

  render() {
    return (<PerfectScrollbar style={{minHeight: "100%", width: "100%"}}> 
        {this.state.loaded_organization ? 
            <LinkComponent
                handleInviteUser={this.handleInviteUser}
                headInfo={this.state.headInfo}
                language={this.language}
                goToView={this.goToView}
                organization={this.state.organization}
                user={this.props.user}
                donwloadQR={this.donwloadQR}
                copyToClipboard={this.copyToClipboard}
              />
            : renderLoader(this.language.link.loader, null, 290)
        }
        {renderSnackBarMessage(
            this.state.snackBarInfo.openSnackBar, 
            this.state.snackBarInfo.message, 
            this.state.snackBarInfo.typesnack,
            () => this.setState({snackBarInfo:{openSnackBar: false}}),
            4000)
        }
      </PerfectScrollbar>
    );
  }
}

OrganizationContainer.propTypes = {
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  user: PropTypes.object.isRequired,                //Variable obtenida del backend con los datos del usuario
  fetchUsersByOrganization: PropTypes.func.isRequired,     //Func. para obtener todos los datos de organizacion
  updateUser: PropTypes.func.isRequired,//Func. actualizar los datos de un usuario de la organizacion
  setOrganizationUser: PropTypes.func.isRequired,   //Func. actualizar informacion mostrada 
  inviteUser: PropTypes.func.isRequired,            //Func. para mandar el correo del usuario al backend y mandar los datos
};

const mapStateToProps = (state) => ({
  language:  getLanguage(state),
  organization:      getCurrentOrganization(state),
  user:      getCurrentUser(state)
});

export default withRouter( connect( mapStateToProps, { 
  fetchUsersByOrganization,
  fetchOrganization,
  updateUser,
  deleteUser,
  setOrganizationUser,
  inviteUser
}) (OrganizationContainer) );