import {createAction} from 'redux-actions';
import { SET_LANGUAGE } from 'constants/index';

export * from './Results';
export * from './Authentication';
export * from './User';
export * from './Dashboard';
export * from './Organization';

//LANGUAGES
export const setLanguages = createAction(SET_LANGUAGE, payload => {
        localStorage.setItem('language', payload);
        return payload;
    }
);