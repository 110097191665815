import React from 'react';
import {
  FormControl, InputLabel, FormGroup, TextField,
  Input, FormHelperText, Switch, MenuItem, Select
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { NoteTooltip } from '../commonRenders';
import ReactTooltip from 'react-tooltip';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

export const renderTextField2 = (props) => {
  const { input, id, type, styles, label, meta: { error, visited, touched }, } = props;
  return (<FormControl style={styles} error={error && visited && touched ? true : false}>
    <InputLabel htmlFor={id}>
      {label}
    </InputLabel>
    <Input
      {...input}
      id={id}
      key={id}
      type={type}
      value={input.value}
      component="input"
    />
    <FormHelperText id="component-error-text">{error && visited && touched ? error : ''}</FormHelperText>
  </FormControl>)
}

export const renderTextField = (props) => {
  const { input, id, type, label, placeholder, tooltipData, subText,
    meta: { error, touched }, } = props;
  if (tooltipData) {
    tooltipData.color = tooltipData.color ? tooltipData.color : "#007bff";
    tooltipData.fontSize = tooltipData.fontSize ? tooltipData.fontSize : "0.875rem";
  }
  return (<FormControl style={{ width: '100%' }}
    error={error && touched ? true : false}>
    <label id={`label_${id}`} htmlFor={id}>
      {label} {subText && <sub>{subText}</sub>}
      {tooltipData && <NoteTooltip title={tooltipData.text} placement="top-start">
        <InfoOutlinedIcon style={{
          color: tooltipData.color,
          fontSize: tooltipData.fontSize,
          margin: "5px"
        }}>
        </InfoOutlinedIcon>
      </NoteTooltip>}
    </label>
    <Input
      {...input}
      id={id}
      type={type}
      value={input.value}
      placeholder={placeholder}
      component="input"
    />
    {/* <FormHelperText id="component-error-text">{error && visited && touched ? error : ''}</FormHelperText> */}
    <FormHelperText id="component-error-text">{error && touched ? error : ''}</FormHelperText>
  </FormControl>)
}

export const renderPassword = (props) => {
  const { input, id, label, placeholder, tooltipData, subText, handleMouseDownPassword,
    meta: { error, touched }, showPassword, handleClickShowPassword } = props;
  if (tooltipData) {
    tooltipData.color = tooltipData.color ? tooltipData.color : "#007bff";
    tooltipData.fontSize = tooltipData.fontSize ? tooltipData.fontSize : "0.875rem";
  }
  return (<FormControl style={{ width: '100%' }}
    error={error && touched ? true : false}>
    <label id={`label_${id}`} htmlFor={id}>
      {label} {subText && <sub>{subText}</sub>}
      {tooltipData && <><p className="tooltipBox" style={{ display: "inline-block" }} data-tip={`<p>${tooltipData.text}</p>`}
        data-text-color="#3F4443"
        data-background-color="white"
        data-class="tooltipPassword"
        data-html={true}>
        <InfoOutlinedIcon style={{
          color: "#D9514E",
          fontSize: "0.875rem",
          margin: "10px 0px 0px 0px"
        }}>
        </InfoOutlinedIcon>
      </p>
        <ReactTooltip /> </>}
    </label>
    <Input {...input} id={id}
      type={showPassword ? 'text' : 'password'}
      value={input.password}
      placeholder={placeholder}
      endAdornment={
        <InputAdornment className="passwordAdornment" position="end">
          <IconButton aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword} >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
    {/* <FormHelperText id="component-error-text">{error && visited && touched ? error : ''}</FormHelperText> */}
    <FormHelperText id="component-error-text">{error && touched ? error : ''}</FormHelperText>
  </FormControl>)
}

export const renderPhoneField2 = (props) => {
  const { input, id, label, methodChange, placeholder,
    tooltipData, meta: { error, visited, touched }, } = props;
  if (tooltipData) {
    tooltipData.color = tooltipData.color ? tooltipData.color : "#007bff";
    tooltipData.fontSize = tooltipData.fontSize ? tooltipData.fontSize : "0.875rem";
  }
  // phoneValue = phoneValue ? phoneValue : input.value;
  return (<FormControl style={{ width: '100%' }}
    error={error && visited && touched ? true : false}>
    <label id={`label_${id}`} htmlFor={id}>
      {label}
      {tooltipData && <Tooltip title={tooltipData.text}>
        <InfoOutlinedIcon style={{
          color: tooltipData.color,
          fontSize: tooltipData.fontSize,
          margin: "5px"
        }}>
        </InfoOutlinedIcon>
      </Tooltip>}
    </label>
    <PhoneInput {...input} className="phoneNumber"
      placeholder={placeholder}
      international={true}
      onChange={(value) => {
        if (methodChange) {
          methodChange(value);
        }
      }}
    />
    <FormHelperText id="component-error-text">{error && visited && touched ? error : ''}</FormHelperText>
  </FormControl>)
}

export const renderTextFieldOutlined = (props) => {
  const { input, id, name, type, label } = props;
  // const { input, id, name, type, label, meta: { error, visited }, } = props;
  return (
    <TextField
      {...input}
      key={id}
      id={id}
      label={label}
      type={type}
      name={name}
      variant="outlined"
    />
  )
}


/**
 * @param { id, name, label, type } props
 * 
 *  - id    -> String .- Id del campo
 *  - name  -> String .- Nombre del campo
 *  - label -> String .- Label del campo
 *  - type  -> String .- Tipo del campo
 */
export const renderCustomInput = (props) => {
  const { id, name, label, classes, options } = props;
  return (
    <FormControl >
      <FormGroup>
        {
          options ?
            <TextField
              className={classes.input}
              id={id}
              select
              label={label}
              // className={classes.textField}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="filled"
            >
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              ))}
            </TextField>
            :
            <TextField
              id={id}
              label={name}
              className={classes.textField}
              margin="normal"
              variant="filled"
            />
        }

      </FormGroup>
    </FormControl>
  );
}

export const inputSelectCustom = (props) => {
  const { id, label, classes, options, input } = props;
  return (
    <TextField
      className={classes.input}
      id={id} key={id} name={id}
      inputProps={input}
      select
      label={label}
      SelectProps={{
        native: true,
        MenuProps: {
          className: classes.menu,
        },
      }}
      margin="normal"
      variant="filled"
    >
      {
        options.map(option => (
          <option
            className={classes.options}
            key={option.value}
            value={option.value}
          >
            {option.value}
          </option>
        ))
      }
    </TextField>
  )
}

export const renderSelectMaterialUi = (props) => {
  const { input, label, classes, options, id, startAdornment } = props;
  //const { input, label, meta: { touched, error }, classes, options, id } = props;
  var selectValue = input.value ? input.value : options[0].value;
  return (<Select
    className={classes.input}
    key={id}
    inputProps={{
      name: id,
      id: id,
    }}
    startAdornment={!!startAdornment && startAdornment}
    onChange={input.onChange}
    value={selectValue}
    label={label}>
    {options.map(option => (
      <MenuItem className="selectItem"
        key={option.value}
        value={option.value}>{option.value}
      </MenuItem>
    ))
    }
  </Select>
  );
}

export const renderSelectWithLabel = (props) => {
  const { input, label, classes, options, id, startAdornment } = props;
  //const { input, label, meta: { touched, error }, classes, options, id } = props;
  // var selectValue = input.value ? input.value : options[0].value;
  // console.log("label", label);
  return (
    <FormControl id={`${id}_FormControl`}>
      <InputLabel id={`${id}_label`}>{label}</InputLabel>
      <Select
        className={classes.input}
        key={id}
        inputProps={{
          name: id,
          id: id,
          value: input.value
        }}
        startAdornment={!!startAdornment && startAdornment}
        // value={selectValue} 
        onChange={input.onChange} >
        {options.map(option => (
          <MenuItem className="selectItem"
            key={option.value}
            value={option.value}>{option.value}
          </MenuItem>
        ))
        }
      </Select>
    </FormControl>
  );
}

export const inputSelectCustomOutlined = (props) => {
  const { id, label, value, classes, options, input } = props;
  return (
    <TextField
      className={classes.input}
      id={id}
      key={id}
      select
      label={label}
      value={value}
      onChange={e => {
        input.onChange(e);
      }}
      SelectProps={{
        native: true,
        MenuProps: {
          className: classes.menu,
        },
      }}
      margin="normal"
      variant="outlined"
    >
      {
        options.map(option => (
          <option
            className={classes.options}
            key={option.value}
            value={option.value}
          >
            {option.value}
          </option>
        ))
      }
    </TextField>
  )
}

export const inputCalendar = (props) => {
  const { classes, label, id, handleChange, dateValue, minDate, maxDate } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        minDate={minDate ? minDate : undefined}
        maxDate={maxDate ? maxDate : null}
        variant="inline"
        //format="MM/dd/yyyy"
        format="yyyy/MM/dd"
        margin="normal"
        autoOk
        emptyLabel={''}
        id={id} key={id} label={label}
        className={classes.inputDate}
        value={dateValue ? dateValue : undefined}
        onChange={date => handleChange(date)}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, inputseparator, formatNumber, NumberValue, placeholder, suffix, tabIndex, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      value={NumberValue}
      placeholder={placeholder}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          }
        });
      }}
      thousandSeparator={inputseparator ? true : false}
      format={formatNumber}
    />
  );
}

export const inputOnlyNumbers = (props) => {
  const { id, label, tooltipData, meta: { error, visited, touched },
    inputseparator, formatNumber, handleChange, NumberValue, placeholder,
    tabIndex } = props;
  if (tooltipData) {
    tooltipData.color = tooltipData.color ? tooltipData.color : "#007bff";
    tooltipData.fontSize = tooltipData.fontSize ? tooltipData.fontSize : "14px";
  }

  return (<FormControl className="formControlWithLabel" style={{ width: '100%' }}
    error={error && visited && touched ? true : false}>
    <label id={`label_${id}`} htmlFor={id}>
      {label}
      {tooltipData && <Tooltip title={tooltipData.text}>
        <InfoOutlinedIcon style={{
          color: tooltipData.color,
          fontSize: tooltipData.fontSize,
          margin: "5px"
        }}>
        </InfoOutlinedIcon>
      </Tooltip>}
    </label>
    <TextField
      id={id} name={id}
      inputseparator={inputseparator}
      value={NumberValue}
      onChange={handleChange}
      InputProps={{
        inputProps: { 
          inputseparator: inputseparator, 
          formatNumber, 
          NumberValue, 
          placeholder,
          tabIndex: tabIndex
        },
        inputComponent: NumberFormatCustom,
      }}
    />
    <FormHelperText id="component-error-text">{error && visited && touched ? error : ''}</FormHelperText>
  </FormControl>)
}


export const ColorSwitch = withStyles({
  switchBase: {
    color: grey[50],
    "&$checked": {
      color: green[400]
    },
    "&$checked + $track": {
      backgroundColor: green[500]
    }
  },
  checked: {},
  track: {}
})(Switch);