import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';

import ContentComponet from './ContentComponent';
import FooterNoAuthComponent from "../general/Footer/layoutNoAuth";
import useStyles from "../../style";
import './styles.css';

const EditUserComponent = (props) => {
  const { handleEditUser, goToProfile, user, updatingUser } = props;
  const classes = useStyles();
  const [language] = useState(props.language);

  return (
    <div className="contentEditUser">
      <Container fluid={true} className={classes.backgroundBody} style={{ padding: "0px" }}>
        <Row noGutters style={{ height: "calc(100% - 70px)", padding: "50px 0px 10px" }}>
          <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 7, offset: 2 }} className={classes.FormLayoutContainer}>
            <Row noGutters>
              <ContentComponet onSubmit={handleEditUser}
                goToProfile={goToProfile}
                updatingUser={updatingUser}
                language={language}
                initialValues={user} />
            </Row>
          </Col>
        </Row>
        <Row noGutters className={classes.footerContainer}>
          <Col xs={{ size: 12, offset: 0 }} className={classes.footer}>
            <FooterNoAuthComponent language={language}></FooterNoAuthComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

EditUserComponent.propTypes = {
  handleEditUser: PropTypes.func.isRequired,       //Func. donde se haran las acciones del submit del form.
  language: PropTypes.object.isRequired,           //Variable donde se guardan las traducciones del lenguaje
  user: PropTypes.object.isRequired,               //Variable obtenida del backend con los datos del usuario
  goToProfile: PropTypes.func.isRequired,          //Func. para regresar a la view correcta
  updatingUser: PropTypes.bool.isRequired,         //Valor que nos dice si se esta en la peticion para actualizar usuario
};

export default EditUserComponent;