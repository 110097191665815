import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import bigInt from 'big-integer';
import html2canvas from 'html2canvas';

import ResultsComponent from 'components/ResultsComponent';
import { getLanguage, getResults, getResultViewSelected, getCurrentUser } from 'selectors';
import { fetchResults, setResultView } from 'actions';
import { getPropertyString, saveAs } from 'helpers/general';
import { VIEW_LIST, VIEW_CARD, VIEW_INDIVIDUAL } from 'constants/index';
import { renderSnackBarMessage, renderLoader } from 'components/general/commonRenders';
import { INDETERMINATE, NEGATIVE, POSITIVE } from 'constants/index';
import { transformDiseaseLanguage, transformGenderLanguage, transformRegionLanguage, transformResultLanguage } from 'helpers/transforms/registersTransforms';

import 'moment/locale/es';

/*
 * Note replace all: defaultResults by  defaultResults
 */

class ResultsContainer extends Component {

  constructor(props) {
    super(props);
    this.language = this.props.language;
    var headInfo = this.getHeadByTypeView(this.props.view.typeSelected);
    this.filters = {
      result: [],
      gender: null,
      startDate: null,
      endDate: null,
      startAge: null,
      endAge: null,
      startQRCode: null,
      endQRCode: null,
      disease: null,
      region: null,
    }
    this.state = {
      headInfo,
      typeView: this.props.view.typeSelected,
      loaded_results: false,
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
      columnsToShow: {
        sickness: true,
        date: true,
        gender: true,
        age: true,
        region: true,
        userId: true
      },
      columnsResults: {
        indeterminate: false,
        positive: false,
        negative: false,
      },
      showBlockFilters: {
        filters: false,
        search: false
      },
      isFilterActive: false,
      openModal: false,
      modalData: {},
      resultsCount: 0,
      page: 0,
      pageSize: 5,
      filteredResults: [],
      optionsFilterRegion: []
    }

    this.getHeadByTypeView = this.getHeadByTypeView.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleToggleColumns = this.handleToggleColumns.bind(this);
    this.handleToggleResultColumns = this.handleToggleResultColumns.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this);
    this.handleDownloadSection = this.handleDownloadSection.bind(this);
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
  }

  /**
   * Método para la informacion del titulo y subtitulo mostrado en el header de results
   * 
   * @param {string} typeView - String que nos dice que view es la que esta seleccionada
   *    
   * @returns {array} objeto para ser usado en header of results 
   *    ejemplo de objeto: {title: string, subtitle: string}
  */
  getHeadByTypeView(typeView) {
    //var date = getTodayDate();
    moment.locale(this.language.locale);
    let todayDate = moment(new Date()).format("yyyy MMMM DD");
    var headInfo = {
      title: '',
      subtitle: this.language.results.date.replace("<date>", todayDate)
    }
    switch (typeView) {
      case VIEW_CARD:
        headInfo.title = this.language.results.title_cards;
        break;
      case VIEW_INDIVIDUAL:
        headInfo.title = this.language.results.title_individual;
        break;
      case VIEW_LIST:
      default:
        headInfo.title = this.language.results.title_list;
        break;
    }
    return headInfo;
  }

  componentDidMount() {
    this.getResults();
    //this.props.setResultView(this.props.viewSelected);
  }

  /**
   * Método para obtener los datos de resultados en el backend backend
  */
  getResults() {
    this.props.fetchResults({ institution: this.props.user.company._id }).then(response => {
      this.successFetchResults(response);
    }).catch(error => {
      this.errorFetchResults(error);
    });
  }

  getRegionFilterOptions(registers){
    let arrayFilterOptions = []
    for(let register of registers){
      let exists = arrayFilterOptions.find((option, _) => {
          return option.value === register.region;
      });
      if(!exists && register.region){
        arrayFilterOptions.push({value: register.region, label: register.region});
      }
    }

    return arrayFilterOptions;
  }

  successFetchResults(response) {
    let { registers } = response.payload.response;

    for (let register of registers) {
      register.createdAt = moment(register.createdAt).format("yyyy-MM-DD");
      register.disease[0].result = transformResultLanguage(register, this.props.language);
      register.disease[0].name = transformDiseaseLanguage(register.disease[0].name, this.props.language);
      register.gender = transformGenderLanguage(register.gender, this.props.language);
      register.region = transformRegionLanguage(register.region, this.props.language);
      let fingerprintLength = register.fingerprint.length;
      register.userId = register.fingerprint.substring(fingerprintLength-6, fingerprintLength);
    }
    
    let listOptions = this.getRegionFilterOptions(registers);
    this.setState({
      loaded_results: true,
      resultsCount:registers.length,
      filteredResults: this.filter(registers, this.filters),
      optionsFilterRegion: listOptions
    });
  }

  errorFetchResults(error) {
    // console.log("catch fetchResults", error);
    error = typeof error === 'string' ? error : error.message;
    this.setState({
      loaded_results: true,
      results: {},
      filteredResults: {},
    })
    // this.setState({
    //   loaded_results: true,
    //   snackBarInfo:{
    //     message: error,
    //     typesnack: 'error',
    //     openSnackBar: true
    //   }
    // })
  }

  /**
   * Método que actualiza los datos para cambiar de view
   * 
   * @param {string} typeView - String que nos dice que view es la que esta seleccionada
   *    
  */
  handleChangeView(typeView) {
    // console.log("handleChangeView", typeView);
    var headInfo = this.getHeadByTypeView(typeView);
    this.props.setResultView(typeView);
    this.filters = {
      result: [],
      age: null,
      gender: null,
      startDate: null,
      endDate: null,
    }
    this.resultsFiltered = this.filter(this.props.results, this.filters)
    let start = this.state.page * this.state.pageSize;
    let end = start + this.state.pageSize;
    let data = this.resultsFiltered.slice(start, end);
    console.log("ya acabo de cortar el arreglo")
    this.setState({
      headInfo,
      typeView: typeView,
      filteredResults: data,
      // filteredResults: this.filter(defaultResults, this.filters)
    });
  }

  /**
   * Método que muestra/oculta las columnas mostradas en la tabla de resultados (vista de tipo Lista)
   * 
   * @param {string} columnName - String que nos dice que columna se quiere mostrar/ocultar
   *    
  */
  handleToggleColumns(columnName) {
    // var newColumnsToShow = {...this.state.columnsToShow};
    // newColumnsToShow[columnName] = !newColumnsToShow[columnName];
    // this.setState({
    //   columnsToShow: newColumnsToShow,
    // });

    this.setState({
      columnsToShow: {
        ...this.state.columnsToShow,
        [columnName]: !this.state.columnsToShow[columnName]
      },
    });
  }

  handleToggleResultColumns(resultFilter) {
    var newResultFilters = [];
    if (this.filters.result.indexOf(resultFilter) === -1) {
      newResultFilters = [...this.filters.result, resultFilter];
    } else {
      for (let result of this.filters.result) {
        if (result !== resultFilter) {
          newResultFilters.push(result);
        }
      }
    }
    var columnName = '';
    switch (resultFilter) {
      case INDETERMINATE:
        columnName = 'indeterminate';
        break;
      case POSITIVE:
        columnName = 'positive';
        break;
      case NEGATIVE:
        columnName = 'negative';
        break;
      default: break;
    }
    this.setState({
      columnsResults: {
        ...this.state.columnsResults,
        [columnName]: !this.state.columnsResults[columnName]
      },
    });
    this.handleFilter("result", newResultFilters)
  }

  handleChangePageSize(){
    this.props.scrollToTop();
  }

  /**
   * Método que filtra los datos de las tarjetas
   * 
   * @param {string} filterType - String que nos dice que view es la que esta seleccionada
   * @param {object} data - String que nos dice que view es la que esta seleccionada
   *    
  */
  handleFilter(filterType, valueSelected) {
    //console.log("handleFilter filterType", filterType, valueSelected);
    this.filters[filterType] = valueSelected;

    this.setState({
      isFilterActive: filterType !== "search" ? true : false,
      filteredResults: this.filter(this.props.results, this.filters),
      page: 0,
    })
  }

  cleanFilters() {
    // console.log("cleanFilters");
    this.filters = {
      result: [],
      gender: null,
      startDate: null,
      endDate: null,
      startAge: null,
      startQRCode: null,
      endQRCode: null,
      endAge: null,
      region: null,
      disease: null,
    }
    
    this.setState({
      page: 0,
      filteredResults: this.filter(this.props.results, this.filters),
      // filteredResults: this.filter(defaultResults, this.filters),
      isFilterActive: false,
      columnsResults: {
        indeterminate: false,
        positive: false,
        negative: false,
      },
    })
  }

  /**
   * Metodo para filtrar los datos del array(registros)
   * 
   * @param {Array<Object>} results - array a filtrar
   * @param {string} filters - todos los filtros que queremos aplicar
   * 
   * @returns {array} - array con los datos filtrados
  */
  filter = (results, filtersData) => {
    // console.log("filtrado", results.filter(result => this.validateDataByObject(result, filtersData)))
    return results.filter(result => this.validateDataByObject(result, filtersData));
  }

  /**
   * Metodo para saber si el objeto cumple con los requisitos de los filtros mandados como parametros
   * 
   * @param {string} obj1 - objecto a validar
   * @param {string} filters - todos los filtros que queremos aplicar
   * 
   * @returns {boolean} - resultado de la comparacion del objeto con los filtros
  */
  validateDataByObject = (obj1, filters) => {
    var isValidValue = true;
    for (let key in filters) {
      if(!filters[key] || filters[key].length===0){
        continue;
      }
      switch (key) {
        case "startDate":
          isValidValue = this.validateByDate(obj1.createdAt, filters[key], "start");
        break;
        case "endDate":
          isValidValue = this.validateByDate(obj1.createdAt, filters[key], "end");
        break;
        case "startAge":
          isValidValue = this.validateByAge(obj1.age, filters[key], "start");
        break;
        case "endAge":
          isValidValue = this.validateByAge(obj1.age, filters[key], "end");
        break;
        case "startQRCode":
          isValidValue = this.validateByQRCode(obj1.qrCode, filters[key], "start");
        break;
        case "endQRCode":
          isValidValue = this.validateByQRCode(obj1.qrCode, filters[key], "end");
        break;
        case "result":
          isValidValue = this.validateByResult(obj1.disease[0].result, filters[key]);
        break;
        case "disease":
          isValidValue = this.validateByDisease(obj1.disease[0].name, filters[key]);
        break;
        case "gender":
          isValidValue = this.validateBySex(obj1.gender, filters[key])
        break;
        case "region":
          isValidValue = this.validateByRegion(obj1.region, filters[key])
        break;
        case "search":
          var valueForSearch = ["qrCode", "gender", "age", "disease[0].name", "createdAt", "disease[0].result", "region"];
          isValidValue = this.validateBySearch(obj1, valueForSearch, filters[key])
          break;
        default: break;
      }

      if (!isValidValue) { //If current value is already not valid on latest filter then it is not necessary continue with the other ones
        return isValidValue;
      }
    }
    return isValidValue;
  }

  /**
   * Metodo especifico para el filtro por el campo de result
   *   se valida el campo si valu y resultOption son iguales
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} resultOption - opcion seleccionada del filtro result 
   * 
   * @return {boolean} - true cuando el valor es aceptado segun todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByResult(value, resultOptions) {
    //var acceptAllOption = this.language.results.cardView.result_option1;
    var isValid = false;
    if (!resultOptions || !resultOptions.length) {
      return true;
    } else {
      for (let resultOption of resultOptions) {
        var valueTranformed = -1;
        value = value.toLowerCase();
        switch (value) {
          case "negative": case "negativo":
          case "no detectado": case "no detected":
            valueTranformed = NEGATIVE;
            break;
          case "positive": case "positivo":
          case "detectado": case "detected":
            valueTranformed = POSITIVE;
            break;
          case "indeterminate": case "indeterminado":
          case "inconcluso":
            valueTranformed = INDETERMINATE;
            break;
          default:
        }
        if (valueTranformed === resultOption) {
          isValid = true;
        }
      }

      if (isValid) {
        return isValid;
      }
    }
    return isValid;
  }

  /**
   * Metodo especifico para el filtro por el campo de disaese, 
   *   se valida el campo si value y diseaseOption son iguales
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} diseaseOption - opcion seleccionada del filtro gender 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByDisease(value, diseaseOption) {
    var acceptAllOption = this.language.results.cardView.disease_option1;
    var valueTransformed = this.transformToCorrectDiseaseName(value);
    return this.isValidFilterOption(diseaseOption, acceptAllOption) ? valueTransformed === diseaseOption : true;
  }

  transformToCorrectDiseaseName(value) {
    if (value === "COVID19") {
      return this.language.results.cardView.disease_option2; //General name for covid19
    } else {
      return value;
    }
  }

  /**
   * Metodo especifico para el filtro por el campo de gender, 
   *   se valida el campo si value y genderOption son iguales
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} genderOption - opcion seleccionada del filtro gender 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateBySex(value, genderOption) {
    var acceptAllOption = this.language.results.cardView.gender_option1;
    if (this.isValidFilterOption(genderOption, acceptAllOption)) {
      let genderValueNum = this.transformGenderStringToNumber(value);
      let genderOptionNum = this.transformGenderStringToNumber(genderOption);
      return genderValueNum === genderOptionNum;
    } else {
      return true;
    }
    //return this.isValidFilterOption(genderOption, acceptAllOption) ? value === genderOption : true;
  }

  /**
   * Metodo especifico para el filtro por el campo de region, 
   *   se valida el campo si value y regionOption son iguales
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} regionOption - opcion seleccionada del filtro gender 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByRegion(value, regionOption) {
    var acceptAllOption = this.language.results.cardView.region_filter;
    return this.isValidFilterOption(regionOption, acceptAllOption) ? value === regionOption : true;
  }

  /**
   * Metodo para transformar el genero en string a numero, con esto evitar que el back tenga que hacer la traduccion del campo 
   * 
   * @param {string} value - valor que vamos a transformar
   * 
   * @return {integer} - 0 cuando el valor es "positivo" o "positive"
   *                   - 1 cuando el valor es "negativo" o "negative"
   *                   - -1 cuando entra a al caso default, cuando no es ninguno de los otros
  */
  transformGenderStringToNumber(gender) {
    gender = gender.toLowerCase();
    switch (gender) {
      case "masculino": case "male":
        return 0;
      case "femenino": case "female":
        return 1;
      default:
        return -1;
    }
  }

  /**
   * Metodo especifico para el filtro por el campo de date, 
   * si el type es 'start' y según la fecha seleccionada se compara si el value es igual o mayor
   * si el type es 'end' u otro y según la fecha seleccionada se compara si el value es igual o antes
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} dateSelected - fecha de inicio para el filtro 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByDate(value, dateSelected, type) {
    if (!dateSelected) {
      return true;
    }

    if (type === 'start') {
      return moment(value).isSameOrAfter(moment(dateSelected), 'day');
    } else {
      return moment(value).isSameOrBefore(moment(dateSelected), 'day');
    }
  }

  /**
   * Metodo especifico para el filtro por el campo de age, 
   * si tl type es 'start' y según la edad se compara si el value es igual o mayor
   * si tl type es 'end' u otro y según la edad se compara si el value es igual o menor
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} ageSelected - fecha de inicio para el filtro 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByAge(value, ageSelected, type) {
    if (!ageSelected) {
      return true;
    }

    if (type === 'start') {
      return ageSelected <= value;
    } else {
      return ageSelected >= value;
    }
  }

  /**
   * Metodo especifico para el filtro por el campo de qrCode, 
   * si el type es 'start' y según el qrCode seleccionado se compara si el value es igual o mayor
   * si el type es 'end' u otro y según el qrCode seleccionado se compara si el value es igual o menor
   * 
   * @param {string} value - valor que vamos a revisar, para saber si es valido
   * @param {string} qrSelected - qr obtenido del filtro 
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateByQRCode(value, qrSelected, type) {
    var qrNumeric = null;
    try {
      qrNumeric = bigInt(qrSelected);
    } catch (err) {
      //console.log("err", err);
    }
    if (!qrSelected || !qrNumeric) {
      return true;
    }

    if (type === 'start') {
      return bigInt(qrSelected).lesserOrEquals(value);
    } else {
      return bigInt(qrSelected).greaterOrEquals(value);
    }
  }

  /**
   * Metodo especifico para filtrar los campos restantes, usando el input de search, 
   * según el parametro fields se usara el string del input para validar si se tiene 
   * la cadena en cualquiera de esos campos
   * 
   * @param {Object} date - objeto en el cual se buscaran los datos de fields
   * @param {Array<string>} fields - un array de cadenas con los nombres de las key con las propiedades a buscar del objeto
   * @param {string} searchData - texto con el que se compararan los fields para saber si se contiene la cadena
   * 
   * @return {boolean} - true cuando el valor es aceptado según todas las condiciones
   *                   - false cuando el valor es no es aceptado
  */
  validateBySearch(data, fields, searchData) {
    searchData = searchData.toLowerCase();
    if (searchData) {
      for (var i = 0; i < fields.length; i++) {
        var valueToCheck = getPropertyString(data, fields[i]);
        if (valueToCheck.includes(searchData)) {
          return true;
        }
      }

      return false;
    } else {
      return true;
    }
  }

  /**
   * Metodo para saber si la variable seleccionada de los filtros esta vacia o si se escogio la opcion para mostrar todos los datos
   *  Por ejemplo en Sexo hay tres opciones: Sexo, Hombre, Mujer, 
   *  si se escoge la primera (Sexo) es como no tener ningun filtro y se muestran todos los resultados
   * 
   * @param {string} filterOption - La opción selecccionada al hacer un change de un filtro
   * @param {string} optionAcceptAll - Es la opcion del select de los filtros para aceptar todos los datos
   * 
   * @returns {boolean} - true Cuando filterOption es un string con el cual se puede filtrar los datos
   *                    - false Cuando filterOption es null, empty o cuando es igual a la opcion de mostrar todos los datos
  */
  isValidFilterOption(filterOption, optionAcceptAll) {
    if (filterOption && filterOption !== optionAcceptAll) {
      return true;
    }
  }

  donwloadQR = () => {
    // console.log("donwloadQr");
    const link = document.createElement('a');
    link.href = "./../../../images/qrExample.png";
    // the filename you want
    link.download = 'qrExample.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Method que es llamado para abrir modal, renderizará el componente mandado en modalComponent
   * 
   * @param {string} modalComponent - Un tipo de elemento React (ej. MyComponent).
  */
  handleOpenModal = (modalComponent) => {
    // console.log("Entro a handleOpenModal", this.state.openModal, modalComponent);
    this.setState({
      openModal: !this.state.openModal,
      modalData: {
        ModalSelected: modalComponent,
        modalInfo: {
          user: this.props.user,
          donwloadQR: this.donwloadQR
        }
      }
    });

    // console.log("handleOpen", this.state.openModal);
  }

  /**
   * Método llamado cuando se hace click en el botón de descargar de la vista individual
   * 
  */
  handleDownloadSection() {
    // console.log("handleDownloadSection",);
    var individualView = document.getElementById("printable_section");
    individualView.className += ' individualViewtExported';

    html2canvas(document.getElementById("printable_section"), {
      useCORS: true,
      allowTaint: false,
      logging: true,
    }).then(canvas => {
      // console.log("entro a onredered");
      let filename = this.language.filenames.IndividiualView + moment(new Date()).format("yyyy-MM-DD");
      saveAs(canvas.toDataURL(), `${filename}.png`);
      individualView.className = individualView.className.replace('individualViewtExported').trim();
    });

  }

  render() {
    return (<PerfectScrollbar style={{ marginRight: "-30px", height: "auto", width: "100%", minHeight: "100%"}}>
      {this.state.loaded_results ?
        <ResultsComponent
          {...this.state}
          handleFilter={this.handleFilter}
          cleanFilters={this.cleanFilters}
          handleChangeView={this.handleChangeView}
          handleToggleResultColumns={this.handleToggleResultColumns}
          handleToggleColumns={this.handleToggleColumns}
          handleOpenModal={this.handleOpenModal}
          handleDownloadSection={this.handleDownloadSection}
          handleChangePageSize={this.handleChangePageSize}
          filters={this.filters}
          user={this.props.user}
          language={this.language} />
        :
        renderLoader(this.language.results.loader, '', 290)
      }
      {renderSnackBarMessage(
        this.state.snackBarInfo.openSnackBar,
        this.state.snackBarInfo.message,
        this.state.snackBarInfo.typesnack,
        () => this.setState({ snackBarInfo: { openSnackBar: false } }),
        4000)
      }
    </PerfectScrollbar>
    );
  }
}

ResultsContainer.propTypes = {
  fetchResults: PropTypes.func.isRequired,  //Action que obtiene los results del backEnd
  language: PropTypes.object.isRequired,    //Variable donde se guarda el lenguaje
  results: PropTypes.array.isRequired,      //Respuesta al fetchResults con los datos del backEnd
  view: PropTypes.object.isRequired,//Valor con el tipo de vista que se va a mostrar
  scrollToTop: PropTypes.func.isRequired, //Func. para mover el scroll al inicio
};

ResultsContainer.defaultProps = {
  results: []
};

const mapStateToProps = (state) => ({
  language: getLanguage(state),
  results: getResults(state),
  view: getResultViewSelected(state),
  user: getCurrentUser(state)
});

export default withRouter(connect(mapStateToProps, {
  fetchResults,
  setResultView,
})(ResultsContainer));