/** Methods for use of redux-form validates, 
 * Each method receives the value of input where the transform method is set
 * Each method must return the value after the transform method.
*/

/**
 * Convert value to uppercase
 * 
 * @param {string} [value] - The value of input where the validation method is set
 * 
 * @returns {string} string after proccess for converting to uppercase
*/
export const toUpper= value =>{
    return value && value.toUpperCase();
}

/**
 * Convert value to lowercase
 * 
 * @param {string} [value] - The value of input where the validation method is set
 * 
 * @returns {string} string after proccess for converting to lowercase
*/
export const toLower = value =>{
    return value && value.toLowerCase();
}

/**
 * Remove whitespace from both ends of the alue. 
 * 
 * @param {string} [value] - The value of input where the validation method is set
 * 
 * @returns {string} string after remove all whitespace
*/
export const stringTrim = value =>{
    return value && value.trim();
}