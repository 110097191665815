import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { renderPassword } from '../general/Form';
import { Button, FormGroup } from '@material-ui/core';
import useStyles from "../../style";
import { isRequired as _isRequired, 
  passwordsMatch as _passwordsMatch,
  maxLength as _maxLength,
  includeLowercase as _includeLowercase,
  includeUppercase as _includeUppercase,
  includeNumber as _includeNumber,
} from '../../helpers/validations';
import './styles.css';
import { PASSWORD_MIN_LENGTH } from 'constants/index';

var isRequired = _isRequired('');
var passwordsMatch = _passwordsMatch('');
var passwordLength = _maxLength('', PASSWORD_MIN_LENGTH);
var hasLowercase = _includeLowercase('');
var hasUppercase = _includeUppercase('');
var hasNumber = _includeNumber('');

const ContentComponet = (props) => {
  const { handleSubmit, submitting, pristine, invalid, error, goToProfile, token, goToLogin } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  useEffect(() => {
    setLanguage(props.language);
    isRequired = _isRequired(props.language.errors.required);
    passwordsMatch = _passwordsMatch(props.language.errors.passwordMatch);
    passwordLength = _maxLength(props.language.errors.maxLength, PASSWORD_MIN_LENGTH);
    hasLowercase = _includeLowercase(props.language.errors.notLowercase);
    hasUppercase = _includeUppercase(props.language.errors.notUppercase);
    hasNumber = _includeNumber(props.language.errors.notNumber);
  }, [props.language]);

  const [showPassword, setShowPassword] = useState(false);    
  const clickShowPassword = (value, phoneData) => {
    setShowPassword(!showPassword);
  }

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);    
  const clickShowConfirmPassword = (value, phoneData) => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);    
  const clickShowCurrentPassword = (value, phoneData) => {
    setShowCurrentPassword(!showCurrentPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (<div className={classes.PasswordRecoveryBox}>
        <h3 className={classes.titleRed}>
          {token ?
            language.passwordRecovery.recoveryTitle
            :
            language.passwordRecovery.title}
        </h3>
        <form className="formChangePassword" onSubmit={handleSubmit}>
          <FormGroup>
            {!token && <Field 
                id="currentPassword" name="currentPassword" type="password"
                label={language.passwordRecovery.label_CurrentPassword}
                placeholder={language.passwordRecovery.input_CurrentPassword}
                showPassword={showConfirmPassword}
                handleClickShowPassword={clickShowConfirmPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                validate={[isRequired]}
                component={renderPassword}
            />}
          </FormGroup>
          <FormGroup> 
            <Field 
                id="password" name="password" type="password"
                label={language.passwordRecovery.label_NewPassword}
                placeholder={language.passwordRecovery.input_NewPassword}
                tooltipData={{
                  text: language.signup.passwordTooltip,
                  color: "#D9514E",
                  fontSize: "0.9375rem"
                }}
                showPassword={showPassword}
                handleClickShowPassword={clickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                validate={[isRequired, passwordLength, hasLowercase, hasUppercase, hasNumber]}
                component={renderPassword}
            />
          </FormGroup>
          <FormGroup>
            <Field 
                id="confirmPassword" name="confirmPassword" type="password"
                label={language.passwordRecovery.label_ConfirmPassword}
                placeholder={language.passwordRecovery.input_ConfirmPassword}
                showPassword={showCurrentPassword}
                handleClickShowPassword={clickShowCurrentPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                validate={[isRequired, passwordsMatch]}
                component={renderPassword}
            />
          </FormGroup>
          {error && <strong className="formError">{error}</strong>}
          <div style={{marginTop: "15px"}}>
            <Button id="passwordRecoveryButton" 
              style={{display: "inline-block", width: "65%", minWidth: "inherit"}}
              className="btn" type="submit" 
              disabled={submitting || pristine || invalid}>
              {language.passwordRecovery.button}
            </Button>
            <Button id="cancelButton"
                style={{width: "30%", minWidth: "inherit", marginLeft:"5%"}}
                className="btn btnCancel" type="button"
                onClick={() => {
                    token ? goToLogin() : goToProfile()
                  }
                }>
                {language.passwordRecovery.btnCancel}
            </Button>
          </div>
        </form>
    </div>
  );
};

ContentComponet.propTypes = {
  handleSubmit: PropTypes.func.isRequired,      //Func. donde se haran las acciones del submit de user.
  goToProfile: PropTypes.func.isRequired,       //Func. funcion para regresar al user-profile
  goToLogin: PropTypes.func.isRequired,       //Func. funcion para regresar al login
  token: PropTypes.string,                      //Variable donde se guardan el token, el token solo es utilizado cuando se hace un reset-password (forgot)
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  submitting: PropTypes.bool.isRequired,        //Flag dada por redux-form para decirnos si se esta ejecutando el submit
  pristine: PropTypes.bool.isRequired,          //Flag dada por redux-form para si el form tiene los mismos valores que al inicio
  invalid: PropTypes.bool.isRequired,           //Flag dada por redux-form para si el form tiene algun input invalido.
  error: PropTypes.bool,                        //Variable dada por redux-form para decirnos si se regreso un error general a traves del submit del form
};


export default reduxForm({
  form:'ContentComponet',
  enableReinitialize: true
})(ContentComponet);