import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';

import { reduxForm } from 'redux-form';

import FiltersComponent from '../FiltersComponent/FiltersComponent';
import './styles.css';

const ListContentComponent = (props) => {
  const {results, language, columnsToShow, pageSize, filteredResults, handleChangePageSize } = props;
  let tableRef = useRef();

  // const [resultsCount, setResultsCount] = useState(props.resultsCount);
  // useEffect(() => {
  //   console.log("resultsCount cambio", resultsCount);
  //   setResultsCount(() => props.resultsCount);
  //   window.scrollTo(0, 0);
  // }, [props.resultsCount]);

  useEffect(() => {
    if(tableRef && tableRef.current)
      tableRef.current.onQueryChange();
  }, [props.results]);

  return (
    <div id="contentResults">
      <Grid container style={{ paddingTop: "20px" }}>
        <FiltersComponent {...props} />
        {results.length > 0 ? <Grid id="boxTableResults" elevation={0} item xs={12}>
          <MaterialTable
            tableRef={tableRef}
            id="tableResults"
            title=''
            columns={[
              { title: language.results.col_sickness, field: 'disease[0].name', hidden: !columnsToShow.sickness, tableData: undefined},
              { title: language.results.col_qrcode, field: 'qrCode', tableData: undefined},
              { title: language.results.col_date, field: 'createdAt', hidden: !columnsToShow.date, tableData: undefined},
              { title: language.results.col_result, field: 'disease[0].result', tableData: undefined},
              { title: language.results.col_gender, field: 'gender', hidden: !columnsToShow.gender, tableData: undefined},
              { title: language.results.col_age, field: 'age', hidden: !columnsToShow.age, tableData: undefined},
              {title: language.results.col_region, field:'region', hidden: !columnsToShow.region, emptyValue: "-", tableData: undefined},
              {title: language.results.col_userId, field:'userId', hidden: !columnsToShow.userId, emptyValue: "-", tableData: undefined}
            ]}
            //data={results}
            data={query => {
              return new Promise((resolve, reject) => {
                const { page, pageSize } = query;
                let dataToShow = filteredResults.slice(page*pageSize, (page*pageSize)+pageSize);
                // console.log("termino de cortar", filteredResults.length);
                resolve({
                    data: dataToShow,
                    page,
                    pageSize,
                    totalCount: filteredResults.length
                });
            })
            }}
            onChangeRowsPerPage={() => {
              handleChangePageSize();
            }}
            options={{
              actionsColumnIndex: 1,
              pageSize,
              selection: false,
              search: false,
              toolbar: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                fontSize: "1rem",
                fontFamily: "Sarabun",
                textTransform: "uppercase",
                fontWeight: "bold",
                color: "#828282",
                lineHeight: "21px"
              },
              rowStyle: {
                fontSize: "1rem",
                lineHeight: "21px",
                fontFamily: "Sarabun",
                color: "#3F4443",
              }
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} ' + language.table.of + ` {count}`,
                labelRowsSelect: language.table.rowSelect
              },
              toolbar: {
                searchTooltip: language.table.search,
                exportTitle: language.table.exportTitle,
                searchPlaceholder: language.table.search,
                nRowsSelected: '{0} ' + language.table.rowsSelected
              },
              body: {
                emptyDataSourceMessage: language.table.emptyData,
              }
            }}
          />
        </Grid>
          :
          <Grid container className="emptySearchBody">
            <h5>{language.results.empty_search_title}</h5>
            <p>{language.results.empty_search_text}</p>
          </Grid>
        }
      </Grid>
    </div>
  );
};

ListContentComponent.propTypes = {
  results: PropTypes.array.isRequired,          //Respuesta al fetchResults con los datos del backEnd
  resultsCount: PropTypes.number.isRequired,      //Total de resultados de la respuesta al fetchResults con los datos del backEnd
  page: PropTypes.number.isRequired,              //Página actual
  pageSize: PropTypes.number.isRequired,          //Filas que tendra la página
  handleChangePageSize: PropTypes.func.isRequired,    //Func que manejara los cambios de tamaño de pagina
  filteredResults: PropTypes.array.isRequired,    //Respuesta al fetchResults con los datos del backEnd
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  columnsToShow: PropTypes.shape({              //Objeto con los datos de columnnas a mostrar dentro de la tabla de resultados (usado en la view list)
    sickness: PropTypes.bool.isRequired,        //Dato para la columna "sickness", al estar en true se muestra
    date: PropTypes.bool.isRequired,            //Dato para la columna "result", al estar en true se muestra
    gender: PropTypes.bool.isRequired,          //Dato para la columna "gender", al estar en true se muestra
    age: PropTypes.bool.isRequired,             //Dato para la columna "age", al estar en true se muestra
    region: PropTypes.bool.isRequired,          //Dato para la columna "region", al estar en true se muestra
    userId: PropTypes.bool.isRequired           //Dato para la columna "userId", al estar en true se muestra
  }),
  handleFilter: PropTypes.func.isRequired,      //Func. para el evento de cambio en los filtros
  showBlockFilters: PropTypes.shape({             //Objeto con los datos de columnnas a mostrar dentro de la tabla de resultados (usado en la view list)
    filters: PropTypes.bool.isRequired,           //Dato para mostrar el bloque de filtros, al estar en true se muestra
    search: PropTypes.bool.isRequired,            //Dato para mostrar el bloque de filtros, mostrando el search, al estar en true se muestra
  }),
  columnsResults: PropTypes.shape({               //Objeto con los datos de columnas para el filtrado de resultado
    indeterminate: PropTypes.bool.isRequired,     //Dato para la columna "indeterminado", al estar en true se filtran los datos
    positive: PropTypes.bool.isRequired,          //Dato para la columna "positive", al estar en true se filtran los datos
    negative: PropTypes.bool.isRequired,          //Dato para la columna "negative", al estar en true se filtran los datos
  }),
  handleToggleColumns: PropTypes.func.isRequired,  //Función que nos permite mostrar/ocultar la columna que se le mande como parametro
  handleToggleResultColumns: PropTypes.func.isRequired,     //Func. para llamar al evento de seleccionar una columna para el filtrado de resultado
  isFilterActive: PropTypes.bool.isRequired,      //Dato que nos dice si hay algun filtro activo, se usara para activar o descativar el boton de "limpiar filtros"
  optionsFilterRegion: PropTypes.array.isRequired, //Array con las opciones que estaran en el filtro de "Region"
  filters: PropTypes.object.isRequired,          //Objeto con los filtros que se han aplicado
};

export default reduxForm({
  form: 'ResultListContentComponent',
  enableReinitialize: true
})(ListContentComponent);

//export default ListContentComponent;