import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import ModalContainer from 'containers/general/ModalContainer/ModalContainer';

import HeadResultsComponent from './HeadResultsComponent';
import ListContentComponent from './ListContentComponent/ListContentComponent.js';
import CardContentComponent from './CardContentComponent.js';
import IndividualContentComponent from './IndividualContentComponent.js';
import ShareQr from '../general/Modals/shareQr/shareQr.js';
import {
  //VIEW_LIST, 
  VIEW_CARD, 
  VIEW_INDIVIDUAL} from '../../constants';
import useStyles from "../../style";
import './styles.css';
// import { PhotoFilterRounded } from '@material-ui/icons';

/**
 * Método para la renderizacion de la view de resultados, segun el parametro se decidira que tipo de contendio se mostrará
 * 
 * @param {Object} props - String que nos dice que view es la que esta seleccionada
 * @param {string} props.typeView - String que nos dice que tipo de contenido renderizaremos (VIEW_CARD | VIEW_INDIVIDUAL | VIEW_LIST)
 * @param {array}  props.language - array con todas las traducciones del lenguaje seleccionado
 * @param {array} props.results - Variable obtenida del backend con todos los datos del backend sobre los resultados de las pruebas
 *    
 * @returns {array} JSX a renderizar
*/
const renderContentComponent = (props) => {
  // const {typeView, language, filteredResults, handleFilter, handleToggleColumns, handleToggleResultColumns,
  //   columnsToShow, handleToggleFilters, cleanFilters, columnsResults, isFilterActive, handleDownloadSection,
  //   user, optionsFilterRegion, filters, page, pageSize, handleChangePage} = props;

  const {typeView, filteredResults } = props;
  var ContentComponent = null;

  if(typeView === VIEW_CARD){
    ContentComponent = CardContentComponent;
  }else if(typeView === VIEW_INDIVIDUAL){
    ContentComponent = IndividualContentComponent;
  }else{   //typeView will be taken as VIEW_LIST
    ContentComponent = ListContentComponent;
  }

  // return (<ContentComponent 
  //   id="ResultsContentComponent"
  //   style={{height: "calc(100% - 65px)"}}
  //   language={language}
  //   handleFilter={handleFilter}
  //   cleanFilters={cleanFilters}
  //   columnsToShow={columnsToShow}
  //   columnsResults={columnsResults}
  //   handleToggleResultColumns={handleToggleResultColumns}
  //   handleToggleColumns={handleToggleColumns}
  //   handleToggleFilters={handleToggleFilters}
  //   isFilterActive={isFilterActive}
  //   typeView={typeView}
  //   user={user}
  //   page={page}
  //   pageSize={pageSize}
  //   handleChangePage={handleChangePage}
  //   filters={filters}
  //   optionsFilterRegion={optionsFilterRegion}
  //   handleDownloadSection={handleDownloadSection}
  //   results={filteredResults}/> );

    return (<ContentComponent 
      id="ResultsContentComponent"
      style={{height: "calc(100% - 65px)"}}
      results={filteredResults}
      {...props}/> );
}

const ResultsComponent = (props) => {
  const { headInfo, language, typeView, handleOpenModal, modalData, openModal,
    handleChangeView, handleToggleColumns, handleToggleFilters, resultsCount} = props;

  const classes = useStyles();

  const [columnsToShow, setColumnsToShow] = useState(props.columnsToShow);
  useEffect(() => {
    setColumnsToShow(props.columnsToShow);
  }, [props.columnsToShow]);

  // const [resultsCount, setResultsCount] = useState(props.resultsCount);
  // useEffect(() => {
  //   console.log("resultsCount cambio", resultsCount);
  //   setResultsCount(props.resultsCount);
  // }, [props.resultsCount]);

  // console.log("resultsCount cambio", resultsCount);
  
  return resultsCount > 0 ? 
      <Typography component={'div'} style={{height: "100%"}} className="bodyResults">
        <HeadResultsComponent 
          headInfo={headInfo}
          language={language}
          typeView={typeView}
          columnsToShow={columnsToShow}
          handleChangeView={handleChangeView}
          handleToggleColumns={handleToggleColumns}
          handleToggleFilters={handleToggleFilters}
        />
        {renderContentComponent(props)}
      </Typography>
    :
    <>
      <Grid container className="emptyBody">
          <h5>{language.dashboard.empty_title}</h5>
          <p>{language.dashboard.empty_text}</p>
          <Button id="ShareLink" type="submit" className="btn"  onClick={() => {
                handleOpenModal(ShareQr);
              }}>
              {language.dashboard.share_link}
          </Button>
      </Grid>
      <ModalContainer id="modalContainer" classes={classes}
        handleOpenModal={handleOpenModal}
        modalData={modalData}
        openContainer={openModal}/>
    </>
};

ResultsComponent.propTypes = {
  headInfo: PropTypes.shape({                     //Objeto con la información del header
    title: PropTypes.string.isRequired,           //Titulo que se mostrará en header
    subtitle: PropTypes.string.isRequired         //Subtitulo que se mostrará en header
  }),
  resultsCount: PropTypes.number.isRequired,      //Total de resultados de la respuesta al fetchResults con los datos del backEnd
  page: PropTypes.number.isRequired,              //Página actual
  pageSize: PropTypes.number.isRequired,          //Filas que tendra la página
  handleChangePageSize: PropTypes.func.isRequired,    //Func que manejara los cambios de tamño de pagina
  filteredResults: PropTypes.array.isRequired,    //Respuesta al fetchResults con los datos del backEnd
  typeView: PropTypes.string.isRequired,          //Nos dice que tipo de vista se va a mostrar. (VIEW_LIST | VIEW_CARD | VIEW_INDIVIDUAL)
  handleChangeView: PropTypes.func.isRequired,    //Func. para el evento del boton descargar
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  handleFilter: PropTypes.func.isRequired,        //Func. para el evento de cambio en los filtros
  cleanFilters: PropTypes.func.isRequired,        //Func. para limpiar los filtros utilizados
  columnsToShow: PropTypes.shape({                //Objeto con los datos de columnnas a mostrar dentro de la tabla de resultados (usado en la view list)
    sickness: PropTypes.bool.isRequired,          //Dato para la columna "sickness", al estar en true se muestra
    date: PropTypes.bool.isRequired,              //Dato para la columna "result", al estar en true se muestra
    gender: PropTypes.bool.isRequired,            //Dato para la columna "gender", al estar en true se muestra
    age: PropTypes.bool.isRequired,               //Dato para la columna "age", al estar en true se muestra
    region: PropTypes.bool.isRequired,            //Dato para la columna "region", al estar en true se muestra
    userId: PropTypes.bool.isRequired             //Dato para la columna "userId", al estar en true se muestra
  }),
  columnsResults: PropTypes.shape({               //Objeto con los datos de columnas para el filtrado de resultado
    indeterminate: PropTypes.bool.isRequired,     //Dato para la columna "indeterminado", al estar en true se filtran los datos
    positive: PropTypes.bool.isRequired,          //Dato para la columna "positive", al estar en true se filtran los datos
    negative: PropTypes.bool.isRequired,          //Dato para la columna "negative", al estar en true se filtran los datos
  }),
  handleToggleColumns: PropTypes.func.isRequired,  //Funcion que nos permite mostrar/ocultar la columna que se le mande como parametro
  modalData: PropTypes.shape({                    //Información a para mandar al modalContainer, para generar el contenido
    ModalSelected: PropTypes.elementType,   //Modal Component to render in modal Container
    modalInfo: PropTypes.object        //Objeto con datos a usar en el modal, el objeto es especifico por cada modalComponent por lo que puede ser opcional y diferente entre cada uno
  }),
  handleOpenModal: PropTypes.func.isRequired,     //Func. para llamar al evento de abrir o cerrar el modal
  openModal: PropTypes.bool.isRequired,           //Nos dice si el modal esta abierto.
  handleToggleResultColumns: PropTypes.func.isRequired,     //Func. para llamar al evento de seleccionar una columna para el filtrado de resultado
  isFilterActive: PropTypes.bool.isRequired,      //Dato que nos dice si hay algun filtro activo
  handleDownloadSection: PropTypes.func.isRequired,     //Func. para descargar la vista individual
  user: PropTypes.object.isRequired,          //Variable obtenida del localstorage con los datos del usuario
  optionsFilterRegion: PropTypes.array.isRequired, //Array con las opciones que estaran en el filtro de "Region"
  filters: PropTypes.object.isRequired,          //Objeto con los filtros que se han aplicado
};

ResultsComponent.defaultProps  = {
  loaded_results: false, 
};

export default ResultsComponent;