import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import RestrictedPageComponent from "components/RestrictedPage";
import {setLanguages, sendForgotPassword} from 'actions';
import { getLanguage} from 'selectors';
import { renderSnackBarMessage } from 'components/general/commonRenders';
import { HOME_URL } from 'constants/index';

class RestrictedPageContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
    }
  }

  componentDidMount(){}

  /**
   * Change the current view to login url
  */
  goToLogin = () => {
    this.props.history.push(HOME_URL);
  }

  render() {
    return (
      <div>
        <RestrictedPageComponent 
          onSubmit={this.handleSendEmail}
          language={this.props.language}
          goToLogin={this.goToLogin}
        />
        {renderSnackBarMessage(
            this.state.snackBarInfo.openSnackBar, 
            this.state.snackBarInfo.message, 
            this.state.snackBarInfo.typesnack,
            () => this.setState({snackBarInfo:{openSnackBar: false}}),
            4000)
        }
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  language:  getLanguage(state),
  //auth:      getAuthenthication(state)
});


export default withRouter( connect(mapStateToProps, { 
  setLanguages,
  sendForgotPassword,
  // setAuthentication,
  // login
}) (RestrictedPageContainer) );