import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid} from '@material-ui/core';
import { reduxForm } from 'redux-form';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
//import ScrollBar from "react-perfect-scrollbar";

import FiltersComponent from './FiltersComponent/FiltersComponent';
import { DarkerTooltip } from 'components/general/commonRenders';

const mapStyles = {
    width: '100%',
    height: '100%',
};

var inputTimer = null;

/**
 * Método para la renderizacion de los elementos de la lista en cada card
 * 
 * @param {string} title - Titulo de la columna
 * @param {string} info- Informacion correspondiente al titulo
 * @param {string} key- ID unico para cada elemento
 *    
 * @returns {array} JSX a renderizar
*/
const renderCardListItem = (title, info, key) =>{
    return (<div key={key} className="card-list-item">
          {title && <div className="title">
            <span>{title}</span>
         </div>}
          <div className="info" style={{width: title ? "50%" : "100%"}}>
              <span>{info}</span>
          </div>
      </div>);
}

let IndividualContentComponent = (props) => {
    const { results, language, handleFilter } = props;

    const [resultSelected, setResultSelected] = useState(props.results[0]);
    useEffect(() => {
        setResultSelected(props.results[0] || {});
        //console.log("resultSelected", resultSelected);
    }, [props.results, resultSelected]);

  /**
   * Métodos y variables necesarios para el manejo de la opcion de filtro de search
   */
  const [searchValue, setSearchVallue] = useState('');
  // const cleanSearch = () => {
  //   console.log("cleanSearch");
  //   setSearchVallue('');
  //   handleFilter("search", '');
  // }
  var handleSearchOnChangeFilter = (event) => {
    setSearchVallue(event.target.value);
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      handleFilter("search", searchValue);
    }, 750);
  }

    return (
        <div id="contentResults">
          <Grid container style={{paddingTop:"20px", height: "90%"}}>
            <FiltersComponent {...props} />
            {results.length > 0 ? <>
              <Grid item xs={12} sm={12} md={2} className="blockCardResult">
                <Card elevation={1} className="card cardResult">
                  <PerfectScrollbar style={{minHeight: "100%"}}>
                    <CardHeader className="cardHeader individualHeader"
                      action={<DarkerTooltip title={props.language.results.head.download} placement="bottom">
                          <div>
                            <SearchIcon className="search-icon individualSearch"/>
                            <TextField id="ContentResultSearch" 
                              className="ContentResultSearch" 
                              value={searchValue}
                              onChange={handleSearchOnChangeFilter}/> 
                          </div>
                        </DarkerTooltip>
                      }/>
                    <CardContent className="cardBody cardBodyDates">
                        <List style={{padding: "0px"}}>
                            <div className="card-list-title active">
                                <div className="title">
                                    <span>{language.results.col_qrcode}</span>
                                </div>
                            </div>
                            { results.map((element, index) => {
                                return (<div key={index} 
                                    className={`card-list-item ${resultSelected._id === element._id ? 'active' : ''}`}>
                                    <div className="info" style={{width:"100%"}}
                                        onClick={() => setResultSelected(element)} >
                                        <span>{element.qrCode}</span>
                                    </div>
                                </div>)
                            })
                            }
                        </List>
                    </CardContent>
                  </PerfectScrollbar>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={10} id="printable_section" className="blockCardInfo" style={{width: "100%"}}>
               <Grid container>
                <Grid item xs={12} sm={12} md={5} style={{padding: "0px 15px"}} className="blockCardInfo">
                  <Card className="card cardInfo">
                      <CardHeader className="cardHeader headerResultIndividual"
                          title={language.results.individual.cardInfo_title} />
                      <CardContent className="cardBody">
                          <List style={{padding: "0px"}}>
                          {renderCardListItem(language.results.col_sickness, resultSelected.disease[0].name)}
                          {renderCardListItem(language.results.col_qrcode, resultSelected.qrCode)}
                          {renderCardListItem(language.results.col_date, resultSelected.createdAt)}
                          {renderCardListItem(language.results.col_result, resultSelected.disease[0].name)}
                          {renderCardListItem(language.results.col_gender, resultSelected.gender)}
                          {renderCardListItem(language.results.col_age, resultSelected.age)}
                          {/* {renderCardListItem(language.results.col_region, resultSelected.region)} */}
                          </List>
                      </CardContent>
                  </Card>
                </Grid>
                {(resultSelected.location.latitude && resultSelected.location.longitude) && <Grid item xs={12} sm={12} md={7} style={{padding: "10px 10px 20px"}} className="blockCardMap">
                  <div className="MapContainer">
                    <div className="map-title">{language.results.individual.map_title}</div>
                    <Map  google={props.google}
                      zoom={10} style={mapStyles}
                      key={new Date().getTime()}
                      initialCenter={{lat: resultSelected.location.latitude, lng: resultSelected.location.longitude}}
                      zoomControl={false}
                      mapTypeControl={false}
                      scaleControl={false}
                      streetViewControl={false}
                      draggable={false}
                      scrollwheel={false}
                      rotateControl={false}
                      fullscreenControl={false} >
                      {/* <Marker name={'location'}
                        position={{lat: resultSelected.location.latitude, lng: resultSelected.location.longitude}} /> */}
                      <Marker name={'location'}
                        title=""
                        optimized={false}
                        icon={{
                            url: "./../../images/unima-marker.png",
                            //anchor: new props.google.maps.Point(12,12),
                            scaledSize: new props.google.maps.Size(28,32)
                        }}
                        position={{lat: resultSelected.location.latitude, lng: resultSelected.location.longitude}} />
                    </Map>
                  </div>
                </Grid>}
               </Grid>
              </Grid>
              </>
              :
              <Grid container className="emptySearchBody">
                <h5>{language.results.empty_search_title}</h5>
                <p>{language.results.empty_search_text}</p>
              </Grid>
            }
          </Grid>
        </div>
    );
};

IndividualContentComponent.propTypes = {
    results: PropTypes.array.isRequired,            //Respuesta al fetchResults con los datos del backEnd
    language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
    filters: PropTypes.object.isRequired,          //Objeto con los filtros que se han aplicado
};

IndividualContentComponent = reduxForm({
    form:'IndividualContentComponent',
    enableReinitialize: true
})(IndividualContentComponent);

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCF6MkvOrGvo1BbTf63ljAEKPKEVe6uXU4'
})(IndividualContentComponent);

// export default IndividualContentComponent;


  