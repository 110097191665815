import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import MakeAdministrator from '../general/Modals/makeAdministrator/makeAdministrator.js';
import MakeUser from '../general/Modals/makeUser/makeUser.js';
import DeleteUser from '../general/Modals/deleteUser/deleteUser.js';
import { ROLE_ADMIN } from 'config/index.js';

class ListItemComponent extends Component {

  constructor(props) {
    super(props);
    this.labelId = `checkbox-list-secondary-label-${props.user.name}`;
    this.state = {
      anchorEl: null
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  /**
   * Métodos y variables necesarios para el manejo de las opciones de fecha
  */
  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleClose() {
    this.setState({
      anchorEl: null
    })
  };

  showRoleUser() {
    const { user, organization, language } = this.props;
    if (user.email === organization.mainUser) {
      return language.organization.role_mainAdmin;
    } else if (user.role.name === ROLE_ADMIN) {
      return language.organization.role_admin;
    } else {
      return language.organization.role_user;
    }
  }

  render() {
    const { user, handleOpenModal, language, organization, sessionUser } = this.props;

    return (
      <ListItem>
        <ListItemText id={this.labelId}
          primary={`${user.name}`}
          style={{ paddingBottom: "7px", marginBottom: "0px" }} />
        <ListItemSecondaryAction>
          {user.email !== organization.mainUser ?
            user.email === sessionUser.email ?
              <Button disableRipple className={"select-role user noEditable"}
                elevation={0} aria-haspopup="true">
                {this.showRoleUser()}
              </Button>
              :
              <Button className={"select-role user" + (user.role.name === ROLE_ADMIN ? 'admin' : 'user')}
                elevation={0} aria-haspopup="true"
                onClick={this.handleClick}>
                {this.showRoleUser()}
                <ArrowDropDownIcon />
              </Button>
            :
            <Button disableRipple className={"select-role admin noEditable"}
              elevation={0} aria-haspopup="true">
              {this.showRoleUser()}
            </Button>
          }
          <Menu id="MenuUser" keepMounted
            elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            anchorEl={this.state.anchorEl}
            open={!!this.state.anchorEl}
            onClose={this.handleClose}>
            {user.role.name === ROLE_ADMIN && <MenuItem className="itemRole"
              onClick={() => {
                this.handleClose();
                handleOpenModal(MakeUser, user);
              }}>
              <span>{language.organization.make_user}</span>
            </MenuItem>
            }
            {user.role.name === "user" && <MenuItem className="itemRole"
              onClick={() => {
                this.handleClose();
                handleOpenModal(MakeAdministrator, user);
              }}>
              <span>{language.organization.make_admin}</span>
            </MenuItem>
            }
            {/* {user.role.name === "user" && 
                        <MenuItem className="itemRole" 
                        onClick={() => {
                            this.handleClose();
                            handleOpenModal(DeleteUser, user);
                        }}>
                            <span>{language.organization.delete_user}</span>
                        </MenuItem>
                    } */}
            <MenuItem className="itemRole"
              onClick={() => {
                this.handleClose();
                handleOpenModal(DeleteUser, user);
              }}>
              <span>{language.organization.delete_user}</span>
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
};

ListItemComponent.propTypes = {
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  user: PropTypes.object.isRequired,            //Objeto con los datos de usuario (obtenido de los usuarios de organizacion)
  sessionUser: PropTypes.object.isRequired,     //Objeto con los datos de usuario que inicio sesion
  handleOpenModal: PropTypes.func.isRequired,   //Func. para llamar al evento de abrir o cerrar el modal
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {})(ListItemComponent);