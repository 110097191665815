import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Select from '@material-ui/core/Select';
import { Field, reduxForm, reset } from 'redux-form';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import useStyles from "../../style";
import { renderTextField } from '../general/Form';
import {
  isRequired as _isRequired,
  isEmail as _isEmail
} from '../../helpers/validations';
import { withStyles } from "@material-ui/core/styles";

import ListItemComponent from './ListItemComponent';
import { DarkerTooltip } from 'components/general/commonRenders';
import { stringTrim } from 'helpers/transforms/formTransforms';
import { EDIT_ORGANIZATION_URL } from 'constants/index';

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    color: "#D9514E",
    '&$checked': {
      transform: 'translateX(16px)',
      color: "#D9514E",
      '& + $track': {
        backgroundColor: '#F9E5E4',
        opacity: 1,
        border: '1px solid #D9514E',
      },
    },
    '&$focusVisible $thumb': {
      color: '#D9514E',
      border: '1px solid #D9514E',
    },
  },
  thumb: {
    width: 20,
    height: 20,
    marginTop: '2px'
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

var isRequired = _isRequired('');
var isEmail = _isEmail('');

const ContentComponent = (props) => {
  const classes = useStyles();
  const { handleSubmit, handleOpenModal, goToView, submitting, pristine,
    invalid, organization, listUsers, user, invitedUserIsAdmin, handleChangeIsAdmin } = props;

  var NewUsers = [...listUsers];

  const [language, setLanguage] = useState(props.language);
  useEffect(() => {
    setLanguage(props.language);
    isRequired = _isRequired(props.language.errors.required);
    isEmail = _isEmail(props.language.errors.email);
  }, [props.language]);

  return (
    <div id="contentOrganization">
      <Grid container>
        <Grid item xs={12} sm={12} md={6} style={{ padding: "0px 30px 0px 0px " }}>
          <Card className="cardInviteUser">
            <CardHeader className="cardHeader"
              title={language.organization.invite_user_title} />
            <CardContent style={{ padding: "0px", textAlign: "center" }}>
              <Grid container>
                <form onSubmit={handleSubmit} style={{ textAlign: "left", width: "100%" }}>
                  <h4 className="text">{language.organization.invite_user_text}</h4>
                  <Field
                    id="inviteUser" name="inviteUser" type="email"
                    label={language.organization.label_email}
                    placeholder={language.organization.input_email}
                    validate={[isRequired, isEmail]}
                    normalize={stringTrim}
                    component={renderTextField}
                  />
                  <FormControlLabel className="switchAdmin"
                    control={<CustomSwitch checked={invitedUserIsAdmin} onChange={() => { handleChangeIsAdmin() }} name="isAdmin" />}
                    label={language.organization.switch_text}
                  />
                  <p style={{ display: "inline-block" }} data-tip={`<p>${language.organization.switch_note1}</p>`}
                    data-text-color="#3F4443"
                    data-background-color="white"
                    data-class="tooltipAdmin"
                    data-html={true}>
                    <InfoOutlinedIcon style={{
                      color: "#D9514E",
                      fontSize: "0.875rem",
                      margin: "5px 0px 0px 0px"
                    }}>
                    </InfoOutlinedIcon>
                  </p>
                  <ReactTooltip />
                  <Button id="InviteButton" type="submit"
                    className="btn"
                    disabled={submitting || pristine || invalid}>
                    {language.organization.invite_user_button}
                  </Button>
                </form>
              </Grid>
            </CardContent>
          </Card>
          <Card className="card">
            <CardHeader className="cardHeader headerInfo"
              action={user.email === organization.mainUser && <DarkerTooltip title={props.language.organization.tooltip_edit} placement="bottom">
                <IconButton className="iconRound btnDanger"
                  aria-label={'Edit user data'}
                  onClick={() => { goToView(EDIT_ORGANIZATION_URL) }}>
                  <EditOutlinedIcon />
                </IconButton>
              </DarkerTooltip>
              }
              title={language.organization.cardProfile_title} />
            <CardContent className="cardBody ProfileCardContent">
              <Grid item xs={12}>
                <span className="title">{language.organization.card_organization}</span>
                <span className="info">{organization.name || '-'}</span>
              </Grid>
              <Grid item xs={12}>
                <span className="title">{language.organization.card_phone}</span>
                <span className="info">{formatPhoneNumberIntl(organization.phone) || '-'}</span>
              </Grid>
              {!organization.email && organization.mainUser === user.email ?
                <Button id="addMailButton" type="button"
                  onClick={() => { goToView(EDIT_ORGANIZATION_URL) }}
                  className="btn">
                  {language.organization.add_mail_button}
                </Button>
                :
                <Grid item xs={12}>
                  <span className="title">{language.organization.card_email}</span>
                  <span className="info">{organization.email || '-'}</span>
                </Grid>

              }
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} style={{ padding: "0px 20px 0px 30px" }}>
          <Card className="card cardOrganizationUsers">
            <CardHeader className="cardHeader"
              title={language.organization.cardList_title} />
            <CardContent className="cardBody CardContent">
              <List className={classes.root}>
                <ListItem key="headers" id="headers">
                  <ListItemText className="title" primary={language.organization.cardList_Subtitle} />
                  <ListItemSecondaryAction>
                    <span className="title">{language.organization.cardList_Subtitle2}</span>
                  </ListItemSecondaryAction>
                </ListItem>
                {NewUsers.map((element, index) => {
                  return (<ListItemComponent
                    key={index}
                    language={language}
                    user={element}
                    sessionUser={user}
                    organization={organization}
                    handleOpenModal={handleOpenModal}>
                  </ListItemComponent>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid >
    </div>
  );
};

ContentComponent.propTypes = {
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  handleSubmit: PropTypes.func.isRequired,  //Func. para el evento del boton invitar usuario
  handleOpenModal: PropTypes.func.isRequired,   //Func. para llamar al evento de abrir o cerrar el modal
  submitting: PropTypes.bool.isRequired,        //flag dada por redux-form para decirnos si se esta ejecutando el submit
  pristine: PropTypes.bool.isRequired,          //flag dada por redux-form para si el form tiene los mismos valores que al inicio
  invalid: PropTypes.bool.isRequired,           //flag dada por redux-form para si el form tiene algun input invalido.
  error: PropTypes.bool,                        //variable dada por redux-form para decirnos si se regreso un error general a traves del submit del form
  goToView: PropTypes.func.isRequired,          //Func. que te lleva a la url pasada como parametro.
  organization: PropTypes.object.isRequired,    //Variable obtenida del backend con los datos del organización
  listUsers: PropTypes.array.isRequired,          //Array con los usuarios que tienen acceso a esta organizacion obtenida de backEnd
  donwloadQR: PropTypes.func.isRequired,          //Func. para descargar la imagen qr del usuario.
  invitedUserIsAdmin: PropTypes.bool.isRequired,  //Campo que nos dice que el usuario al que se va a invitar es de tipo admin
  handleChangeIsAdmin: PropTypes.func.isRequired,  //Funcion para actualizar el swicth de invistar usuario usando la variable "invitedUserIsAdmin"
};

const mapStateToProps = (state) => ({
  //initialValues:  getCurrentOrganization(state)
});

const afterSubmit = (result, dispatch) => {
  dispatch(reset('ContentComponent'));
}


let InitializeFromStateForm = reduxForm({
  form: 'ContentComponent',
  enableReinitialize: true,
  onSubmitSuccess: afterSubmit,
})(ContentComponent);

export default connect(mapStateToProps, {})(InitializeFromStateForm);