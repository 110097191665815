import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';


import ContentComponent from './ContentComponent.js';
import {renderLoader} from '../general/commonRenders';
import HeadUserComponent from './HeadUserComponent.js';
import './styles.css';

const UserProfile = (props) => {
  const { loaded_profile, goToView, user, printQr, handleChangeLanguage} = props;

  const [language, setLanguage] = useState(props.language);
    useEffect(() => {
      // console.log("entro a cmabio de lenguaje");
      setLanguage(props.language);
    }, [props.language]);

  return (
      <Typography component={'div'}>
        <HeadUserComponent
          language={language}
          handleChangeLanguage={handleChangeLanguage}
        />
        {loaded_profile ? 
            <ContentComponent language={language}
              printQr={printQr}
              goToView={goToView} user={user}/> 
            : renderLoader(language.profile.loader)
        }
      </Typography>
  );
};

UserProfile.propTypes = {
  headInfo: PropTypes.shape({                     //Objeto con la información del header
    title: PropTypes.string.isRequired,           //Titulo que se mostrará en header
    subtitle: PropTypes.string.isRequired         //Subtitulo que se mostrará en header
  }),
  loaded_profile: PropTypes.bool.isRequired,      //Nos dice si ya se cargaron los datos del perfil de usuario
  language: PropTypes.object.isRequired,          //Variable donde se guardan las traducciones del lenguaje
  goToView: PropTypes.func.isRequired,            //Func. que te lleva a la url pasada como parametro.
  user: PropTypes.object.isRequired,              //Variable obtenida del backend con los datos del usuario
  printQr: PropTypes.func.isRequired,             //Func. para imprimir la imagen qr del usuario.
  handleChangeLanguage: PropTypes.func.isRequired,     //Func. para cambiar el lenguaje
};

export default UserProfile;