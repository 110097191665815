import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from 'prop-types';

import {setLanguages} from 'actions';
import FooterDashboardComponent from 'components/general/Footer/layoutWithAuth';
import MenuContainer from '../../general/MenuContainer';
import DashboardContainer from './DashboardContainer';
import ProfileContainer from './ProfileContainer';
import OrganizationContainer from './OrganizationContainer';
import ResultsContainer from './ResultsContainer';
import LinkContainer from './LinkContainer';
import DeviceContainer from './DeviceContainer';
import { getLanguage, getAuthenthication, getResultViewSelected } from 'selectors';
import permission from 'config';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import './styles.css';
import { renderSnackBarMessage } from 'components/general/commonRenders';
import { DASHBOARD_URL, DEVICE_MANAGMENT_URL, LINK_URL, ORGANIZATION_PROFILE_URL, RESULTS_URL, USER_PROFILE_URL } from 'constants/index';

class LayoutWithAuthContainer extends Component {
  constructor(props){
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
    }

    this.validateEndpointPermission = this.validateEndpointPermission.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount(){
    this.validateEndpointPermission();
  }

  /**
   * Método llamado para renderizar el body de la app, aqui es donde se decide cual view se va a mostrar
   * 
  */
  renderBody = (scrollToTop) =>{
    return (<>
      <Route path={DASHBOARD_URL} component={DashboardContainer} exact />
      <Route path={RESULTS_URL}  component={() => <ResultsContainer scrollToTop={scrollToTop} />} />
      <Route path={LINK_URL} component={LinkContainer} />
      <Route path={DEVICE_MANAGMENT_URL} component={DeviceContainer} />
      <Route path={USER_PROFILE_URL} component={ProfileContainer} />
      <Route path={ORGANIZATION_PROFILE_URL} component={OrganizationContainer} />
    </>)
  }

  /**
   * Método para quitar las secciones a la que el usaurio no tiene permiso
   * 
  */
  validateEndpointPermission(){
    var endpoint=this.props.location.pathname;
    var { language, auth: {user} } = this.props;
    permission.menu.map( menu => {
      if(menu.url === endpoint && !menu.hasPermission.includes(user.role.name)) {
        // console.log("should redirect to dashboard");
        this.setState({
          snackBarInfo:{
            message: language.errors.missingPermission,
            typesnack: 'warning',
            openSnackBar: true
          }
        });
        this.props.history.push(DASHBOARD_URL);
      }
      return null;
    });
  }

  scrollToTop(){
    this.containerRef.scrollTop = 230;
    // this.containerRef.updateScroll = 0;
  }

  render() {
    const { language, auth: {user}, location: {pathname}, view: {typeSelected} } = this.props;

    return (<ScrollBar component="div">
      <div className="layoutAuthBox">
        <CssBaseline />
        <MenuContainer user_role={user.role.name}/>
        <div className="boxMain">
          <ScrollBar containerRef={(ref) => {this.containerRef = ref}} component="div">
            <>
              <main className={`main ${(pathname === '/results' && typeSelected === 'individual') ? 'mainResulstIndividual' : ''}`}>
                {this.renderBody(this.scrollToTop)}
              </main>
              <FooterDashboardComponent language={language} />
            </>
          </ScrollBar> 
        </div>
        {renderSnackBarMessage(
            this.state.snackBarInfo.openSnackBar, 
            this.state.snackBarInfo.message, 
            this.state.snackBarInfo.typesnack,
            () => this.setState({snackBarInfo:{openSnackBar: false}}),
            5000)
        }
      </div>
      </ScrollBar> 
    );
  }
}

LayoutWithAuthContainer.propTypes = {
  setLanguages: PropTypes.func.isRequired,  //Func. obtenida de action para poner el lenguaje en caso de detectar cambios o si no se ha seleccionado
  language: PropTypes.object.isRequired,     //Variable donde se guarda el lenguaje
  auth: PropTypes.object.isRequired,        //Variable donde se guardan la informacion de la session
};

const mapStateToProps = (state, props) => ({
  language:  getLanguage(state),
  auth:     getAuthenthication(state),
  view:  getResultViewSelected(state),
});

export default withRouter( connect(mapStateToProps, { 
  setLanguages,
  // setAuthentication,
  // login
}) (LayoutWithAuthContainer) );