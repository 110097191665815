import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, FormGroup, CircularProgress } from '@material-ui/core';

import { renderPhoneField2, renderTextField } from '../general/Form';
import useStyles from "../../style";
import { stringTrim } from '../../helpers/transforms/formTransforms.js';
import {
  isRequired as _isRequired,
  isEmail as _isEmail,
  isPhoneNumber as _isPhoneNumber,
} from '../../helpers/validations';
import './styles.css';

var isRequired = _isRequired('');
var isEmail = _isEmail('');
var isPhoneNumber = _isPhoneNumber('');

const ContentComponet = (props) => {
  const { handleSubmit, submitting, pristine, invalid, error, goToOrganization, updatingOrg } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  useEffect(() => {
    setLanguage(props.language);
    isRequired = _isRequired(props.language.errors.required);
    isEmail = _isEmail(props.language.errors.email);
    isPhoneNumber = _isPhoneNumber(props.language.errors.notPhone);
  }, [props.language]);

  const changePhoneContact = (value) => {
    props.change("phone", value);
  }

  return (<div className={classes.FormBox}>
    <h3 className={classes.titleRed}>{language.editOrganization.title}</h3>
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Field id="name" name="name" type="text"
          label={language.editOrganization.label_organization}
          placeholder={language.editOrganization.input_organization}
          validate={[isRequired]}
          component={renderTextField} />
      </FormGroup>
      <FormGroup>
        <Field
          id="phone" name="phone" type="text"
          label={language.editOrganization.label_phone}
          placeholder={language.editOrganization.input_phone}
          validate={[isRequired, isPhoneNumber]}
          methodChange={changePhoneContact}
          component={renderPhoneField2} />
      </FormGroup>
      <FormGroup>
        <Field id="email" name="email" type="text"
          label={language.editOrganization.label_email}
          placeholder={language.editOrganization.input_email}
          validate={[isEmail]}
          normalize={stringTrim}
          component={renderTextField} />
      </FormGroup>
      {error && <strong className="formError">{error}</strong>}
      <div style={{ marginTop: "15px" }}>
        {/* <Button id="editPasswrdButton"
          className="btn" type="submit"
          style={{ display: "inline-block", width: "65%", minWidth: "inherit" }}
          disabled={submitting || pristine || invalid}>
          {language.editOrganization.btnAccept}
        </Button>
        <Button id="cancelButton"
          className="btn btnCancel" type="button"
          style={{ width: "30%", minWidth: "inherit", marginLeft: "5%" }}
          onClick={() => { goToOrganization() }}>
          {language.editOrganization.btnCancel}
        </Button> */}

        {updatingOrg ?
          <div className="loaderEditOrg">
            <CircularProgress size={30} />
          </div> :
          <Button id="editPasswrdButton"
            className="btn" type="submit"
            style={{ display: "inline-block", width: "65%", minWidth: "inherit" }}
            disabled={submitting || pristine || invalid}>
            {language.editOrganization.btnAccept}
          </Button>}
        {!updatingOrg && <Button id="cancelButton"
          className="btn btnCancel" type="button"
          style={{ width: "30%", minWidth: "inherit", marginLeft: "5%" }}
          onClick={() => { goToOrganization() }}>
          {language.editOrganization.btnCancel}
        </Button>}
      </div>
    </form>
  </div>
  );
};

ContentComponet.propTypes = {
  handleSubmit: PropTypes.func.isRequired,      //Func. donde se haran las acciones del submit de user.
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
  goToOrganization: PropTypes.func.isRequired,       //Func. para regresar a la view anterior para el edit
  initialValues: PropTypes.object.isRequired,        //Variable obtenida del backend con los datos del usuario
  submitting: PropTypes.bool.isRequired,        //Flag dada por redux-form para decirnos si se esta ejecutando el submit
  pristine: PropTypes.bool.isRequired,          //Flag dada por redux-form para si el form tiene los mismos valores que al inicio
  invalid: PropTypes.bool.isRequired,           //Flag dada por redux-form para si el form tiene algun input invalido.
  error: PropTypes.bool,                        //Variable dada por redux-form para decirnos si se regreso un error general a traves del submit del form
  updatingOrg: PropTypes.bool.isRequired,        //Valor que nos dice si se esta en la peticion para actualizar organizacion
};


export default reduxForm({
  form: 'ContentComponet',
  enableReinitialize: true
})(ContentComponet);