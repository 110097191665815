import React, { Component } from 'react';
import ForgotPasswordComponent from "../../../components/ForgotPasswordComponent";
import { setLanguages, sendForgotPassword } from '../../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLanguage } from '../../../selectors';
import { renderSnackBarMessage } from '../../../components/general/commonRenders';
import { HOME_URL } from 'constants/index';

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
    }
  }

  componentDidMount() { }

  /**
   * Method what is called on submit of login, this function call to backEnd and go to dashboard
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * @param {string} values.email - string with user's email
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  handleForgotPassword = (values) => {
    // console.log("handleForgotPassword", values);

    return this.props.sendForgotPassword(values).then(response => {
      this.successForgotPassword(response);
    }).catch((error) => {
      this.errorForgotPassword(error);
    });
  }

  successForgotPassword = (response) => {
    // console.log("successForgotPassword", response);
    this.setState({
      snackBarInfo: {
        message: this.props.language.forgot.success_forgot_password,
        typesnack: 'success',
        openSnackBar: true
      }
    });

    setTimeout(() => {
      this.goToLogin();
    }, 1000);
  }

  errorForgotPassword = (error) => {
    // console.log("successForgotPassword", error);
    var message = this.props.language.forgot.error_forgot_password;
    this.setState({
      snackBarInfo: {
        message,
        openSnackBar: true,
        typesnack: 'error'
      }
    });
  }

  /**
   * Change the current view to login url
  */
  goToLogin = () => {
    this.props.history.push(HOME_URL);
  }

  render() {
    return (
      <div>
        <ForgotPasswordComponent
          onSubmit={this.handleForgotPassword}
          language={this.props.language}
          goToLogin={this.goToLogin}
        />
        {renderSnackBarMessage(
          this.state.snackBarInfo.openSnackBar,
          this.state.snackBarInfo.message,
          this.state.snackBarInfo.typesnack,
          () => this.setState({ snackBarInfo: { openSnackBar: false } }),
          4000)
        }
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  language: getLanguage(state),
  //auth:      getAuthenthication(state)
});


export default withRouter(connect(mapStateToProps, {
  setLanguages,
  sendForgotPassword,
  // setAuthentication,
  // login
})(ForgotPasswordContainer));