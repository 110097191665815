import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, IconButton } from '@material-ui/core';
import {
    Modal, 
    ModalHeader, 
    ModalBody } from 'reactstrap';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import { SiMicrosoftoutlook, SiGmail, SiWhatsapp } from "react-icons/si";
import { 
    //Field, 
    reduxForm } from 'redux-form';
import SVG from 'react-inlinesvg';
import { DarkerTooltip } from '../../commonRenders';
import './styles.css';

class ShareQr extends Component{

    constructor(props){
        super(props);
        if(props.modalData && props.modalData.modalInfo && props.modalData.modalInfo.user){
            const {user } = props.modalData.modalInfo;

            let shareCode = user.company.assignCode ? user.company.assignCode.code : '-';
            this.textWhatsapp = props.language.shareText.whatsappText.replace('<LINKCODE>', shareCode);
            this.textWhatsapp = this.textWhatsapp.replace('<ORGANIZATION>', user.company.name);
            this.textEmail = props.language.shareText.mailBody.replace('<LINKCODE>', shareCode);
            this.textEmail = this.textEmail.replace('<ORGANIZATION>', user.company.name);
            this.ShareSubjectText = props.language.shareText.subject.replace('<USER>', user.name);
            this.gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&su=${this.ShareSubjectText}&body=${this.textEmail}`;
            this.outlookUrl = `https://outlook.live.com/mail/deeplink/compose?subject=${this.ShareSubjectText}&body=${this.textEmail}`;
        }
        this.state = {
            shareAnchorEl: null,
            open: false,
            modal: false,
        }

        this.openShareMenu = this.openShareMenu.bind(this);
        this.closeShareMenu = this.closeShareMenu.bind(this);
    }

    /*
     * Variables y metodos para manejar el menu share 
     */
    openShareMenu(event){
        this.setState({
            shareAnchorEl: event.currentTarget,
            open: Boolean(event.currentTarget)
        });
    }

    closeShareMenu(){
        this.setState({
            shareAnchorEl: null,
            open: Boolean(null)
        });
    }

    toggle = () => {
        this.props.handleOpenModal();
    }

    /**
     * Copy text of company's code on clipboard
     * 
     * @param {string} text - variable with company's code
     */
    copyToClipboard = (text) => {
        var copyText = document.createElement("textarea");

        document.body.appendChild(copyText);
        copyText.value = text;
        copyText.select();
        document.execCommand("copy");
        document.body.removeChild(copyText);
    }

    render(){
        const { openModal, modalData, language,
            handleOpenModal  } = this.props;
            const { modalInfo: {user, donwloadQR}} = modalData || {};
        return (
            <Modal 
              id="modalShareQr" 
              isOpen={openModal} 
              toggle={() => handleOpenModal()} 
              size="lg" style={{ height: "auto" }}>
              <ModalHeader toggle={this.toggle}>{language.modals.shareQr.header_title}</ModalHeader>
              <ModalBody>
                <Grid
                  container 
                  direction="row"
                  alignItems="center"
                  justify="center"
                  style={{ margin: "auto"}} >
                    <Grid item xs={10}>
                      <span id="cardOrganizationQR">
                        <div style={{padding: "0px", textAlign: "center"}}>
                            <Grid item xs={12} className="boxInfo">
                                <span className="info">{user.company.name || '-'}</span>
                            </Grid>
                            <Grid container id="cardQrBody">
                            <Grid id="cardHalfCode" item xs={12} md={6} style={{alignSelf: "flex-start"}}>
                                <h5 className="titleCode">{language.link.share_option_1}</h5>
                                <h5 className="codeBox">{user.company.assignCode ? user.company.assignCode.code : '-'}</h5>
                                <div className="qrButtonContainer">
                                <DarkerTooltip title={language.modals.shareQr.copy}  placement="bottom">
                                    <IconButton aria-label="download" className="icon"
                                        onClick={() => { 
                                                this.copyToClipboard(user.company.assignCode ? user.company.assignCode.code : '-');
                                        }}>
                                        <Icon className="material-icons-outlined iconMenu">content_copy</Icon>
                                    </IconButton>
                                </DarkerTooltip>
                                <DarkerTooltip title={language.modals.shareQr.share} placement="bottom">
                                    <IconButton aria-label="share" className="icon"
                                        onClick={this.openShareMenu}>
                                        <ShareOutlinedIcon fontSize="large"/>
                                    </IconButton>
                                </DarkerTooltip>
                                <Menu id="shareMenu"
                                    anchorEl={this.state.shareAnchorEl}
                                    keepMounted
                                    open={this.state.open}
                                    onClose={this.closeShareMenu} >
                                    <MenuItem>
                                    <a className="shareLink" 
                                        href={`https://web.whatsapp.com/send?text=${this.textWhatsapp }`} 
                                        target="_blank" 
                                        rel="noopener noreferrer">
                                        <ListItemIcon>
                                            <SiWhatsapp className="whatsapp" fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit">Whatsapp</Typography>
                                    </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <SiGmail className="mail" fontSize="small" />
                                        </ListItemIcon>
                                        <a className="shareLink" 
                                        href={`${this.gmailUrl}`}
                                        target="_blank" 
                                        rel="noopener noreferrer">
                                            <Typography variant="inherit">Gmail</Typography>
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <SiMicrosoftoutlook className="mail" fontSize="small" />
                                        </ListItemIcon>
                                        <a className="shareLink" 
                                        href={`${this.outlookUrl}`}
                                        target="_blank" 
                                        rel="noopener noreferrer">
                                            <Typography variant="inherit">Outlook</Typography>
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <MailIcon className="mail" fontSize="small" />
                                        </ListItemIcon>
                                        <a className="shareLink" 
                                        href={`mailto:?subject=${this.ShareSubjectText}&body=${this.textEmail}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer">
                                            <Typography variant="inherit">Email</Typography>
                                        </a>
                                    </MenuItem>
                                </Menu>
                                </div>
                            </Grid>
                            <Grid id="cardHalfQR" item xs={12} md={6} style={{textAlign: "center"}}>
                                <h5 className="titleCode" style={{textAlign: "center"}}>{language.link.share_option_2}</h5>
                                <div id="QRImg" className="QRImg">
                                <SVG src={user.company.qrcode} />
                                </div>
                                <div className="qrButtonContainer">
                                    <DarkerTooltip title={language.modals.shareQr.donwload}  placement="bottom">
                                    <IconButton aria-label="download" className="icon"
                                        onClick={() => { 
                                            donwloadQR();
                                            //ReactPDF.render(<MyDocument {...props} />, `example.pdf`);
                                        }}>
                                        <GetAppOutlinedIcon fontSize="large"/>
                                    </IconButton>
                                    </DarkerTooltip>
                                    {/* <DarkerTooltip title={language.modals.shareQr.print} placement="bottom">
                                    <IconButton aria-label="print" className="icon"
                                        onClick={() => {printQrOrganization()}}>
                                        <PrintOutlinedIcon fontSize="large"/>
                                    </IconButton>
                                    </DarkerTooltip> */}
                                    {/* <DarkerTooltip title={language.modals.shareQr.share} placement="bottom">
                                    <IconButton aria-label="share" className="icon"
                                        onClick={openShareMenu}>
                                        <ShareOutlinedIcon fontSize="large"/>
                                    </IconButton>
                                    </DarkerTooltip> */}
                                    <Menu id="shareMenu"
                                    anchorEl={this.shareAnchorEl}
                                    keepMounted
                                    open={this.open}
                                    onClose={this.closeShareMenu} >
                                    <MenuItem>
                                        <a className="shareLink" 
                                        href={`https://web.whatsapp.com/send?text=${this.Sharetext}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer">
                                        {/* <a href="https://api.whatsapp.com/send?text=textToshare" target="_blank"> */}
                                        <ListItemIcon>
                                            <SiWhatsapp className="whatsapp" fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit">Whatsapp</Typography>
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <SiGmail className="mail" fontSize="small" />
                                        </ListItemIcon>
                                        <a className="shareLink" 
                                            href={`${this.gmailUrl}`}
                                            target="_blank" 
                                            rel="noopener noreferrer">
                                            <Typography variant="inherit">Gmail</Typography>
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <SiMicrosoftoutlook className="mail" fontSize="small" />
                                        </ListItemIcon>
                                        <a className="shareLink" 
                                            href={`${this.outlookUrl}`}
                                            target="_blank" 
                                            rel="noopener noreferrer">
                                            <Typography variant="inherit">Outlook</Typography>
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <MailIcon className="mail" fontSize="small" />
                                        </ListItemIcon>
                                        <a className="shareLink" 
                                            href={`mailto:?subject=${this.ShareSubjectText}&body=${this.Sharetext}`} 
                                            target="_blank" 
                                            rel="noopener noreferrer">
                                            <Typography variant="inherit">{language.modals.shareQr.email_title}</Typography>
                                        </a>
                                    </MenuItem>
                                    </Menu>
                                </div>
                            </Grid>
                            </Grid>
                        </div>
                      </span>
                        {/* <Grid id="cardUserQR" item xs={12} >
                            <div className="cardBody">
                                <h5 className="qrTitle">{(user && user.company) ? user.company.name : '-'}</h5>
                                <h4 className="qrCode">
                                    <b>{language.profile.cardQr.code}</b>
                                    <span>{(user && user.company.assignCode) ? user.company.assignCode.code: '-' }</span>
                                </h4>
                                <div id="QRImg" className="QRImg">
                                    <SVG src={user.company.qrcode} />
                                </div>
                                <div className="qrButtonContainer">
                                    <DarkerTooltip title={language.modals.shareQr.donwload} placement="bottom">
                                        <IconButton aria-label="download" className="iconRound"
                                            onClick={() => { donwloadQR() }}>
                                            <GetAppOutlinedIcon fontSize="large"/>
                                        </IconButton>
                                    </DarkerTooltip>
                                    <DarkerTooltip title={language.modals.shareQr.print} placement="bottom">
                                        <IconButton aria-label="download" className="iconRound"
                                            onClick={() => {printQrUser()}}>
                                            <PrintOutlinedIcon fontSize="large"/>
                                        </IconButton>
                                    </DarkerTooltip>
                                    <DarkerTooltip title={language.modals.shareQr.share} placement="bottom">
                                        <IconButton aria-label="download" className="iconRound"
                                            onClick={this.openShareMenu}>
                                            <ShareOutlinedIcon fontSize="large"/>
                                        </IconButton>
                                    </DarkerTooltip>
                                    <Menu id="shareMenu"
                                        anchorEl={this.state.shareAnchorEl}
                                        keepMounted
                                        open={this.state.open}
                                        onClose={this.closeShareMenu} >
                                        <MenuItem>
                                            <a className="shareLink" 
                                                href={`https://web.whatsapp.com/send?text=${this.sharetext}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer">
                                                <ListItemIcon>
                                                    <WhatsAppIcon className="whatsapp" fontSize="small" />
                                                </ListItemIcon>
                                                <Typography variant="inherit">{language.modals.shareQr.whatsapp}</Typography>
                                            </a>
                                        </MenuItem>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <SiGmail className="mail" fontSize="small" />
                                            </ListItemIcon>
                                            <a className="shareLink" 
                                                href={`${this.gmailUrl}`}
                                                target="_blank" 
                                                rel="noopener noreferrer">
                                                <Typography variant="inherit">Gmail</Typography>
                                            </a>
                                            </MenuItem>
                                            <MenuItem>
                                            <ListItemIcon>
                                                <SiMicrosoftoutlook className="mail" fontSize="small" />
                                            </ListItemIcon>
                                            <a className="shareLink" 
                                                href={`${this.outlookUrl}`}
                                                target="_blank" 
                                                rel="noopener noreferrer">
                                                <Typography variant="inherit">Outlook</Typography>
                                            </a>
                                        </MenuItem>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <MailIcon className="mail" fontSize="small" />
                                            </ListItemIcon>
                                            <a className="shareLink" 
                                                href={`mailto:?subject=${this.shareSubjectText}&body=${this.sharetext}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer">
                                                <Typography variant="inherit">{language.modals.shareQr.email}</Typography>
                                            </a>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </Grid> */}
                    </Grid>
                </Grid>
              </ModalBody>
          </Modal>
        );
    }
};

ShareQr.propTypes = {
  handleSubmit: PropTypes.func.isRequired,      //Func. donde se haran las acciones del submit.
  handleOpenModal: PropTypes.func.isRequired,   //Func. para llamar al evento de abrir o cerrar el modal
  handleCancel: PropTypes.func.isRequired,      //Func que será llamada al hacer click en cancelar.
  handleChange: PropTypes.func.isRequired,      //Func que será llamada en el onChange de cada input. 
  openModal: PropTypes.bool.isRequired,         //Nos dice si el modal esta abierto.
  modalData: PropTypes.any.isRequired,
};

ShareQr.defaultProps  = {
    modalData: {
        acceptText: 'Aceptar',  //Texto para el botón submit
        cancelText: 'Cancelar',  //Texto para el botón cancelar
    }
};

export default reduxForm({
    form: 'ModalForm',
    enableReinitialize: true
  }) (ShareQr);