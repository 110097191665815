import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import { Button, CircularProgress } from '@material-ui/core';

import useStyles from "../../style";
import FooterNoAuthComponent from "../general/Footer/layoutNoAuth";
import PerfectScrollbar from 'react-perfect-scrollbar';
import './styles.css';
import { Field, reduxForm } from 'redux-form';
import {
  isRequired as _isRequired,
} from '../../helpers/validations';
import { stringTrim } from '../../helpers/transforms/formTransforms.js';
import { renderTextField } from '../general/Form';

var isRequired = _isRequired('');

const ExpiredLinkComponent = (props) => {
  const { goToLogin, handleSubmit, submitting, pristine, invalid } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  useEffect(() => {
    setLanguage(props.language);
    isRequired = _isRequired(props.language.errors.required);
  }, [props.language]);

  return (
    <div id="contentExpiredLink">
      <Container fluid={true} className={classes.backgroundBody} style={{ padding: "0px" }}>
        <Row noGutters style={{ height: "calc(100% - 70px)", padding: "90px 0px 10px" }}>
          <Col xs={12} sm={{ size: 12, offset: 0 }} md={{ size: 8, offset: 2 }} className={classes.ExpiredLinkContainer}>
            <PerfectScrollbar style={{ minHeight: "100%" }}>
              <Row noGutters>
                <Col xs={12} sm={10} className={classes.ExpiredLinkBox}>
                  {/* <img id="confirmMailLogo" alt="logo" src="./../../images/logoUnima.png" /> */}
                  <h3 className={`expiredLinkTitle ${classes.titleRed}`}>{language.expirationLink.title}</h3>
                  <h4 className={classes.subtitleGrey}>{language.expirationLink.subtitle}</h4>
                  <form onSubmit={handleSubmit} className="blockInfo">
                    <div className="emailBlock">
                      <Field
                        id="email" name="email" type="email"
                        label={language.expirationLink.label_email}
                        placeholder={language.expirationLink.placeholder_email}
                        validate={[isRequired]}
                        normalize={stringTrim}
                        component={renderTextField}
                      />
                    </div>
                    <div className="">
                      {submitting ?
                        <div className="loaderExpiredLink">
                          <CircularProgress size={30} />
                        </div> :
                        <Button id="expiredButton"
                          className="btn" type="submit"
                          style={{ display: "inline-block" }}
                          disabled={submitting || pristine || invalid}>
                          {language.forgot.button}
                        </Button>}
                      {!submitting && <Button id="cancelButton"
                        className="btn btnCancel" type="button"
                        onClick={goToLogin}>
                        {language.forgot.cancelBtn}
                      </Button>}
                    </div>
                  </form>
                </Col>
              </Row>
            </PerfectScrollbar>
          </Col>
        </Row>
        <Row noGutters className={classes.footerContainer}>
          <Col xs={{ size: 12, offset: 0 }} className={classes.footer}>
            <FooterNoAuthComponent language={language}></FooterNoAuthComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ExpiredLinkComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,          //Func. donde se haran las acciones del submit de login.
  goToLogin: PropTypes.func.isRequired,         //Func. que te lleva a la url pasada como parametro.
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
};


export default reduxForm({
  form: 'ExpiredLinkComponent',
  enableReinitialize: true
})(ExpiredLinkComponent);