import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const FooterNoAuthComponent = (props) => {
  const {backgroundColor} = props;
  const [language, setLanguage] = useState(props.language);
  useEffect(() => {
    setLanguage(props.language);
  }, [props.language]);

  return (
      <div style={{backgroundColor: backgroundColor}} className="footerNoAuth">
        <img id="footerLogo" className="footerImg" width={110} alt="logo" src="./../../images/logoUnima-footer.png" />
        <p className="footerParagraph">
          {language.footer.title}
        </p>
      </div>
  );
};

FooterNoAuthComponent.propTypes = {
  language: PropTypes.object.isRequired,      //Variable donde se guardan las traducciones del lenguaje
  backgroundColor: PropTypes.string,          //Variable para ponerle un color de background al footer
};

FooterNoAuthComponent.defaultProps  = {
  backgroundColor: "transparent", 
};

export default FooterNoAuthComponent;