import { isValidPhoneNumber } from 'react-phone-number-input';

/** Methods for use of redux-form validates, 
 * Each method receives the value of input where the validation method is set
 * Each method must return false when validation works or a string when validation fails.
*/

/**
 * Validate if value has a value
 * 
 * @param {string} [value] - The value of input where the validation method is set
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
*/
export const isRequired = errorMessage => {
    return value => {
        return !value && errorMessage
    }
};

/**
 * Validate if value has a value
 * 
 * @param {string} [value] - The value of input where the validation method is set
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
*/
export const isEmail = errorMessage => {
    return value => {
        let regEmail = /^[a-zA-Z0-9._-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
        //let regEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        return value && !regEmail.test(value) && errorMessage
    }
};

/**
 * Validate if password with name 'password' match with the value where is set this validation
 * 
 * @param {string} [value] - The value of input where the validation method is set
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
*/
export const passwordsMatch = (errorMessage) => {
    return (value, allvAlues) => {
        return value !== allvAlues['password'] && errorMessage
    }
};

/**
 * Validate if value is a number
 * 
 * @param {string} [value] - The value of input where the validation method is set
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
 */
export const isNumber = errorMessage => {
    return value => {
        return isNaN(Number(value)) && errorMessage
    }
};

/**
 * Validate if value has the maxLEngth set in parameter
 * 
 * @param {string} errorMessage - Message to show when get a error
 * @param {string} maxLength - max lenth (This will be used to check length of value)
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
 */
export const maxLength = (errorMessage, maxLength) => {
    return value => {
        value = value || '';
        return value.length < maxLength && errorMessage
    }
};

/**
 * Check if value includes a lowercase character
 * 
 * @param {string} errorMessage - Message to show when get a error
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
 */
export const includeLowercase = errorMessage => {
    return value => {
        var lowerCaseLetters = /[a-z]/g;
        return !!value && !value.match(lowerCaseLetters) && errorMessage
    }
}

/**
 * Check if value includes a uppercase character
 * 
 * @param {string} errorMessage - Message to show when get a error
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
 */
export const includeUppercase = errorMessage => {
    return value => {
        var upperCaseLetters = /[A-Z]/g;
        return !!value && !value.match(upperCaseLetters) && errorMessage
    }
}

/**
 * Check if value includes a number character
 * 
 * @param {string} errorMessage - Message to show when get a error
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
 */
export const includeNumber = errorMessage => {
    return value => {
        var numberLetters = /[0-9]/g;
        return !!value && !value.match(numberLetters) && errorMessage
    }
}

/**
 * Check if value includes a number character
 * 
 * @param {string} errorMessage - Message to show when get a error
 * 
 * @returns {false | string} false when validation works or a string with error message when validation fails.
 */
export const isPhoneNumber = errorMessage => {
    return value => {
        return !isValidPhoneNumber(value) && errorMessage
    }
}