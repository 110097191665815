import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MakeAdministrator from 'components/general/Modals/makeAdministrator/makeAdministrator.js';
import MakeUser from 'components/general/Modals/makeUser/makeUser.js';
// import DeleteUser from 'components/general/Modals/deleteUser/deleteUser.js';
// import ShareQr from 'components/general/Modals/shareQr/shareQr.js';
import ConfirmUnlinkDevice from 'components/general/Modals/confirmUnlinkDevice/confirmUnlinkDevice.js';
import { getLanguage } from 'selectors';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import confirmInviteUser from 'components/general/Modals/confirmInviteUser/confirmInviteUser';

class ModalContainer extends Component {

    constructor(props) {
        super(props);
        this.language = this.props.language;
    }

    /**
     * Método que es llamado cuando se hace el submit del form del modal, este metodo tranforma los datos del 
     * modal y los mando al submit individual que es obtenido por props
     * 
     * @param {object} event - Evento obtenido por el click, de aqui se obtendran los valores de los inputs 
     * 
    */
    handleSubmit = (values) => {
        values = this.props.modalData.modalInfo.user_selected;
        console.log("values", values);
        var dataModal = "DeleteUser";
        if (this.props.modalData.ModalSelected === MakeAdministrator) {
            dataModal = "MakeAdministrator";
        } else if (this.props.modalData.ModalSelected === MakeUser) {
            dataModal = "MakeUser";
        } else if (this.props.modalData.ModalSelected === confirmInviteUser) {
            dataModal = "ConfirmInviteUser";
        } else if (this.props.modalData.ModalSelected === ConfirmUnlinkDevice) {
            dataModal = "ConfirmUnlinkDevice";
            values = this.props.modalData.modalInfo.unlinkData;
        }
        console.log("values", values);
        this.props.handleSubmit(dataModal, values);
        this.props.handleOpenModal();
    }

    /**
     * Método que es llamado cuando se hace un onChange en un input.
     * 
     * @param {object} event - Evento obtenido por el onChange, se llamará cuando suceda un cambio
     * @param {object} value - El valor del input despues del cambio
     * 
    */
    handleChange = (event, value) => {
        // console.log("handleChangeInputProducer ", value);
        if (this.props.handleChange) {
            this.props.handleChange(event, value);
        }
    }

    /**
     * Método que es llamado cuando se hace click en la opción  cancelar, cierra el modal
     * 
     * 
    */
    handleCancel = () => {
        if (this.props.handleCancel) {
            this.props.handleCancel();
        }
        this.props.handleOpenModal();
    }

    render() {
        const { classes, openContainer, handleOpenModal, modalData: { ModalSelected, modalInfo } } = this.props;
        return (
            <div>
                {ModalSelected &&
                    <ModalSelected
                        id="ModalComponent"
                        class="ModalComponent"
                        classes={classes}
                        language={this.language}
                        openModal={openContainer}
                        modalData={this.props.modalData}
                        handleOpenModal={handleOpenModal}
                        handleCancel={this.handleCancel}
                        onSubmit={this.handleSubmit}
                        user={modalInfo.user_selected}
                        handleChange={this.handleChange} >
                    </ModalSelected>
                }
            </div>
        );
    }
}

ModalContainer.propTypes = {
    handleOpenModal: PropTypes.func.isRequired, //Func. para llamar al evento de abrir o cerrar el modal
    openContainer: PropTypes.bool.isRequired,   //Nos dice si el modal esta abierto.
    classes: PropTypes.object,                   //A hook. Este campo se usa para poner los estilos en los componentes.
    modalData: PropTypes.shape({                    //Información a para mandar al modalContainer, para generar el contenido
        ModalSelected: PropTypes.elementType,   //Modal Component to render in modal Container
        modalInfo: PropTypes.object        //Objeto con datos a usar en el modal, el objeto es especifico por cada modalComponent por lo que puede ser opcional y diferente entre cada uno
    }),
};

const mapStateToProps = (state) => ({
    language: getLanguage(state),
});

export default withRouter(connect(mapStateToProps, null)(ModalContainer));