import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';

import ContentComponet from './ContentComponent';
import useStyles from "../../style";
import FooterNoAuthComponent from "../general/Footer/layoutNoAuth";
import './styles.css';

const EditOrganizationComponent = (props) => {
  const { handleEditOrganization, goToOrganization, organization, updatingOrg } = props;
  const classes = useStyles();
  const [language] = useState(props.language);

  return (
    <div className="contentEditUser">
      <Container fluid={true} className={classes.backgroundBody} style={{ padding: "0px" }}>
        <Row noGutters style={{ height: "calc(100% - 70px)", padding: "50px 0px 10px" }}>
          <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 7, offset: 2 }} className={classes.FormLayoutContainer}>
            <Row noGutters>
              <ContentComponet onSubmit={handleEditOrganization}
                goToOrganization={goToOrganization}
                updatingOrg={updatingOrg}
                language={language}
                initialValues={organization} />
            </Row>
          </Col>
        </Row>
        <Row noGutters className={classes.footerContainer}>
          <Col xs={{ size: 12, offset: 0 }} className={classes.footer}>
            <FooterNoAuthComponent language={language}></FooterNoAuthComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};


EditOrganizationComponent.propTypes = {
  handleEditOrganization: PropTypes.func.isRequired,   //Func. donde se haran las acciones del submit del form.
  language: PropTypes.object.isRequired,           //Variable donde se guardan las traducciones del lenguaje
  goToOrganization: PropTypes.func.isRequired,     //Func. para regresar a la view correcta
  organization: PropTypes.object.isRequired,       //Objeto con los datos de la organización
  updatingOrg: PropTypes.bool.isRequired,        //Valor que nos dice si se esta en la peticion para actualizar organizacion
};

export default EditOrganizationComponent;