import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import { FormGroup } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';

import useStyles from "../../style";
import { renderSelectMaterialUi } from '../general/Form';
import FooterNoAuthComponent from '../general/Footer/layoutNoAuth';
import FormUserComponent from './FormUser';
import FormOrganizationComponent from './FormOrganization';
import {SIGN_UP_USER} from "./../../constants";
import './styles.css';
import { setLanguageSelected } from '../../helpers/general';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import LanguageIcon from '@material-ui/icons/Language';

const createLanguageOptions = (language) => [
  {value: language.spanish},
  {value: language.english},
];
var language_options = null;

/**
  * Method what is called for render a form used on sign up desing
  * 
  * @param {Object} props - Object of pairs key-value given for redux-form
  * @param {string} props.handleSubmitUser - Func. donde se haran las acciones del submit de tipo user.
  * @param {string} props.handleSubmitOrganization - Func. donde se haran las acciones del submit de tipo organización.
  * @param {string} props.goToLogin - Func. que al hacer clic te llevará a la view de login.
  * @param {string} props.signUpType - string what tell us what form set
  * @param {string} props.language - array with all translations used on the language selected
  * 
  * @returns {JSX} - informacion a renderizar
*/
const renderFormSignUp = (props) => {
  const { handleSubmit, goToLogin, signUpType, language} = props;
  const FormComponent = signUpType === SIGN_UP_USER ? FormUserComponent : FormOrganizationComponent;
  return (<FormComponent
      onSubmit={handleSubmit}
      goToLogin={goToLogin}
      language={language} />);
}

/**
  * Method what is called for render image used on sign up desing
  * 
  * @param {Object} props - Object of pairs key-value given for redux-form
  * @param {string} props.signUpType - string what tell us what image set
  * 
  * @returns {JSX} - informacion a renderizar
*/
const renderImgLeft = (props) => {
  const {signUpType} = props;
  const stringSrc = signUpType === SIGN_UP_USER ? "/../../images/signup-usercover-sq.png" : "/../../images/signup-orgcover-sq.png";
  return (
    <div style={{backgroundImage: "url("+stringSrc+")",width: "100%", backgroundPosition: "left top", backgroundSize: "cover"}}></div>
    // <img id="back" width="100%" height="100%" alt="sign up img" src={stringSrc} />
  );
}

const SignUpComponent = (props) => {
  const { signUpType, handleChangeLanguage } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  language_options = language_options ? language_options : createLanguageOptions(props.language);
  var language_selected = setLanguageSelected(props.language);

  useEffect(() => {
    setLanguage(props.language);
    language_options = createLanguageOptions(props.language);
  }, [props.language]);

  return (
    <div>
      <Container fluid={true} className={classes.SignUpBody} style={{padding: "0px"}}>
        <Row noGutters className="signUp">
          <Col xs={{size:10, offset:2}} sm={{size:4, offset:0}} lg={{size:4}} className="containerImg">
            <Row noGutters className="halfText">
              <h3 className={classes.titleRed}>{language.signup[signUpType].title}</h3>
              <h4 className={classes.subtitleGrey}>{language.signup[signUpType].subtitle}</h4>
              <img id="loginLogo" alt="Unima logo" src="./../../images/Logo_Dxterleft.png" />
            </Row>
            <Row noGutters className="halfImg">
              {renderImgLeft(props)}
            </Row>
          </Col>
          <Col xs={{size:10, offset:2}} sm={{size:8, offset:0}} lg={{size:8}} className="containerForm">
            <Row noGutters>
              <Col xs={{size:10, offset:2}} sm={{size:6, offset:6}} className="selectContainer">
                <FormGroup id="groupLanguage">
                  <Field id="language" name="language" key="language" 
                  classes={classes}
                  options={language_options}
                  value={language_selected}
                  onChange={handleChangeLanguage}

                  label="Select language" 
                  startAdornment={
                    <InputAdornment position="start">
                      <LanguageIcon className="iconLanguage" />
                    </InputAdornment>
                  }
                  component={renderSelectMaterialUi}/>
                </FormGroup>
              </Col>
            </Row>
            <Row noGutters  style={{height: "calc(100% - 65px)"}}>
              <Col xs={{size:12, offset:0}} sm={{size:10}} className={classes.FormBlockSingUp}>
                <PerfectScrollbar style={{minHeight: "100%"}}>
                  { renderFormSignUp(props)}
                </PerfectScrollbar>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row noGutters className={classes.footerContainer}>
          <Col xs={{size:12, offset:0}} className={classes.footer}>
            <FooterNoAuthComponent language={language} backgroundColor="white"></FooterNoAuthComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

SignUpComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,  //Func. donde se haran las acciones del submit.
  goToLogin: PropTypes.func.isRequired,         //Func. que te a la view de login
  signUpType: PropTypes.string.isRequired,      //Variable que dice que tipo de sign up estamos.
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
};

// export default reduxForm({
//   form:'SignUpComponent',
//   enableReinitialize: true
// })(SignUpComponent);

const mapStateToProps = (state, props) => {
  return {
      initialValues: { language: setLanguageSelected(props.language) }
  };
};

let InitializeFromStateForm = reduxForm({
  form: 'SignUpComponent',
  enableReinitialize: true
})(SignUpComponent)

export default connect(mapStateToProps)(InitializeFromStateForm)