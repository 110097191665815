import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { 
    Modal, 
    ModalHeader, 
    ModalBody } from 'reactstrap';
import { 
    //Field, 
    reduxForm } from 'redux-form';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import './styles.css';

/**
 * @param {classes, openModal, handleOpenModal, handleSubmit, modalData, handleCancel, handleChange} props 
 *  - classes -> A hook. Este campo se usa para poner los estilos en los componentes.
 *  - openModal -> boolean .- Variable que dice si el modal esta abierto o cerrado.
 *  - handleOpenModal -> function .- Func que cambia el estado de openContainer, para mostrar o no el modal
 *  - handleSubmit -> function .- Func que será llamada al hacer el submit del form, 
 *  - modalData -> Objetc .-  objeto con todos los datos necesarios para el modal
 *    - title -> string .- Titulo del modal, mostrado en el header
 *    - subtitle -> string .- Subtitulo del modal, mostrado en un <h4> tag abajo del header
 *    - acceptText -> string .- Default:'Aceptar' .- Texto para el boton submit
 *    - cancelText -> string .- Default:'Cancelar' .- Texto para el boton cancelar
 *    - text -> string .- Texto a mostrarse debajo del subtitulo en un <p> tag
 *    - fields -> array .- array object  con toda la información de los inputs a renderizar (id, name, label, type)
 *  - handleCancel -> function .- Func que será llamada al hacer click en cancelar.
 *  - handleChange -> function .- Func que será llamada en el onChange de cada input. 
*/
class DeleteUser extends Component{

    // constructor(props){
    //     super(props);
    // }

    toggle = () => {
        this.props.handleOpenModal();
    }

    render(){
        const { openModal, modalData,
            handleOpenModal, handleCancel, handleSubmit, language } = this.props;
        // const { text, subtitle, acceptText, cancelText} = modalData || {};
        const { text, subtitle, accept_button: acceptText, cancel_button: cancelText } = language.modals.deleteUser;
        return (
            <Modal 
              id="modalDeleteUser" 
              isOpen={openModal} 
              toggle={() => handleOpenModal(modalData.code)} 
              size="md" style={{ height: "auto" }}>
              <ModalHeader toggle={this.toggle}><WarningRoundedIcon className="iconModalText" /></ModalHeader>
              <ModalBody>
                <Grid
                  container 
                  direction="row"
                  alignItems="center"
                  justify="center"
                  style={{ margin: "auto"}} >
                    <Grid item xs={10}>
                        {/* <Grid container item xs={12}>
                            <HighlightOffIcon className="iconModalText" />
                        </Grid> */}
                        <Grid container item xs={12}>
                            <p className="adminModalText">{text}</p>
                        </Grid>
                        <Grid container item xs={12}>
                            <p className="userModalSubtitle">
                                <b>{subtitle}</b>
                            </p>
                        </Grid>
                        <Grid container item xs={12}>
                            <div style={{margin: "auto"}}>
                                <Button id="btnCancelDeleteUser" type="button"
                                    style={{textTransform: "inherit", width: "220px"}}
                                    onClick={handleCancel}
                                    className="btn" >
                                    {cancelText}
                                </Button>
                                <Button id="btnDeleteUser" type="button"
                                    style={{textTransform: "inherit", width: "220px"}}
                                    onClick={handleSubmit}
                                    className="btn btnCancel" >
                                    {acceptText}
                                </Button>
                                
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
              </ModalBody>
          </Modal>
        );
    }
};

DeleteUser.propTypes = {
  handleSubmit: PropTypes.func.isRequired,      //Func. donde se haran las acciones del submit.
  handleOpenModal: PropTypes.func.isRequired,   //Func. para llamar al evento de abrir o cerrar el modal
  handleCancel: PropTypes.func.isRequired,      //Func que será llamada al hacer click en cancelar.
  handleChange: PropTypes.func.isRequired,      //Func que será llamada en el onChange de cada input. 
  openModal: PropTypes.bool.isRequired,         //Nos dice si el modal esta abierto.
  modalData: PropTypes.any.isRequired,
};

DeleteUser.defaultProps  = {
    modalData: {
        acceptText: 'Aceptar',  //Texto para el botón submit
        cancelText: 'Cancelar',  //Texto para el botón cancelar
    }
};

export default reduxForm({
    form: 'ModalForm',
    enableReinitialize: true
  }) (DeleteUser);