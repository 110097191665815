import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import { Button } from '@material-ui/core';

import useStyles from "../../style";
import FooterNoAuthComponent from "../general/Footer/layoutNoAuth";
import PerfectScrollbar from 'react-perfect-scrollbar';
import './styles.css';

const MailNotConfirmedComponent = (props) => {
  const { goToLogin, sendConfirmationMail } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  useEffect(() => {
    setLanguage(props.language);
  }, [props.language]);

  return (
    <div id="contentNotMailConfirmed">
      <Container fluid={true} className={classes.backgroundBody} style={{ padding: "0px" }}>
        <Row noGutters style={{ height: "calc(100% - 70px)", padding: "90px 0px 10px" }}>
          <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} className={classes.NotConfirmedMailContainer}>
            <PerfectScrollbar style={{ minHeight: "100%" }}>
              <Row noGutters>
                <Col xs={12} sm={10} className={classes.NotConfirmedMailBox}>
                  <img id="confirmMailLogo" alt="logo" src="./../../images/logo_dxter.png" />
                  <h3 className={classes.titleRed}>{language.confirmationMail.title}</h3>
                  <h4 className={classes.subtitleGrey}>{language.confirmationMail.subtitle}</h4>
                  <div className="blockInfo">
                    {/* <label >
                        {language.confirmationMail.label_email + email}
                    </label> */}
                    <div className="NotConfirmedMailBlockButtons">
                      <Button id="confirmMailButton"
                        className="btn" type="button"
                        onClick={sendConfirmationMail}
                        style={{ display: "inline-block" }}>
                        {language.confirmationMail.button}
                      </Button>
                      <Button id="cancelButton"
                        className="btn btnCancel" type="button"
                        onClick={goToLogin}>
                        {language.confirmationMail.cancelBtn}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </PerfectScrollbar>
          </Col>
        </Row>
        <Row noGutters className={classes.footerContainer}>
          <Col xs={{ size: 12, offset: 0 }} className={classes.footer}>
            <FooterNoAuthComponent language={language}></FooterNoAuthComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

MailNotConfirmedComponent.propTypes = {
  sendConfirmationMail: PropTypes.func.isRequired,      //Func. donde se haran las acciones del submit de login.
  goToLogin: PropTypes.func.isRequired,          //Func. que te lleva a la url pasada como parametro.
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
};

export default MailNotConfirmedComponent;