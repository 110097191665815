import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row} from 'reactstrap';
import { Button } from '@material-ui/core';
import useStyles from "../../style";
import FooterNoAuthComponent from "../general/Footer/layoutNoAuth";
import PerfectScrollbar from 'react-perfect-scrollbar';
import './styles.css';

const RestrictedPageComponent = (props) => {
  const { goToLogin } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(props.language);

  useEffect(() => {
    setLanguage(props.language);
  }, [props.language]);

  return (
    <div id="contentRestrictedPage">
      <Container fluid={true} className={classes.backgroundBody} style={{padding: "0px"}}>
        <Row noGutters style={{height: "calc(100% - 70px)", padding: "90px 0px 10px"}}>
          <Col xs={12} sm={{size:10, offset:1}} md={{size:8, offset:2}} className={classes.RestrictedPageContainer}>
            <PerfectScrollbar style={{minHeight: "100%"}}>
              <Row noGutters>
                <Col xs={12} sm={10} className={classes.RestrictedPageBox}>
                  <h3>{language.restrictedPage.title}</h3>
                  <h4>{language.restrictedPage.subtitle}</h4>
                  <h6>{language.restrictedPage.text}</h6>
                  <div className="RestrictedPageBlockButtons">
                    <Button id="restrictedButton" 
                      className="btn" type="button" 
                      onClick={goToLogin}
                      style={{display: "inline-block"}}>
                        {language.restrictedPage.button}
                    </Button>
                  </div>
                </Col>
              </Row>
            </PerfectScrollbar>
          </Col>
        </Row>
        <Row noGutters className={classes.footerContainer}>
          <Col xs={{size:12, offset:0}} className={classes.footer}>
            <FooterNoAuthComponent language={language}></FooterNoAuthComponent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

RestrictedPageComponent.propTypes = {
  goToLogin: PropTypes.func.isRequired,         //Func. funcion para regresar al login
  language: PropTypes.object.isRequired,        //Variable donde se guardan las traducciones del lenguaje
};

export default RestrictedPageComponent;