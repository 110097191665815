import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PasswordRecoveryComponent from "components/PasswordRecoveryComponent";
import { renderSnackBarMessage } from 'components/general/commonRenders';
import { setLanguages, updateUser, resetUserPassword } from 'actions';
import { getCurrentUser, getLanguage } from 'selectors';
import { USER_PROFILE_URL, HOME_URL } from 'constants/index';

class PasswordRecoveryContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      snackBarInfo: {
        message: '',
        openSnackBar: false,
        typesnack: 'info'
      },
    }
  }

  componentDidMount(){
    // console.log("this.props", this.props);
    // console.log("token", this.props.match.params.token)
    if(this.props.match.params.token){
      this.setState({
        token: this.props.match.params.token
      })
    }
  }

  /**
   * Method what is called on submit of login, this function call to backEnd and go to dashboard
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * @param {string} values.email - string with user's email
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  handleChangePassword = (values) => {
    // console.log("handleChangePassword", values);

    if(this.state.token){
      this.resetUserPassword(values, this.state.token);
    }else{
      this.updateUserPassword(values);
    }
  }

  /**
   * Método que es llamado cuando el usuario quiere cambiar su contraseña a través de proceso de "forgot-password"
   * para esto es necesario un token obtenido a través del link recibido en el correo electrónico 
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  resetUserPassword = (values, token) => {
    return this.props.resetUserPassword(token, {password: values.password}).then( response => {
        this.successEditUser(response);
    }).catch((error)=>{
        this.errorEditUser(error);
    });
  }

  /**
   * Método que es llamado cuando el usuario quiere cambiar su contraseña
   * 
   * @param {Object} values - Object of pairs key-value given for redux-form
   * 
   * @returns {promise} Promise to be resolved, If it is rejected with an object like new SubmissionError({ field1: 'error', field2: 'error' }) then the submission errors will be added to each field.
  */
  updateUserPassword = (values) => {
    var dataToEdit = {
      currentPassword: values.currentPassword,
      password: values.password,
    }
    return this.props.updateUser(this.props.user._id, dataToEdit).then( response => {
        this.successEditUser(response, this.state.token);
    }).catch((error)=>{
        this.errorEditUser(error);
    });
  }

  successEditUser = (response, token) => {
    // console.log("successEditUser", response);

    // this.setState({
    //   snackBarInfo:{
    //     message: this.props.language.passwordRecovery.success_change_password,
    //     typesnack: 'success',
    //     openSnackBar: true
    //   }
    // });
    console.log("antes del timeout", token);
    setTimeout(() => {
      console.log("en el timeout el token", token);
      if(token){
        this.goToLogin();
      }else{
        this.goToProfile({showSnackbar: true, snackbarMessage: this.props.language.passwordRecovery.success_change_password}); 
      }
    }, 500);
  }

  errorEditUser = (error) => {
    // console.log("successEditUser", error);
    var message = error.key ? this.props.language.errors[error.key] : this.props.language.errors.updateData;
    this.setState({
      snackBarInfo:{ message,
        typesnack: 'error',
        openSnackBar: true
      }
    });
  }

  /**
   * Change the current view to login url
  */
  goToProfile = (data = null) => {
    if(data){
      this.props.history.push(USER_PROFILE_URL, data);
    }else{
      this.props.history.push(USER_PROFILE_URL);
    }
  }

  /**
   * Change the current view to login url
  */
  goToLogin = () => {
    this.props.history.push(HOME_URL);
  }

  render() {
    return (
      <div>
        <PasswordRecoveryComponent 
          language={this.props.language}
          goToProfile={this.goToProfile}
          goToLogin={this.goToLogin}
          token={this.state.token}
          handleChangePassword={this.handleChangePassword} />
        {renderSnackBarMessage(
            this.state.snackBarInfo.openSnackBar, 
            this.state.snackBarInfo.message, 
            this.state.snackBarInfo.typesnack,
            () => this.setState({snackBarInfo:{openSnackBar: false}}),
            4000)
        }
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  language:  getLanguage(state),
  user:      getCurrentUser(state)
  //auth:      getAuthenthication(state)
});


export default withRouter( connect(mapStateToProps, { 
  setLanguages,
  updateUser,
  resetUserPassword,
  // setAuthentication,
  // login
}) (PasswordRecoveryContainer) );