import {createAction} from 'redux-actions';

import {
    CREATE_USER, 
    DELETE_USER, 
    // FETCH_USERS,
    UPDATE_ORGANIZATION_USER,
    FETCH_USERS_ORGANIZATION,
    UPDATE_USER,
    INVITE_USER,
    FETCH_USER,
    CONFIRMATION_USER,
    FORGOT_USER,
    RESET_USER_PASSWORD,
} from 'constants/index';
import {Post, Patch, Get, Delete} from 'api/api';
import {Request} from 'api/request';

const USERS_ENDPOINT = "users";
const USER_ENDPOINT = USERS_ENDPOINT + "/:id";
const USER_INVITE_ENDPOINT = USERS_ENDPOINT + "/invite";
const USER_CONFIRMATION_MAIL_ENDPOINT = USERS_ENDPOINT + "/confirmation-mail";
const FORGOT_PASSWORD_MAIL_ENDPOINT = USERS_ENDPOINT + "/forgot-password";
const RESET_PASSWORD_ENDPOINT = USERS_ENDPOINT + "/reset-password/:token";

var User = new Request(USERS_ENDPOINT);

export const createUser = createAction(CREATE_USER, 
    customer => {
        return Post(User.url(), customer )();
});

export const deleteUser = createAction(DELETE_USER, 
    (id) => {
       return Delete(User.url(USER_ENDPOINT, {id: id}), id)();
   });

// export const fetchUsers = createAction(FETCH_USERS, () => {
//     return Get(User.url());
// });

export const fetchUser = createAction(FETCH_USER, (id) => {
        var user_url = User.replaceEndPointParams(USER_ENDPOINT, {id: id});
        return Get(User.url(user_url));
    }   
);

export const updateUser = createAction(UPDATE_USER, 
    (id, customer) => {
        var edit_user_url = User.replaceEndPointParams(USER_ENDPOINT, {id: id});
        return Patch(User.url(edit_user_url), customer)()
    }   
);

export const inviteUser = createAction(INVITE_USER, 
    (data) => {
        return Post(User.url(USER_INVITE_ENDPOINT), data)()
    }   
);

export const confirmationMail = createAction(CONFIRMATION_USER, 
    (data) => {
        return Post(User.url(USER_CONFIRMATION_MAIL_ENDPOINT), data)()
    }   
);

export const sendForgotPassword = createAction(FORGOT_USER, 
    (data) => {
        return Post(User.url(FORGOT_PASSWORD_MAIL_ENDPOINT), data)()
    }   
);

export const resetUserPassword = createAction(RESET_USER_PASSWORD, 
    (token, data) => {
        var reset_password_url = User.replaceEndPointParams(RESET_PASSWORD_ENDPOINT, {token: token});
        return Patch(User.url(reset_password_url), data)()
    }   
);

export const updateOrganizationUser = createAction(UPDATE_ORGANIZATION_USER, 
    (id, customer) => {
         var edit_user_url = User.replaceEndPointParams(USER_ENDPOINT, {id: id});
        return Patch(User.url(edit_user_url), customer)()
});

export const fetchUsersByOrganization = createAction(FETCH_USERS_ORGANIZATION, 
    (organizationId) => {
      return Get(User.url(USERS_ENDPOINT, null, {organizationId: organizationId}))
});