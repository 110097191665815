import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Switch} from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';

import LoginContainer from 'containers/layoutsNoAuth/LoginContainer';
import ForgotPasswordContainer from 'containers/layoutsNoAuth/ForgotPasswordContainer';
import MailNotConfirmedContainer from 'containers/layoutsNoAuth/MailNotConfirmContainer';
import ExpiredLinkContainer from 'containers/layoutsNoAuth/ExpiredLinkContainer';
import SignUpContainer from 'containers/layoutsNoAuth/SignUpContainer';
import RestrictedPageContainer from 'containers/layoutsNoAuth/RestrictedPage';
import PasswordRecoveryContainer from 'containers/layoutsWithAuth/NoTemplate/PasswordRecoveryContainer';
import EditUserContainer from 'containers/layoutsWithAuth/NoTemplate//EditUser';
import EditOrganizationContainer from 'containers/layoutsWithAuth/NoTemplate/EditOrganization';
import LayoutWithAuthContainer from 'containers/layoutsWithAuth/DashboardTemplate';
import { getLanguage } from 'selectors';
import { setLanguages, setAuthentication } from 'actions';
import { connect } from 'react-redux';
//import { DEFAULT_LANGUAGE } from 'constants/index';
import { getAuthenthication } from './selectors';
import { 
  AUTH_ONLY,  HAS_AUTH, HAS_EDIT_ACCESS, HAS_PASSWORD_ACCESS, 
  HOME_URL, 
  LOGIN_URL,
  NOT_ALLOWED_URL,
  PASSWORD_RECOVERY_URL,
  SIGN_ORGANIZATION_URL,
  SIGN_USER_URL,
  USER_PROFILE_URL,
  ORGANIZATION_PROFILE_URL,
  BASE_WEB_URL,
  CONFIRM_EMAIL_URL,
  EDIT_ORGANIZATION_URL, 
  EDIT_USER_URL, 
  EXPIRED_LINK_URL, 
  FORGOT_PASSWORD_URL,
} from 'constants/index';
import { requireLogin, hasAuthAccess, hasEditAccess, hasPasswordAccess } from 'guards';

import "react-perfect-scrollbar/dist/css/styles.css";

class App extends Component {

  constructor(props){
    super(props);
    var idioma = navigator.language || navigator.userLanguage;
    var defaultLanguage = 'en';
    if(idioma.includes('es')){
      defaultLanguage = 'es';
    }
    if(idioma.includes('en')){
      defaultLanguage = 'en';
    }

    this.language = localStorage.getItem('language') || defaultLanguage;
    this.props.setLanguages(this.language);
    this.props.setAuthentication({
      token: localStorage.getItem('token'),
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : ''
    });
    this.state = {
      token: localStorage.getItem('token')
    }
    // this.token = localStorage.getItem('token');
  }

  componentDidMount(){}

  componentDidUpdate(){
    //console.log("auth", this.props.auth);
    //TODO CHECK AUTHENTHICATION REQUEST
    if(this.props.auth.token !== this.state.token){
      this.setState({
        token: this.props.auth.token
      });
    }
  }

  render() {
    return (
      <Router>
        <div className="App">
        <GuardProvider guards={[requireLogin, hasAuthAccess, hasPasswordAccess, hasEditAccess]}>
          <Switch>
            <GuardedRoute exact path={HOME_URL} component={LoginContainer} 
                meta={{ [HAS_AUTH]: true, data:{token: this.state.token} }}
            />
            <GuardedRoute exact path={LOGIN_URL} component={LoginContainer} 
                meta={{ [HAS_AUTH]: true, data:{token: this.state.token}  }}  
            />
            <GuardedRoute exact path={FORGOT_PASSWORD_URL} component={ForgotPasswordContainer} 
                meta={{ [HAS_AUTH]: true, data:{token: this.state.token} }}
            />
            <GuardedRoute exact path={CONFIRM_EMAIL_URL} component={MailNotConfirmedContainer} 
                meta={{ [HAS_AUTH]: true, data:{token: this.state.token} }}
            />
            <GuardedRoute exact path={EXPIRED_LINK_URL} component={ExpiredLinkContainer} 
                meta={{ [HAS_AUTH]: true, data:{token: this.state.token} }}
            />
            <GuardedRoute exact path={SIGN_USER_URL} component={SignUpContainer} 
                meta={{ [HAS_AUTH]: true, data:{token: this.state.token} }}
            />
            <GuardedRoute exact path={NOT_ALLOWED_URL} component={RestrictedPageContainer} 
                meta={{ [HAS_AUTH]: true, data:{token: this.state.token} }}
            />
            <GuardedRoute exact path={SIGN_ORGANIZATION_URL} component={SignUpContainer} 
                meta={{ [HAS_AUTH]: true, data:{token: this.state.token} }}
            />
            <GuardedRoute path={BASE_WEB_URL} component={LayoutWithAuthContainer} 
                meta={{ [AUTH_ONLY]: true, data:{token: this.state.token} }}
            />
            <GuardedRoute exact path={PASSWORD_RECOVERY_URL} component={PasswordRecoveryContainer} 
                meta={{ [HAS_PASSWORD_ACCESS]: true, accessPage: {USER_PROFILE_URL} }} 
            />
            <GuardedRoute exact path={EDIT_USER_URL} component={EditUserContainer} 
                meta={{ [HAS_EDIT_ACCESS]: true, accessPage: {USER_PROFILE_URL} }} 
            />
            <GuardedRoute exact path={EDIT_ORGANIZATION_URL} component={EditOrganizationContainer} 
                meta={{ [HAS_EDIT_ACCESS]: true, accessPage: {ORGANIZATION_PROFILE_URL} }} 
            />
            <Redirect exact strict  from='/*' to={LOGIN_URL} />
          </Switch>
        </GuardProvider>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  language:  getLanguage(state),
  auth:     getAuthenthication(state)
});

export default connect(mapStateToProps, { 
  setLanguages,
  setAuthentication 
})(App);
