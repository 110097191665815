const es = {
  locale: 'es',

  spanish: 'Español',
  english: 'Inglés',

  select_language: 'Seleccionar idioma',

  footer: {
    title: "© 2021 UNIMA, INC. Todos los derechos reservados.",
  },

  //Login view
  login: {
    language: 'Idioma',
    title: 'Dxter',
    log_in: 'Ingresa para comenzar a gestionar los resultados de las pruebas Find™.',
    email: 'Correo electrónico',
    email_placeholder: 'Ej. nombre@dominio.com',
    password: 'Contraseña',
    password_placeholder: 'Ingresa tu contraseña',
    start: 'Ir a Dxter',
    forgot_password: '¿Olvidaste tu contraseña?',
    signup_link: 'Crea una cuenta',
    signup_label: ' O ',
    organization_link: ' registra tu organización ',
    organization_label: ' como administrador ',
    error_confirmation_1: 'El usuario ya fue confirmado',
    error_confirmation_2: 'Fallo al hacer la confirmación de usuario',
  },

  //Signup view
  signup: {
    passwordTooltip: "<b>La contraseña debe tener al menos: </b><br>-8 caracteres <br>-1 mayúscula (A-Z) <br>-1 minúscula (a-z) <br>-1 número  (0-9)",
    success_register: "Se ha enviado un enlace de verificación. Revisa tu correo para completar el proceso. Si no recibiste el correo, revisa en la carpeta de spam o puedes obtener un nuevo enlace.",
    error_register: "Lo sentimos, hubo un problema mientras se procesaban los datos. Por favor intenta de nuevo.",
    error_duplicated_register: "El correo ingresado ya fue registrado. Revisa el enlace de verificación en tu correo para acceder Dxter",
    user: {
      title: 'Regístrate como usuario',
      subtitle: 'Unidos llegamos más lejos',
      formTitle: 'Completa este formulario para acceder a Dxter',
      userSectionTitle: 'DATOS DE CONTACTO',
      accountSectionTitle: 'DATOS DE CUENTA',
      orgSectionTitle: 'DATOS DE ORGANIZACIÓN',
      personalSectionTitle: 'DATOS DE CUENTA PERSONAL',
      label_name: 'Nombre de contacto',
      input_name: 'Nombre completo',
      label_phone: 'Teléfono de contacto',
      input_phone: '+___  ( ___ )  _____ - ______',
      label_email: 'Correo electrónico de contacto',
      input_email: 'Ej. nombre@dominio.com',
      label_extension: 'Ext.',
      label_optional: 'Opcional',
      input_extension: 'Ej. 8040',
      label_code: 'Código de usuario',
      input_code: '********',
      codeTooltip: 'El código de usuario es proporcionado por el administrador de tu organización.',
      label_password: 'Contraseña',
      input_password: 'Ingresa una contraseña ',
      label_confirm_password: 'Confirmar contraseña',
      input_confirm_password: 'Repite tu contraseña',
      already_logged: ' ¿Ya estas registrado? ',
      link_login: ' Ir a Dxter ',
      submit_btn: 'Registrar',
    },
    organization: {
      title: 'Registra tu organización',
      subtitle: 'Unidos llegamos más lejos',
      formTitle: 'Completa este formulario para registrar tu organización y acceder a Dxter',
      label_name: 'Nombre de contacto',
      input_name: 'Tú nombre completo',
      label_organization: 'Organización',
      input_organization: 'Nombre de tu organización',
      label_code: 'Código llave',
      input_code: '********',
      codeTooltip: 'El código llave debe ser proporcionado por tu proveedor de Find™.',
      label_extension: 'Ext.',
      input_extension: 'Ej. 8040',
      label_phone_contact: 'Tú teléfono de contacto',
      label_phone: 'Número de teléfono de tu organización',
      input_phone: '+___  ( ___ )  _____ - ______',
      label_email: 'Tú correo electrónico de contacto',
      input_email: 'Ej. nombre@dominio.com',
      label_password: 'Contraseña',
      input_password: 'Ingresa una contraseña ',
      label_confirm_password: 'Confirmar contraseña',
      input_confirm_password: 'Repite tu contraseña',
      already_logged: ' ¿Ya tienes un registro? ',
      link_login: ' Ir a Dxter ',
      submit_btn: 'Registrar',
      success_org: 'Organización creada correctamente',
    },
  },

  //Forgot password view
  forgot: {
    title: 'Restablecimiento de contraseña',
    titleRecovery: 'Restablecimiento de contraseña',
    subtitle: 'Ingresa el correo electrónico registrado, te enviaremos instrucciones para establecer una nueva contraseña y recuperar el acceso a Dxter.',
    label_email: 'Correo electrónico',
    input_email: 'Ej. nombre@dominio.com',
    already_logged: ' ¿Ya estas registrado? ',
    button: 'Enviar instrucciones',
    cancelBtn: 'Cancelar',
    success_forgot_password: "Las instrucciones fueron enviadas correctamente al correo ingresado.",
    error_forgot_password: "Hubo un problema mientras se enviaban las instrucciones. Por favor intenta de nuevo más tarde.",
  },

  //Not allowed page view
  restrictedPage: {
    title: 'Lo sentimos,',
    subtitle: 'al parecer no tienes acceso a esta página.',
    text: 'Puedes iniciar sesión con la siguiente opción: ',
    button: 'Ir a iniciar sesión',
  },

  //Password recovery view
  passwordRecovery: {
    title: 'Modificación de contraseña',
    recoveryTitle: 'Restablecimiento de contraseña',
    label_CurrentPassword: 'Contraseña actual',
    input_CurrentPassword: 'Ingresa tu contraseña actual',
    label_NewPassword: 'Nueva contraseña',
    input_NewPassword: 'Ingresa una contraseña ',
    label_ConfirmPassword: 'Confirmar contraseña',
    input_ConfirmPassword: 'Repite tu nueva contraseña',
    button: 'Guardar nueva contraseña',
    btnCancel: 'Cancelar',
    success_forgot_password: "Correo de recuperación de contraseña ha sido enviado",
    success_change_password: "Tu contraseña ha sido actualizada correctamente",
  },

  //Confirm your email view
  confirmationMail: {
    success_send_email: "El correo ha sido enviado.",
    title: "Confirmación de correo electrónico",
    subtitle: "Necesitas confirmar tu cuenta de correo para poder acceder a Dxter. Si no has recibido el correo, haz clic en el botón para recibir un nuevo enlace.",
    label_email: "Correo electrónico: ",
    button: "Recibir nuevo enlace",
    cancelBtn: "Cancelar"
  },

  //expiration Link view
  expirationLink: {
    success_send_email: "El correo ha sido enviado.",
    title: "El enlace de verificación ha expirado",
    subtitle: "Para tener acceso a Dxter™, ingresa el correo electrónico que registraste. Recibirás un nuevo enlace de verificación.",
    label_email: "Correo electrónico: ",
    placeholder_email: "Ej. nombre@dominio.com",
    button: "Enviar nuevo enlace",
    cancelBtn: "Cancelar"
  },

  editUser: {
    title: "Edición de información",
    label_name: "Nombre",
    input_name: "Nombre",
    label_phone: "Número de teléfono",
    input_phone: "+___  ( ___ )  _____ - ______",
    optional: "Opcional",
    label_extension: "Ext.",
    input_extension: "Ej. 8040",
    label_email: "Correo electrónico",
    input_email: "nombre@dominio.com",
    btnAccept: "Guardar cambios",
    btnCancel: "Cancelar",
    successEdit: "Los cambios han sido guardados correctamente.",
  },

  editOrganization: {
    title: "Edición de información",
    label_organization: "Organización",
    input_organization: "Nombre de tu organización",
    label_phone: "Número de teléfono de tu organización",
    input_phone: "+___  ( ___ )  _____ - ______",
    input_email: "Ej. name@email.com",
    label_email: "Correo electrónico de organización",
    btnAccept: "Guardar cambios",
    btnCancel: "Cancelar",
  },

  //Menu (NavBar)
  navbar: {
    dashboard: 'Panel',
    results: 'Resultados',
    link: 'Xplora',
    profile: 'Mi perfil',
    organization: 'Organización',
    logout: 'Salir',
  },

  //DashBoard Section
  dashboard: {
    title: 'Resultados generales de las pruebas',
    date: 'Datos registrados hasta: <date>',
    loader: "Cargando datos",
    loader_graph: "Cargando gráfica",
    pie_title: "Prueba Find™ SARS-CoV-2 ",
    pie_subtitle: "Total de casos: ",
    pie_positive_legend: "<totalCases> resultados detectados",
    pie_negative_legend: "<totalCases> resultados no detectados",
    pie_inderteminate_legend: "<totalCases> resultados inconclusos",
    pie_results: "",
    pie_total: "",
    line_title: ' por sexo y edad',
    line_male: "Hombres",
    line_female: "Mujeres",
    line_hAxis: "Rango de edades",
    line_vAxis: "Número de casos",
    bubble_day: "Día",
    bubble_total: "Casos detectados",
    bubble_title: " por día",
    bubble_vAxis: "Casos detectados",
    bubble_hAxis: "Día",
    bubble_tooltip: "Casos detectados:",
    geo_country: "Country",
    geo_cases: "Positive cases",
    geo_title: " por región",
    title_prefix: "Total de casos detectados",
    empty_title: "Aún no hay datos registrados",
    empty_text: "Invita a que el personal que realiza las pruebas Find™ enlace su aplicación Xplora™ a esta organización para poder accesar a los resultados de todas la pruebas que realizan.",
    share_link: "Compartir código de enlace",
    error_title: "Ocurrió un error inesperado",
    error_text: "Hubo un problema mientras se sincronizaban los datos. Estamos trabajando en solucionarlo. Por favor, regresa más tarde."
  },

  //Profile Section
  profile: {
    title: 'Perfil de usuario',
    loader: "Cargando datos",
    cardProfile_title: "Información de contacto",
    card_label_extension: 'EXTENSÓN: ',
    card_label_name: "NOMBRE: ",
    card_label_org: "ORGANIZACIÓN: ",
    card_label_phone: "TELÉFONO DE CONTACTO: ",
    card_label_email: "CORREO DE CONTACTO: ",
    card_btn_password: "Cambiar contraseña",
    input_name: 'Nombre completo',
    input_phone: '+___  ( ___ )  _____ - ______',
    input_email: 'ejemplo@dominio.com',
    role_user: 'Usuario',
    role_admin: 'Usuario administrador',
    input_code: 'XXXX-XXXX-XXXX',
    cardQr: {
      title: "Datos para enlace de organización",
      subtitle: "Unima S.A. de C.V.",
      code: "CÓDIGO: ",
      url: "URL: ",
      link_txt: "https://qrcodexplora.com",
    }
  },

  //Organization Section
  organization: {
    title: 'Gestión de organización',
    loader: "Cargando datos",
    cardProfile_title: "Información de organización",
    card_name: "NOMBRE DE CONTACTO: ",
    card_organization: "ORGANIZACIÓN: ",
    card_email: "CORREO DE ORGANIZACIÓN: ",
    card_phone: "NO. DE TELÉFONO REGISTRADO: ",
    card_button: "Cambiar contraseña",
    cardList_title: "Usuarios de la organización",
    cardList_Subtitle: "NOMBRE DE USUARIO",
    cardList_Subtitle2: "NIVEL DE ACCESO",
    make_admin: "Hacer administrador",
    make_user: "Hacer usuario",
    delete_user: "Eliminar usuario",
    cardQR_title: "Información para enlace de organización",
    qrTitle: "Unima S.A. de C.V.",
    qrCode_label: "CÓDIGO: ",
    qrUrl_label: "URL: ",
    invite_user_title: "Invitación de acceso a Dxter™",
    invite_user_text: "Ingresa el correo al que se enviará la invitación para acceder como usuario a Dxter.",
    switch_text: "Dar acceso como administrador",
    switch_note: "Activa esta opción solo si deseas invitar a este usuario como administrador. \nUn administrador puede gestionar a todos los usuarios de Dxter.",
    switch_note1: "<b>Activa esta opción solo si deseas <br>invitar a este usuario como <br>administrador.</b><br>Un administrador puede gestionar <br>a todos los usuarios de Dxter.",
    label_email: 'Correo electrónico',
    input_email: 'Ej. nombre@dominio.com',
    input_name: 'Nombre completo',
    input_phone: '+___  ( ___ )  _____ - ______',
    input_organization: 'Nombre de tu organización',
    input_code: 'XXXX-XXXX-XXXX',
    add_mail_button: 'Registrar correo de organización',
    invite_user_button: 'Invitar usuario',
    success_invite_user: 'La invitación ha sido enviada con éxito al correo ingresado.',
    success_delete_user: 'El usuario ha sido eliminado con éxito.',
    error_invite_user: 'Hubo un error inesperado. Por favor intenta más tarde.',
    role_user: 'Usuario',
    role_admin: 'Admin',
    role_mainAdmin: 'Administrador principal',
    tooltip_edit: 'Editar',
    error_load: "Hubo un problema mientras se sincronizaban los datos de usuarios. Estamos trabajando para solucionarlo. Por favor, revisa más tarde.",
    succes_user_role: "El nivel de acceso se cambió correctamente.",
    error_user_role: "Hubo un error inesperado mientras se cambiaba el nivel de acceso. Por favor intenta más tarde.",
  },

  //Link Section
  link: {
    title: "Enlace de Xplora™ a tu organización",
    loader: "Cargando datos",
    introdution: "Para consultar en Dxter los resultados de las pruebas Find™, invita al personal a enlazar Xplora con tu organización.",
    //introdution2: "Xplora™ a esta organización para poder accesar a los resultados de todas la pruebas que realizan.",
    card_organization: "ORGANIZACIÓN: ",
    card_subtitle: "Elige una de las siguientes opciones para compartir el código de enlace: ",
    share_option_1: "Para enlace con código manual",
    share_option_2: "Para enlace con código QR",
    deviceViewBtn: "Gestionar teléfonos enlazados",
    instruction1_1: "Envía el código de  ",
    instruction1_2: " a los usuarios de Xplora.",
    instruction2_1: "En Xplora los usuarios deben acceder a la sección del menú CONFIGURACIÓN DE ORGANIZACIÓN e ingresar el código de manera manual o con el código QR. ",
  },

  //Device Section
  devices: {
    title: "Gestión de teléfonos enlazados",
    col_profileId: 'ID DE USUARIO',
    col_phoneInfo: 'INFO DEL TELÉFONO',
    col_linkDate: 'FECHA DE ENLACE',
    col_lastDate: 'FECHA DE ÚLTIMA PRUEBA',
    col_totalTests: 'PRUEBAS REALIZADAS',
    clean_filters: 'Limpiar filtros',
    phone_filter_txt: 'Teléfono',
    linkDate_filter_txt: ' Fecha de enlace',
    linkDate_from_label: 'Desde:',
    linkDate_to_label: 'Hasta:',
    tests_filter_txt  : 'Pruebas',
    tests_from_label: 'Desde:',
    tests_from_placeholder: 'Ej. 0',
    tests_to_label: ' Hasta:',
    tests_to_placeholder: ' Ej. 250',
    search_filter_txt  : 'Buscar ID de usuario',
    search_filter_tooltip  : 'El Identificador de usuario (ID), se <br>encuentra en la sección del menú <br>CONFIGURACIÓN DE ORGANIZACIÓN <br>de Xplora.',
    option_unlink: 'Desenlazar teléfono',
    empty_last_test: 'no tests',
    empty_phone_filter: "Sin opciones",
  },

  //Profile
  results: {
    title_cards: 'Resultados - vista comparativa',
    title_list: 'Resultados - vista en lista',
    title_individual: 'Resultados - vista individual',
    date: 'Datos registrados hasta: <date>',
    loader: "Cargando datos",
    empty_search_title: "No se encontraron coincidencias",
    empty_search_text: "No hay coincidencia de datos con los filtros usados, intenta con otros.",
    clean_filters: "Limpiar filtros",
    head: {
      search: "Buscar",
      download: "Descargar",
      print: "Imprimir",
      hide_columns: "Columnas",
      filter: "Filtrar",
      view_list: "Vista en lista",
      view_cards: "Vista comparativa",
      view_individual: "Vista individual",
    },
    col_sickness: 'ENFERMEDAD',
    col_qrcode: 'CÓDIGO QR',
    col_date: 'DÍA DE PRUEBA',
    col_result: 'RESULTADO',
    col_gender: 'SEXO',
    col_age: 'EDAD',
    col_region: 'REGIÓN',
    col_userId: 'USER ID',
    cardView: {
      date: "Fecha",
      from: "Desde:",
      to: "Hasta:",
      fromAge: "Desde:",
      toAge: "Hasta:",
      fromPlaceholderAge: "Ej. 18 años",
      toPlaceholderAge: "Ej. 50 años",
      toSuffixAge: " años",
      fromQR: "Del código QR:",
      toQR: "Al código QR:",
      fromPlaceholderQR: "Ej. 000000000000",
      toPlaceholderQR: "Ej. 000000000000",
      map_title: "Ubicación en mapa",
      marker_title: "The marker`s title will appear as a tooltip.",
      result_option1: "Resultado",
      result_option2: "Inconcluso",
      result_option3: "Detectado",
      result_option4: "No detectado",
      gender_option1: "Sexo",
      gender_option2: "Masculino",
      gender_option3: "Femenino",
      disease_option1: "Tipo de test",
      disease_option2: "COVID-19",
      disease_option3: "Tuberculosis (TB)",
      qrCode_option1: "Código QR",
      age_option1: "Edad",
      region_filter: "Región",
      empty_region_filter: "Sin opciones",
    },
    individual: {
      cardInfo_title: 'Detalles de resultados',
      map_title: "Ubicación en mapa",
      marker_title: "The marker`s title will appear as a tooltip.",
    },
    pdf: {
      title: "Lista de resultados",
      subtitle: "Datos registrados hasta: ",
      footer: "Datos recopilados por: ",
      footerData: "<USER> en Dxter™ ",
      page: "Página ",
      pageOf: "de",
      filtersTilte: "Parámetros de resultados enfocados en: ",
      ageTextFilter: " De <STARTAGE> hasta <ENDAGE> años",
      startAgeTextFilter: " Desde <STARTAGE> años",
      endAgeTextFilter: " Hasta <ENDAGE> años",
      dateTextFilter: " De <STARTDATE> a <ENDDATE>",
      startDateTextFilter: " Desde <STARTDATE>",
      endDateTextFilter: " Hasta <ENDDATE>",
      qrTextFilter: " De <STARTQR> a <ENDQR>",
      startQrTextFilter: " Desde <STARTQR>",
      endQrTextFilter: " Hasta <ENDQR>",
    },
    donwload_pdf: "Exportar como PDF",
    loading_pdf: "Cargando...",
    donwload_csv: "Exportar como CSV",
    withoutRegion: "Indefinido"
  },

  ///TABLES  of Users and Registers components
  /*NOTE THIS VALUES ARE FIELD USED IN ALL TABLES*/
  table: {
    search: 'Buscar',
    exportTitle: 'Exportar',
    emptyData: 'No hay datos para mostrar',
    rowSelect: 'filas',
    rowsSelected: 'fila(s) seleccionadas',
    of: 'de',
    actionsName: 'ACCIONES',
  },

  modals: {
    general_success_submit: "Éxito al actualizar...",
    accept_button: "GUARDAR",
    cancel_button: "CANCELAR",
    makeAdmininistrador: {
      text: "Los administradores tienen acceso a la gestión de todos los usuarios de Dxter™ y a la información de la organización.",
      subtitle: "¿Deseas cambiar el nivel de acceso?",
      accept_button: "Si, cambiar el nivel de acceso",
      cancel_button: "No, cancelar acción",
    },
    makeUser: {
      text: "Al cambiar el nivel de acceso de este usuario removerás sus permisos y no podrá invitar a otros usuarios a Dxter™.",
      subtitle: "¿Deseas cambiar el nivel de acceso?",
      accept_button: "Si, cambiar el nivel de acceso",
      cancel_button: "No, cancelar acción",
    },
    inviteAdmin: {
      text: "Estás a punto de invitar a alguien como usuario con  nivel de acceso de administrador.",
      subtitle: "¿Deseas dar acceso de administrador a este usuario?",
      accept_button: "Sí, enviar invitación",
      cancel_button: "No, cancelar invitación",
    },
    deleteUser: {
      text: "Estás a punto de eliminar de forma permamente a este usuario y su acceso a Dxter™.",
      subtitle: "¿Deseas eliminar a este usuario?",
      accept_button: "Si, eliminar el usuario",
      cancel_button: "No, cancelar acción",
    },
    confirmLogout: {
      text: "Estás a punto de salir. Podrás iniciar sesión nuevamente ingresando tus credenciales de usuario. ",
      subtitle: "¿Deseas salir del Dxter™?",
      accept_button: "Sí, salir",
      cancel_button: "No, permanecer en el Dxter",
    },
    confirmUnlinkDevice: {
      text: "Estás a punto de desenlazar el teléfono de un usuario de Xplora en tu organización. ",
      subtitle: "¿Deseas desenlazar este teléfono?",
      accept_button: "Sí, desenlazar teléfono",
      cancel_button: "No desenlazar",
    },
    shareQr: {
      header_title: "Elige una opción para compartir el código de enlace a tu organización",
      donwload: "Descargar",
      copy: "Copiar",
      whatsapp: "Whatsapp",
      email_title: "Otro correo",
      email: "Email",
      share: "Compartir",
      print: "Imprimir",
    }
  },

  //Error messages
  errors: {
    general: "Hubo un error inesperado. Por favor intenta más tarde.",
    required: "Este campo es requerido",
    email: "Por favor, ingresa un correo electrónico válido",
    passwordMatch: "Las contraseñas no coinciden",
    number: "Este campo debe de ser un número",
    missingPermission: "No tienes acceso a esta sección.",
    maxLength: "La contraseña debe tener al menos 8 caracteres",
    notLowercase: "La contraseña debe tener al menos 1 letra minúscula",
    notUppercase: "La contraseña debe tener al menos 1 letra mayúscula",
    notNumber: "La contraseña debe tener al menos 1 número",
    notPhone: "Ingresa una clave internacional y número de teléfono válidos",
    expiredLinkGeneral: "Algo ocurrió mientras se procesaba la verificación. Intenta de nuevo o recibe un nuevo enlace.",
    updateData: "Hubo un error inesperado al guardar los cambios. Por favor intenta de nuevo más tarde.",
    linkData: "Ocurrió un error al cargar el código de enlace para Xplora. Por favor regresa más tarde.",
    codeInvalidOrg: "El código llave ingresado es incorrecto o no existe. Verifica que ingresas un código existente e intenta de nuevo.",
    EMAL001: "El correo o la contraseña son incorrectos o inválidos. Intenta nuevamente.",
    EMAL002: "Error al autenticar al usuario",
    EMAL003: "El código de usuario ingresado es incorrecto o no existe. Verifica que ingresas un código existente e intenta de nuevo.",
    EMAL004: "Ya existe una organización con ese email",
    EMAL005: "No se pudo encontrar el rol de usuario",
    EMAL006: "Error al crear la organización",
    EMAL007: "El código de usuario ingresado es incorrecto o no existe. Verifica que ingresas un código existente e intenta de nuevo.",
    EMAL008: "Ya existe un usuario con ese email",
    EMAL009: "Error al crear el usuario",
    EMAL010: "Error al recuperar los usuarios",
    EMAL011: "Organización no encontrada",
    EMAL012: "Error al recuperar la información de la organización",
    EMAL013: "Error al recuperar los datos de inicio",
    EMAL014: "Usuario no encontrado",
    EMAL015: "Las contraseñas no coinciden",
    EMAL016: "El usuario principal no puede ser degradado",
    EMAL017: "Error al actualizar el usuario",
    EMAL018: "Error al mandar la invitación del usuario",
    EMAL019: "Error al actualizar la compañía",
    EMAL020: "Error al obtener al usuario",
    EMAL021: "Necesitas confirmar tu email para acceder a Dxter",
    EMAL022: "Error al confirmar el usuario",
    EMAL023: "Este correo ya ha sido verificado. Inicia sesión con tus datos registrados.",
    EMAL024: "Necesitas la autorización para esta ruta",
    EMAL025: "Token inválido",
    EMAL026: "Error al resetear la contraseña",
    EMAL027: "Error al desligar el teléfono",
    EMAL028: "El teléfono a desligar no fue encontrado",
  },

  shareText: {
    subject: "<USER> te ha invitado a enlazar tu aplicación Xplora",
    mailBody: "Hola, %0A%0AEsta es una invitación para enlazar tu aplicación Xplora a <ORGANIZATION> %0A%0ACopia el siguiente código: <LINKCODE> %0A%0ADespués pégalo en la sección de “GESTIÓN DE ORGANIZACIÓN” que está dentro del menú de Xplora para comenzar a guardar todos los resultados que obtienes al realizar las pruebas Find. %0A%0A¡Saludos!",
    base: "Código de enlace de la empresa ",
    whatsappText: "Hola, te invito a enlazar tu aplicación Xplora a <ORGANIZATION>. %0D%0DIngresa el código <LINKCODE> en la sección de “GESTIÓN DE ORGANIZACIÓN” que está dentro del menú de Xplora para comenzar a guardar todos los resultados que obtengas al realizar las pruebas Find. %0D%0D¡Saludos!",

  },

  filenames: {
    line: "gráf-sexo-edad-",
    bubble: "gráf-días-",
    geo: "mapa-casos-",
    pie: "gráf-total-casos-",
    pdfList: "resultados-",
    IndividiualView: "resultado-individual-",
    QRCode: "codqr-xplora-",
  }
}

export default es;